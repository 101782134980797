import Layout from "../../components/Layout/Layout";
import { Button, ThemeCard } from "../../tailwind";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { fetchSearchTheme, fetchTheme, fetchThemeReport, setBulkAction, setBulkActionCall } from "../../redux/slice​/themesSlice";
import toast from "react-hot-toast";
import { useDebounce } from "use-debounce";
import { RegisterUser } from "../../http/Apis";
import SearchBar from "../../components/SearchBar";
import { Link } from "react-router-dom";

const Themes = () => {
    const { data, pageInfo, status, themeReport } = useSelector(state => state.themes);
    const dispatch = useDispatch();

    const [user, setUser] = useState({
        name: '',
        email: '',
        password: '',
        phone: '',
    });
    const [loading, setLoading] = useState(false);
    const [itemView, setItemView] = useState(5);
    const [search, setSearch] = useState();
    const [value] = useDebounce(search, 1000);

    useEffect(() => {
        if (value) {
            dispatch(fetchSearchTheme(value));
        }
    }, [value]);

    const [pagination, setPagination] = useState({
        page: 1,
        limit: 8,
    })

    useEffect(() => {
        dispatch(fetchTheme(pagination.page, pagination.limit));
        dispatch(fetchThemeReport())
    }, [pagination]);

    const handleSubmit = async (e) => {
        const { name, email, password, phone } = user;
        // validate
        if (!name || !email || !password || !phone) {
            toast.error('Please fill all fields');
            return;
        }
        // email validate
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            toast.error('Please enter a valid email');
            return;
        }
        // password validate
        if (password.length < 6) {
            toast.error('Password must be at least 6 characters');
            return;
        }
        // phone validate
        if (!/^[0-9]{10}$/.test(phone)) {
            toast.error('Please enter a valid phone number');
            return;
        }
        try {
            setLoading(true);
            const res = await RegisterUser(user);
            if (res.status === 200) {
                toast.success('User created successfully');
                setLoading(false);

            }
        } catch (error) {
            toast.error(error.response.data.message);
            setLoading(false);
        }
    }

    const headlePrev = () => {
        if (pagination.page > 1) {
            setPagination({
                ...pagination,
                page: pagination.page - 1
            })
        } else {
            toast.error("You are on first page")
        }
    }

    const handleNext = () => {
        if (pagination.page < Math.ceil(themeReport.total / pagination.limit)) {
            setPagination({
                ...pagination,
                page: pagination.page + 1
            })
        } else {
            toast.error("No more pages")
        }
    }
    return (
        <Layout>
            <div className='flex justify-between items-center'>
                <div className='flex items-center gap-4'>
                    <div>
                        <h1 className='text-white font-medium text-xl'>Themes</h1>
                        <span className='text-white opacity-50 text-xs tracking-wider'>Your all themes list.</span>
                    </div>
                    <select
                        className="select max-w-xs bg-secondary-color"
                        defaultValue={'none'}
                        onChange={(e) => dispatch(setBulkAction(e.target.value))}>
                        <option value="none">Bulk Actions</option>
                        <option value='Delete'>Delete</option>
                        <option value='Deactivate'>Deactivate</option>
                        <option value='Activate'>Activate</option>
                        <option value='Restore'>Restore</option>
                    </select>
                    <span onClick={() => dispatch(setBulkActionCall(true))} className="bg-secondary-color py-3.5 px-6 rounded-md shadow-md text-sm cursor-pointer hover:bg-secondary-blue duration-100 text-white hover:text-white" >Apply</span>
                    <div className="tooltip" data-tip="Themes Show Limit">
                        <select className="select max-w-xs bg-secondary-color"
                            defaultValue={itemView}
                            onChange={(e) => {
                                setItemView(e.target.value)
                                dispatch(fetchTheme(pagination.page, e.target.value));
                            }}>
                            <option value="8">Show {itemView}</option>
                            <option value="10">Show 10</option>
                            <option value="15">Show 15</option>
                            <option value="20">Show 20</option>
                            <option value="25">Show 25</option>
                        </select>
                    </div>
                </div>
                <div className='flex gap-2 items-center'>
                    <SearchBar
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    <Link to='/admin/themes/create'>
                        <Button
                            title={'+ Add New'}
                            titleStyle={'text-white font-medium'}
                            height={'h-11'}
                        />
                    </Link>

                </div>
            </div>
            <div className="my-5">
                <ThemeCard Data={data} />
            </div>
            <div className='flex justify-between items-center my-4'>
                {
                    data.length > 0 && <div className="stats stats-vertical lg:stats-horizontal shadow bg-secondary-color rounded-md">
                        <div className="stat flex py-3">
                            <div className="stat-title">Total Themes</div>
                            <div className="">{themeReport.total}</div>
                        </div>
                        <div className="stat flex py-3">
                            <div className="stat-title">Deactivate</div>
                            <div className="">
                                {themeReport.Deactivate}
                            </div>
                        </div>
                        <div className="stat flex py-3">
                            <div className="stat-title">Active</div>
                            <div className="">{
                                themeReport.ActiveTheme
                            }</div>
                        </div>
                    </div>
                }
                <div>
                    <div className="btn-group">
                        <button className="btn bg-secondary-color" onClick={headlePrev}>«</button>
                        <button className="btn bg-secondary-color-800">Page {pagination.page}</button>
                        <button className="btn bg-secondary-color" onClick={handleNext} >»</button>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Themes;