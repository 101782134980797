import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';

interface ITabPanelProps {
    children?: React.ReactNode;
    value: number;
    index: number;
    classes?: any;
}

function TabPanel(props: ITabPanelProps): JSX.Element {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const useStyles: any = makeStyles({
    tab: {
        '& .MuiBox-root': {
            padding: '0px',
        },
    },
});

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

interface ITabProps {
    tabs: {
        label: string;
        hide?: boolean;
    }[];
    tabPanels: {
        children: React.ReactNode;
    }[]
    activeTab?: number;
}

export default function BasicTabs({ tabs, tabPanels, activeTab }: ITabProps) {
    const classes: any = useStyles();
    const [value, setValue] = React.useState(activeTab || 0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <>
            {
                tabs && tabs.length > 0 && (
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                {tabs && tabs.map((tab, index) => {
                                    return tab.hide ? null : <Tab label={tab.label} {...a11yProps(index)} />
                                })}
                            </Tabs>
                        </Box>
                        {tabPanels && tabPanels.map((child, index) => (
                            <TabPanel value={value} index={index}
                                classes={{
                                    root: classes.tab
                                }}
                            >
                                {child.children}
                            </TabPanel>
                        ))}
                    </Box>
                )
            }
        </>
    );
}
