import React from 'react'
import Layout from '../../components/Layout/Layout'
import { Button, Input, InputField, TextAreaField, Title } from '../../tailwind'
import toast from 'react-hot-toast'
import { createCast, createCastEndPoint } from '../../http/Apis'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import LoadingButton from '@mui/lab/LoadingButton'

const AddNewCast = () => {
    const [loading, setLoading] = React.useState(false)
    const { darkMode } = useSelector((state: RootState) => state.theme)
    const [castData, setCastData] = React.useState({
        name: '',
        type: 'actor',
        description: '',
    })

    const setNameWithSlug = (name: any) => {
        setCastData({ ...castData, name: name})
    }

    // captureAvatar function
    const [avatar, setAvatar] = React.useState<File | undefined>()
    const [avatarUrl, setAvatarUrl] = React.useState<string>()
    const captureAvatar = (e: any) => {
        const file = e.target.files[0];
        setAvatar(file);
        setAvatarUrl(URL.createObjectURL(file));
    }


    const handleSubmit = async () => {
        const formData = new FormData();
        formData.append('name', castData.name);
        formData.append('type', castData.type);
        formData.append('description', castData.description);
        if (avatar) {
            formData.append('avatar', avatar);
        }

        setLoading(true)
        try {
            const res = await createCastEndPoint(formData)
            if (res.status === 201) {
                toast.success('Cast created successfully')
                setLoading(false)
                setAvatar(undefined)
                setAvatarUrl('')
                setCastData({
                    name: '',
                    type: 'actor',
                    description: '',
                })
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.error?.message || 'Something went wrong')
            setLoading(false)
        }
    }

    return (
        <Layout>
            <Title className="font-medium text-xl mb-5 pr-5">
                Create New Cast
            </Title>

            <div className='grid grid-cols-2 gap-5 pr-5'>
                <div className='flex flex-col gap-4'>
                    <InputField
                        type="text"
                        title="Name"
                        fieldName='name'
                        placeholder="Name"
                        value={castData.name}
                        onChange={(e) => setNameWithSlug(e.target.value)}
                    />
                    <select className={`select w-full bg-transparent border ${darkMode ? 'border-[#343434]' : 'border-gray-200'} rounded`}
                        onChange={(e) => setCastData({ ...castData, type: e.target.value })}>
                        <option value='actor'>Actor</option>
                        <option value='director'>Director</option>
                        <option value='writer'>Writer</option>
                        <option value='producer'>Producer</option>
                    </select>
                    <TextAreaField
                        type="textarea"
                        title="Description"
                        fieldName='description'
                        placeholder="Description"                 
                        value={castData.description}
                        textAreaOnChange={(e) => setCastData({ ...castData, description: e.target.value })}
                    />
                </div>

                <div className={`w-full h-96 bg-transparent border ${darkMode ? 'border-[#343434]' : 'border-gray-200'} rounded overflow-hidden`}>
                    <input
                        onChange={captureAvatar}
                        accept="image/*"
                        id="poster"
                        type="file"
                        name="poster"
                        className='hidden'
                    />
                    <label htmlFor="poster" className="cursor-pointer rounded flex justify-center items-center w-full h-full overflow-hidden">
                        <span className="capitalize">
                            {
                                avatar ? <div>
                                    <img src={avatarUrl} className="w-full h-full object-cover" alt="poster" />
                                </div> : <div>
                                    <h1 className='text-center leading-6 px-5'>
                                        upload avatar image. 500x500px.
                                    </h1>
                                </div>
                            }
                        </span>
                    </label>
                </div>
            </div>



            <div className="flex justify-end mt-5 px-5">
                <LoadingButton
                    onClick={() => handleSubmit()}
                    variant="contained"
                    loading={loading}
                >
                    Submit
                </LoadingButton>
            </div>
        </Layout>
    )
}

export default AddNewCast