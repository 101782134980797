import Layout from "../../components/Layout/Layout"
import { FolderIcon, ImageIcon, LinkIcon, TsIcon, UnknownIcon, VideoFileIcon } from "../../tailwind/Icon";
import AWS from 'aws-sdk';
import { useCallback, useEffect, useMemo, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import DialogContentText from '@mui/material/DialogContentText';
import { Line } from 'rc-progress';
import TextField from '@mui/material/TextField';
import toast from "react-hot-toast";
import HomeIcon from '@mui/icons-material/Home';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import VideoSettingsIcon from '@mui/icons-material/VideoSettings';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
// import ContextMenu from "@agjs/react-right-click-menu";
import { BasicTabs, Breadcrumbs, FullScreenDialog, Hr } from "../../components";
import { Button as TWButton } from "../../tailwind";
import ReactPlayer from 'react-player'

import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';

import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ContentCut from '@mui/icons-material/ContentCut';
import ContentCopy from '@mui/icons-material/ContentCopy';
import ContentPaste from '@mui/icons-material/ContentPaste';
import Cloud from '@mui/icons-material/Cloud';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
    Menu,
    Item,
    Separator,
    Submenu,
    useContextMenu
} from "react-contexify";
import ReactJson from 'react-json-view'
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';

import "react-contexify/dist/ReactContexify.css";
import { createJobNew } from "../../http/Apis";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { List, ListItem } from "@mui/material";
import { Input } from "../../tailwind";
import axios from "axios";
import Base64 from 'base-64'
import { CopyToClipboard } from 'react-copy-to-clipboard';

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 500,
    },
});

const MENU_ID = "menu-id";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props: any) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};


const fileTypes = ["JPG", "PNG", "GIF", "MP4", "MOV"];




const BUCKET_NAME = process.env.REACT_APP_AWS_PRIVATE_BUCKET;

const s3 = new AWS.S3({
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
});

interface IImageIconViewProps {
    file: {
        name: string;
    }
    bucket?: string;
}

const ImageIconView = ({ file, bucket }: IImageIconViewProps) => {
    const ext = file?.name?.split('.').pop();
    const [image, setImage] = useState<string | null>(null);
    function createSignedUrl(file: any) {
        const params = {
            Bucket: bucket ? bucket : BUCKET_NAME,
            Key: file.name,
            Expires: 60 * 60 * 24 * 7
        };
        s3.getSignedUrl('getObject', params, (err, url) => {
            if (err) {
                console.log(err);
            }
            if (url) {
                setImage(url);
            }
        });
    }
    useEffect(() => {
        createSignedUrl(file as any);
    }, [file]);

    return (
        <div className="w-fit h-20 bg-black rounded-md flex items-center justify-center">
            {
                image ? <img
                    src={image ? image : createSignedUrl(file) as any}
                    className="w-full h-full object-cover rounded-md"
                /> : <ImageIcon />
            }
        </div>
    )
}

// check file extension and return icon
interface IFileIconProps {
    name: string;
}
const getFileIcon = (file: IFileIconProps, bucket?: string | undefined) => {
    const ext = file.name.split('.').pop();
    switch (ext) {
        case 'mp4':
            return <VideoFileIcon className="w-16" />
        case 'ts':
            return <TsIcon className="w-16" />
        case 'm3u8':
            return <LinkIcon className="w-16" />
        case 'jpg':
            return <ImageIconView file={file} bucket={bucket} />
        case 'jpeg':
            return <ImageIconView file={file} bucket={bucket} />
        case 'png':
            return <ImageIconView file={file} bucket={bucket} />
        case 'gif':
            return <ImageIconView file={file} bucket={bucket} />
        case 'webp':
            return <ImageIconView file={file} bucket={bucket} />
        default:
            return <UnknownIcon />
    }
}
// check file extension and return true or false
const checkFileExtension = (file: IFileIconProps, types: any) => {
    const ext = file.name.split('.').pop();
    if (types.includes(ext)) {
        return true;
    }
    return false;
}

// kb to mb
const formatBytes = (bytes: any, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

const fetchS3BucketContents = async (bucket: any, prefix: any) => {
    return s3
        .listObjectsV2({
            Bucket: bucket,
            Delimiter: '/',
            Prefix: prefix !== '/' ? prefix : '',
        })
        .promise()
        .then((response) => {
            const chonkyFiles = [];
            const s3Objects = response.Contents;
            const s3Prefixes = response.CommonPrefixes;

            if (s3Objects) {
                chonkyFiles.push(
                    ...s3Objects.map(
                        (object) => ({
                            id: object.Key,
                            name: object.Key,
                            modDate: object.LastModified,
                            size: object.Size,
                        })
                    )
                );
            }

            if (s3Prefixes) {
                chonkyFiles.push(
                    ...s3Prefixes.map(
                        (prefix) => ({
                            id: prefix.Prefix,
                            name: prefix.Prefix,
                            isDir: true,
                        })
                    )
                );
            }
            return chonkyFiles;

        });
};


const FileManager = () => {
    const [error, setError] = useState(null);
    const [folderPrefix, setKeyPrefix] = useState('/');
    const [lastFolderPrefixHistory, setLastFolderPrefixHistory] = useState<string[]>([]);
    const [copied, setCopied] = useState<boolean>(false);
    const [files, setFiles] = useState([]);
    const [bucketName, setBucketName] = useState<string | undefined>(BUCKET_NAME);
    interface ISingleFile {
        id: string;
        name: string;
        size: number;
    }
    // after 5 seconds, set copied to false
    useEffect(() => {
        if (copied) {
            setTimeout(() => {
                setCopied(false);
            }, 5000);
        }
    }, [copied]);

    const [singleFile, setSingleFile] = useState<ISingleFile>({
        id: '',
        name: '',
        size: 0
    });
    const [folderName, setFolderName] = useState('');
    const [loading, setLoading] = useState(false);
    const [Resolution, setResolution] = useState([
        {
            id: 1,
            name: '144p',
            value: '144p',
            selected: true,
            disabled: true
        },
        {
            id: 2,
            name: '240p',
            value: '240p',
            selected: true,
            disabled: true
        },
        {
            id: 3,
            name: '360p',
            value: '360p',
            selected: true,
            disabled: true
        },
        {
            id: 4,
            name: '480p',
            value: '480p',
            selected: true,
            disabled: true
        },
        {
            id: 5,
            name: '720p',
            value: '720p',
            selected: true,
            disabled: true
        },
        {
            id: 6,
            name: '1080p',
            value: '1080p',
            selected: true,
            disabled: true
        },
        {
            id: 7,
            name: '4K',
            value: '2160p',
            selected: false,
            disabled: false
        },
    ]);
    const [inputUrl, setInputUrl] = useState('');
    const [submitData, setSubmitData] = useState({
        priority: 'low',
        input: inputUrl,
        template: 'hls',
        resolutions: Resolution.filter((res) => res.selected).map((res) => res.value).reverse(),
        storage: 's3'
    });

    // if resolution is selected then set it to true
    useEffect(() => {
        setSubmitData({
            ...submitData,
            input: inputUrl,
            resolutions: Resolution.filter((res) => res.selected).map((res) => res.value).reverse()
        })
    }, [Resolution, inputUrl])

    const onSubmitTranscoding = async () => {
        try {
            let newData = {
                priority: submitData.priority,
                input: submitData.input,
                template: submitData.template,
                resolutions: submitData.resolutions.reverse(),
                storage: submitData.storage
            }
            const { data, status } = await axios.post(`${process.env.REACT_APP_ZEZO_CLOUD_TRANSCODER_URL}/v1/jobs`, newData, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Basic " + Base64.encode(`${'admin'}:${'supersecret'}`),
                }
            });
            console.log(data);
            toast.success('Transcoding started successfully');
        } catch (error) {
            console.log(error);
            toast.error('Error starting transcode');
        }
    };

    // transcoding state
    const [transcodingProvider, setTranscodingProvider] = useState<string | null>(null);

    const [fullScreenDialog, setFullScreenDialog] = useState(false);
    const fullScreenDialogOpen = () => {
        setFullScreenDialog(true);
    };
    const handleFullScreenDialogClose = () => {
        setFullScreenDialog(false);
        setTranscodingProvider(null);
    };

    interface IFileProps {
        name: string;
        size: number;
    }

    function DragDrop() {
        const [file, setFile] = useState<IFileProps | null>();
        const [recentFiles, setRecentFiles] = useState([]);
        const [uploadProgress, setUploadProgress] = useState(0);
        // if file available then upload to s3
        useEffect(() => {
            if (file) {
                const s3 = new AWS.S3({
                    region: process.env.REACT_APP_AWS_REGION,
                    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
                    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
                });
                const params = {
                    Bucket: bucketName,
                    // Key: `${folderPrefix}${file ? file.name : ''}`,
                    //Key: `${file ? file.name : ''}`,
                    Key: folderPrefix === '/' ? `${file ? file.name : ''}` : `${folderPrefix}${file ? file.name : ''}`,
                    Body: file
                };
                const uploading = s3.upload(params as any, (err: any, data: any) => {
                    if (err) {
                        console.log(err);
                    }
                    if (data) {
                        console.log(data);
                        setTimeout(() => {
                            handleClose();
                            setFile(null);
                            setUploadProgress(0);
                        }, 1000);
                        fetchS3BucketContents(bucketName, folderPrefix)
                            .then(setFiles as any)
                            .catch((error) => setError(error.message));
                        toast.success('File uploaded successfully');
                    }
                });

                uploading.on('httpUploadProgress', (progress) => {
                    setUploadProgress(Math.round((progress.loaded / progress.total) * 100));
                });
            }
        }, [file]);

        const handleChange = (file: any) => {
            setFile(file);
        };

        return (
            <FileUploader
                handleChange={handleChange}
                name="file"
                children={
                    <div className="flex flex-col w-96 my-5">
                        <div className="border-2 border-gray-500 px-16 py-5 border-dashed rounded flex flex-col items-center justify-between mx-5">
                            <div className="w-32 h-20 rounded-md flex items-center justify-center">
                                <img
                                    src={require('../../assets/img/upload.png')}
                                />
                            </div>
                            <p className="text-sm text-gray-500">Drag and drop your files here</p>
                            <p className="my-1 text-gray-400">OR</p>
                            <button className="bg-blue-500 text-white px-6 py-1.5 rounded-md">Browse</button>
                        </div>
                        <div className="mt-4 mx-5">
                            <p className="text-gray-400">
                                Uploaded file
                            </p>
                            <div>
                                {
                                    file && <div className="flex items-center justify-between my-2">
                                        <div className="flex items-center">
                                            {/* <div className="w-20 h-20 bg-gray-900 rounded-md flex items-center justify-center">
                                                {
                                                    fileTypes.includes(file.type.split('/')[1].toUpperCase()) ? <ImageIconView file={file} /> : <VideoFileIcon />
                                                }
                                            </div> */}
                                            <div className="ml-3">
                                                <p>
                                                    Upload {uploadProgress}%
                                                </p>
                                                <Line percent={uploadProgress} strokeWidth={4}
                                                    strokeColor={uploadProgress === 100 ? '#52c41a' : '#D3D3D3'}
                                                />
                                                <p className="text-sm text-gray-500">{file.name}</p>
                                                <p className="text-xs text-gray-400">{formatBytes(file.size)}</p>
                                            </div>
                                        </div>
                                        <button className="text-blue-500">
                                            remove
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>

                    </div>
                }
                types={fileTypes} />
        );
    }
    // create folder
    const [createFolderOpen, setCreateFolderOpen] = useState(false);

    const handleClickCreateFolderOpen = () => {
        setCreateFolderOpen(true);
    };
    const handleCreateFolderClose = () => {
        setCreateFolderOpen(false);
    };

    const folderChain = useMemo(() => {
        let folderChain: any = [];
        if (folderPrefix === '/') {
            folderChain = [];
        } else {
            let currentPrefix: string = '';
            folderChain = folderPrefix
                .replace(/\/*$/, '')
                .split('/')
                .map(
                    (prefixPart) => {
                        currentPrefix = currentPrefix
                            ? (currentPrefix as any, prefixPart)
                            : prefixPart;
                        return {
                            id: currentPrefix,
                            name: prefixPart,
                            isDir: true,
                        };
                    }
                );
        }
        folderChain.unshift({
            id: '/',
            name: bucketName,
            isDir: true,
        });
        return folderChain;
    }, [folderPrefix]);
    // open file details dialog
    const [detailsOpen, setDetailsOpen] = useState(false);
    const handleClickDetailsOpen = () => {
        setDetailsOpen(true);
    };
    const handleDetailsClose = () => {
        setDetailsOpen(false);
    };

    // handle double click on file
    const handleClick = (e: any, item: any) => {
        if (e.detail === 1) {
        }
        if (e.detail === 2) {
            if (item.isDir) {
                setKeyPrefix(item.id);
            } else {
                handleClickDetailsOpen();
                setSingleFile(item);
            }
        }
    }

    useEffect(() => {
        fetchS3BucketContents(bucketName, folderPrefix)
            .then(setFiles as any)
            .catch((error) => setError(error.message));
    }, [folderPrefix, setFiles, bucketName]);

    // create folder
    const createFolder = () => {
        if (folderName === 'undefined' || folderName === 'null' || folderName === 'NaN' || folderName === '/') return toast.error('Folder name is required');
        if (folderName !== '') {
            const params = {
                Bucket: bucketName,
                Key: folderPrefix === '/' ? `${folderName}/` : `${folderPrefix}${folderName}/`,

            };
            s3.putObject(params as any, (err, data) => {
                if (err) {
                    console.log(err);
                    alert(JSON.stringify(err));
                    toast.error('Error creating folder');
                }
                if (data) {
                    console.log(data);
                    setFolderName('');
                    handleCreateFolderClose();
                    fetchS3BucketContents(bucketName, folderPrefix)
                        .then(setFiles as any)
                        .catch((error) => setError(error.message));
                    toast.success('Folder created successfully');
                }
            });
        } else {
            toast.error('Folder name is required');
        }
    }
    // delete file
    const deleteFile = (file: any) => {
        const params = {
            Bucket: bucketName,
            Key: file.id
        };
        s3.deleteObject(params as any, (err, data) => {
            if (err) {
                console.log(err);
                toast.error('Error deleting file');
            }
            if (data) {
                console.log(data);
                fetchS3BucketContents(bucketName, folderPrefix)
                    .then(setFiles as any)
                    .catch((error) => setError(error.message));
                toast.success('File deleted successfully');
            }
        });
    }
    // rename file
    const renameFile = (file: any, newName: any) => {
        const params = {
            Bucket: bucketName,
            CopySource: `${bucketName}/${file.id}`,
            Key: newName
        };
        s3.copyObject(params as any, (err, data) => {
            if (err) {
                console.log(err);
                toast.error('Error renaming file');
            }
            if (data) {
                console.log(data);
                deleteFile(file);
                fetchS3BucketContents(bucketName, folderPrefix)
                    .then(setFiles as any)
                    .catch((error) => setError(error.message));
                toast.success('File renamed successfully');
            }
        });
    }
    // copy file url
    const copyFileUrl = (file: any) => {
        const params = {
            Bucket: bucketName,
            Key: file.id
        };
        s3.getSignedUrl('getObject', params, (err, url) => {
            if (err) {
                console.log(err);
                toast.error('Error copying file url');
            }
            if (url) {
                console.log(url);
                navigator.clipboard.writeText(url);
                toast.success('File url copied successfully');
            }
        });
    }
    // download file
    const downloadFile = (file: any) => {
        const params = {
            Bucket: bucketName,
            Key: file.id
        };
        s3.getObject(params as any, (err, data) => {
            if (err) {
                console.log(err);
                toast.error('Error downloading file');
            }
            if (data) {
                console.log(data);
                const blob = new Blob([data.Body] as any, { type: data.ContentType });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', file.name);
                document.body.appendChild(link);
                link.click();
                if (link.parentNode)
                    link.parentNode.removeChild(link);
                toast.success('File downloaded successfully');
            }
        });
    }

    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [selectedImage, setSelectedImage] = useState(null);
    // generate signed url
    function createSignedUrl(file: any) {
        // now expires time based on file size
        const expiresTime = Math.round(file.size / 1000000) * 60; // 1MB = 1 minute
        const params = {
            Bucket: bucketName,
            Key: file.name,
            Expires: expiresTime
        };
        s3.getSignedUrl('getObject', params, (err, url) => {
            if (err) {
                console.log(err);
            }
            if (url) {
                setSelectedImage(url as any);
                setInputUrl(url);
            }
        });
    }
    useEffect(() => {
        if (singleFile) {
            createSignedUrl(singleFile);
        }
    }, [singleFile]);
    // handle refresh function
    const refreshFiles = useCallback(() => {
        fetchS3BucketContents(bucketName, folderPrefix)
            .then(setFiles as any)
            .catch((error) => setError(error.message));
    }, [folderPrefix, setFiles]);

    // 🔥 you can use this hook from everywhere. All you need is the menu id
    const { show } = useContextMenu({
        id: MENU_ID
    });
    // function handleItemClick({ event, props, triggerEvent, data }) {
    //     // console.log(event, props, triggerEvent, data);
    // }
    function displayMenu(e: any, item: any) {
        e.preventDefault();
        setSingleFile(item);
        // put whatever custom logic you need
        // you can even decide to not display the Menu
        show(e);
    }

    // onclick back button
    const handleBack = () => {
        // prefix data split by '/' create array with index
        const prefix = folderPrefix.split('/').filter((item: any) => item !== '')
        // save lastFolderNameFrefixHistory
        setLastFolderPrefixHistory(prefix);
        // remove last item from array
        prefix.pop();
        // join array with '/'
        const newPrefix = prefix.join('/');
        // set new prefix
        setKeyPrefix(newPrefix);
    }

    // start trascodeing
    const startTranscode = async (file: any) => {
        // remove file extension
        fullScreenDialogOpen();
        createSignedUrl(file);

        const uniqueFileName = file.name.split('.')[0] + new Date().getTime();
        // try {
        //     const data = {
        //         detination: `output/${uniqueFileName}`,
        //         key: `${file.name}`,
        //     }
        //     const response = await createJobNew(data);
        //     console.log(response);
        //     if (response.status === 201) {
        //         toast.success('Transcode started successfully');
        //     }
        // } catch (err) {
        //     console.log(err);
        //     toast.error('Error starting transcode');
        // }
    };


    // get file name and folder name
    const getFileName = (item: any) => {
        if (item.isDir) {
            // remore last slash
            const folderName = item.id.slice(0, -1);
            const folderNameArray = folderName.split('/');
            return folderNameArray[folderNameArray.length - 1];
            return item.id.slice(0, -1);
        } else {
            const fileName = item.name.split('/');
            return fileName[fileName.length - 1];
        }
    }

    return (
        <Layout>
            <div className="h-full">
                <div>
                    <div className="flex justify-between items-center pr-5">
                        <div className="flex items-center gap-3">
                            <IconButton
                                onClick={handleBack}
                                disabled={folderPrefix === '/' || folderPrefix === ''}
                                color="primary" component="label">
                                <ArrowBackIcon />
                            </IconButton>
                        </div>
                        <h1 className="font-medium w-[30rem] border px-4 rounded border-gray-700 py-2">
                            <input
                                type="text"
                                value={`${folderPrefix}`}
                                className="w-full outline-none bg-transparent"
                                onChange={(e) => {
                                    setKeyPrefix(e.target.value)
                                }}
                            />
                        </h1>
                        {/* <input
                            type="text"
                            value={bucketName}
                            className="w-full outline-none bg-transparent"
                            onChange={(e) => {
                                setBucketName(e.target.value)
                            }}
                        /> */}

                        <div className="flex gap-4">

                            <CustomWidthTooltip title="Create Folder" placement="top">
                                <IconButton
                                    onClick={handleClickCreateFolderOpen}
                                    color="primary" component="label">
                                    <CreateNewFolderIcon />
                                </IconButton>
                            </CustomWidthTooltip>

                            <CustomWidthTooltip title="Refresh" placement="top">
                                <IconButton
                                    onClick={refreshFiles}
                                    color="primary" component="label">
                                    <RefreshIcon />
                                </IconButton>
                            </CustomWidthTooltip>

                            <CustomWidthTooltip title="Upload" placement="top">
                                <IconButton
                                    onClick={handleClickOpen}
                                    color="primary" component="label">
                                    <DriveFolderUploadIcon />
                                </IconButton>
                            </CustomWidthTooltip>
                        </div>

                    </div>
                    <div className="mt-5">
                        <Breadcrumbs
                            onClick={(e: any) => {
                                setKeyPrefix('/')
                            }}
                            data={folderPrefix} />
                    </div>
                    {
                        files.length > 0 ? <div className="pr-5 my-5 w-full">
                            <div className="grid grid-cols-12 gap-5 justify-center items-center">
                                {
                                    files.map((item: any, index) => (
                                        <CustomWidthTooltip placement="top" title={getFileName(item)}>
                                            <div
                                                onContextMenu={(e) => displayMenu(e, item)}
                                                onClick={(e) => handleClick(e, item)}
                                                key={index}
                                                className="flex flex-col justify-center items-center w-full">
                                                <div>
                                                    {
                                                        item.isDir ? <FolderIcon className="w-16" /> : getFileIcon(item, bucketName)
                                                    }
                                                </div>

                                                <div className="text-gray-400 font-normal text-xs mt-2 line-clamp-1 w-20 text-center">
                                                    {
                                                        getFileName(item)
                                                    }
                                                </div>

                                            </div>
                                        </CustomWidthTooltip>
                                    )).reverse()
                                }
                            </div>
                        </div> : <div className="flex justify-center items-center h-full">
                            <div className="flex flex-col items-center justify-center">
                                <p className="text-gray-400 font-medium text-lg mt-72">
                                    Loading...
                                </p>
                            </div>
                        </div>
                    }

                </div>
                <div>
                    <BootstrapDialog
                        onClose={handleClose}
                        aria-labelledby="customized-dialog-title"
                        open={open}
                    >
                        <div className="flex flex-col justify-center items-center my-5">
                            <p className="uppercase font-semibold text-xl text-gray-300">
                                Upload Files
                            </p>
                            <p className="text-xs mt-1 w-72 text-center text-gray-400 tracking-wider">
                                Upload files to your cloud storage and access them anywhere.
                            </p>
                        </div>
                        <div>
                            <DragDrop />
                        </div>
                        <DialogActions>
                            <Button autoFocus onClick={handleClose}>
                                Close
                            </Button>
                        </DialogActions>
                    </BootstrapDialog>
                </div>
                <div>
                    <Dialog
                        open={detailsOpen}
                        onClose={handleDetailsClose}
                        aria-labelledby="scroll-dialog-title"
                        aria-describedby="scroll-dialog-description"
                    >
                        <DialogTitle id="scroll-dialog-title">
                            <div className="flex justify-between items-center">
                                <h1 className="font-medium text-2xl">
                                    File Details
                                </h1>
                            </div>
                        </DialogTitle>
                        <DialogContent>
                            {
                                singleFile && <div>
                                    <div className="mb-2">
                                        {
                                            checkFileExtension(singleFile, ["mp4", "m3u8"]) && (<>
                                                {
                                                    <ReactPlayer
                                                        className="w-full"
                                                        width={'100%'}
                                                        url={selectedImage || ''}
                                                        controls
                                                    />
                                                }
                                            </>)
                                        }
                                        {
                                            checkFileExtension(singleFile, ["jpg", "jpeg", "png", "gif", "webp"]) && (<>
                                                <img
                                                    className="w-full"
                                                    src={selectedImage || ''}
                                                />
                                            </>)
                                        }

                                        <p className="my-2">
                                            {`https://${bucketName}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${singleFile?.name || ''}`} <span className="uppercase cursor-pointer text-blue-400"><CopyToClipboard text={`https://${bucketName}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${singleFile?.name || ''}`}>
                                                <button onClick={() => setCopied(true)}>
                                                    {copied ? 'Copied' : 'Copy'}
                                                </button>
                                            </CopyToClipboard></span>
                                        </p>

                                        <p>
                                            {formatBytes(singleFile.size)}
                                        </p>
                                    </div>
                                </div>
                            }

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleDetailsClose}>Cancel</Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog open={createFolderOpen} onClose={handleCreateFolderClose}>
                        <DialogTitle>
                            Create Folder
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                To create a folder, please enter the folder name here.
                            </DialogContentText>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Folder Name"
                                type="text"
                                fullWidth
                                value={folderName}
                                onChange={(e) => setFolderName(e.target.value)}
                                variant="standard"
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCreateFolderClose}>Cancel</Button>
                            <Button onClick={createFolder}>
                                Create
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>

            <Menu
                style={{
                    width: 200,
                    backgroundColor: 'transparent',
                }}
                id={MENU_ID}>
                <Paper sx={{ width: 320, maxWidth: '100%' }}>
                    <MenuList>
                        <MenuItem onClick={() => setKeyPrefix(singleFile.id)}>
                            <ListItemIcon>
                                <FolderOpenIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>
                                Open
                            </ListItemText>
                        </MenuItem>
                        <MenuItem onClick={() => deleteFile(singleFile)}>
                            <ListItemIcon>
                                <DeleteIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>
                                Delete
                            </ListItemText>
                        </MenuItem>
                        <MenuItem onClick={() => {
                            toast.error("Renaming is not supported yet.")
                        }}>
                            <ListItemIcon>
                                <DriveFileRenameOutlineOutlinedIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>
                                Rename
                            </ListItemText>
                        </MenuItem>
                        {
                            checkFileExtension(singleFile, ["jpg", "jpeg", "png", "gif", "mp4", "mov"]) && (<>
                                <MenuItem onClick={() => copyFileUrl(singleFile)}>
                                    <ListItemIcon>
                                        <LinkOutlinedIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>
                                        Copy URL
                                    </ListItemText>
                                </MenuItem>
                                <MenuItem onClick={() => downloadFile(singleFile)}>
                                    <ListItemIcon>
                                        <FileDownloadOutlinedIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>
                                        Download
                                    </ListItemText>
                                </MenuItem>
                            </>)
                        }
                        {
                            checkFileExtension(singleFile, ["mp4"]) && (<>
                                <MenuItem onClick={() => startTranscode(singleFile)}>
                                    <ListItemIcon>
                                        <VideoSettingsIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>
                                        Video Transcoding
                                    </ListItemText>
                                </MenuItem>
                            </>)
                        }
                        <MenuItem onClick={() => {
                            handleClickDetailsOpen();
                        }}>
                            <ListItemIcon>
                                <InfoOutlinedIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>
                                Details
                            </ListItemText>
                        </MenuItem>
                    </MenuList>
                </Paper>
            </Menu>

            <FullScreenDialog
                title="Video Transcoding"
                open={fullScreenDialog}
                onClose={handleFullScreenDialogClose}>
                <div>
                    {
                        transcodingProvider ? <div className="px-7 my-5">
                            <div className="flex items-center">
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={() => setTranscodingProvider(null)}
                                    aria-label="close"
                                >
                                    <ArrowBackIcon />
                                </IconButton>
                                <Typography variant="h6" component="div" sx={{ flexGrow: 1, textTransform: 'capitalize', marginLeft: 1 }}>
                                    {transcodingProvider} Transcoding Provider
                                </Typography>
                            </div>
                            <div className="my-20">
                                {
                                    transcodingProvider === "aws" && <div>
                                        aws hello {singleFile.name}
                                    </div>
                                }
                                {
                                    transcodingProvider === "zezo" && <div className="flex flex-col justify-center items-center">
                                        <h1 className="text-2xl mb-10">
                                            Zezo Cloud Transcoding
                                        </h1>
                                        <div className="w-11/12 2xl:w-2/4">
                                            <BasicTabs
                                                tabs={[
                                                    {
                                                        label: "url"
                                                    },
                                                    {
                                                        label: "upload"
                                                    }
                                                ]}
                                                tabPanels={[
                                                    {
                                                        children: <div className="pt-2">
                                                            {/* <Input
                                                               
                                                                requiredTitle
                                                                style="border px-5 py-2.5 rounded border-gray-600 mt-3 w-full"
                                                                placeholder="Enter video url"
                                                                value={inputUrl}
                                                                //onChange={(e) => setInputUrl(e.target.value)}
                                                                className="w-full border border-white rounded-md"
                                                            /> */}

                                                            <div className="grid grid-cols-3 my-10">
                                                                <div className="col-span-1">
                                                                    <div className="grid grid-cols-2 gap-5">
                                                                        <div>
                                                                            <h1 className="opacity-80 mb-1">Format</h1>
                                                                            <select className="select font-normal w-full max-w-xs bg-transparent border border-gray-600 rounded">
                                                                                <option selected>HLS</option>
                                                                                {/* <option>DASH</option> */}
                                                                            </select>
                                                                            <h1 className="opacity-80 mb-1 mt-4">Codec</h1>
                                                                            <select className="select font-normal w-full max-w-xs bg-transparent border border-gray-600 rounded">
                                                                                <option selected>H.264</option>
                                                                                {/* <option>DASH</option> */}
                                                                            </select>
                                                                        </div>
                                                                        <div>
                                                                            <h1 className="opacity-80 mb-1">Resolution</h1>
                                                                            <div className="bg-dark-color-box rounded w-fit">
                                                                                {
                                                                                    Resolution && Resolution.map((singleResolution, index) => {
                                                                                        return (
                                                                                            <div key={index} className="">
                                                                                                <div className="flex items-center py-1 px-3">
                                                                                                    <div className="form-control">
                                                                                                        <label className="cursor-pointer label">
                                                                                                            <input
                                                                                                                type="checkbox"
                                                                                                                checked={singleResolution.selected}
                                                                                                                disabled={singleResolution.disabled}
                                                                                                                onChange={(e) => {
                                                                                                                    const newResolution = [...Resolution];
                                                                                                                    newResolution[index].selected = e.target.checked;
                                                                                                                    setResolution(newResolution);
                                                                                                                }}
                                                                                                                className="checkbox checkbox-secondary" />
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <span className="ml-2">{singleResolution.name}</span>
                                                                                                </div>
                                                                                                <Hr />
                                                                                            </div>
                                                                                        )
                                                                                    }).reverse()
                                                                                }

                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-span-2">
                                                                    <h1 className="opacity-80 mb-1">Your JSON</h1>
                                                                    <div className="p-5 bg-dark-color-box">
                                                                        <ReactJson src={submitData}
                                                                            theme="monokai"
                                                                            style={{ backgroundColor: "#282828" }}
                                                                            collapsed={false}
                                                                            enableClipboard={false}
                                                                            displayObjectSize={false}
                                                                            displayDataTypes={false}
                                                                        />
                                                                    </div>

                                                                    <div className="my-4">
                                                                        <TWButton
                                                                            title={"Start Transcoding"}
                                                                            onClick={onSubmitTranscoding}

                                                                            height="h-12"
                                                                            loading={loading}
                                                                        />

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    },
                                                    {
                                                        children: <div className="flex justify-center items-center mt-10">
                                                            Upload file service is not available yet.
                                                        </div>
                                                    }
                                                ]}

                                            />
                                        </div>
                                    </div>
                                }
                            </div>

                        </div> : <List>
                            <ListItem button onClick={() => {
                                const timeOut = setTimeout(() => {
                                    setTranscodingProvider("aws")
                                }, 200)

                                return () => {
                                    clearTimeout(timeOut)
                                }
                            }}>
                                <ListItemText
                                    primary="AWS MediaConvert"
                                    secondary="Transcode your video to a different format. This is a paid service."
                                />
                            </ListItem>
                            {
                                process.env.REACT_APP_ZEZO_CLOUD_TRANSCODER && <>
                                    <Divider />
                                    <ListItem button onClick={() => {
                                        const timeOut = setTimeout(() => {
                                            setTranscodingProvider("zezo")
                                        }, 200)

                                        return () => {
                                            clearTimeout(timeOut)
                                        }
                                    }}>
                                        <ListItemText
                                            primary="Zezo Cloud Transcoder"
                                            secondary="Transcode your video to a different format. This is a paid service."
                                        />
                                    </ListItem>
                                </>
                            }
                        </List>
                    }

                </div>
            </FullScreenDialog>

            {/* {
                menuRef && <ContextMenu
                    isOpenAfterInteraction={false}
                    trigger={menuRef && menuRef.current}
                    component={
                        <div>
                            <Paper sx={{ width: 320, maxWidth: '100%' }}>
                                <MenuList>
                                    <MenuItem>
                                        <ListItemIcon>
                                            <ContentCut fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>Cut</ListItemText>
                                        <Typography variant="body2" color="text.secondary">
                                            ⌘X
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem>
                                        <ListItemIcon>
                                            <ContentCopy fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>Copy</ListItemText>
                                        <Typography variant="body2" color="text.secondary">
                                            ⌘C
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem>
                                        <ListItemIcon>
                                            <ContentPaste fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>Paste</ListItemText>
                                        <Typography variant="body2" color="text.secondary">
                                            ⌘V
                                        </Typography>
                                    </MenuItem>
                                    <Divider />
                                    <MenuItem>
                                        <ListItemIcon>
                                            <Cloud fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>Web Clipboard</ListItemText>
                                    </MenuItem>
                                </MenuList>
                            </Paper>
                        </div>
                    }
                    isOpen={isMenuShown}
                    setIsOpen={setIsMenuShown}
                />
            } */}
        </Layout>
    )
}

export default FileManager;














// import Layout from "../../components/Layout/Layout"
// import AWS from 'aws-sdk';
// import {
//     ChonkyActions,
//     ChonkyFileActionData,
//     FileArray,
//     FileBrowser,
//     FileData,
//     FileList,
//     FileNavbar,
//     FileToolbar,
//     setChonkyDefaults,
// } from 'chonky';
// import { ChonkyIconFA } from 'chonky-icon-fontawesome';
// // import path from 'path';
// import React, { useCallback, useEffect, useState } from 'react';

// setChonkyDefaults({ iconComponent: ChonkyIconFA });

// // The AWS credentials below only have read-only access to the Chonky demo bucket.
// // You will need to create custom credentials for your bucket.
// const BUCKET_NAME = 'zezo'
// const BUCKET_REGION = process.env.AWS_REGION;
// const ACCESS_KEY_ID = 'AKIAQG22GSK3ZI4W3Q3T';
// const SECRET_ACCESS_KEY = 'R6pUpu8BEf/4rJy5fgK6PJLvG07b8NLyucuSpSUK';

// // AWS.config.update({
// //     apiVersion: "2010-12-01",
// //     region: process.env.AWS_REGION,
// //     credentials: {
// //         accessKeyId: process.env.AWS_ACCESS_KEY_ID,
// //         secretAccessKey: process.env.AWS_SECRET_ACCESS_KEY,
// //     },
// //     // accessKeyId: process.env.AWS_ACCESS_KEY_ID,
// //     // secretAccessKey: process.env.AWS_SECRET_ACCESS_KEY,
// // });
// // const s3 = new AWS.S3();

// const s3 = new AWS.S3({
//     region: 'ap-south-1',
//     accessKeyId: 'AKIAWO3GNAD5ABWWXKN5',
//     secretAccessKey: '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'
// });

// const fetchS3BucketContents = (bucket, prefix) => {
//     return s3
//         .listObjectsV2({
//             Bucket: bucket,
//             Delimiter: '/',
//             Prefix: prefix !== '/' ? prefix : '',
//         })
//         .promise()
//         .then((response) => {
//             const chonkyFiles = [];
//             const s3Objects = response.Contents;
//             const s3Prefixes = response.CommonPrefixes;

//             if (s3Objects) {
//                 chonkyFiles.push(
//                     ...s3Objects.map(
//                         (object) => ({
//                             id: object.Key,
//                             name: object.Key,
//                             modDate: object.LastModified,
//                             size: object.Size,
//                         })
//                     )
//                 );
//             }

//             if (s3Prefixes) {
//                 chonkyFiles.push(
//                     ...s3Prefixes.map(
//                         (prefix) => ({
//                             id: prefix.Prefix,
//                             name: prefix.Prefix,
//                             isDir: true,
//                         })
//                     )
//                 );
//             }

//             return chonkyFiles;
//         });
// };

// const storyName = 'AWS S3 Browser';

// const FileManager = () => {
//     const [error, setError] = useState(null);
//     const [folderPrefix, setKeyPrefix] = useState('/');
//     const [files, setFiles] = useState([]);

//     useEffect(() => {
//         fetchS3BucketContents(BUCKET_NAME, folderPrefix)
//             .then(setFiles)
//             .catch((error) => setError(error.message));
//     }, [folderPrefix, setFiles]);

//     const folderChain = React.useMemo(() => {
//         let folderChain;
//         if (folderPrefix === '/') {
//             folderChain = [];
//         } else {
//             let currentPrefix = '';
//             folderChain = folderPrefix
//                 .replace(/\/*$/, '')
//                 .split('/')
//                 .map(
//                     (prefixPart) => {
//                         currentPrefix = currentPrefix
//                             ? (currentPrefix, prefixPart)
//                             : prefixPart;
//                         return {
//                             id: currentPrefix,
//                             name: prefixPart,
//                             isDir: true,
//                         };
//                     }
//                 );
//         }
//         folderChain.unshift({
//             id: '/',
//             name: BUCKET_NAME,
//             isDir: true,
//         });
//         return folderChain;
//     }, [folderPrefix]);

//     const handleFileAction = useCallback(
//         (data) => {
//             if (data.id === ChonkyActions.OpenFiles.id) {
//                 if (data.payload.files && data.payload.files.length !== 1) return;
//                 if (!data.payload.targetFile || !data.payload.targetFile.isDir) return;

//                 const newPrefix = `${data.payload.targetFile.id.replace(/\/*$/, '')}/`;
//                 console.log(`Key prefix: ${newPrefix}`);
//                 setKeyPrefix(newPrefix);
//             }
//         },
//         [setKeyPrefix]
//     );

//     return (
//         <Layout>
//             <div className="px-5">
//                 <div className="">
//                     <h1 className="story-title">{storyName}</h1>
//                     {error && (
//                         <div className="story-error">
//                             An error has occurred while loading bucket:{' '}
//                             <strong>{error}</strong>
//                         </div>
//                     )}
//                 </div>
//                 <div className="h-screen">
//                     <FileBrowser
//                         instanceId={storyName}
//                         files={files}
//                         folderChain={folderChain}
//                         onFileAction={handleFileAction}
//                     >
//                         <FileNavbar />
//                         <FileToolbar />
//                         <FileList />
//                     </FileBrowser>
//                 </div>
//             </div>
//         </Layout>
//     );
// }

// export default FileManager