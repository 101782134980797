import { createSlice } from '@reduxjs/toolkit'
import { getUsers, getUserReport, searchUser, getUser } from '../../http/Apis'

export interface IUsers {
    users: {
        id: string
        name: string
        email: string
        phone: string
        lastLogin: string
        role: string
        avatar: string
        verified: boolean
        isActive: boolean
        isDeleted: boolean
        createdAt: string
        updatedAt: string
    }[]
    meta: {
        pagination: {
            page: number,
            pageSize: number,
            pageCount: number,
            total: number,
        },
        report: {
            total: number,
            ActiveUsers: number,
            Deactivate: number,
            inTrash: number,
        }
    }
    status: 'idle' | 'seccess' | 'failed' | 'loading'
    bulkAction: string
    bulkActionCall: boolean,
    singleUser: {
        name: string,
        address: string,
        city: string,
        state: string,
        zip: string,
        birthdate: string,
        subscription: {
            name: string,
        }
        country: string,
        avatar: string,
        lastActivity: string,
        createdAt: string,
    },
    userReport: {
        total: number,
        Deactivate: number,
        ActiveUsers: number,
    },
}

const initialState: IUsers = {
    users: [],
    meta: {
        pagination: {
            page: 0,
            pageSize: 0,
            pageCount: 0,
            total: 0,
        },
        report: {
            total: 0,
            ActiveUsers: 0,
            Deactivate: 0,
            inTrash: 0,
        }
    },
    status: 'idle',
    bulkAction: 'none',
    bulkActionCall: false,
    singleUser: {
        name: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        birthdate: '',
        subscription: {
            name: '',
        },
        country: '',
        avatar: '',
        lastActivity: '',
        createdAt: '',
    },
    userReport: {
        total: 0,
        Deactivate: 0,
        ActiveUsers: 0,
    },
}

export const UsersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setUsers: (state, actions) => {
            const { users, meta } = actions.payload
            state.users = users
            state.meta = meta
        },
        setStatus: (state, actions) => {
            state.status = actions.payload
        },
        setBulkAction: (state, actions) => {
            state.bulkAction = actions.payload
        },
        setBulkActionCall: (state, actions) => {
            state.bulkActionCall = actions.payload
        },
        setSingleUser: (state, actions) => {
            const { users } = actions.payload
            state.singleUser = users
        },
        setUserReport: (state, actions) => {
            const { data } = actions.payload
            state.userReport = data
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    setUsers,
    setStatus,
    setBulkAction,
    setBulkActionCall,
    setSingleUser,
    setUserReport
} = UsersSlice.actions

export default UsersSlice.reducer

// Thunks
export function fetchUsers(page = 1, limit = 25, order = 'desc') {
    return async (dispatch: any) => {
        dispatch(setStatus('loading'))
        try {
            const {data} = await getUsers(page, limit, order);
            dispatch(setUsers(data.data))
            dispatch(setStatus('success'))
        } catch (error) {
            console.log(error)
            dispatch(setStatus('failed'))
        }
    }
}
// fetch Reports
export function fetchUserReport() {
    return async (dispatch: any) => {
        dispatch(setStatus('loading'))
        try {
            const res = await getUserReport();
            dispatch(setUserReport(res.data))
            dispatch(setStatus('success'))
        } catch (error) {
            console.log(error)
            dispatch(setStatus('failed'))
        }
    }
}

export function fetchSearchUsers(search: string) {
    return async (dispatch: any) => {
        dispatch(setStatus('loading'))
        try {
            const {data} = await searchUser(search);
            dispatch(setUsers(data.data))
            dispatch(setStatus('success'))
        } catch (error) {
            console.log(error)
            dispatch(setStatus('failed'))
        }
    }
}

export function fetchUser(id: string) {
    return async (dispatch: any) => {
        dispatch(setStatus('loading'))
        try {
            const {data} = await getUser(id);
            dispatch(setSingleUser(data.data))
            dispatch(setStatus('success'))
        } catch (error) {
            console.log(error)
            dispatch(setStatus('failed'))
        }
    }
}

