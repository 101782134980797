import { createSlice } from '@reduxjs/toolkit'
import { getSlider, getSliders, sliderReport, sliderSearch } from '../../http/Apis'


export const STATUS = Object.freeze({
    SECCESS: 'secces',
    FAILED: 'failed',
    LOADING: 'loading',
})

const initialState = {
    data: [],
    status: STATUS.SECCESS,
    bulkAction: 'none',
    bulkActionCall: false,
    single: false,
    report: false,
}

export const sliderSlice = createSlice({
    name: 'slider',
    initialState,
    reducers: {
        setSliders: (state, actions) => {
            const { data } = actions.payload
            state.data = data
        },
        setStatus: (state, actions) => {
            state.status = actions.payload
        },
        setBulkAction: (state, actions) => {
            state.bulkAction = actions.payload
        },
        setBulkActionCall: (state, actions) => {
            state.bulkActionCall = actions.payload
        },
        setSingle: (state, actions) => {
            const { data } = actions.payload
            state.single = data
        },
        setReport: (state, actions) => {
            const { data } = actions.payload
            state.report = data
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    setSliders,
    setStatus,
    setBulkAction,
    setBulkActionCall,
    setSingle,
    setReport
} = sliderSlice.actions

export default sliderSlice.reducer

// Thunks
export function fetchSliders(page = 1, limit = 5, order = 'desc') {
    return async function fetchThunk(dispatch) {
        dispatch(setStatus(STATUS.LOADING))
        try {
            const res = await getSliders(page, limit, order);
            dispatch(setSliders(res.data))
            dispatch(setStatus(STATUS.SECCESS))
        } catch (error) {
            console.log(error)
            dispatch(setStatus(STATUS.FAILED))
        }
    }
}
// fetch Reports
export function fetchSliderReport() {
    return async function fetchReportThunk(dispatch) {
        dispatch(setStatus(STATUS.LOADING))
        try {
            const res = await sliderReport();
            dispatch(setReport(res.data))
            dispatch(setStatus(STATUS.SECCESS))
        } catch (error) {
            console.log(error)
            dispatch(setStatus(STATUS.FAILED))
        }
    }
}

export function fetchSearchSlider(search) {
    return async function fetchThunk(dispatch) {
        dispatch(setStatus(STATUS.LOADING))
        try {
            const res = await sliderSearch(search);
            dispatch(setSliders(res.data))
            dispatch(setStatus(STATUS.SECCESS))
        } catch (error) {
            console.log(error)
            dispatch(setStatus(STATUS.FAILED))
        }
    }
}

export function fetchSlider(id) {
    return async function fetchThunk(dispatch) {
        dispatch(setStatus(STATUS.LOADING))
        try {
            const res = await getSlider(id);
            dispatch(setSingle(res.data))
            dispatch(setStatus(STATUS.SECCESS))
        } catch (error) {
            console.log(error)
            dispatch(setStatus(STATUS.FAILED))
        }
    }
}

