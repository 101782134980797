import Hr from "./Hr";
import Title from "./Title";

const Box = ({
    children,
    title = "",
    className = "bg-secondary-color rounded-md shadow-xl py-2",
    colSpan = '',
    rowSpan = '',
}) => {
    return (
        <div className={`bg-secondary-color rounded-md shadow-xl py-2 ${className} col-span-${colSpan} row-span-${rowSpan} `}>
            <div className="my-3 mx-4">
                <Title className="text-lg">{title}</Title>
            </div>
            <Hr className="my-4" />
            <div className="mx-5 my-3 flex flex-col gap-4">
                {children}
            </div>
        </div>
    )
}

export default Box;