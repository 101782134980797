import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { getAppSettings, getSettings } from '../../http/Apis';

export interface IAPIStatus {
    IDLE: 'idle',
    LOADING: 'loading',
    SUCCEEDED: 'succeeded',
    FAILED: 'failed'
}

export const STATUS: IAPIStatus = {
    IDLE: 'idle',
    SUCCEEDED: 'succeeded',
    FAILED: 'failed',
    LOADING: 'loading',
}

export interface IConfigInitialState {
    config: {
        _id: string
        name: string,
        favicon: string,
        description: string,
        logo: string,
        siteUrl: string,
        seo_title: string,
        meta_description: string,
        meta_keywords: string[],
        google_teg_manager: string,
        social_links: {
            name: string,
            status: boolean,
            link: string
        }[],
        credentials: {
            smtp_server: {
                host: string,
                port: number,
                secure: boolean,
                user: string,
                pass: string
            }
            payment_gateways: {
                name: string,
                status: boolean,
                client_id: string,
                client_secret: string,
            }[],
            fcm: {
                server_key: string,
                sender_id: string
            }
        }
        createdAt: string,
        updatedAt: string,
    }
    status: IAPIStatus[keyof IAPIStatus]
    appConfig: {
        _id: string
        splesh_screen: {
            background_color: string,
            screen_time: number,
            logoSize: number,
            splesh_logo: string
        },
        logo: string,
        data: {
            ipinfo_api_key: string
        }
        createdAt: string,
        updatedAt: string,
    }
}

const initialState: IConfigInitialState = {
    config: {
        _id: '',
        name: '',
        favicon: '',
        description: '',
        logo: '',
        siteUrl: '',
        seo_title: '',
        meta_description: '',
        meta_keywords: [],
        google_teg_manager: '',
        social_links: [],
        credentials: {
            smtp_server: {
                host: '',
                port: 0,
                secure: false,
                user: '',
                pass: ''
            },
            payment_gateways: [],
            fcm: {
                server_key: '',
                sender_id: ''
            }
        },
        createdAt: '',
        updatedAt: ''
    },
    status: STATUS.IDLE,
    appConfig: {
        _id: '',
        splesh_screen: {
            background_color: '',
            screen_time: 0,
            logoSize: 0,
            splesh_logo: ''
        },
        logo: '',
        data: {
            ipinfo_api_key: ''
        },
        createdAt: '',
        updatedAt: ''
    }
};

export const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        setConfig: (state, action: PayloadAction<IConfigInitialState['config']>) => {
            state.config = action.payload
        },
        setAppConfig: (state, action: PayloadAction<IConfigInitialState['appConfig']>) => {
            state.appConfig = action.payload
        },
        setStatus: (state, action) => {
            state.status = action.payload;
        }
    }
});

export const { setConfig, setStatus, setAppConfig } = configSlice.actions;

export default configSlice.reducer;

// redux thunk api call
export function fetchConfig() {
    return async (dispatch: any) => {
        try {
            dispatch(setStatus({ status: STATUS.LOADING }))
            const { data } = await getSettings()
            dispatch(setConfig(data.data))
            dispatch(setStatus({ status: STATUS.SUCCEEDED }))
        } catch (error) {
            console.log(error)
            dispatch(setStatus({ status: STATUS.FAILED }))
        }
    }
}

export const fetchAppConfig = () => async (dispatch: any) => {
    try {
        dispatch(setStatus({ status: STATUS.LOADING }))
        const { data } = await getAppSettings()
        dispatch(setAppConfig(data.data))
        dispatch(setStatus({ status: STATUS.SUCCEEDED }))
    } catch (error) {
        console.log(error)
        dispatch(setStatus({ status: STATUS.FAILED }))
    }
}
