import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

export interface LogoProps {
    url?: string | null;
    className?: string;
}
const Logo = ({ url = null, className = 'w-36' }: LogoProps) => {
    const { config } = useSelector((state: RootState) => state.config);
    return (
        <div className={className}>
            <img src={url || config.logo} />
        </div>
    )
}

export default Logo;