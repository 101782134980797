import axios from "axios";

const apis = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
    headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
    }
});

// list all endpoints
// settings endpoints
export const getSettings = () => apis.get("/settings");
export const updateSettingApi = (id, data) => apis.put(`/settings/${id}`, data);
export const getAppSettings = () => apis.get("/settings/app");
export const updateAppSettingApi = (id, data) => apis.put(`/settings/app/${id}`, data);
// users endpoints
export const getUsers = (page, limit, order) => apis.get(`/users?limit=${limit}&page=${page}&sort=${order}`);
export const userActionsApi = (data) => apis.put("/users/action", data);
export const getUser = (id) => apis.get(`/users?id=${id}`);
// old endpoints
export const getUserReport = () => apis.get('/user/report');
export const searchUser = (search) => apis.get(`/users?keyword=${search}`);
export const RegisterUser = (data) => apis.post('/auth/register', data);
export const deleteUser = (data) => apis.put('/user/delete', data);
export const statusUser = (data) => apis.put('/user/status', data);
export const restoreUser = (data) => apis.put('/user/restore', data);

// Orders endpoints
export const getOrders = (page, limit, order) => apis.get(`/payment/list?limit=${limit}&page=${page}&order=${order}`);
export const getOrderReport = (id) => apis.get(`/payment/list/${id}`);

// Themes endpoints
export const getThemes = (page, limit, order) => apis.get(`/theme/list?limit=${limit}&page=${page}&order=${order}`);
export const themeStatus = (data) => apis.put('/theme/status', data);
export const themeDelete = (data) => apis.put('/theme/delete', data);
export const themeRestore = (data) => apis.put('/theme/restore', data);
export const themeSearch = (search) => apis.get(`/theme/search/${search}`);
export const getTheme = (id) => apis.get(`/theme/list/${id}`);
export const themeReport = () => apis.get(`/theme/report`);

// Store endpoints
export const getStores = (page, limit, order) => apis.get(`/store/list?limit=${limit}&page=${page}&order=${order}`);
export const storeStatus = (data) => apis.put('/store/status', data);
export const storeDelete = (data) => apis.put('/store/delete', data);
export const storeRestore = (data) => apis.put('/store/restore', data);
export const storeSearch = (search) => apis.get(`/store/search/${search}`);
export const getStore = (id) => apis.get(`/store/single/${id}`);
export const storeReport = () => apis.get(`/store/report`);

// Subscription endpoints
export const getSubscriptions = (queryString) => apis.get(`/subscriptions${queryString ? `?${queryString}` : ''}`);
export const subscriptionStatus = (data) => apis.put('/subscriptions/status', data);
export const UpdateSubscription = (id, data) => apis.put(`/subscriptions/update/${id}`, data);

// deprecated endpoints
export const subscriptionDelete = (data) => apis.put('/subscription/delete', data);
export const subscriptionRestore = (data) => apis.put('/subscription/restore', data);
export const subscriptionSearch = (search) => apis.get(`/subscription/search/${search}`);
export const getSubscription = (id) => apis.get(`/subscription/list/${id}`);
export const subscriptionReport = () => apis.get(`/subscription/report`);
export const CreateSubscription = (data) => apis.post('/subscription/create', data);

// movie endpoints
export const createMovie = (data) => apis.post('/movie/create', data);
export const getMovies = (page = 1, limit = 25, order = 'desc') => apis.get(`/movie/list?limit=${limit}&page=${page}&order=${order}`);
export const movieStatus = (data) => apis.put('/movie/status', data);
export const movieDelete = (data) => apis.put('/movie/delete', data);
export const movieRestore = (data) => apis.put('/movie/restore', data);
export const movieSearch = (search) => apis.get(`/movie/search/${search}`);
export const getMovie = (id) => apis.get(`/movie/list/${id}`);
export const movieReport = () => apis.get(`/movie/report`);
export const updateMovie = (id, data) => apis.put(`/movie/update/${id}`, data);
export const updateJob = (id) => apis.get(`movie/updateJob/${id}`);
export const premanentlyDeleteMovie = (id) => apis.delete(`/movie/${id}`);

// media convertion endpoints aws
export const createJob = (data) => apis.post('/media-convert/create', data);
export const createJobNew = (data) => apis.post('/media-convert', data);
export const getJob = (id) => apis.get(`/media-convert/list/${id}`);


// genre endpoints
export const createGenreApi = (data) => apis.post('/genre', data);
export const getGenres = (queryString) => apis.get(`/genre${queryString ? `?${queryString}` : ''}`);
export const genreActionsApi = (data) => apis.patch("/genre/action", data);
export const genreSearch = (search) => apis.get(`/genre?keyword=${search}`);

// deprecated endpoints
export const genreStatus = (data) => apis.put('/genre/status', data);
export const genreDelete = (data) => apis.put('/genre/delete', data);
export const genreRestore = (data) => apis.put('/genre/restore', data);
export const getGenre = (id) => apis.get(`/genre/list/${id}`);
export const genreReport = () => apis.get(`/genre/report`);
export const updateGenre = (id, data) => apis.put(`/genre/update/${id}`, data);

// category endpoints
export const getCategories = (queryString) => apis.get(`/categories${queryString ? `?${queryString}` : ''}`);
export const createCategoryEndPoint = (data) => apis.post('/categories', data);
export const categoryActionsEndPoint = (data) => apis.patch("/categories/action", data);
export const updateCategoryEndPoint = (id, data) => apis.put(`/categories/${id}`, data);
export const categorySearch = (search) => apis.get(`/categories?keyword=${search}`);


//deprecated endpoints
export const getCategory = (id) => apis.get(`/categories/list/${id}`);
export const categoryStatus = (data) => apis.put('/categories/status', data);
export const categoryDelete = (data) => apis.put('/categories/delete', data);
export const categoryRestore = (data) => apis.put('/categories/restore', data);
export const categoryReport = () => apis.get(`/categories/report`);

// language endpoints
export const getLanguagesEndPoint = (queryString) => apis.get(`/language${queryString ? `?${queryString}` : ''}`);
export const createLanguageEndPoint = (data) => apis.post('/language', data);
export const languageActionsEndPoint = (data) => apis.patch("/language/action", data);
export const updateLanguageEndPoint = (id, data) => apis.put(`/language/${id}`, data);
export const languageSearch = (search) => apis.get(`/language?keyword=${search}`);

// deprecated endpoints
export const languageStatus = (data) => apis.put('/language/status', data);
export const languageDelete = (data) => apis.put('/language/delete', data);
export const languageRestore = (data) => apis.put('/language/restore', data);
export const getLanguage = (id) => apis.get(`/language/list/${id}`);
export const languageReport = () => apis.get(`/language/report`);
export const updateLanguage = (id, data) => apis.put(`/language/update/${id}`, data);

// Cast endpoints
export const getCastsEndPoint = (queryString) => apis.get(`/cast${queryString ? `?${queryString}` : ''}`);
export const createCastEndPoint = (data) => apis.post('/cast', data);
export const castActionsEndPoint = (data) => apis.patch("/cast/action", data);
export const updateCastEndPoint = (id, data) => apis.put(`/cast/${id}`, data);
export const castSearch = (search) => apis.get(`/cast?keyword=${search}`);

// deprecated endpoints
export const createCast = (data) => apis.post('/cast/create', data);
export const castStatus = (data) => apis.put('/cast/status', data);
export const castDelete = (data) => apis.put('/cast/delete', data);
export const castRestore = (data) => apis.put('/cast/restore', data);
export const getCast = (id) => apis.get(`/cast/list/${id}`);
export const castReport = () => apis.get(`/cast/report`);
export const updateCast = (id, data) => apis.put(`/cast/update/${id}`, data);

// slider endpoints
export const createSlider = (data) => apis.post('/slider/create', data);
export const getSliders = (page = 1, limit = 25, order = 'desc') => apis.get(`/slider/list?limit=${limit}&page=${page}&order=${order}`);
export const sliderStatus = (data) => apis.put('/slider/status', data);
export const sliderDelete = (data) => apis.put('/slider/delete', data);
export const sliderSearch = (search) => apis.get(`/slider/search/${search}`);
export const getSlider = (id) => apis.get(`/slider/list/${id}`);
export const sliderReport = () => apis.get(`/slider/report`);
export const updateSlider = (id, data) => apis.put(`/slider/update/${id}`, data);

// section endpoints
export const createSection = (data) => apis.post('/section/create', data);
export const getSections = (page = 1, limit = 25, order = 'desc') => apis.get(`/section?limit=${limit}&page=${page}&order=${order}`);
export const sectionStatus = (data) => apis.put('/section/status', data);
export const sectionDelete = (data) => apis.put('/section', data);
export const sectionSearch = (search) => apis.get(`/section/search/${search}`);
export const getSection = (id) => apis.get(`/section/list/${id}`);
export const sectionReport = () => apis.get(`/section/report`);
export const updateSection = (id, data) => apis.put(`/section/${id}`, data);
export const updateSectionOrder = (data) => apis.put(`/section/update-order`, data);

// series endpoints
export const createSeries = (data) => apis.post('/series/create?version=v1.0.0', data);
export const getAllSeries = (page = 1, limit = 25, sort = 'desc') => apis.get(`/series/v1/list?limit=${limit}&page=${page}&sort=${sort}`);
export const getSeries = (queryString) => apis.get(`/series/v1?${queryString}`);
export const seriesAction = (data) => apis.put('/series/action', data);
export const createSeriesSeason = (id, data) => apis.post(`/series/season/${id}?version=v1.0.0`, data);
export const deleteSeriesSeason = (id) => apis.delete(`/series/season/${id}`);
export const updateSeriesSeason = (id, data) => apis.put(`/series/season/${id}`, data);
export const createSeriesEpisode = (seriesid, seasonid, data) => apis.post(`/series/episode/${seriesid}/${seasonid}?version=v1.0.0`, data);
export const deleteSeriesEpisode = (id) => apis.delete(`/series/episode/${id}`);
export const updateSeriesEpisode = (id, data) => apis.put(`/series/episode/${id}`, data);




export const seriesStatus = (data) => apis.put('/series/status', data);
export const seriesDelete = (data) => apis.put('/series/softdelete', data);
export const SeriesRestore = (data) => apis.put('/series/restore', data);
export const premanentlyDeleteApi = (id) => apis.delete(`/series/delete/${id}`);
export const updateSeriesJob = (id) => apis.put(`/series/update-job/${id}`);
export const createSeason = (id, data) => apis.post(`/series/create-season/${id}`, data);
export const deleteSeason = (id, seasonId) => apis.delete(`/series/remove-season/${id}/${seasonId}`);
export const getSeason = (id, seasonId) => apis.get(`/series/season/${id}/${seasonId}`);
export const updateSeason = (id, seasonId, data) => apis.put(`/series/update-season/${id}/${seasonId}`, data);
export const createEpisode = (id, seasonId, data) => apis.post(`/series/create-episode/${id}/${seasonId}`, data);
export const deleteEpisode = (id, seasonId, episodeId) => apis.delete(`/series/remove-episode/${id}/${seasonId}/${episodeId}`);
export const updateEpisodeJob = (data) => apis.put(`/series/update-episode-job/${data.id}/${data.seasonId}/${data.episodeId}`);
export const getEpisode = (id, seasonId, episodeId) => apis.get(`/series/episode/${id}/${seasonId}/${episodeId}`);
export const updateEpisodeApi = (id, seasonId, episodeId, data) => apis.put(`/series/update-episode/${id}/${seasonId}/${episodeId}`, data);

// analytics endpoints
export const getAnalytics = (queryString) => apis.get(`/analytics?${queryString}`);
export const getTrending = () => apis.get(`/trending`);
export const userAnalytics = (queryString) => apis.get(`/analytics/users?${queryString}`);
export const contentAnalytics = (queryString) => apis.get(`/analytics/content?${queryString}`);
export const getRevenue = (queryString) => apis.get(`/analytics/revenue?${queryString}`);

// content endpoints
export const getContent = (queryString) => apis.get(`/content?${queryString}`);
export const searchContent = (keyword) => apis.get(`/content?type=none&keyword=${keyword}`);

// new contact endpoints
export const getContant = (queryString) => apis.get(`/content?${queryString || ''}`);
export const contactAction = (data) => apis.put('/content/action', data);
export const contantSeasonsDelete = (id) => apis.delete(`/content/season/${id}`);
export const contantCreateSeason = (id, data) => apis.post(`/content/season/${id}`, data);
export const contantUpdateSeason = (id, data) => apis.put(`/content/season/${id}`, data);
export const contantCreateEpisode = (id, data) => apis.post(`/content/episode/${id}`, data);
export const contantEpisodeDelete = (id) => apis.delete(`/content/episode/${id}`);
export const contantUpdateEpisode = (id, data) => apis.put(`/content/episode/${id}`, data);
// marketing endpoints
export const sendPushNotification = (data) => apis.post('/marketing', data);




// Interceptor for handling 401 errors
apis.interceptors.response.use(
    (config) => {
        return config;
    },
    async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && originalRequest && !originalRequest.isRetry) {
            originalRequest.isRetry = true;
            try {
                await axios.get(
                    `${process.env.REACT_APP_API_URL}/auth/refresh`,
                    {
                        withCredentials: true,
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                        }
                    }
                );

                return apis.request(originalRequest);
            } catch (err) {
                console.log(err.message);
            }
        }

        throw error;
    }
);

export default apis;