import Layout from "../../components/Layout/Layout";
import { Button, SeriesTable, } from "../../tailwind";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDebounce } from "use-debounce";
import SearchBar from "../../components/SearchBar";
import { fetchMovieReport, fetchMovies, fetchSearchMovie, fetchSearchStore, fetchStoreReport, setBulkAction, setBulkActionCall } from "../../redux/slice​/movieSlice";
import { Link } from "react-router-dom";
import { fetchAllSeries, fetchSeries } from "../../redux/slice​/seriesSlice";

const Series = () => {
    const { data, meta, report } = useSelector(state => state.series);
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [itemView, setItemView] = useState(25);
    const [search, setSearch] = useState();
    const [value] = useDebounce(search, 1000);

    useEffect(() => {
        if (value) {
            dispatch(fetchSearchMovie(value));
        }
    }, [value]);

    const [pagination, setPagination] = useState({
        page: 1,
        limit: process.env.REACT_APP_PAGE_SIZE,
    })

    useEffect(() => {
        dispatch(fetchAllSeries(pagination.page, pagination.limit));
        dispatch(fetchMovieReport())
    }, [pagination]);


    const headlePrev = () => {
        if (pagination.page > 1) {
            setPagination({
                ...pagination,
                page: pagination.page - 1
            })
        } else {
            toast.error("You are on first page")
        }
    }

    const handleNext = () => {
        if (pagination.page < Math.ceil(report.total / pagination.limit)) {
            setPagination({
                ...pagination,
                page: pagination.page + 1
            })
        } else {
            toast.error("No more pages")
        }
    }
    return (
        <Layout>
            <div className='flex justify-between items-center'>
                <div className='flex items-center gap-4'>
                    <div>
                        <h1 className='text-white font-medium text-xl'>Series</h1>
                        <span className='text-white opacity-50 text-xs tracking-wider'>Your all Series list.</span>
                    </div>
                    <select
                        className="select max-w-xs bg-secondary-color"
                        defaultValue={'none'}
                        onChange={(e) => dispatch(setBulkAction(e.target.value))}>
                        <option value="none">Bulk Actions</option>
                        <option value='trash'>
                            Move to Trash
                        </option>
                        <option value='unpublish'>UnPublish</option>
                        <option value='publish'>Publish</option>
                        <option value='restore'>Restore</option>
                        <option value='delete'>Delete</option>
                    </select>
                    <span onClick={() => dispatch(setBulkActionCall(true))} className="bg-secondary-color py-3.5 px-6 rounded-md shadow-md text-sm cursor-pointer hover:bg-secondary-blue duration-100 text-white hover:text-white" >Apply</span>
                </div>
                <div className='flex gap-2 items-center'>
                    <SearchBar
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    <Link to='/admin/series/add'>
                        <Button
                            title={'+ Add New'}
                            titleStyle={'text-white font-medium'}
                            height={'h-11'}
                        />
                    </Link>
                </div>
            </div>
            <div className="my-5">
                <SeriesTable data={data} page={pagination.page} />
            </div>
            <div className='flex justify-between items-center my-4'>
                {
                    data.length > 0 && <div className="stats stats-vertical lg:stats-horizontal shadow bg-secondary-color rounded-md">
                        <div className="stat flex py-3">
                            <div className="stat-title">Total Series</div>
                            <div className="">{meta?.total?.count}</div>
                        </div>
                        <div className="stat flex py-3">
                            <div className="stat-title">
                                Published
                            </div>
                            <div className="">
                                {meta?.total?.countPublished}
                            </div>
                        </div>
                        <div className="stat flex py-3">
                            <div className="stat-title">
                                Unpublished
                            </div>
                            <div className="">{
                                meta?.total?.countUnpublished
                            }</div>
                        </div>
                    </div>
                }
                <div>
                    <div className="btn-group">
                        <button className="btn bg-secondary-color" onClick={headlePrev}>«</button>
                        <button className="btn bg-secondary-color-800">Page {pagination.page}</button>
                        <button className="btn bg-secondary-color" onClick={handleNext} >»</button>
                    </div>
                </div>
            </div>
        </Layout >
    )
}

export default Series;