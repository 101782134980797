import { useEffect, useState } from "react";
import { Card, Row, Col } from 'antd';
import { bytesToBand } from "../../../utils/Utils";
import Chart from 'react-apexcharts'
import Base64 from 'base-64';


import Layout from "../../../components/Layout/Layout";
import { Button, DeleteIcon, Input, Title } from "../../../tailwind";
import { UploadIcon } from "../../../tailwind/Icon";
import { useSelector } from "react-redux";
import { sendPushNotification, updateSettingApi } from "../../../http/Apis";
import toast from "react-hot-toast";
import { Hr } from "../../../components";







const RtmpDashboard = () => {
  let count = 0;
  let lastInBytes = 0;
  let lastOtBytes = 0;

  const [cpuOption, setCpuOption] = useState({
    uptime: 0,
    xAxis: [],
    cpu: [],
  });
  const [memOption, setMemOption] = useState({
    uptime: 0,
    xAxis: [],
    mem: [],
  });
  const [netOption, setNetOption] = useState({
    uptime: '',
    xAxis: [],
    xAxis2: [],
    inbytes: [],
    outbytes: [],
  });
  const [ConnOption, setConnOption] = useState({
    uptime: '',
    connections: '',
    xAxis: [],
    rtmp: [],
    http: [],
    ws: [],
  })

  // fetch data
  const fetchData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_RTMP_SERVER_URL}/api/server`, {
        method: 'GET',
        headers: {
          "Authorization": "Basic " + Base64.encode(`${'admin'}:${'nms2018'}`),
          "Content-Type": "application/json"
        }
      });
      const data = await res.json();
      const { cpu, mem, clients, net } = data;
      lastInBytes = lastInBytes || net.inbytes;
      lastOtBytes = lastOtBytes || net.outbytes;

      let now = new Date();
      let axisData = now.toLocaleTimeString().replace(/^\D*/, '');


      let conOption = { ...ConnOption };
      let netOptions = { ...netOption };

      conOption.uptime = now;
      conOption.connections = "Connections " + (data.clients.rtmp + data.clients.http + data.clients.ws);
      conOption.xAxis.push(axisData);
      conOption.rtmp.push(data.clients.rtmp);
      conOption.http.push(data.clients.http);
      conOption.ws.push(data.clients.ws);

      // if length of xAxis is greater than 10 then remove first element
      if (conOption.xAxis.length > 7) {
        conOption.xAxis.shift();
        conOption.rtmp.shift();
        conOption.http.shift();
        conOption.ws.shift();
      }
      setConnOption(conOption);

      // net Options
      netOptions.uptime = now;
      netOptions.xAxis.push(axisData);
      netOptions.xAxis2.push(axisData);
      netOptions.inbytes.push(bytesToBand((net.inbytes - lastInBytes) / 2).toFixed(2));
      netOptions.outbytes.push(bytesToBand((net.outbytes - lastOtBytes) / 2).toFixed(2));
      lastInBytes = net.inbytes;
      lastOtBytes = net.outbytes;
      setNetOption(netOptions);

      // if length of xAxis is greater than 10 then remove first element
      if (netOptions.xAxis.length > 7) {
        netOptions.xAxis.shift();
        netOptions.xAxis2.shift();
        netOptions.inbytes.shift();
        netOptions.outbytes.shift();
      }

      // cpu options
      let cpuOptions = { ...cpuOption };
      cpuOptions.uptime = now;
      cpuOptions.xAxis.push(axisData);
      cpuOptions.cpu.push(cpu.load);
      setCpuOption(cpuOptions);

      // if length of xAxis is greater than 10 then remove first element
      if (cpuOptions.xAxis.length > 7) {
        cpuOptions.xAxis.shift();
        cpuOptions.cpu.shift();
      }

      // mem options
      let memOptions = { ...memOption };
      memOptions.uptime = now;
      memOptions.xAxis.push(axisData);
      memOptions.mem.push((100 - 100 * mem.free / mem.totle).toFixed(2));

      setMemOption(memOptions);

      // if length of xAxis is greater than 10 then remove first element
      if (memOptions.xAxis.length > 7) {
        memOptions.xAxis.shift();
        memOptions.mem.shift();
      }







    } catch (error) {
      console.log(error);
    }
  }

  // run useEffect with interval every 2 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      fetchData();
    }, 3000);
    return () => clearInterval(interval);
  }, []);





  const { user } = useSelector(state => state.auth);
  const { config } = useSelector(state => state.config);
  const { credentials } = config;
  const [loading, setLoading] = useState(false);

  return (
    <Layout>
      <div className="px-5">
        <h1 className="text-2xl text-white opacity-80 font-semibold tracking-wide">
          RTMP Dashboard
        </h1>
        <p className="mt-2 text-white opacity-70">
          Here you can manage your RTMP servers. You can add, edit, delete and monitor your servers.
        </p>
      </div>
      <div className="my-5">
        <Hr />
      </div>
      <div className="grid grid-cols-2 gap-10 p-5">
        <div className="w-full">
          <div className="text-black bg-dark-color-box p-5 rounded-lg shadow">
            {
              ConnOption && (<Chart
                options={{
                  chart: {
                    id: 'realtime',
                    height: 350,
                    type: 'line',
                    animations: {
                      enabled: true,
                      easing: 'linear',
                      dynamicAnimation: {
                        speed: 1000
                      }
                    },
                    toolbar: {
                      show: false
                    },
                    zoom: {
                      enabled: false
                    }
                  },
                  dataLabels: {
                    enabled: false
                  },
                  stroke: {
                    curve: 'smooth'
                  },
                  title: {
                    text: ConnOption.connections,
                    align: 'left',
                    style: {
                      fontSize: '14px',
                      fontWeight: 'bold',
                      fontFamily: undefined,
                      color: '#fff'
                    },
                  },
                  markers: {
                    size: 0
                  },
                  xaxis: {
                    type: 'text',
                    categories: ConnOption.xAxis.map((x) => x),
                    labels: {
                      style: {
                        colors: '#fff',
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 400,
                        cssClass: 'apexcharts-xaxis-label',
                      }
                    },
                  },
                  yaxis: {
                    labels: {
                      style: {
                        colors: '#fff',
                      }
                    }
                  },
                  legend: {
                    show: true,
                    position: 'top',
                    horizontalAlign: 'right',
                    floating: true,
                    offsetY: -25,
                    offsetX: -5,
                    labels: {
                      colors: '#fff',
                      useSeriesColors: false
                    },

                  },

                }}
                series={[
                  {
                    name: 'Rtmp',
                    data: ConnOption.rtmp.map((item) => item)
                  },
                  {
                    name: 'Http',
                    data: ConnOption.http.map((item) => item)
                  },
                  {
                    name: 'Websocket',
                    data: ConnOption.ws.map((item) => item)
                  }
                ]}
                type="line"
                width={'100%'}
                height={450}
              />
              )
            }
          </div>
        </div>
        <div className="w-full">
          <div className="text-black bg-dark-color-box p-5 rounded-lg shadow">
            {
              netOption && (<Chart
                options={{
                  chart: {
                    id: 'realtime',
                    height: 350,
                    type: 'line',
                    animations: {
                      enabled: true,
                      easing: 'linear',
                      dynamicAnimation: {
                        speed: 1000
                      }
                    },
                    toolbar: {
                      show: false
                    },
                    zoom: {
                      enabled: false
                    }
                  },
                  dataLabels: {
                    enabled: false
                  },
                  stroke: {
                    curve: 'smooth'
                  },
                  title: {
                    text: 'Network Bandwidth in Mbps',
                    align: 'left',
                    style: {
                      fontSize: '14px',
                      fontWeight: 'bold',
                      fontFamily: undefined,
                      color: '#fff'
                    },
                  },
                  markers: {
                    size: 0
                  },
                  xaxis: {
                    type: 'text',
                    categories: netOption.xAxis.map((x) => x),
                    labels: {
                      style: {
                        colors: '#fff',
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 400,
                        cssClass: 'apexcharts-xaxis-label',
                      }
                    },
                  },
                  yaxis: {
                    labels: {
                      style: {
                        colors: '#fff',
                      }
                    }
                  },
                  legend: {
                    show: true,
                    position: 'top',
                    horizontalAlign: 'right',
                    floating: true,
                    offsetY: -25,
                    offsetX: -5,
                    labels: {
                      colors: '#fff',
                      useSeriesColors: false
                    },

                  },

                }}
                series={[
                  {
                    name: 'Input',
                    // remove .000 from data
                    data: netOption.inbytes.map((item) => item)
                  },
                  {
                    name: 'Output',
                    data: netOption.outbytes.map((item) => item)
                  },
                ]}
                type="line"
                width={'100%'}
                height={450}
              />
              )
            }
          </div>
        </div>
        <div className="w-full">
          <div className="text-black bg-dark-color-box p-5 rounded-lg shadow">
            {
              cpuOption && (<Chart
                options={{
                  chart: {
                    id: 'realtime',
                    height: 350,
                    type: 'area',
                    animations: {
                      enabled: true,
                      easing: 'linear',
                      dynamicAnimation: {
                        speed: 1000
                      }
                    },
                    toolbar: {
                      show: false
                    },
                    zoom: {
                      enabled: false
                    }
                  },
                  dataLabels: {
                    enabled: false
                  },
                  stroke: {
                    curve: 'smooth'
                  },
                  title: {
                    text: 'CPU Usage',
                    align: 'left',
                    style: {
                      fontSize: '14px',
                      fontWeight: 'bold',
                      fontFamily: undefined,
                      color: '#fff'
                    },
                  },
                  markers: {
                    size: 0
                  },
                  xaxis: {
                    type: 'text',
                    categories: cpuOption.xAxis.map((x) => x),
                    labels: {
                      style: {
                        colors: '#fff',
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 400,
                        cssClass: 'apexcharts-xaxis-label',
                      }
                    },
                  },
                  yaxis: {
                    max: 100,
                    labels: {
                      style: {
                        colors: '#fff',
                      }
                    }
                  },
                  legend: {
                    show: false,
                    position: 'top',
                    horizontalAlign: 'right',
                    floating: true,
                    offsetY: -25,
                    offsetX: -5,
                    labels: {
                      colors: '#fff',
                      useSeriesColors: false
                    },

                  },

                }}
                series={[
                  {
                    name: 'CPU',
                    // remove .000 from data
                    data: cpuOption.cpu.map((item) => item)
                  },
                ]}
                type="area"
                width={'100%'}
                height={450}
              />
              )
            }
          </div>
        </div>
        <div className="w-full">
          <div className="text-black bg-dark-color-box p-5 rounded-lg shadow">
            {
              memOption && (<Chart
                options={{
                  chart: {
                    id: 'realtime',
                    height: 250,
                    type: 'area',
                    animations: {
                      enabled: true,
                      easing: 'linear',
                      dynamicAnimation: {
                        speed: 1000
                      }
                    },
                    toolbar: {
                      show: false
                    },
                    zoom: {
                      enabled: false
                    }
                  },
                  dataLabels: {
                    enabled: false
                  },
                  stroke: {
                    curve: 'smooth'
                  },
                  title: {
                    text: 'Memory Usage',
                    align: 'left',
                    style: {
                      fontSize: '14px',
                      fontWeight: 'bold',
                      fontFamily: undefined,
                      color: '#fff'
                    },
                  },
                  markers: {
                    size: 0
                  },
                  xaxis: {
                    type: 'text',
                    categories: memOption.xAxis.map((x) => x),
                    labels: {
                      style: {
                        colors: '#fff',
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 400,
                        cssClass: 'apexcharts-xaxis-label',
                      }
                    },
                  },
                  yaxis: {
                    max: 100,
                    labels: {
                      style: {
                        colors: '#fff',
                      }
                    }
                  },
                  legend: {
                    show: false,
                    position: 'top',
                    horizontalAlign: 'right',
                    floating: true,
                    offsetY: -25,
                    offsetX: -5,
                    labels: {
                      colors: '#fff',
                      useSeriesColors: false
                    },

                  },

                }}
                series={[
                  {
                    name: 'Memory',
                    // remove .000 from data
                    data: memOption.mem.map((item) => item)
                  },
                ]}
                type="area"
                width={'100%'}
                height={450}
              />
              )
            }
          </div>
        </div>
      </div>

      {/* <div className="px-5">
                <h1 className="text-2xl text-white opacity-80 font-semibold tracking-wide">
                RTMP Dashboard
                </h1>
                <p className="mt-2 text-white opacity-70">
                    Here you can manage your RTMP servers. You can add, edit, delete and monitor your servers.
                </p>
            </div>
            <div className="my-5">
                <Hr />
            </div>
            <h6 className="text-white opacity-70 px-5">* – Required fields. Enter all fields.</h6>
            <div className="my-5 pr-36 flex flex-col gap-5 px-5">
                <Input
                    title="Notification Title"
                    requiredTitle
                    placeholder="Enter your notification title"
                    value={pushNotification.title}
                    onChange={(e) => setPushNotification({ ...pushNotification, title: e.target.value })}
                />
                <div className="flex w-full">
                    <Input
                        title="Notification Body"
                        requiredTitle
                        inputType="textarea"
                        rows="5"
                        placeholder="Enter your notification body"
                        defaultValue={pushNotification.body}
                        onChange={(e) => setPushNotification({ ...pushNotification, body: e.target.value })}
                    />
                </div>
                <div className="my-4">

                    <div className="flex w-full items-center">
                        <div className="w-full p-5 border border-gray-700 rounded">
                            <Title required={false} className='w-1/3'>
                                Notification Image
                            </Title>
                            <div className="flex flex-col justify-center items-center">
                                <input
                                    type="file"
                                    className="hidden"
                                    id="logo"
                                    onChange={handleLogoChange}
                                />
                                <div className="relative">
                                    <label htmlFor="logo" className="cursor-pointer">
                                        <img
                                            src={pushNotification.image_url}
                                            className="w-96 h-60 object-contain rounded-3xl shadow-sm bg-dark-color-box text-center p-5"
                                        />
                                    </label>
                                    <div className="cursor-pointer absolute -top-2 -right-2">
                                        <DeleteIcon
                                            width={40}
                                            height={40}
                                        />
                                    </div>
                                </div>

                                <div className="mt-8">
                                    <label htmlFor="logo" className="cursor-pointer flex items-center gap-3">
                                        <div className="mt-[2px]">
                                            <UploadIcon width={22} height={22} />
                                        </div>
                                        <p className="text-white opacity-70 text-lg font-medium cursor-pointer">
                                            Replace
                                        </p>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="my-16 pr-36 flex flex-col gap-8 px-5">
                <div className="flex justify-end">
                    <div className="w-44">
                        <Button
                            className="mt-16"
                            title={"Send Notification"}
                            onClick={sentPushNotifiction}
                            loading={loading}
                        />
                    </div>

                </div>
            </div> */}








    </Layout>

  );
}

export default RtmpDashboard;
