import Layout from "../../components/Layout/Layout";
import { Title, VisibilityIcon } from "../../tailwind";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { fetchOrders } from "../../redux/slice​/ordersSlice";
import { Link } from "react-router-dom";

const Orders = () => {
    const dispatch = useDispatch();
    const { data, status } = useSelector(state => state.orders);

    useEffect(() => {
        dispatch(fetchOrders());
    }, [])

    return (
        <Layout>
            <Title>All Orders</Title>
            <>
                <div className="overflow-x-auto my-5">
                    <table className="table w-full">
                        <thead>
                            <tr>
                                <th className="bg-secondary-color"></th>
                                <th className="bg-secondary-color">Order Id</th>
                                <th className="bg-secondary-color">Receipt</th>
                                <th className="bg-secondary-color">Amount</th>
                                <th className="bg-secondary-color">Status</th>
                                <th className="bg-secondary-color">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data && data.map((order, index) => {
                                    return (
                                        <tr key={index}>
                                            <th className="bg-slate-800">{index + 1}</th>
                                            <td className="bg-slate-800 border-slate-500">{order.id}</td>
                                            <td className="bg-slate-800 border-slate-500">{order.receipt}</td>
                                            <td className="bg-slate-800 border-slate-500">{order.currency === 'INR' && '₹ ' + order.amount}</td>
                                            <td className="bg-slate-800 border-slate-500">
                                                <span className={`
                                                ${order.status === 'paid' && 'bg-green-500'}  
                                                ${order.status === 'created' && 'bg-orange-500'}
                                                text-white py-1 capitalize px-3 rounded-full`}>
                                                    {order.status}
                                                </span>
                                            </td>
                                            <td className="bg-slate-800 border-slate-500 cursor-pointer">
                                                <Link to={`/admin/orders/view/${order.id}`}>
                                                    <VisibilityIcon height={50} width={50} backgroundColor="#22C55E" />
                                                </Link>
                                            </td>
                                        </tr>
                                    )
                                })
                            }

                        </tbody>
                    </table>
                </div>
            </>
        </Layout>
    );
}

export default Orders;