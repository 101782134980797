export const DashboardIcon = ({ width = 21, height = 21, fill = "white" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27.7778 0V16.6667H50V0H27.7778ZM27.7778 50H50V22.2222H27.7778V50ZM0 50H22.2222V33.3333H0V50ZM0 27.7778H22.2222V0H0V27.7778Z" fill={fill} />
        </svg>
    )
}

export const ProductIcon = ({ width = 50, height = 50, fill = "white" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.88889 5.55556H44.0833L41.4722 2.77778H8.13889L5.88889 5.55556ZM48.7222 6.19444C49.5278 7.13889 50 8.33333 50 9.72222V44.4444C50 45.9179 49.4147 47.3309 48.3728 48.3728C47.3309 49.4147 45.9179 50 44.4444 50H5.55556C4.08213 50 2.66905 49.4147 1.62718 48.3728C0.585316 47.3309 0 45.9179 0 44.4444V9.72222C0 8.33333 0.472222 7.13889 1.27778 6.19444L5.11111 1.52778C5.88889 0.583333 7.02778 0 8.33333 0H41.6667C42.9722 0 44.1111 0.583333 44.8611 1.52778L48.7222 6.19444ZM8.33333 41.6667H25V33.3333H8.33333V41.6667Z" fill={fill} />
        </svg>
    )
}

export const MenuIcon = ({ width = 104, height = 72, fill = "white" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 104 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_52_197)">
                <path d="M4 10.6667H100V0H4V10.6667ZM68 37.3333H4V26.6667H68V37.3333ZM68 64H4V53.3333H68" fill="white" />
            </g>
            <defs>
                <filter id="filter0_d_52_197" x="0" y="0" width="104" height="72" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_52_197" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_52_197" result="shape" />
                </filter>
            </defs>
        </svg>
    )
}

export const DeleteIcon = ({
    iconColor = 'white',
    width = 45,
    height = 45,
    backgroundColor = '#B80041',
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_779_3)">
                <circle cx="32" cy="28" r="28" fill={backgroundColor} />
            </g>
            <path d="M27.9444 16V17.3889H21V20.1667H22.3889V38.2222C22.3889 38.9589 22.6815 39.6655 23.2025 40.1864C23.7234 40.7073 24.43 41 25.1667 41H39.0556C39.7923 41 40.4988 40.7073 41.0197 40.1864C41.5407 39.6655 41.8333 38.9589 41.8333 38.2222V20.1667H43.2222V17.3889H36.2778V16H27.9444ZM25.1667 20.1667H39.0556V38.2222H25.1667V20.1667ZM27.9444 22.9444V35.4444H30.7222V22.9444H27.9444ZM33.5 22.9444V35.4444H36.2778V22.9444H33.5Z" fill={iconColor} fillOpacity="0.9" />
            <defs>
                <filter id="filter0_d_779_3" x="0" y="0" width="64" height="64" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_779_3" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_779_3" result="shape" />
                </filter>
            </defs>
        </svg>
    )
}

export const VisibilityIcon = ({
    iconColor = 'white',
    width = 45,
    height = 45,
    backgroundColor = '#B80041',
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_780_5)">
                <path d="M60 28C60 43.464 47.464 56 32 56C16.536 56 4 43.464 4 28C4 12.536 16.536 0 32 0C47.464 0 60 12.536 60 28Z" fill={backgroundColor} />
            </g>
            <path d="M32 25.5C31.2044 25.5 30.4413 25.8161 29.8787 26.3787C29.3161 26.9413 29 27.7044 29 28.5C29 29.2956 29.3161 30.0587 29.8787 30.6213C30.4413 31.1839 31.2044 31.5 32 31.5C32.7956 31.5 33.5587 31.1839 34.1213 30.6213C34.6839 30.0587 35 29.2956 35 28.5C35 27.7044 34.6839 26.9413 34.1213 26.3787C33.5587 25.8161 32.7956 25.5 32 25.5ZM32 33.5C30.6739 33.5 29.4021 32.9732 28.4645 32.0355C27.5268 31.0979 27 29.8261 27 28.5C27 27.1739 27.5268 25.9021 28.4645 24.9645C29.4021 24.0268 30.6739 23.5 32 23.5C33.3261 23.5 34.5979 24.0268 35.5355 24.9645C36.4732 25.9021 37 27.1739 37 28.5C37 29.8261 36.4732 31.0979 35.5355 32.0355C34.5979 32.9732 33.3261 33.5 32 33.5ZM32 21C27 21 22.73 24.11 21 28.5C22.73 32.89 27 36 32 36C37 36 41.27 32.89 43 28.5C41.27 24.11 37 21 32 21Z" fill={iconColor} />
            <defs>
                <filter id="filter0_d_780_5" x="0" y="0" width="64" height="64" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_780_5" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_780_5" result="shape" />
                </filter>
            </defs>
        </svg>
    )
}

export const VisibilityOffIcon = ({
    iconColor = '#F8E5EC',
    width = 45,
    height = 45,
    backgroundColor = '#1A237E',
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_780_6)">
                <circle cx="32" cy="28" r="28" fill={backgroundColor} />
            </g>
            <path d="M31.83 25L35 28.16C35 28.11 35 28.05 35 28C35 27.2044 34.6839 26.4413 34.1213 25.8787C33.5587 25.3161 32.7956 25 32 25C31.94 25 31.89 25 31.83 25ZM27.53 25.8L29.08 27.35C29.03 27.56 29 27.77 29 28C29 28.7956 29.3161 29.5587 29.8787 30.1213C30.4413 30.6839 31.2044 31 32 31C32.22 31 32.44 30.97 32.65 30.92L34.2 32.47C33.53 32.8 32.79 33 32 33C30.6739 33 29.4021 32.4732 28.4645 31.5355C27.5268 30.5979 27 29.3261 27 28C27 27.21 27.2 26.47 27.53 25.8ZM22 20.27L24.28 22.55L24.73 23C23.08 24.3 21.78 26 21 28C22.73 32.39 27 35.5 32 35.5C33.55 35.5 35.03 35.2 36.38 34.66L36.81 35.08L39.73 38L41 36.73L23.27 19L22 20.27ZM32 23C33.3261 23 34.5979 23.5268 35.5355 24.4645C36.4732 25.4021 37 26.6739 37 28C37 28.64 36.87 29.26 36.64 29.82L39.57 32.75C41.07 31.5 42.27 29.86 43 28C41.27 23.61 37 20.5 32 20.5C30.6 20.5 29.26 20.75 28 21.2L30.17 23.35C30.74 23.13 31.35 23 32 23Z" fill={iconColor} />
            <defs>
                <filter id="filter0_d_780_6" x="0" y="0" width="64" height="64" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_780_6" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_780_6" result="shape" />
                </filter>
            </defs>
        </svg>

    )
}

export const EditIcon = ({
    iconColor = 'white',
    width = 45,
    height = 45,
    backgroundColor = '#B80041',
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_780_7)">
                <circle cx="32" cy="28" r="28" fill={backgroundColor} />
            </g>
            <path d="M32 17C30.5555 17 29.1251 17.2845 27.7905 17.8373C26.4559 18.3901 25.2433 19.2004 24.2218 20.2218C22.1589 22.2847 21 25.0826 21 28C21 30.9174 22.1589 33.7153 24.2218 35.7782C25.2433 36.7996 26.4559 37.6099 27.7905 38.1627C29.1251 38.7155 30.5555 39 32 39C34.9174 39 37.7153 37.8411 39.7782 35.7782C41.8411 33.7153 43 30.9174 43 28H40.8C40.8 30.3339 39.8729 32.5722 38.2225 34.2225C36.5722 35.8729 34.3339 36.8 32 36.8C29.6661 36.8 27.4278 35.8729 25.7775 34.2225C24.1271 32.5722 23.2 30.3339 23.2 28C23.2 25.6661 24.1271 23.4278 25.7775 21.7775C27.4278 20.1271 29.6661 19.2 32 19.2V17ZM39.458 18.1C39.271 18.1 39.073 18.177 38.93 18.32L37.588 19.651L40.338 22.401L41.68 21.07C41.966 20.784 41.966 20.3 41.68 20.025L39.975 18.32C39.832 18.177 39.645 18.1 39.458 18.1ZM36.807 20.432L28.7 28.55V31.3H31.45L39.557 23.182L36.807 20.432Z" fill={iconColor} />
            <defs>
                <filter id="filter0_d_780_7" x="0" y="0" width="64" height="64" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_780_7" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_780_7" result="shape" />
                </filter>
            </defs>
        </svg>
    )
}


export const ReStore = ({
    iconColor = 'white',
    width = 45,
    height = 45,
    backgroundColor = '#23792B',
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_80_75)">
                <circle cx="23.4068" cy="20.4068" r="20.4068" fill={backgroundColor} />
            </g>
            <path d="M25.0001 22.0001H27.0001L23 18L19 22.0001H21V26.0001H25.0001V22.0001ZM17 15H29.0001V27.0001C29.0001 27.5001 28.8001 28.0001 28.3901 28.3901C28.0001 28.8001 27.5001 29.0001 27.0001 29.0001H19C18.5 29.0001 18 28.8001 17.61 28.3901C17.2 28.0001 17 27.5001 17 27.0001V15ZM30.0001 12V14H16V12H19.5L20.5 11H25.5001L26.5001 12H30.0001Z" fill={iconColor} />
            <defs>
                <filter id="filter0_d_80_75" x="0.0847459" y="0" width="46.644" height="46.644" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="2.91525" />
                    <feGaussianBlur stdDeviation="1.45763" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_80_75" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_80_75" result="shape" />
                </filter>
            </defs>
        </svg>
    )
}

export const CloseIcon = ({
    iconColor = 'white',
    width = 28,
    height = 28,
    backgroundColor = '#B80041',
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="14" cy="13" r="10" fill="white" />
            <g filter="url(#filter0_d_102_5)">
                <path d="M14 3C19.53 3 24 7.47 24 13C24 18.53 19.53 23 14 23C8.47 23 4 18.53 4 13C4 7.47 8.47 3 14 3ZM17.59 8L14 11.59L10.41 8L9 9.41L12.59 13L9 16.59L10.41 18L14 14.41L17.59 18L19 16.59L15.41 13L19 9.41L17.59 8Z" fill="#B80041" />
            </g>
            <defs>
                <filter id="filter0_d_102_5" x="0" y="0" width="28" height="28" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_102_5" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_102_5" result="shape" />
                </filter>
            </defs>
        </svg>
    )
}

export const ProfileIcon = ({
    iconColor = 'white',
    width = 350,
    height = 350,
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 676 676"
        >
            <g clipPath="url(#clip0_102_2)">
                <path
                    fill="#000"
                    d="M279.35 670.93c-2.03-.36-4.057-.733-6.08-1.12-1.32-38.31-5.85-116.94-21.3-199.29-8.45-45.07-20.18-91.27-36.45-132.57a412.694 412.694 0 00-19.07-41.84c-16.44-31.05-36.38-57.19-60.56-74.9l3.56-4.86c20.11 14.74 38.183 35.433 54.22 62.08 4.81 7.98 9.43 16.493 13.86 25.54 4.75 9.68 9.28 19.957 13.59 30.83 2.75 6.92 5.407 14.08 7.97 21.48 11.16 32.13 20.647 68.79 28.46 109.98 1.73 9.1 3.377 18.423 4.94 27.97 12.11 73.97 15.76 142.36 16.86 176.7zM202.57 647.77c-2.16-.94-4.32-1.91-6.46-2.91-2.09-22.7-5.93-50.86-12.95-77.59a254.64 254.64 0 00-11.81-35.2c-8.01-18.75-18.38-34.69-31.79-44.52l3.56-4.85c14.04 10.28 24.87 26.53 33.24 45.54 9.43 21.42 15.72 46.35 19.91 70.17 3.11 17.69 5.07 34.78 6.3 49.36zM102.262 221.07c16.617 0 30.088-13.471 30.088-30.088 0-16.618-13.471-30.089-30.088-30.089-16.618 0-30.089 13.471-30.089 30.089 0 16.617 13.471 30.088 30.089 30.088zM111.951 480.079c16.618 0 30.089-13.471 30.089-30.088 0-16.618-13.471-30.089-30.089-30.089-16.617 0-30.088 13.471-30.088 30.089 0 16.617 13.471 30.088 30.088 30.088zM221.714 241.521c-6.38 35.998 7.705 68.593 7.705 68.593s24.43-25.769 30.81-61.766c6.381-35.997-7.704-68.592-7.704-68.592s-24.43 25.768-30.811 61.765z"
                    opacity="0.2"
                ></path>
                <path
                    fill="#000"
                    d="M121.718 326.971c34.335 12.555 68.836 4.498 68.836 4.498s-21.166-28.413-55.501-40.968c-34.335-12.555-68.836-4.498-68.836-4.498s21.166 28.413 55.501 40.968zM115.895 556.333c24.066 8.8 48.283 3.059 48.283 3.059s-14.801-20.008-38.867-28.808c-24.066-8.8-48.283-3.06-48.283-3.06s14.801 20.009 38.867 28.809z"
                    opacity="0.2"
                ></path>
                <path
                    fill="#D0CDE1"
                    d="M337.306 412.849c72.775 0 131.77-58.996 131.77-131.77 0-72.775-58.995-131.77-131.77-131.77s-131.77 58.995-131.77 131.77c0 72.774 58.995 131.77 131.77 131.77z"
                ></path>
                <path
                    fill="#D0CDE1"
                    d="M285.833 381.965s16.472 78.239 16.472 86.474c0 8.236 78.238 45.296 78.238 45.296l70.003-12.353 24.707-74.121s-41.178-61.767-41.178-86.474l-148.242 41.178z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M648.78 471.1l-1.12 2.58a338.559 338.559 0 01-368.31 197.25c-2.03-.36-4.057-.733-6.08-1.12a334.979 334.979 0 01-61.14-18.03 344.682 344.682 0 01-9.56-4.01c-2.16-.94-4.32-1.91-6.46-2.91a337.571 337.571 0 01-55.25-32.28l-15.62-45.31 8.78-6.7 18.06-13.79 19.27-14.71 5.01-3.83 75.61-57.72 5.58-4.26 39.3-30 .01-.01s42.5 69.25 104.27 48.66c61.77-20.59 60.42-79.63 60.42-79.63l187.23 65.82zM223.035 174.916s41.837-90.646 122.024-69.728c80.186 20.919 125.509 52.296 128.996 83.674 3.486 31.377-1.744 78.443-1.744 78.443s-8.716-64.498-64.498-50.552c-55.782 13.945-142.941 3.486-142.941 3.486l-13.946 125.51s-15.688-22.662-33.12-8.716c-17.432 13.945-50.553-134.226 5.229-162.117z"
                ></path>
                <path
                    fill="#2F2E41"
                    d="M212.13 651.78a344.682 344.682 0 01-9.56-4.01c-2.16-.94-4.32-1.91-6.46-2.91a338.844 338.844 0 01-87.59-58.7c9.19-12.52 16.72-18.89 16.72-18.89h61.77l9.26 31.14 15.86 53.37zM594.67 464.32l52.99 9.36a337.967 337.967 0 01-56.76 88.57l3.77-97.93z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_102_2">
                    <path fill="#fff" d="M0 0H676V676H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export const ThemeIcon = ({
    width = "21",
    height = "21",
    color = "#000",
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 21 21"
        >
            <path
                stroke={color}
                strokeLinejoin="round"
                strokeWidth="1.313"
                d="M18.375 2.625H2.625c-.725 0-1.313.588-1.313 1.313v10.5c0 .724.588 1.312 1.313 1.312h15.75c.725 0 1.313-.588 1.313-1.313v-10.5c0-.724-.588-1.312-1.313-1.312z"
            ></path>
            <path
                fill={color}
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.313"
                d="M12.469 18.375l-.328-2.625H8.859l-.328 2.625h3.938z"
            ></path>
            <path
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.313"
                d="M15.094 18.375H5.906"
            ></path>
            <path
                fill={color}
                d="M1.313 12.469v1.969a1.316 1.316 0 001.312 1.312h15.75a1.316 1.316 0 001.313-1.313V12.47H1.313zm9.187 2.625a.656.656 0 110-1.313.656.656 0 010 1.313z"
            ></path>
        </svg>
    );
}

export const StoreIcon = ({
    width = "21",
    height = "21",
    color = "#FFF",
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 19 19"
        >
            <path
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.188"
                d="M10.688 17.219v-5.64a.89.89 0 01.89-.892h2.375a.89.89 0 01.89.891v5.64m1.782-.593V8.906v7.719zM2.375 8.906v7.719-7.719zm11.818-7.125H4.807c-.809 0-1.54.446-1.853 1.13L1.347 6.42c-.541 1.18.357 2.518 1.751 2.56h.074c1.166 0 2.11-.934 2.11-1.938 0 1.002.945 1.938 2.11 1.938S9.5 8.113 9.5 7.042c0 1.002.944 1.938 2.11 1.938 1.165 0 2.11-.867 2.11-1.938 0 1.07.944 1.938 2.11 1.938h.072c1.394-.043 2.292-1.38 1.75-2.56l-1.606-3.508c-.314-.685-1.044-1.13-1.853-1.13zM1.188 17.22h16.625H1.188zm3.859-6.532h2.969a.89.89 0 01.89.891v3.266h-4.75v-3.266a.89.89 0 01.89-.89z"
            ></path>
        </svg>
    );
}

export const SubscriptionIcon = ({
    width = "21",
    height = "21",
    color = "#FFF",
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                fill={color}
                d="M19 14V6c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zm-9-1c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm13-6v11c0 1.1-.9 2-2 2H4v-2h17V7h2z"
            ></path>
        </svg>
    );
}

export const PaymentIcon = ({
    width = "21",
    height = "21",
    color = "#FFF",
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 25 25"
        >
            <path
                fill={color}
                d="M20.833 4.167H4.167A2.067 2.067 0 002.094 6.25l-.01 12.5c0 1.156.926 2.083 2.083 2.083h16.666a2.076 2.076 0 002.084-2.083V6.25a2.076 2.076 0 00-2.084-2.083zm0 14.583H4.167V12.5h16.666v6.25zm0-10.417H4.167V6.25h16.666v2.083z"
            ></path>
        </svg>
    );
}

export const MovieIcon = ({
    width = "21",
    height = "21",
    color = "#FFF",
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="ionicon"
            viewBox="0 0 512 512"
            width={width}
            height={height}
        >
            <path
                fill="none"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="32"
                d="M374.79 308.78L457.5 367a16 16 0 0022.5-14.62V159.62A16 16 0 00457.5 145l-82.71 58.22A16 16 0 00368 216.3v79.4a16 16 0 006.79 13.08z"
            ></path>
            <path
                fill="none"
                stroke={color}
                strokeMiterlimit="10"
                strokeWidth="32"
                d="M268 384H84a52.15 52.15 0 01-52-52V180a52.15 52.15 0 0152-52h184.48A51.68 51.68 0 01320 179.52V332a52.15 52.15 0 01-52 52z"
            ></path>
        </svg>
    );
}

export function SeriesIcon({
    className = "",
}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            viewBox="0 0 24 24"
        >
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M1.75 4.5a.25.25 0 00-.25.25v14.5c0 .138.112.25.25.25h20.5a.25.25 0 00.25-.25V4.75a.25.25 0 00-.25-.25H1.75zM0 4.75C0 3.784.784 3 1.75 3h20.5c.966 0 1.75.784 1.75 1.75v14.5A1.75 1.75 0 0122.25 21H1.75A1.75 1.75 0 010 19.25V4.75z"
            ></path>
            <path
                fill="currentColor"
                d="M9 15.584V8.416a.5.5 0 01.77-.42l5.576 3.583a.5.5 0 010 .842L9.77 16.005a.5.5 0 01-.77-.42z"></path>
        </svg>
    );
}

export const TrascodeingIcon = ({
    width = 45,
    height = 45,
    color = "#172337",
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 47 47"
        >
            <g filter="url(#filter0_d_301_11)">
                <path
                    fill="#172337"
                    d="M43.814 20.407c0 11.27-9.137 20.407-20.407 20.407S3 31.677 3 20.407 12.136 0 23.407 0c11.27 0 20.407 9.136 20.407 20.407z"
                ></path>
            </g>
            <path
                fill="#F8E5EC"
                d="M32.604 17.494c-.618-.91-1.437-1.486-2.373-1.666-.916-.175-1.917.056-2.828.658l-.916.601a.628.628 0 00-.189.205 4.076 4.076 0 00-2.97-1.293c-2.289 0-4.148 1.912-4.148 4.27 0 .605.126 1.182.346 1.705l-.377.25c-.644.424-1.297.587-1.904.47-.599-.114-1.142-.507-1.566-1.135a2.37 2.37 0 01-.375-1.823 2.456 2.456 0 011.075-1.558l9.859-6.484-.285 1.755a.633.633 0 00.107.47c.1.138.247.228.4.253l.112.01a.624.624 0 00.62-.529l.462-2.85c.03-.138.094-.458-.1-.746a.799.799 0 00-.349-.285l-.779-.2-2.368-.554L23.915 9c-.292 0-.544.2-.611.483a.626.626 0 00.469.763l1.749.41-9.83 6.467a3.693 3.693 0 00-1.618 2.362 3.629 3.629 0 00.568 2.785c.617.912 1.439 1.486 2.372 1.666.205.038.413.058.62.058.734 0 1.5-.247 2.209-.716l.345-.226a4.088 4.088 0 003.14 1.486c2.29 0 4.149-1.913 4.149-4.27 0-.74-.184-1.436-.505-2.042a.581.581 0 00.208-.084l.914-.601c.637-.42 1.306-.586 1.901-.471.6.114 1.14.507 1.567 1.133a2.37 2.37 0 01.375 1.823 2.462 2.462 0 01-1.075 1.558l-10.124 6.664.276-1.704a.639.639 0 00-.11-.471.624.624 0 00-.426-.256l-.08-.004a.628.628 0 00-.625.53l-.462 2.852c-.03.148-.092.458.103.748.094.139.22.224.334.278l.629.166 2.534.595.146.018a.635.635 0 00.539-.96.623.623 0 00-.395-.288l-1.805-.422 10.16-6.687c.846-.559 1.422-1.396 1.618-2.361a3.628 3.628 0 00-.57-2.788z"
            ></path>
            <path
                fill="#F8E5EC"
                d="M22.433 21.043v.545l-1.886-.84v-.466l1.886-.842v.55l-1.313.523 1.313.53zM23.043 20.688l-.06-.925a7.443 7.443 0 01-.018-.388c0-.108.027-.191.083-.25a.273.273 0 01.215-.09c.108 0 .18.039.216.115a.778.778 0 01.056.333c0 .085-.005.17-.014.258l-.08.951a.67.67 0 01-.056.26c-.03.061-.077.093-.142.093-.067 0-.114-.03-.141-.088a.848.848 0 01-.059-.269zm.207 1.27a.29.29 0 01-.2-.076.274.274 0 01-.085-.213.28.28 0 01.083-.204.266.266 0 01.2-.083c.078 0 .146.026.202.083a.276.276 0 01.083.204.277.277 0 01-.283.29zM25.95 20.746l-1.885.847v-.546l1.32-.532-1.32-.525v-.541l1.888.837v.46h-.002z"
            ></path>
            <defs>
                <filter
                    id="filter0_d_301_11"
                    width="46.644"
                    height="46.644"
                    x="0.085"
                    y="0"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dy="2.915"></feOffset>
                    <feGaussianBlur stdDeviation="1.458"></feGaussianBlur>
                    <feComposite in2="hardAlpha" operator="out"></feComposite>
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_301_11"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_dropShadow_301_11"
                        result="shape"
                    ></feBlend>
                </filter>
            </defs>
        </svg>
    );
}

export function TranscoderIcon({
    className,
}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0" y="0"
            viewBox="0 0 1000 1000"
            className={className}
        >
            <path
                fill="currentColor"
                d="M358.6 513.3c0-38.1-.1-76.2 0-114.3.2-56.4 56.8-90.8 106-63 66.2 37.4 131.8 75.7 196.8 115.1 47.6 28.9 47.1 95.1-.5 123.9-64.2 38.7-128.8 77-194.1 113.7-51.2 28.8-109.2-5.3-108.3-64.3.6-37 .1-74 .1-111.1z"></path>
            <path
                fill="currentColor"
                d="M623.7 134.8c43.6 14 83 34.8 118.6 62.5 73.1 56.8 121.2 130.2 143.3 219.9 28.6 115.9 9.5 224.4-56.2 324.1-43.8 66.6-104.1 114.1-177.4 144.8-21.4 9-34 1.7-43.2-15.7-8-15.1-.1-33.5 17.7-41.5 34.7-15.5 67.2-34.3 95.5-59.7 52.5-47.2 88.6-104.6 103.9-173.9 24.9-112.4 1.1-213.6-73.5-301.7-48.9-57.8-112-92.8-185.8-108.1-20.6-4.3-42.1-4.5-63.1-7.1-16.4-2-27.1-11.5-29.3-24.8-2.7-15.9 4-27.7 19.5-36.1 63.3-34.3 126.5-68.7 189.9-103 13.5-7.3 28.6-5.4 38.2 4.3 10.1 10.2 12.6 26.8 5 39.1-2.7 4.4-6.9 8.2-11.2 11.2-30 21.1-60.3 41.9-90.4 62.8-.5.3-.6 1.1-1.5 2.9zM377.2 899.6c-20.4-9.1-39.4-16.6-57.5-25.8-80.8-41.3-140.4-103.1-178.9-185.4C113 629.1 98.6 566.8 102 501.6c5.7-110.3 47.8-204.5 129.1-280.5 34.4-32.1 73.2-56.9 116.7-74.6 21.8-8.9 41.1.7 45.9 22.5 3 13.7-5.5 29-20.9 35.2-27.3 11.1-52.6 25.6-75.7 43.8-69 54.1-112.7 123.5-127.5 210.6-14.5 85.4 1.8 164.9 47 237.9 39.5 63.7 95.1 109.1 165.7 135.7 36.9 13.9 75.2 20 114.3 22.1 15.3.8 27.2 10.7 29.6 24.2 2.9 16.1-4.1 29.6-19.4 35.8-59.4 24.4-118.8 48.6-178.3 72.7-18.7 7.5-36.7.6-43-15.9-7-18.3.8-35.9 19.6-43.4 23.4-9.4 46.9-18.3 72.1-28.1z"></path>
        </svg>
    );
}

export function AnalyticsIcon({ className }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 490 490"
            version="1.1"
            viewBox="0 0 490 490"
            xmlSpace="preserve"
            className={className}
        >
            <path fill="currentColor"
                d="M84.765 454.31h66.17V280.967h-66.17V454.31zm15.312-158.03h35.545v142.717h-35.545V296.28zM66.17 345.589H0v108.72h66.17v-108.72zm-15.313 93.408H15.313v-78.095h35.545v78.095zM169.529 454.31h66.17V193.16h-66.17v261.15zm15.313-245.838h35.545v230.525h-35.545V208.472zM254.294 454.31h66.17V35.13h-66.17v419.18zm15.312-403.868h35.545v388.555h-35.545V50.442zM405.235 454.31V116.612h-66.177V454.31h66.177zm-50.864-322.386h35.552v307.073h-35.552V131.924zM423.823 454.87H490V246.409h-66.177V454.87zm15.312-193.148h35.552v177.836h-35.552V261.722z"></path>
        </svg>
    );
}

export function MarketingIcon({ className }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 247.065 247.065"
            version="1.1"
            viewBox="0 0 247.065 247.065"
            xmlSpace="preserve"
            className={className}
        >
            <path
                fill="currentColor"
                d="M160.551 36.935a7.5 7.5 0 00-9.451-4.815l-31.376 10.196a7.499 7.499 0 00-4.815 9.45l1.568 4.828-79.811 58.625-31.483 10.229a7.502 7.502 0 00-4.815 9.451l19.129 58.862a7.504 7.504 0 007.131 5.184 7.5 7.5 0 002.32-.369l31.478-10.229 16.173.085c3.248 15.336 16.888 26.88 33.176 26.88 16.164 0 29.714-11.371 33.095-26.531l16.587.087 1.568 4.829a7.495 7.495 0 007.133 5.182c.78 0 1.563-.122 2.317-.367l31.377-10.195a7.5 7.5 0 004.816-9.45L160.551 36.935zM31.444 181.992l-14.492-44.597 18.364-5.967 14.49 44.597-18.362 5.967zm78.33 18.32c-7.912 0-14.694-4.887-17.513-11.797l34.958.184c-2.863 6.815-9.602 11.613-17.445 11.613zm-45.06-26.943l-7.888-24.277-7.888-24.277 72.419-53.194 22.209 68.349 11.006 33.873-89.858-.474zm108.258 8.56l-.921-2.833-.004-.017-19.815-60.983-20.74-63.833 17.111-5.561 41.48 127.665-17.111 5.562zM185.807 73.393a7.5 7.5 0 0010.088-3.277l13.461-26.41a7.5 7.5 0 10-13.363-6.812l-13.461 26.41a7.5 7.5 0 003.275 10.089zM242.176 144.712l-26.414-13.455a7.5 7.5 0 00-6.809 13.366l26.414 13.455a7.502 7.502 0 006.809-13.366zM204.242 101.204a7.504 7.504 0 007.131 5.184 7.5 7.5 0 002.32-.369l28.188-9.16a7.5 7.5 0 00-4.637-14.266l-28.188 9.16a7.501 7.501 0 00-4.814 9.451z"></path>
        </svg>
    );
}

export function RTMPServerIcon({
    className
}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            className={className}
        >
            <path
                fill="currentColor"
                d="M391.1 373.9H334V334l16.2 16.1c11.2 11.2 24.9 3.9 28.9-.1 7.9-8 7.9-20.9-.1-28.9l-51-50.6c-8-7.9-20.8-7.9-28.8 0l-51 50.6c-8 7.9-8.1 20.9-.1 28.9 7.9 8 20.9 8.1 28.9.1l16.2-16.1v39.8H122.9c-38.6 0-70.1-31.4-70.1-70s31.4-70.1 70.1-70.1h268.2c38.6 0 70 31.4 70 70.1.1 38.6-31.4 70.1-70 70.1zm-338.3-251c0-38.6 31.4-70 70.1-70h268.2c38.6 0 70 31.4 70 70s-31.4 70.1-70 70.1H122.9c-38.6-.1-70.1-31.5-70.1-70.1zm402.4 90.4c28.3-20.1 46.8-53.2 46.8-90.5C502 61.7 452.3 12 391.1 12H122.9C61.7 12 12 61.7 12 122.9c0 37.3 18.5 70.4 46.8 90.5C30.5 233.5 12 266.5 12 303.8c0 61.1 49.7 110.9 110.9 110.9h170.3v66.9c0 11.3 9.1 20.4 20.4 20.4 11.3 0 20.4-9.1 20.4-20.4v-66.9h57.1c61.1 0 110.9-49.7 110.9-110.9 0-37.3-18.5-70.3-46.8-90.5z"></path>
            <path
                fill="currentColor"
                d="M113.3 143.3h8.9c11.3 0 20.4-9.1 20.4-20.4 0-11.3-9.1-20.4-20.4-20.4h-8.9c-11.3 0-20.4 9.1-20.4 20.4-.1 11.3 9.1 20.4 20.4 20.4zM189.8 143.3h8.9c11.3 0 20.4-9.1 20.4-20.4 0-11.3-9.1-20.4-20.4-20.4h-8.9c-11.3 0-20.4 9.1-20.4 20.4 0 11.3 9.1 20.4 20.4 20.4zM126.7 283.4h-8.9c-11.3 0-20.4 9.1-20.4 20.4 0 11.3 9.1 20.4 20.4 20.4h8.9c11.3 0 20.4-9.1 20.4-20.4 0-11.3-9.2-20.4-20.4-20.4zM203.2 283.4h-8.9c-11.3 0-20.4 9.1-20.4 20.4 0 11.3 9.1 20.4 20.4 20.4h8.9c11.3 0 20.4-9.1 20.4-20.4 0-11.3-9.1-20.4-20.4-20.4z"></path>
        </svg>
    );
}

export const TrascodeInProsessIcon = ({
    width = 45,
    height = 45,
    prosess = 0,
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 47 47"
        >
            <g filter="url(#filter0_d_306_4)">
                <path
                    fill="#F3A847"
                    d="M43.814 20.407c0 11.27-9.137 20.407-20.407 20.407S3 31.677 3 20.407 12.136 0 23.407 0c11.27 0 20.407 9.136 20.407 20.407z"
                ></path>
            </g>
            <path
                fill="#F8E5EC"
                d="M23.27 20.417l4.881 4.109-.673.138-.49.091c-.482.1-.758.636-.543 1.072l.206.444 1.04 2.25-1.086.504-1.04-2.242-.2-.444a.754.754 0 00-1.162-.275l-.39.306-.544.436v-6.389zm-.567-1.768a.582.582 0 00-.582.582v8.761c0 .322.26.582.582.582a.589.589 0 00.367-.123l1.462-1.186 1.27 2.77c.1.207.306.33.528.33.084 0 .168 0 .252-.062l2.112-.98c.291-.137.429-.49.276-.772l-1.278-2.778 1.844-.344a.673.673 0 00.329-.2c.207-.244.176-.604-.092-.825l-6.687-5.625-.008.008a.578.578 0 00-.375-.138zm3.244 1.003v-1.53h3.826v1.53h-3.826zm-.895-4.01l2.166-2.165 1.078 1.079-2.165 2.165-1.079-1.079zM22.122 12h1.53v3.826h-1.53V12zm-4.645 11.218l2.165-2.166 1.08 1.079-2.166 2.165-1.08-1.078zm0-8.662l1.079-1.08 2.165 2.166-1.079 1.08-2.165-2.166zm2.349 5.096H16v-1.53h3.826v1.53z"
            ></path>
            <defs>
                <filter
                    id="filter0_d_306_4"
                    width="46.644"
                    height="46.644"
                    x="0.085"
                    y="0"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dy="2.915"></feOffset>
                    <feGaussianBlur stdDeviation="1.458"></feGaussianBlur>
                    <feComposite in2="hardAlpha" operator="out"></feComposite>
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_306_4"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_dropShadow_306_4"
                        result="shape"
                    ></feBlend>
                </filter>
            </defs>
        </svg>
    );
}

export const TrascodeingDoneIcon = ({
    width = 45,
    height = 45,
    fill = '#fff',
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 47 47"
        >
            <g filter="url(#filter0_d_305_41)">
                <circle cx="23.407" cy="20.407" r="20.407" fill="#23792B"></circle>
            </g>
            <g clipPath="url(#clip0_305_41)">
                <path
                    fill="#F8E5EC"
                    d="M29.931 17.785A6.554 6.554 0 0023.5 12.5a6.559 6.559 0 00-5.819 3.535A5.245 5.245 0 0013 21.25a5.254 5.254 0 005.25 5.25h11.375A4.377 4.377 0 0034 22.125c0-2.31-1.794-4.183-4.069-4.34zm-.306 6.965H18.25a3.499 3.499 0 01-3.5-3.5 3.477 3.477 0 013.115-3.474l.936-.096.438-.831a4.785 4.785 0 014.261-2.6 4.815 4.815 0 014.716 3.877l.263 1.313 1.338.096a2.608 2.608 0 012.433 2.59 2.633 2.633 0 01-2.625 2.625zm-7.875-3.343l-1.829-1.829-1.233 1.234 3.062 3.063 5.259-5.259-1.234-1.234-4.025 4.025z"
                ></path>
            </g>
            <defs>
                <filter
                    id="filter0_d_305_41"
                    width="46.644"
                    height="46.644"
                    x="0.085"
                    y="0"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dy="2.915"></feOffset>
                    <feGaussianBlur stdDeviation="1.458"></feGaussianBlur>
                    <feComposite in2="hardAlpha" operator="out"></feComposite>
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_305_41"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_dropShadow_305_41"
                        result="shape"
                    ></feBlend>
                </filter>
                <clipPath id="clip0_305_41">
                    <path
                        fill="#fff"
                        d="M0 0H21V21H0z"
                        transform="translate(13 9)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export const GenreIcon = ({
    width = "21",
    height = "21",
    color = '#fff',
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 74 74"
        >
            <path
                fill={color}
                d="M51.09 32.585l-15.03-9.25a9.246 9.246 0 00-14.093 7.886v18.498a9.245 9.245 0 007.813 9.132 9.252 9.252 0 006.28-1.258l15.03-9.25a9.249 9.249 0 000-15.758zM31.216 49.719V31.221l15.03 9.25-15.03 9.248z"
            ></path>
            <path
                fill={color}
                d="M67.3 17.172L45.335 2.523v.001a15.03 15.03 0 00-16.673 0L6.694 17.174v-.002A15.032 15.032 0 000 29.682v30.444A13.871 13.871 0 0013.874 74H60.12a13.875 13.875 0 0013.874-13.874V29.682a15.032 15.032 0 00-6.694-12.51zm-2.555 42.954a4.627 4.627 0 01-4.624 4.624H13.874a4.626 4.626 0 01-4.624-4.624V29.682a5.78 5.78 0 012.578-4.81l21.967-14.648a5.778 5.778 0 016.417 0L62.18 24.873a5.78 5.78 0 012.567 4.809l-.001 30.444z"
            ></path>
        </svg>
    );
}

export const CategoryIcon = ({
    width = "20",
    height = "21",
    color = '#fff',
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 19 20"
        >
            <path
                fill={color}
                d="M8 11.5v8H0v-8h8zM9 0l5.5 9h-11L9 0zm5.5 11c2.5 0 4.5 2 4.5 4.5S17 20 14.5 20 10 18 10 15.5s2-4.5 4.5-4.5z"
            ></path>
        </svg>
    );
}

export const LanguageIcon = ({
    width = "21",
    height = "21",
    color = '#fff',
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="ionicon"
            viewBox="0 0 512 512"
            width={width}
            height={height}
        >
            <path
                fill="none"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="32"
                d="M48 112h288M192 64v48m80 336l96-224 96 224m-162.5-64h133M281.3 112S257 206 199 277 80 384 80 384"
            ></path>
            <path
                fill="none"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="32"
                d="M256 336s-35-27-72-75-56-85-56-85"
            ></path>
        </svg>
    );
}

export const StarCastIcon = ({
    width = "21",
    height = "21",
    color = '#fff',
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="ionicon"
            viewBox="0 0 512 512"
            width={width}
            height={height}
        >
            <path
                fill="none"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="32"
                d="M402 168c-2.93 40.67-33.1 72-66 72s-63.12-31.32-66-72c-3-42.31 26.37-72 66-72s69 30.46 66 72z"
            ></path>
            <path
                fill="none"
                stroke={color}
                strokeMiterlimit="10"
                strokeWidth="32"
                d="M336 304c-65.17 0-127.84 32.37-143.54 95.41-2.08 8.34 3.15 16.59 11.72 16.59h263.65c8.57 0 13.77-8.25 11.72-16.59C463.85 335.36 401.18 304 336 304z"
            ></path>
            <path
                fill="none"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="32"
                d="M200 185.94c-2.34 32.48-26.72 58.06-53 58.06s-50.7-25.57-53-58.06C91.61 152.15 115.34 128 147 128s55.39 24.77 53 57.94z"
            ></path>
            <path
                fill="none"
                stroke={color}
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="32"
                d="M206 306c-18.05-8.27-37.93-11.45-59-11.45-52 0-102.1 25.85-114.65 76.2-1.65 6.66 2.53 13.25 9.37 13.25H154"
            ></path>
        </svg>
    );
}


export const SliderIcon = ({
    width = "21",
    height = "21",
    color = '#fff',
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 512 512"
            version="1.1"
            viewBox="0 0 512 512"
            xmlSpace="preserve"
            width={width}
            height={height}
        >
            <path d="M434.605 285.767H77.395C34.72 285.767 0 320.487 0 363.163s34.72 77.395 77.395 77.395h357.209c42.676 0 77.395-34.72 77.395-77.395s-34.719-77.396-77.394-77.396zm-65.165 119.07H77.395c-22.979 0-41.674-18.695-41.674-41.674s18.695-41.674 41.674-41.674H369.44c-7.729 12.039-12.231 26.337-12.231 41.674s4.502 29.635 12.231 41.674zm65.165 0c-22.979 0-41.674-18.695-41.674-41.674s18.695-41.674 41.674-41.674 41.674 18.695 41.674 41.674-18.695 41.674-41.674 41.674zM434.605 71.442H77.395C34.72 71.442 0 106.161 0 148.837s34.72 77.395 77.395 77.395h357.209c42.676 0 77.395-34.72 77.395-77.395s-34.719-77.395-77.394-77.395zm-357.21 119.07c-22.979 0-41.674-18.695-41.674-41.674s18.695-41.674 41.674-41.674 41.674 18.695 41.674 41.674c.001 22.978-18.694 41.674-41.674 41.674zm357.21 0H142.561c7.728-12.039 12.23-26.337 12.23-41.674s-4.502-29.635-12.23-41.674h292.044c22.979 0 41.674 18.695 41.674 41.674 0 22.978-18.695 41.674-41.674 41.674z" fill={color} ></path>
        </svg>
    );
}

export const SectionIcon = ({
    width = "21",
    height = "21",
    color = '#fff',
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="ionicon"
            viewBox="0 0 512 512"
            width={width}
            height={height}
        >
            <rect
                width="336"
                height="336"
                x="128"
                y="128"
                fill="none"
                stroke={color}
                strokeLinejoin="round"
                strokeWidth="32"
                rx="57"
                ry="57"
            ></rect>
            <path
                fill="none"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="32"
                d="M383.5 128l.5-24a56.16 56.16 0 00-56-56H112a64.19 64.19 0 00-64 64v216a56.16 56.16 0 0056 56h24m168-168v160m80-80H216"
            ></path>
        </svg>
    );
}

export const VideoIcon = ({
    width = "50",
    height = "50",
    color = '#fff',
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 100 100"
        >
            <circle cx="50" cy="50" r="50" fill="#373E67"></circle>
            <path
                stroke="#876CE1"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3.438"
                d="M63.26 56.17l8.886 6.254a1.72 1.72 0 002.416-1.57V40.146a1.719 1.719 0 00-2.416-1.57L63.26 44.83a1.72 1.72 0 00-.73 1.405v8.53a1.72 1.72 0 00.73 1.405v0z"
            ></path>
            <path
                stroke="#876CE1"
                strokeMiterlimit="10"
                strokeWidth="3.438"
                d="M51.79 64.25H32.022a5.602 5.602 0 01-5.586-5.586V42.336a5.602 5.602 0 015.586-5.586h19.818a5.552 5.552 0 015.534 5.534v16.38a5.602 5.602 0 01-5.586 5.586z"
            ></path>
        </svg>
    );
}

export const ViewIcon = ({
    width = "50",
    height = "50",
    color = '#fff',
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 100 100"
        >
            <circle cx="50" cy="50" r="50" fill="#2D586F"></circle>
            <path
                stroke="#2FB8F3"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3.5"
                d="M50.464 35.031c-8.373 0-16.961 4.846-23.723 14.538a1.718 1.718 0 00-.029 1.908C31.907 59.61 40.382 65.97 50.465 65.97c9.973 0 18.62-6.379 23.825-14.53a1.734 1.734 0 000-1.876c-5.217-8.056-13.928-14.532-23.825-14.532z"
            ></path>
            <path
                stroke="#2FB8F3"
                strokeMiterlimit="10"
                strokeWidth="3.5"
                d="M50.5 59.094a8.594 8.594 0 100-17.188 8.594 8.594 0 000 17.188z"
            ></path>
        </svg>
    );
}

export const UsersIcon = ({
    width = "50",
    height = "50",
    color = '#fff',
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 100 100"
        >
            <circle cx="50" cy="50" r="50" fill="#6C5D35"></circle>
            <path
                stroke="#FFCC3E"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3.813"
                d="M66.183 41.047c-.314 4.369-3.555 7.734-7.09 7.734-3.534 0-6.78-3.364-7.09-7.734-.321-4.545 2.834-7.734 7.09-7.734 4.258 0 7.413 3.272 7.09 7.734z"
            ></path>
            <path
                stroke="#FFCC3E"
                strokeMiterlimit="10"
                strokeWidth="3.813"
                d="M59.094 55.656c-7 0-13.733 3.477-15.42 10.25-.223.895.339 1.781 1.26 1.781h28.321c.92 0 1.48-.886 1.26-1.782-1.687-6.88-8.42-10.249-15.421-10.249z"
            ></path>
            <path
                stroke="#FFCC3E"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3.813"
                d="M44.484 42.974c-.251 3.49-2.87 6.237-5.693 6.237-2.823 0-5.446-2.747-5.693-6.237-.257-3.63 2.292-6.224 5.693-6.224s5.95 2.66 5.693 6.224z"
            ></path>
            <path
                stroke="#FFCC3E"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="3.813"
                d="M45.129 55.871c-1.94-.888-4.075-1.23-6.338-1.23-5.586 0-10.968 2.777-12.316 8.186-.177.715.272 1.423 1.007 1.423h12.06"
            ></path>
        </svg>
    );
}

export const AddUSerIcon = ({
    width = "50",
    height = "50",
    color = '#fff',
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 100 100"
        >
            <circle cx="50" cy="50" r="50" fill="#623355"></circle>
            <path
                stroke="#DD44A7"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3.438"
                d="M63.39 38.469c-.42 5.68-4.726 10.312-9.453 10.312-4.726 0-9.039-4.632-9.453-10.312-.43-5.909 3.76-10.313 9.453-10.313 5.694 0 9.883 4.512 9.454 10.313z"
            ></path>
            <path
                stroke="#DD44A7"
                strokeMiterlimit="10"
                strokeWidth="3.438"
                d="M53.938 55.656c-9.346 0-18.832 5.157-20.587 14.889-.215 1.173.452 2.299 1.681 2.299h37.812c1.229 0 1.893-1.126 1.68-2.3-1.755-9.731-11.24-14.888-20.587-14.888z"
            ></path>
            <path
                stroke="#DD44A7"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3.438"
                d="M32.453 41.906v12.032m6.016-6.016H26.437"
            ></path>
        </svg>
    );
}

export function ContentIcon({
    className = '',
}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className={className}
            display="block"
            pointerEvents="none"
        >
            <g fill="#909090" className="style-scope tp-yt-iron-icon">
                <path
                    d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-8 12.5v-9l6 4.5-6 4.5z"
                    className="style-scope tp-yt-iron-icon"
                ></path>
            </g>
        </svg>
    )
}

export function FolderIcon({
    width = "136",
    height = "99",
    className = 'w-24',
}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            className={className}
            viewBox="0 0 136 99"
        >
            <path
                fill="#EC8327"
                d="M0 14.488h121.833c7.824 0 14.167 5.405 14.167 12.073v60.366C136 93.595 129.657 99 121.833 99H14.167C6.343 99 0 93.595 0 86.927v-72.44z"
            ></path>
            <path
                fill="url(#paint0_linear_6_4)"
                d="M0 32.598v49.5C0 88.765 6.343 94.17 14.167 94.17h107.666c7.824 0 14.167-5.406 14.167-12.073V31.39c0-6.668-6.343-12.073-14.167-12.073h-59.5v6.037c0 4-3.805 7.244-8.5 7.244H0z"
            ></path>
            <path
                fill="#EC8327"
                d="M0 9.659C0 4.324 5.074 0 11.333 0H51c6.26 0 11.333 4.324 11.333 9.659v4.829H0v-4.83z"
            ></path>
            <defs>
                <linearGradient
                    id="paint0_linear_6_4"
                    x1="0"
                    x2="163.6"
                    y1="0"
                    y2="115.022"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFCD38"></stop>
                    <stop offset="1" stopColor="#F1882C"></stop>
                </linearGradient>
            </defs>
        </svg>
    );
}

export function VideoFileIcon({
    className = '',
}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 145 145"
            className={className}
        >
            <g filter="url(#filter0_ii_51_439)">
                <path
                    fill="#7C8EEE"
                    fillRule="evenodd"
                    d="M39.563 12.083c-8.837 0-16 7.164-16 16v88.834c0 8.836 7.163 16 16 16h65.875c8.836 0 16-7.164 16-16V42.292L91.229 12.083H39.563z"
                    clipRule="evenodd"
                ></path>
            </g>
            <g filter="url(#filter1_dd_51_439)">
                <path
                    fill="#fff"
                    d="M95.56 80.44l-35.232 21.003c-2.354 1.418-5.349-.285-5.349-3.051V56.387c0-2.696 2.995-4.398 5.35-3.05l35.23 21.001c2.282 1.42 2.282 4.755 0 6.103z"
                ></path>
            </g>
            <path
                fill="url(#paint0_linear_51_439)"
                d="M118.603 39.46H94.061l27.377 27.376V42.292l-2.835-2.832z"
            ></path>
            <path
                fill="#CAD1F8"
                d="M107.229 42.292h14.209L91.229 12.083v14.209c0 8.836 7.164 16 16 16z"
            ></path>
            <defs>
                <filter
                    id="filter0_ii_51_439"
                    width="97.875"
                    height="122.833"
                    x="23.563"
                    y="11.083"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feBlend
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    ></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dy="-2"></feOffset>
                    <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
                    <feComposite
                        in2="hardAlpha"
                        k2="-1"
                        k3="1"
                        operator="arithmetic"
                    ></feComposite>
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"></feColorMatrix>
                    <feBlend in2="shape" result="effect1_innerShadow_51_439"></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dy="2"></feOffset>
                    <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
                    <feComposite
                        in2="hardAlpha"
                        k2="-1"
                        k3="1"
                        operator="arithmetic"
                    ></feComposite>
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.3 0"></feColorMatrix>
                    <feBlend
                        in2="effect1_innerShadow_51_439"
                        result="effect2_innerShadow_51_439"
                    ></feBlend>
                </filter>
                <filter
                    id="filter1_dd_51_439"
                    width="51.291"
                    height="60.089"
                    x="53.979"
                    y="51.865"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dx="4" dy="6"></feOffset>
                    <feGaussianBlur stdDeviation="2"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_51_439"
                    ></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"></feColorMatrix>
                    <feBlend
                        in2="effect1_dropShadow_51_439"
                        result="effect2_dropShadow_51_439"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect2_dropShadow_51_439"
                        result="shape"
                    ></feBlend>
                </filter>
                <linearGradient
                    id="paint0_linear_51_439"
                    x1="100.197"
                    x2="127.574"
                    y1="33.324"
                    y2="60.7"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopOpacity="0.2"></stop>
                    <stop offset="1" stopOpacity="0"></stop>
                </linearGradient>
            </defs>
        </svg>
    );
}

export function ImageIcon({
    className = "",
}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 145 145"
            className={className}
        >
            <g filter="url(#filter0_ii_414_8129)">
                <path
                    fill="#F6AD00"
                    fillRule="evenodd"
                    d="M39.563 12.083c-8.837 0-16 7.164-16 16v88.834c0 8.836 7.163 16 16 16h65.875c8.836 0 16-7.164 16-16V42.292L91.229 12.083H39.563z"
                    clipRule="evenodd"
                ></path>
            </g>
            <g fill="#fff" filter="url(#filter1_dd_414_8129)">
                <path d="M45.135 99.648l11.01-16.182a1.712 1.712 0 012.616-.258l7.276 7.13 15.966-25.025a1.712 1.712 0 012.927.066l19.836 34.377a1.708 1.708 0 01.001 1.713 1.714 1.714 0 01-1.484.856H46.551a1.714 1.714 0 01-1.417-2.677zM48.432 63.006a8.631 8.631 0 1017.263 0 8.631 8.631 0 00-17.263 0z"></path>
            </g>
            <path
                fill="url(#paint0_linear_414_8129)"
                d="M118.603 39.46H94.061l27.376 27.376V42.292l-2.834-2.832z"
            ></path>
            <path
                fill="#FBDE99"
                d="M107.229 42.292h14.209L91.229 12.083v14.209c0 8.836 7.164 16 16 16z"
            ></path>
            <defs>
                <filter
                    id="filter0_ii_414_8129"
                    width="97.875"
                    height="122.833"
                    x="23.563"
                    y="11.083"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feBlend
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    ></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dy="-2"></feOffset>
                    <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
                    <feComposite
                        in2="hardAlpha"
                        k2="-1"
                        k3="1"
                        operator="arithmetic"
                    ></feComposite>
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"></feColorMatrix>
                    <feBlend in2="shape" result="effect1_innerShadow_414_8129"></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dy="2"></feOffset>
                    <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
                    <feComposite
                        in2="hardAlpha"
                        k2="-1"
                        k3="1"
                        operator="arithmetic"
                    ></feComposite>
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.3 0"></feColorMatrix>
                    <feBlend
                        in2="effect1_innerShadow_414_8129"
                        result="effect2_innerShadow_414_8129"
                    ></feBlend>
                </filter>
                <filter
                    id="filter1_dd_414_8129"
                    width="69.16"
                    height="58.95"
                    x="43.836"
                    y="53.375"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dx="4" dy="6"></feOffset>
                    <feGaussianBlur stdDeviation="2"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_414_8129"
                    ></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"></feColorMatrix>
                    <feBlend
                        in2="effect1_dropShadow_414_8129"
                        result="effect2_dropShadow_414_8129"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect2_dropShadow_414_8129"
                        result="shape"
                    ></feBlend>
                </filter>
                <linearGradient
                    id="paint0_linear_414_8129"
                    x1="100.197"
                    x2="127.573"
                    y1="33.324"
                    y2="60.7"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopOpacity="0.2"></stop>
                    <stop offset="1" stopOpacity="0"></stop>
                </linearGradient>
            </defs>
        </svg>
    );
}

export function FileManagerIcon({
    size = 24,
    color = 'currentColor',
    className = 'w-24',
}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            className={className}
            viewBox="0 0 24 24"
        >
            <path
                stroke="currentColor"
                strokeWidth="2"
                d="M4 8c0-1.886 0-2.828.586-3.414C5.172 4 6.114 4 8 4h.343c.818 0 1.226 0 1.594.152.368.152.657.442 1.235 1.02l.656.656c.579.578.867.868 1.235 1.02.368.152.776.152 1.594.152H16c1.886 0 2.828 0 3.414.586C20 8.172 20 9.114 20 11v4c0 1.886 0 2.828-.586 3.414C18.828 19 17.886 19 16 19H8c-1.886 0-2.828 0-3.414-.586C4 17.828 4 16.886 4 15V8z"
            ></path>
            <path
                stroke="currentColor"
                strokeMiterlimit="1.414"
                strokeWidth="2"
                d="M5 11h14"
            ></path>
        </svg>
    );
}

export const UploadIcon = ({
    width = 64,
    height = 64,
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 512 512"
        >
            <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="32"
                d="M320 367.79h76c55 0 100-29.21 100-83.6s-53-81.47-96-83.6c-8.89-85.06-71-136.8-144-136.8-69 0-113.44 45.79-128 91.2-60 5.7-112 43.88-112 106.4s54 106.4 120 106.4h56"
            ></path>
            <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="32"
                d="M320 255.79l-64-64-64 64m64 192.42V207.79"
            ></path>
        </svg>
    );
}

export function TsIcon({
    className = 'w-24',
}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className={className}
        >
            <path
                fill="#1677C7"
                d="M0 12v12h24V0H0v12zm14.563 7.626c.108-.061.511-.294.892-.515l.69-.4.145.214c.202.308.643.731.91.872.766.404 1.817.347 2.335-.118a.88.88 0 00.314-.675l-.001-.047v.002c0-.278-.035-.4-.18-.61-.186-.266-.567-.49-1.649-.96-1.238-.533-1.771-.864-2.259-1.39a3.152 3.152 0 01-.653-1.178l-.006-.022c-.091-.339-.114-1.189-.042-1.531.255-1.197 1.158-2.03 2.461-2.278.423-.08 1.406-.05 1.821.053v.001c.61.152 1.074.423 1.501.865.221.236.549.666.575.77.008.03-1.036.73-1.668 1.123-.023.015-.115-.084-.217-.236-.31-.45-.633-.644-1.128-.678-.728-.05-1.196.331-1.192.967l-.001.037c0 .151.038.293.105.417l-.002-.005c.16.331.458.53 1.39.933 1.719.74 2.454 1.227 2.911 1.92.51.773.625 2.008.278 2.926-.38.998-1.325 1.676-2.655 1.9-.411.073-1.386.062-1.828-.018-.964-.172-1.878-.648-2.442-1.273-.221-.243-.652-.88-.625-.925a1.58 1.58 0 01.22-.141zm-9.305-7.561c0-.534.011-.98.026-.99.012-.016 1.913-.024 4.217-.02l4.195.012.011.979.008.983H10.59v8.876H8.38v-8.876H5.258v-.964z"
            ></path>
        </svg>
    );
}

export function LinkIcon({
    className = 'w-24',
}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            viewBox="0 0 145 145"
        >
            <g filter="url(#filter0_ii_51_460)">
                <path
                    fill="#4876F9"
                    fillRule="evenodd"
                    d="M39.563 12.083c-8.837 0-16 7.164-16 16v88.834c0 8.836 7.163 16 16 16h65.875c8.836 0 16-7.164 16-16V42.292L91.229 12.083H39.563z"
                    clipRule="evenodd"
                ></path>
            </g>
            <g filter="url(#filter1_dd_51_460)">
                <path
                    fill="#fff"
                    d="M69.058 71.291c2.122 0 4.122.456 6.013 1.36a13.77 13.77 0 013.85 2.727 13.721 13.721 0 012.727 3.845c-.91.911-2.005 1.36-3.285 1.36a4.892 4.892 0 01-1.36-.201 9.477 9.477 0 00-3.09-3.09 9.169 9.169 0 00-4.849-1.36 9.28 9.28 0 00-3.522.692 9.16 9.16 0 00-3.049 2.034l-6.975 6.975a9.161 9.161 0 00-2.035 3.05 9.295 9.295 0 000 7.044 9.017 9.017 0 002.035 3.05 9.13 9.13 0 003.05 2.034 9.363 9.363 0 003.527.692 9.29 9.29 0 003.523-.692 9.165 9.165 0 003.049-2.035l4.883-4.882a18.294 18.294 0 005.936.6c-.183.202-.37.401-.559.598l-6.975 6.975a13.662 13.662 0 01-4.57 3.05 13.98 13.98 0 01-10.584-.006 13.796 13.796 0 01-4.572-3.044 13.702 13.702 0 01-3.048-4.565 13.983 13.983 0 01-1.038-5.291c0-1.816.351-3.615 1.038-5.292a13.711 13.711 0 013.048-4.566l6.975-6.975c.133-.132.335-.317.6-.564a13.758 13.758 0 014.341-2.623 14.136 14.136 0 014.916-.899zm16.274-16.267c1.816 0 3.614.352 5.291 1.038 1.701.685 3.223 1.707 4.572 3.05 1.343 1.342 2.364 2.864 3.05 4.564a13.889 13.889 0 011.03 5.292c0 1.816-.35 3.58-1.042 5.292a13.725 13.725 0 01-3.044 4.565L88.214 85.8c-.133.139-.334.324-.6.565a13.627 13.627 0 01-4.34 2.623c-1.573.594-3.241.898-4.923.899a13.76 13.76 0 01-6.012-1.36 13.778 13.778 0 01-3.852-2.726 13.722 13.722 0 01-2.726-3.846c.906-.91 2.002-1.36 3.286-1.36.45 0 .906.07 1.361.202a9.46 9.46 0 003.084 3.09 9.182 9.182 0 004.848 1.36c1.21 0 2.386-.23 3.522-.692a9.103 9.103 0 003.049-2.035l6.975-6.975a9.072 9.072 0 002.035-3.05 9.324 9.324 0 000-7.043 9.1 9.1 0 00-2.035-3.05 9.132 9.132 0 00-3.05-2.034 9.352 9.352 0 00-3.522-.692c-1.21 0-2.386.23-3.521.692a9.103 9.103 0 00-3.05 2.035l-4.888 4.882a18.286 18.286 0 00-5.938-.6c.185-.201.37-.403.565-.599l6.975-6.975a13.724 13.724 0 014.566-3.043 13.886 13.886 0 015.309-1.044z"
                ></path>
            </g>
            <path
                fill="url(#paint0_linear_51_460)"
                d="M118.603 39.46H94.061l27.377 27.376V42.292l-2.835-2.832z"
            ></path>
            <path
                fill="#B5C8FC"
                d="M107.229 42.292h14.209L91.229 12.083v14.209c0 8.836 7.164 16 16 16z"
            ></path>
            <defs>
                <filter
                    id="filter0_ii_51_460"
                    width="97.875"
                    height="122.833"
                    x="23.563"
                    y="11.083"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feBlend
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    ></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dy="-2"></feOffset>
                    <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
                    <feComposite
                        in2="hardAlpha"
                        k2="-1"
                        k3="1"
                        operator="arithmetic"
                    ></feComposite>
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"></feColorMatrix>
                    <feBlend in2="shape" result="effect1_innerShadow_51_460"></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dy="2"></feOffset>
                    <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
                    <feComposite
                        in2="hardAlpha"
                        k2="-1"
                        k3="1"
                        operator="arithmetic"
                    ></feComposite>
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.3 0"></feColorMatrix>
                    <feBlend
                        in2="effect1_innerShadow_51_460"
                        result="effect2_innerShadow_51_460"
                    ></feBlend>
                </filter>
                <filter
                    id="filter1_dd_51_460"
                    width="60.136"
                    height="62.13"
                    x="47.14"
                    y="54.024"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dx="4" dy="6"></feOffset>
                    <feGaussianBlur stdDeviation="2"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_51_460"
                    ></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"></feColorMatrix>
                    <feBlend
                        in2="effect1_dropShadow_51_460"
                        result="effect2_dropShadow_51_460"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect2_dropShadow_51_460"
                        result="shape"
                    ></feBlend>
                </filter>
                <linearGradient
                    id="paint0_linear_51_460"
                    x1="100.197"
                    x2="127.574"
                    y1="33.324"
                    y2="60.7"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopOpacity="0.2"></stop>
                    <stop offset="1" stopOpacity="0"></stop>
                </linearGradient>
            </defs>
        </svg>
    );
}

export function UnknownIcon({
    className = 'w-24',
}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            fill="none"
            viewBox="0 0 145 145"
        >
            <g filter="url(#filter0_ii_51_453)">
                <path
                    fill="#CCC"
                    fillRule="evenodd"
                    d="M39.563 12.083c-8.837 0-16 7.164-16 16v88.834c0 8.836 7.163 16 16 16h65.875c8.836 0 16-7.164 16-16V42.292L91.229 12.083H39.563z"
                    clipRule="evenodd"
                ></path>
            </g>
            <g filter="url(#filter1_dd_51_453)">
                <path
                    fill="#fff"
                    d="M57.396 70.99c0-3.021.604-5.438 1.208-6.948.604-1.51 1.813-3.021 3.323-4.23 1.51-1.51 3.02-2.416 5.135-3.02 1.813-.605 3.625-.907 5.74-.907 4.23 0 7.552 1.209 10.573 3.625 2.719 2.417 4.23 5.438 4.23 9.365 0 1.813-.605 3.323-1.21 4.833-.603 1.51-2.114 3.323-4.53 5.438-2.417 2.114-3.323 3.625-4.23 4.531-.906.906-1.208 2.115-1.812 3.323-.604 1.208-.302 2.115-.302 5.135h-6.042c0-3.02.302-4.53.604-6.041.302-1.51.907-2.719 1.813-3.927.906-1.209 1.812-2.72 3.927-4.834 2.114-2.114 3.625-3.625 4.229-4.53.604-.907 1.208-2.418 1.208-4.532 0-2.115-.906-3.625-2.416-5.136-1.51-1.51-3.625-2.416-6.344-2.416-6.042 0-9.063 4.229-9.063 10.27h-6.041zm18.125 33.229h-6.042v-6.042h6.042v6.042z"
                ></path>
            </g>
            <path
                fill="url(#paint0_linear_51_453)"
                d="M118.603 39.46H94.061l27.377 27.376V42.292l-2.835-2.832z"
            ></path>
            <path
                fill="#EAEAEA"
                d="M107.229 42.292h14.209L91.229 12.083v14.209c0 8.836 7.164 16 16 16z"
            ></path>
            <defs>
                <filter
                    id="filter0_ii_51_453"
                    width="97.875"
                    height="122.833"
                    x="23.563"
                    y="11.083"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feBlend
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    ></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dy="-2"></feOffset>
                    <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
                    <feComposite
                        in2="hardAlpha"
                        k2="-1"
                        k3="1"
                        operator="arithmetic"
                    ></feComposite>
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"></feColorMatrix>
                    <feBlend in2="shape" result="effect1_innerShadow_51_453"></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dy="2"></feOffset>
                    <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
                    <feComposite
                        in2="hardAlpha"
                        k2="-1"
                        k3="1"
                        operator="arithmetic"
                    ></feComposite>
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.3 0"></feColorMatrix>
                    <feBlend
                        in2="effect1_innerShadow_51_453"
                        result="effect2_innerShadow_51_453"
                    ></feBlend>
                </filter>
                <filter
                    id="filter1_dd_51_453"
                    width="39.208"
                    height="59.333"
                    x="56.396"
                    y="54.885"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dx="4" dy="6"></feOffset>
                    <feGaussianBlur stdDeviation="2"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_51_453"
                    ></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"></feColorMatrix>
                    <feBlend
                        in2="effect1_dropShadow_51_453"
                        result="effect2_dropShadow_51_453"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect2_dropShadow_51_453"
                        result="shape"
                    ></feBlend>
                </filter>
                <linearGradient
                    id="paint0_linear_51_453"
                    x1="100.197"
                    x2="127.574"
                    y1="33.324"
                    y2="60.7"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopOpacity="0.2"></stop>
                    <stop offset="1" stopOpacity="0"></stop>
                </linearGradient>
            </defs>
        </svg>
    );
}

export function TranscodingIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="10"
            fill="none"
            viewBox="0 0 27 47"
        >
            <path
                fill="#1F2937"
                d="M0 42.185L19.175 23.01 0 3.835 3.49 0 26.5 23.01 3.49 46.02 0 42.185z"
            ></path>
        </svg>
    )
}

