import { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import { DeleteIcon, InputField, Title } from "../../tailwind";
import { UploadIcon } from "../../tailwind/Icon";
import { useSelector } from "react-redux";
import { updateSettingApi } from "../../http/Apis";
import toast from "react-hot-toast";
import { Hr } from "../../components";
import { RootState } from "../../redux/store";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";


const Settings = () => {
    const { config } = useSelector((state: RootState) => state.config);
    const { credentials } = config;
    const [loading, setLoading] = useState(false);
    const [settings, setSettings] = useState({
        name: config.name,
        description: config.description,
        siteUrl: config.siteUrl,
        seo_title: config.seo_title,
        meta_description: config.meta_description,
        google_teg_manager: config.google_teg_manager,
        logo: "",
        logoUrl: config.logo,
        favicon: "",
        faviconUrl: config.favicon,
        fcm: {
            server_key: credentials?.fcm?.server_key || "",
        }
    });

    useEffect(() => {
        setSettings({
            name: config.name,
            description: config.description,
            siteUrl: config.siteUrl,
            seo_title: config.seo_title,
            meta_description: config.meta_description,
            google_teg_manager: config.google_teg_manager,
            logo: "",
            logoUrl: config.logo,
            favicon: "",
            faviconUrl: config.favicon,
            fcm: {
                server_key: credentials?.fcm?.server_key || "",
            }
        });
    }, [config]);

    const handleLogoChange = (e: any) => {
        // get object url and set it to logoUrl
        if (e.target.files[0]) {
            const logoUrl = URL.createObjectURL(e.target.files[0]);
            setSettings({ ...settings, logoUrl, logo: e.target.files[0] });
        }
    }

    const handleIconChange = (e: any) => {
        // get object url and set it to faviconUrl
        if (e.target.files[0]) {
            const faviconUrl = URL.createObjectURL(e.target.files[0]);
            setSettings({ ...settings, faviconUrl, favicon: e.target.files[0] });
        }
    }

    const updateSettings = async () => {
        if (!config._id) return toast.error("Config not found");
        setLoading(true);
        const formData = new FormData();
        formData.append("name", settings.name);
        formData.append("description", settings.description);
        formData.append("siteUrl", settings.siteUrl);
        formData.append("seo_title", settings.seo_title);
        formData.append("meta_description", settings.meta_description);
        formData.append("google_teg_manager", settings.google_teg_manager);
        // append fcm object
        formData.append("fcm", JSON.stringify(settings.fcm));

        if (settings.logo) {
            formData.append("logo", settings.logo);
        }
        if (settings.favicon) {
            formData.append("favicon", settings.favicon);
        }
        try {
            const response = await updateSettingApi(config._id, formData);
            if (response.status === 200) {
                toast.success("Settings updated successfully");
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
            toast.error("Something went wrong");
        }
    }

    return (
        <Layout>
            <div className="pr-5">
                <h1 className="text-2xl opacity-80 font-semibold tracking-wide">
                    Site Configuration
                </h1>
                <p className="mt-2 opacity-70">
                    Edit your site configuration here and save. You can change your site name, description, logo and icon.
                </p>
            </div>
            <div className="my-5">
                <Hr />
            </div>
            <h6 className="opacity-70 pr-5">* – Required fields. Enter all fields.</h6>
            <h1 className="my-6 opacity-80 font-medium tracking-wide text-xl pr-5">
                Site details
            </h1>
            <h6 className="opacity-70 pr-5">
                set your site name, description, logo and icon.
            </h6>
            <div className="my-14 pr-36 flex flex-col gap-8">
                <InputField
                    title="Site Name"
                    fieldName="site-name"
                    type="text"
                    placeholder="Enter your site name"
                    value={settings.name}
                    onChange={(e) => setSettings({ ...settings, name: e.target.value })}
                />
            </div>

            <h1 className="my-6 opacity-80 font-medium tracking-wide text-xl pr-5">Graphics</h1>
            <div className="my-16 pr-36 flex flex-col gap-8">
                <div className="flex w-full items-center">
                    <Title required={true} className='w-1/3'>
                        Logo
                    </Title>
                    <div className="w-full p-10 border border-gray-700 rounded">
                        <div className="flex flex-col justify-center items-center">
                            <input
                                type="file"
                                className="hidden"
                                id="logo"
                                onChange={handleLogoChange}
                            />
                            <div className="relative">
                                <label htmlFor="logo" className="cursor-pointer">
                                    <img
                                        src={settings.logoUrl}
                                        className="w-60 h-60 object-contain rounded-3xl shadow-sm bg-dark-color-box text-center p-5"
                                    />
                                </label>
                                <div className="cursor-pointer absolute -top-2 -right-2">
                                    <DeleteIcon
                                        width={40}
                                        height={40}
                                    />
                                </div>
                            </div>

                            <div className="mt-8">
                                <label htmlFor="logo" className="cursor-pointer flex items-center gap-3">
                                    <div className="mt-[2px]">
                                        <UploadIcon width={22} height={22} />
                                    </div>
                                    <p className="opacity-70 text-lg font-medium cursor-pointer">
                                        Replace
                                    </p>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex w-full items-center">
                    <Title required={true} className='w-1/3'>
                        Favicon
                    </Title>
                    <div className="w-full p-10 border border-gray-700 rounded">
                        <div className="flex flex-col justify-center items-center">
                            <input
                                type="file"
                                className="hidden"
                                id="icon"
                                onChange={handleIconChange}
                            />
                            <div className="relative">
                                <label htmlFor="icon" className="cursor-pointer">
                                    <img
                                        src={settings.faviconUrl}
                                        className="w-60 h-60 object-contain rounded-3xl shadow-sm bg-dark-color-box text-center p-5"
                                    />
                                </label>
                                <div className="cursor-pointer absolute -top-2 -right-2">
                                    <DeleteIcon
                                        width={40}
                                        height={40}
                                    />
                                </div>
                            </div>

                            <div className="mt-8">
                                <label htmlFor="icon" className="cursor-pointer flex items-center gap-3">
                                    <div className="mt-[2px]">
                                        <UploadIcon width={22} height={22} />
                                    </div>
                                    <p className="opacity-70 text-lg font-medium cursor-pointer">
                                        Replace
                                    </p>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-24 pr-5">
                    <h1 className="text-xl opacity-80 font-semibold tracking-wide">
                        Firebase Cloud Messaging
                    </h1>
                    <p className="mt-2 opacity-70">
                        Firebase Cloud Messaging, formerly known as Google Cloud Messaging, is a cross-platform cloud solution for messages and notifications for Android, iOS, and web applications.
                    </p>
                </div>

                <div className="my-10 flex flex-col gap-8 pr-5">
                    <InputField
                        fieldName="server-key"
                        type="text"
                        placeholder="Enter your server key"
                        value={settings?.fcm?.server_key || ''}
                        onChange={(e) => setSettings({ ...settings, fcm: { ...settings.fcm, server_key: e.target.value } })}
                    />
                </div>
                <div className="flex justify-end">
                    <LoadingButton
                        loading={loading}
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="contained"
                        onClick={updateSettings}>
                        Save
                    </LoadingButton>
                </div>
            </div>
        </Layout>

    );
}

export default Settings;
