import { useEffect } from "react";
import { useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import { createTheme, updateTheme } from "../../http";
import { Button, Input, Title } from "../../tailwind";
import { useSelector, useDispatch } from "react-redux";
import { fetchSingleTheme } from "../../redux/slice​/themesSlice";

const EditTheme = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { singleTheme, status } = useSelector((state) => state.themes);

    useEffect(() => {
        dispatch(fetchSingleTheme(id));
    }, [])
    const [thumbnail, setThumbnail] = useState(false);
    const [thumbnailUrl, setThumbnailUrl] = useState(false);
    const [theme, setTheme] = useState(false)
    const [themeName, setThemeName] = useState(false)
    const [themeDescription, setThemeDescription] = useState(false)
    const [themePrice, setThemePrice] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setThemeName(singleTheme.name)
        setThemeDescription(singleTheme.description)
        setThemePrice(singleTheme.price)
        setThumbnailUrl(singleTheme.thumbnail)
    }, [singleTheme])

    // Capture Thumbnail function
    function captureThumbnail(e) {
        const file = e.target.files[0];
        setThumbnail(file);
        setThumbnailUrl(URL.createObjectURL(file));
    }

    // Capture Theme function
    function captureTheme(e) {
        console.log(e.target.files[0]);
        const file = e.target.files[0];
        setTheme(file);
    }

    const handleSubmit = async () => {
        setLoading(true);
        const formData = new FormData();
        formData.append('name', themeName);
        formData.append('description', themeDescription);
        formData.append('price', themePrice);
        if (thumbnail) {
            formData.append('thumbnail', thumbnail);
        }
        if (theme) {
            formData.append('theme', theme);
        }

        try {
            if (!themeName || !themeDescription || !themePrice) {
                toast.error('Please fill all fields');
                setLoading(false);
                return;
            }
            const response = await updateTheme(id, formData);
            if (response.status === 200) {
                setLoading(false);
                toast.success('Theme updated successfully');
            }
        } catch (error) {
            setLoading(false);
            toast.error(error.response.data.message);
            console.log(error);
        }

    }
    return (
        <Layout>
            <Title className="text-white font-medium text-xl">Edit Theme</Title>
            <div className="grid grid-cols-2 my-5 gap-5">
                <div>
                    <div className="w-full h-96 cursor-pointer">
                        <input
                            onChange={captureThumbnail}
                            accept="image/*"
                            id="thumbnail"
                            type="file"
                            name="thumbnail"
                            className='hidden'
                        />
                        <label htmlFor="thumbnail">
                            <img className="w-full h-96 object-cover rounded cursor-pointer" src={`${thumbnailUrl ? thumbnailUrl : '/thumbnail-default.jpg'}`} />
                        </label>
                    </div>
                    <div className="my-3">
                        <input
                            onChange={captureTheme}
                            accept="application/zip"
                            id="theme"
                            type="file"
                            name="theme"
                            className='hidden'
                        />
                        <label htmlFor="theme" className="w-full h-14 bg-dark-color-2 cursor-pointer rounded flex justify-center items-center">
                            <span className="capitalize">
                                {
                                    theme ? theme.name : 'Select a theme'
                                }
                            </span>
                        </label>
                    </div>
                </div>
                <div>
                    <div className="w-full h-96 flex flex-col gap-3">
                        <Input
                            title="Name"
                            style2
                            titleStyle={'text-sm mb-1'}
                            type="text"
                            name="name"
                            placeholder="Theme Name"
                            className="w-full"
                            onChange={(e) => setThemeName(e.target.value)}
                            value={themeName}
                        />
                        <Input
                            title="Description"
                            style2
                            titleStyle={'text-sm mb-1'}
                            type="text"
                            name="description"
                            placeholder="Theme Description"
                            className="w-full"
                            onChange={(e) => setThemeDescription(e.target.value)}
                            value={themeDescription}
                        />
                        <Input
                            title="Price"
                            style2
                            titleStyle={'text-sm mb-1'}
                            type="text"
                            name="price"
                            placeholder="Theme Price"
                            className="w-full"
                            onChange={(e) => setThemePrice(e.target.value)}
                            value={themePrice}
                        />
                    </div>
                    <div className="my-3 flex justify-end">
                        <Button
                            title={'Update Theme'}
                            height={'h-14'}
                            width={'w-40'}
                            onClick={handleSubmit}
                            loading={loading}
                        />
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default EditTheme;