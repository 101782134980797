import React, { MouseEventHandler } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import { ErrorMessage } from 'formik'

const animatedComponents = makeAnimated();

interface InputFieldProps {
    title?: string;
    fieldType?: 'withFormik' | 'withoutFormik'
    type: 'text' | 'password' | 'email' | 'number' | 'textarea' | 'select'
    fieldName: string
    placeholder: string
    label?: string
    value: string | undefined | string[] | number
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
    textAreaOnChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
    textAreaOnBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void
    error?: string | undefined
    touched?: boolean
    required?: boolean
    textLength?: number
    maxLength?: number
    limitValidation?: boolean
    cols?: number
    rows?: number
    col?: string
}

export const TextAreaField = ({ fieldType, fieldName, placeholder, label, value, textAreaOnChange, textAreaOnBlur, error, touched, required, textLength, maxLength, limitValidation, cols, rows, col, title }: InputFieldProps) => {
    const { darkMode } = useSelector((state: RootState) => state.theme)
    return (
        <div className={`border px-5 py-3 rounded ${error ? 'border-red-500' : darkMode ? 'border-[#343434]' : 'border-gray-200'} w-full`}>
            <p className={`${error && 'text-red-500'} text-xs capitalize`}>{title ? title : fieldName} {required && '(required)'}</p>
            <textarea
                placeholder={placeholder}
                className="bg-transparent border-none focus:outline-none font-medium mt-2 w-full"
                name={fieldName}
                id={fieldName}
                cols={cols ? cols : 30}
                rows={rows ? rows : 10}
                onChange={textAreaOnChange}
                onBlur={textAreaOnBlur}
                value={value}
                defaultValue={value}
            >
                {value}
            </textarea>
            {limitValidation && <div className='mt-1 flex justify-end'>
                <p className={`${error && 'text-red-500'} text-sm`}>{value &&
                    // if value is string then get length else if value is number then convert it to string and get length
                    (typeof value === 'string' ? value.length : value.toString().length)}{!value && '0'}/{maxLength}</p>
            </div>
            }
        </div>
    )
}


export const InputField = ({ fieldType, type, fieldName, placeholder, label, value, onChange, onBlur, error, touched, required, textLength, maxLength, limitValidation, cols, rows, textAreaOnChange, textAreaOnBlur, col, title }: InputFieldProps) => {
    const { darkMode } = useSelector((state: RootState) => state.theme)
    return (
        <div className={`border px-5 py-3 rounded ${error ? 'border-red-500' : darkMode ? 'border-[#343434]' : 'border-gray-200'} w-full`}>
            <p className={`${error && 'text-red-500'} text-xs capitalize`}>{title ? title : fieldName} {required && '(required)'}</p>
            <input
                type={type}
                placeholder={placeholder}
                className="bg-transparent border-none focus:outline-none font-medium mt-2 w-full"
                name={fieldName}
                id={fieldName}
                onChange={onChange}
                onBlur={onBlur ? onBlur : undefined}
                value={value}
            />
            {limitValidation && <div className='mt-1 flex justify-end'>
                <p className={`${error && 'text-red-500'} text-sm`}>{value &&
                    // if value is string then get length else if value is number then convert it to string and get length
                    (typeof value === 'string' ? value.length : value.toString().length)}{!value && '0'}/{maxLength}</p>
            </div>
            }
        </div>
    )
}

interface SelectFieldProps {
    options: any
    isMulti?: boolean
    onChange: (e: any) => void
    onBlur?: (e: any) => void
    placeholder: string
    defaultValue?: []
    getOptionLabel?: (option: any) => string
    getOptionValue?: (option: any) => string
    promiseOptions?: (inputValue: string) => Promise<any>
    col?: string
}

export const SelectField = ({ options, isMulti, onChange, onBlur, placeholder, defaultValue, getOptionLabel, getOptionValue, promiseOptions, col }: SelectFieldProps) => {
    const { darkMode } = useSelector((state: RootState) => state.theme)
    return (

        <AsyncSelect
            styles={{
                control: (provided, state) => ({
                    ...provided,
                    backgroundColor: darkMode ? 'transparent' : 'transparent',
                    border: state.isFocused ? '1px solid #3b82f6' : darkMode ? '1px solid #343434' : '1px solid #e5e7eb',
                    boxShadow: state.isFocused ? '0 0 0 1px #3b82f6' : 'none',
                    '&:hover': {
                        border: '1px solid #3b82f6',
                    },

                }),
                menuList(base, props) {
                    return {
                        ...base,
                        backgroundColor: darkMode ? '#2D2D2D' : 'white',
                        color: darkMode ? 'white' : 'black',
                    }
                },
                option(base, props) {
                    return {
                        ...base,
                        backgroundColor: props.isFocused ? '#3b82f6' : darkMode ? '#2D2D2D' : 'white',
                        color: props.isFocused ? 'white' : darkMode ? 'white' : 'black',

                    }
                },
                multiValue(base, props) {
                    return {
                        ...base,
                        backgroundColor: darkMode ? '#3b82f6' : '#3b82f6',
                        color: darkMode ? 'white' : 'white',
                        //border: props.isFocused ? '1px solid #3b82f6' : darkMode ? '1px solid #343434' :'1px solid #e5e7eb',
                    }
                },
                multiValueLabel(base, props) {
                    return {
                        ...base,
                        color: darkMode ? 'white' : 'white',
                    }
                },
                input(base, props) {
                    return {
                        ...base,
                        color: darkMode ? 'white' : 'black',
                    }
                },
                singleValue(base, props) {
                    return {
                        ...base,
                        color: darkMode ? 'white' : 'black',
                    }
                },
            }}
            closeMenuOnSelect={false}
            components={animatedComponents}
            defaultValue={defaultValue}
            value={defaultValue}
            isMulti={isMulti}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            cacheOptions
            defaultOptions={options}
            loadOptions={promiseOptions}
        />

    )
}

interface TagFieldProps {
    fieldName: string
    title?: string
    placeholder: string
    value?: any
    onKeyDown: (e: any) => void
    onRemove?: (e: any) => void
    col?: string
}

export const TagField = ({ fieldName, title, placeholder, value, onKeyDown, onRemove, col }: TagFieldProps) => {
    const { darkMode } = useSelector((state: RootState) => state.theme)
    return (
        <>
            <div className={`border px-5 py-3 rounded ${darkMode ? 'border-[#343434]' : 'border-gray-200'} w-full`}>
                <input
                    placeholder={placeholder}
                    className="bg-transparent border-none focus:outline-none font-medium w-full"
                    name={fieldName}
                    id={fieldName}
                    onKeyDown={onKeyDown}
                />
            </div>
            {
                value && value.length > 0 && <div className={`bg-transparent border ${darkMode ? 'border-[#343434]' : 'border-gray-200'} rounded-md px-2 py-2.5 flex items-center flex-wrap gap-3`}>
                    {
                        value?.map((item: any, index: number) => {
                            return (
                                <div key={index} className="bg-blue-500 py-1 px-3 rounded flex gap-2">
                                    <div className="text-white">{item}</div>
                                    <div className="text-white bg-gray-800 px-1 w-6 items-center justify-center flex rounded-full cursor-pointer"
                                        onClick={onRemove ? () => onRemove(index) : undefined}
                                    >&times;</div>
                                </div>
                            )
                        })
                    }
                </div>
            }
        </>

    )
}

interface ImageFieldProps {
    fieldName: string
    title?: string
    placeholder: string
    value?: any
    onChange: (e: any) => void
    onBlur?: (e: any) => void
    url?: string
    className?: string
    error?: string | undefined
    imageStyle?: string
    placeholderStyle?: string
    col?: string
}
export const ImageField = ({
    fieldName,
    title,
    placeholder,
    value,
    onChange,
    onBlur,
    url,
    className,
    error,
    imageStyle = 'w-full h-full object-cover',
    placeholderStyle = 'cursor-pointer text-sm',
    col
}: ImageFieldProps) => {
    const { darkMode } = useSelector((state: RootState) => state.theme)
    return (
        <>
            <div className={`${className} ${error ? 'border-red-500 text-red-500' : darkMode ? 'border-[#343434]' : 'border-gray-200 '} relative`}>
                <label htmlFor={fieldName}>
                    {
                        url ? <img src={url} className={`${imageStyle}`} alt={fieldName} /> : <div
                            className='w-full h-full flex justify-center items-center'
                        >
                            <h1 className={`${placeholderStyle}`}>
                                {error ? error : placeholder}
                            </h1>
                        </div>
                    }
                    <input
                        onChange={onChange}
                        accept="image/*"
                        id={fieldName}
                        type="file"
                        name={fieldName}
                        className="hidden"
                    />
                </label>
                {
                    error && <div className="absolute top-2 left-4">
                        <div className='flex items-center gap-2 bg-black px-4 py-1 rounded-full bg-opacity-90'>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-red-500" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M10 2a8 8 0 100 16 8 8 0 000-16zm0 14a6 6 0 110-12 6 6 0 010 12zm-1-5a1 1 0 112 0v3a1 1 0 11-2 0v-3zm1-5a1 1 0 100 2 1 1 0 000-2z" clipRule="evenodd" />
                            </svg>
                            <p>
                                {error}
                            </p>
                        </div>

                    </div>

                }
            </div>
        </>

    )
}




