import { createSlice } from '@reduxjs/toolkit'
import { getAllSeries, getEpisode, getMovie, getMovies, getSeason, getSeries, getStore, getStores, movieReport, movieSearch, storeReport, storeSearch } from '../../http/Apis'


export const STATUS = Object.freeze({
    SECCESS: 'secces',
    FAILED: 'failed',
    LOADING: 'loading',
})

const initialState = {
    data: false,
    meta: false,
    status: STATUS.SECCESS,
    bulkAction: 'none',
    bulkActionCall: false,
    single: false,
    season: false,
    episode: false,
    report: false,
}

export const SeriesSlice = createSlice({
    name: 'series',
    initialState,
    reducers: {
        setSeries: (state, actions) => {
            const { data, meta } = actions.payload
            state.data = data
            state.meta = meta
        },
        setStatus: (state, actions) => {
            state.status = actions.payload
        },
        setBulkAction: (state, actions) => {
            state.bulkAction = actions.payload
        },
        setBulkActionCall: (state, actions) => {
            state.bulkActionCall = actions.payload
        },
        setSingle: (state, actions) => {
            const { data } = actions.payload
            state.single = data
        },
        setReport: (state, actions) => {
            const { data } = actions.payload
            state.report = data
        },
        setSeason: (state, actions) => {
            const { data } = actions.payload
            state.season = data
        },
        setEpisode: (state, actions) => {
            const { data } = actions.payload
            state.episode = data
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    setSeries,
    setStatus,
    setBulkAction,
    setBulkActionCall,
    setSingle,
    setReport,
    setSeason,
    setEpisode
} = SeriesSlice.actions

export default SeriesSlice.reducer

// Thunks
export function fetchAllSeries(page = 1, limit = 25, order = 'desc') {
    return async function fetchMoviesThunk(dispatch) {
        dispatch(setStatus(STATUS.LOADING))
        try {
            const res = await getAllSeries(page, limit, order);
            dispatch(setSeries(res.data))
            dispatch(setStatus(STATUS.SECCESS))
        } catch (error) {
            console.log(error)
            dispatch(setStatus(STATUS.FAILED))
        }
    }
}
// fetch Reports
export function fetchSeriesReport() {
    return async function fetchReportThunk(dispatch) {
        dispatch(setStatus(STATUS.LOADING))
        try {
            const res = await movieReport();
            dispatch(setReport(res.data))
            dispatch(setStatus(STATUS.SECCESS))
        } catch (error) {
            console.log(error)
            dispatch(setStatus(STATUS.FAILED))
        }
    }
}

export function fetchSearchSeries(search) {
    return async function fetchThunk(dispatch) {
        dispatch(setStatus(STATUS.LOADING))
        try {
            const res = await movieSearch(search);
            dispatch(setSeries(res.data))
            dispatch(setStatus(STATUS.SECCESS))
        } catch (error) {
            console.log(error)
            dispatch(setStatus(STATUS.FAILED))
        }
    }
}

export function fetchSeries(queryString) {
    return async function fetchSeriesThunk(dispatch) {
        dispatch(setStatus(STATUS.LOADING))
        try {
            const res = await getSeries(queryString);
            dispatch(setSingle(res.data))
            dispatch(setStatus(STATUS.SECCESS))
        } catch (error) {
            console.log(error)
            dispatch(setStatus(STATUS.FAILED))
        }
    }
}

export function fetchSeason(queryString) {
    return async function fetchSeasonThunk(dispatch) {
        dispatch(setStatus(STATUS.LOADING))
        try {
            const res = await getSeries(queryString);
            dispatch(setSeason(res.data))
            dispatch(setStatus(STATUS.SECCESS))
        } catch (error) {
            console.log(error)
            dispatch(setStatus(STATUS.FAILED))
        }
    }
}

// get episode
export function fetchEpisode(id, seasonId, episodeId) {
    return async function fetchEpisodeThunk(dispatch) {
        dispatch(setStatus(STATUS.LOADING))
        try {
            const res = await getEpisode(id, seasonId, episodeId);
            dispatch(setEpisode(res.data))
            dispatch(setStatus(STATUS.SECCESS))
        } catch (error) {
            console.log(error)
            dispatch(setStatus(STATUS.FAILED))
        }
    }
}

