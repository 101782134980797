import { useEffect, useState } from "react"
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { deleteUser, restoreUser, statusUser, userActionsApi } from "../../http/Apis";
import { fetchUsers, setBulkActionCall } from "../../redux/slice​/userSlice";
import { RootState } from "../../redux/store";
import { Button, Checkbox } from "@mui/material";
import { IMuiDialog } from "../../Pages/Content/EditContent";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import { MuiDialog } from "../../components";

interface TableProps {
    Data: any;
    page: number;
}

const Table = ({ Data, page }: TableProps) => {

    const dispatch = useDispatch();
    const [allSelectArray, setallSelectArray] = useState([])
    console.log("🚀 ~ file: index.tsx:26 ~ Table ~ allSelectArray", allSelectArray)
    const { bulkAction, bulkActionCall } = useSelector((state: RootState) => state.users);
    const { darkMode } = useSelector((state: RootState) => state.theme);
    const [singleSelect, setSingleSelect] = useState([])
    const [status, setStatus] = useState<string>("")
    interface IPagination {
        page: number;
        limit: number;
    }
    const [pagination, setPagination] = useState<IPagination>({
        page: 1,
        limit: process.env.REACT_APP_PAGE_SIZE || 25 as any
    })

    const [muiDialogContant, setMuiDialogContant] = useState<IMuiDialog>({
        title: "",
        contentText: "",
        buttonOneText: "",
        buttonTwoText: "",
        twoAction: false,
        actionKey: false,
        bulkDeleteData: false,
    })

    // status dialog
    const [muiDialogOpen, setMuiDialogOpen] = useState(false);
    const handleMuiDialogOpen = () => {
        setMuiDialogOpen(true);
    };
    const handleMuiDialogClose = () => {
        setMuiDialogOpen(false);
        setTimeout(() => {
            setMuiDialogContant({
                title: '',
                contentText: '',
            })
        }, 100);
    };
    const data = {
        id: singleSelect,
        action: status
    }
    // handleStatus
    const handleActions = async (data: any) => {
        try {
            const response = await userActionsApi(data)
            if (response.status === 200) {
                toast.success('User action performed successfully')
                dispatch(fetchUsers(page, pagination.limit) as any)
            }
        } catch (error) {
            toast.error('Something went wrong')
        }
    }

    // headle checkbox
    const headleCheckbox = (e: any) => {
        const { name: id, checked } = e.target;
        if (id === 'selectAll') {
            if (checked) {
                setallSelectArray(Data.map((user: any) => user._id))

            } else {
                setallSelectArray([])
            }
        } else {
            setallSelectArray((prev: any) => {
                if (checked) {
                    return [...prev, id]
                } else {
                    return prev.filter((item: any) => item !== id)
                }
            })
        }

    }

    useEffect(() => {
        if (bulkActionCall) {
            headleBulkActions()
            dispatch(setBulkActionCall(false))
        }
    }, [bulkActionCall])

    const handleDeleteForeverdata = {
        id: singleSelect,
        action: 'delete'
    }
    // handleDelete
    const handleDeleteForever = async (data: any) => {

        try {
            const response = await userActionsApi(data)
            if (response.status === 200) {
                toast.success('Status updated successfully')
                dispatch(fetchUsers(page, pagination.limit) as any)
                handleMuiDialogClose()
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.error?.message || 'Something went wrong')
            handleMuiDialogClose()
        }
    }


    // headle bulk actions
    const headleBulkActions = async () => {
        const formData = new FormData();
        if (allSelectArray.length < 1) {
            formData.append('id', allSelectArray as any)
        } else {
            for (let i = 0; i < allSelectArray.length; i++) {
                formData.append('id', allSelectArray[i])
            }
        }
        const newFormData = allSelectArray.length > 0 ? { id: allSelectArray, action: bulkAction } : formData

        if (bulkAction === 'none') {
            toast.error("Please select bulk action")
        } else {
            if (bulkAction === 'delete') {
                setStatus('restore')
                handleMuiDialogOpen()
                setMuiDialogContant({
                    title: 'Restore from Trash',
                    contentText: 'Are you sure you want to restore this contant from trash?',
                    buttonOneText: 'Delete Forever',
                    buttonTwoText: 'Restore',
                    twoAction: true,
                    actionKey: 'restore',
                    bulkDeleteData: newFormData
                })
            } else {
                try {
                    const response = await userActionsApi(newFormData)
                    if (response.status === 200) {
                        toast.success('User action performed successfully')
                        dispatch(fetchUsers(page, pagination.limit) as any)
                    }
                } catch (error: any) {
                    toast.error(error.response.data.error.message || 'Something went wrong')
                }
            }
        }
    }

    return (
        <>
            <div className="overflow-x-auto w-full pr-5">
                <table className="table w-full">
                    <thead className="w-full">
                        <tr className="w-full">
                            <th className={`bg-transparent border-b ${darkMode ? 'border-[#343434]' : 'border-gray-200'} capitalize text-base font-normal w-10`}>
                                <div className="tooltip tooltip-right -ml-2.5" data-tip="Select All">
                                    <Checkbox
                                        checked={allSelectArray.length === Data.length}
                                        onChange={headleCheckbox}
                                        indeterminate={allSelectArray.length > 0 && allSelectArray.length < Data.length}
                                        color="primary"
                                        name="selectAll"
                                    />
                                </div>
                            </th>
                            <th className={`bg-transparent border-b ${darkMode ? 'border-[#343434]' : 'border-gray-200'} capitalize text-base font-normal`}>Name</th>
                            <th className={`bg-transparent border-b ${darkMode ? 'border-[#343434]' : 'border-gray-200'} capitalize text-base font-normal`}>Email/Phone</th>
                            <th className={`bg-transparent border-b ${darkMode ? 'border-[#343434]' : 'border-gray-200'} capitalize text-base font-normal`}>
                                Status
                            </th>
                            <th className={`bg-transparent border-b ${darkMode ? 'border-[#343434]' : 'border-gray-200'} capitalize text-base font-normal w-full`}>
                                Subscription Plan
                            </th>

                            <th className={`bg-transparent border-b ${darkMode ? 'border-[#343434]' : 'border-gray-200'} capitalize text-base font-normal w-full`}>
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            Data.length > 0 && Data.map((item: any, index: number) => {
                                return (
                                    <tr key={index}>
                                        <th className={`bg-transparent border-b ${darkMode ? 'border-[#343434]' : 'border-gray-200'} capitalize text-base font-normal w-10`}>
                                            <div className="tooltip tooltip-right -ml-2.5" data-tip="Select One">
                                                <Checkbox
                                                    checked={allSelectArray.includes(item._id as never)}
                                                    onChange={headleCheckbox}
                                                    color="primary"
                                                    name={item._id}
                                                />
                                            </div>
                                        </th>
                                        <td className={`bg-transparent border-b ${darkMode ? 'border-[#343434]' : 'border-gray-200'} capitalize text-base font-normal`}>
                                            <div className="flex items-center space-x-3">
                                                <div className="w-11 h-11 bg-secondary-color rounded-full overflow-hidden">
                                                    <img
                                                        src={item.avatar || 'https://i.stack.imgur.com/dr5qp.jpg'}
                                                    />
                                                </div>
                                                <div>
                                                    <div className="font-semibold opacity-80 capitalize text-sm">{item.name || 'N/A'}</div>
                                                    <div className="text-xs mt-1 w-fit text-white bg-green-500 text-center pt-0.5 px-2 capitalize rounded-full">{item.role}</div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className={`bg-transparent border-b ${darkMode ? 'border-[#343434]' : 'border-gray-200'} capitalize text-base font-normal`}>
                                            <span className="capitalize">{item.email || item.phone}</span>
                                        </td>
                                        <td className={`bg-transparent border-b ${darkMode ? 'border-[#343434]' : 'border-gray-200'} capitalize text-base font-normal w-[50%]`}>
                                            <span>
                                                {
                                                    item.isActivated ? (
                                                        <div className="flex items-center space-x-2">
                                                            <div className="w-2 h-2 bg-green-500 rounded-full"></div>
                                                            <div className="text-xs text-green-500">Active</div>
                                                        </div>
                                                    ) : (
                                                        <div className="flex items-center space-x-2">
                                                            <div className="w-2 h-2 bg-red-500 rounded-full"></div>
                                                            <div className="text-xs text-red-500">
                                                                Deactivated
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </span>
                                        </td>
                                        <td className={`bg-transparent border-b ${darkMode ? 'border-[#343434]' : 'border-gray-200'} capitalize text-base font-normal w-full`}>
                                            <span>{'N/A'}</span>
                                        </td>
                                        <td className={`bg-transparent border-b ${darkMode ? 'border-[#343434]' : 'border-gray-200'} w-full`}>
                                            <div className="flex gap-4">
                                                <label onClick={() => {
                                                    setSingleSelect([item._id as never])
                                                    setStatus(item.isActivated ? 'deactivate' : 'activate')
                                                    handleMuiDialogOpen()
                                                    setMuiDialogContant({
                                                        title: 'User Status',
                                                        contentText: 'Are you sure you want to change the status of this user?',
                                                        buttonOneText: 'Cancel',
                                                        buttonTwoText: 'Yes',
                                                        actionKey: 'statusUser'
                                                    })
                                                }} className="cursor-pointer"> {item.isActivated ? <div className="tooltip tooltip-accent" data-tip="Public">
                                                    <Button
                                                        className="w-10 h-10 bg-gray-800 hover:bg-gray-700"
                                                        variant="text">
                                                        <VisibilityIcon />
                                                    </Button>
                                                </div> : <div className="tooltip tooltip-accent" data-tip="Private">
                                                    <Button
                                                        className="w-10 h-10 bg-gray-800 hover:bg-gray-700"
                                                        variant="text">
                                                        <VisibilityOffIcon />
                                                    </Button>
                                                </div>}</label>

                                                <Link to={`/admin/users/edit/${item._id}`} className="cursor-pointer">
                                                    <div className="tooltip tooltip-accent" data-tip="Edit">
                                                        <Button
                                                            className="w-10 h-10 bg-gray-800 hover:bg-gray-700"
                                                            variant="text">
                                                            <EditIcon />
                                                        </Button>
                                                    </div>
                                                </Link>

                                                {
                                                    !item.trash && <label onClick={() => {
                                                        setSingleSelect([item._id] as any)
                                                        setStatus('trash')
                                                        handleMuiDialogOpen()
                                                        setMuiDialogContant({
                                                            title: 'Delete User',
                                                            contentText: 'Are you sure you want to delete this user?',
                                                            buttonOneText: 'Cancel',
                                                            buttonTwoText: 'Yes',
                                                            actionKey: 'deleteUser'
                                                        })
                                                    }}
                                                        className="cursor-pointer">
                                                        <div className="tooltip tooltip-accent" data-tip="Delete">
                                                            <Button
                                                                className="w-10 h-10 bg-gray-800 hover:bg-gray-700"
                                                                variant="text">
                                                                <DeleteIcon />
                                                            </Button>
                                                        </div>
                                                    </label>
                                                }
                                                {
                                                    item.trash && <label onClick={() => {
                                                        setSingleSelect([item._id] as any)
                                                        setStatus('restore')
                                                        handleMuiDialogOpen()
                                                        setMuiDialogContant({
                                                            title: 'Restore User',
                                                            contentText: 'Are you sure you want to restore this user?',
                                                            buttonOneText: 'Cancel',
                                                            buttonTwoText: 'Yes',
                                                            actionKey: 'restoreUser'
                                                        })
                                                    }}
                                                        className="cursor-pointer">
                                                        <div className="tooltip tooltip-accent" data-tip="Delete">
                                                            <Button
                                                                className="w-10 h-10 bg-gray-800 hover:bg-gray-700"
                                                                variant="text">
                                                                <RestoreFromTrashIcon />
                                                            </Button>
                                                        </div>
                                                    </label>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }

                    </tbody>
                </table>
            </div>

            <MuiDialog
                open={muiDialogOpen}
                onClose={handleMuiDialogClose}
                title={muiDialogContant.title}
                contentText={muiDialogContant.contentText}
                onClickClose={() => muiDialogContant.twoAction ? handleDeleteForever(muiDialogContant.bulkDeleteData || handleDeleteForeverdata) : handleMuiDialogClose()}
                onClickYes={() => muiDialogContant.twoAction ? handleActions({
                    id: muiDialogContant.bulkDeleteData.id,
                    action: 'restore'
                }) : handleActions(data)}
                // onClickYes={() => handleActions(data)}
                buttonOneText={muiDialogContant.buttonOneText}
                buttonTwoText={muiDialogContant.buttonTwoText}
            />
        </>
    )
}

export default Table;