import Layout from "../../components/Layout/Layout";
import { Input, InputField, TailwindBox, Title } from "../../tailwind";
import { ProfileIcon } from "../../tailwind/Icon";
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchUser } from "../../redux/slice​/userSlice";
import moment from "moment";
import { updateUser } from "../../http";
import toast from "react-hot-toast";
import { RootState } from "../../redux/store";
import Button from "@mui/material/Button";

const EditUser = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { singleUser } = useSelector((state: RootState) => state.users);
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState("User Info");
    useEffect(() => {
        dispatch(fetchUser(id as string) as any);
    }, [])
    const [user, setUser] = useState({
        name: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        birthdate: "",
        country: "",
        avatar: ""
    });
    useEffect(() => {
        // set date to be in the format of MM/DD/YYYY
        setUser({
            name: singleUser.name,
            address: singleUser.address,
            city: singleUser.city,
            state: singleUser.state,
            zip: singleUser.zip,
            birthdate: moment(singleUser.birthdate).format("MM/DD/YYYY"),
            country: singleUser.country,
            avatar: singleUser.avatar
        })
    }, [singleUser])

    const onSubmit = async () => {
        const data = new FormData();
        data.append("name", user.name);
        data.append("address", user.address);
        data.append("city", user.city);
        data.append("state", user.state);
        data.append("zip", user.zip);
        data.append("birthdate", user.birthdate);
        data.append("country", user.country);

        try {
            setLoading(true);
            const res = await updateUser(id, data);
            if (res.status === 200) {
                setLoading(false);
                toast.success("User updated successfully");
            }
        } catch (error) {
            setLoading(false);
            toast.error("Something went wrong");
        }

    }
    return (
        <Layout>
            <div className="pr-5">
                <Title className='text-xl mb-4 font-semibold opacity-80'>Edit User</Title>
                <div>
                    <div className="grid grid-cols-3 gap-5">
                        <div className="flex col-span-1 overflow-hidden rounded w-full">
                            <TailwindBox>
                                <div className="flex flex-col w-full items-center">
                                    <h1 className="text-2xl font-semibold tracking-wider capitalize">{singleUser.name}</h1>
                                    <span className="text-sm my-2"> {singleUser.lastActivity ? moment(singleUser.lastActivity).fromNow() : 'Not Login'} </span>
                                    <div className="mt-6">
                                        {
                                            singleUser.avatar ?
                                                <img className="w-40 h-40 rounded-full border-2 object-cover" src={singleUser.avatar} alt="avatar" /> :
                                                <div className="w-40 h-40 rounded-full border-2 object-cover overflow-hidden">
                                                    <ProfileIcon width={160} height={160} />
                                                </div>
                                        }
                                    </div>
                                    <div className="mt-4">
                                        <Button
                                            variant="contained"
                                            color="success"
                                            size="small"
                                            className="w-40 h-10"
                                            disabled={loading}
                                            onClick={onSubmit}
                                        >
                                            Update Profile
                                        </Button>
                                    </div>
                                    <div className="w-full bg-dark-color-2 py-4 px-5 mt-4 rounded shadow border border-gray-400">
                                        <h1 className="text-center text-sm font-thin">Upload a new avatar. Larger image will be resized automatically.</h1>
                                        <h1 className="text-center text-sm font-thin mt-3">Maximum upload size is 1MB</h1>
                                    </div>
                                    <h1 className="text-center text-sm font-thin mt-3">Member Since: <span className="font-bold">{moment(singleUser.createdAt).format("MMM Do YY")}</span></h1>
                                </div>
                            </TailwindBox>
                        </div>
                        <div className="col-span-2 overflow-hidden rounded">
                            <TailwindBox>
                                <div className="flex gap-10">
                                    <div className={`${activeTab === 'User Info' ? 'border-b-2 cursor-pointer pb-1' : 'cursor-pointer'} `} onClick={() => setActiveTab('User Info')}>
                                        <span className={`text-base font-semibold uppercase ${activeTab === 'User Info' && 'text-white'}`}>User Info</span>
                                    </div>
                                    <div className={`${activeTab === 'Subscription' ? 'border-b-2 cursor-pointer pb-1' : 'cursor-pointer'} `} onClick={() => setActiveTab('Subscription')}>
                                        <span className={`text-base font-semibold uppercase ${activeTab === 'Subscription' && 'text-white'}`}>Subscription</span>
                                    </div>
                                </div>
                                {
                                    activeTab === "User Info" && <div className="grid grid-cols-2 gap-x-5 gap-y-3 mt-5">
                                        <InputField
                                            fieldName="Name"
                                            type="text"
                                            placeholder="Name"
                                            value={user.name}
                                            onChange={(e: any) => setUser({ ...user, name: e.target.value })}
                                        />
                                    </div>
                                }
                                {
                                    activeTab === "Subscription" && <div className="grid grid-cols-2 gap-x-5 gap-y-3 mt-5">
                                        <p>{singleUser.subscription ? singleUser.subscription.name : "No Subscription"}</p>
                                    </div>
                                }
                            </TailwindBox>
                        </div>
                    </div>

                    <div className='w-full my-5'>
                        <div className='flex gap-4 justify-end'>
                            <Button
                                variant="contained"
                                color="success"
                                size="small"
                                className="w-40 h-10"
                                disabled={loading}
                                onClick={onSubmit}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default EditUser;