import qs from "qs";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { fetchAnalyticsUser } from "../../../../../redux/slice​/analyticsSlice";
import _ from "lodash";
import MovieAndSeries from "../../Charts/MovieAndSeries/MovieAndSeries";
import Chart from 'react-apexcharts'
import moment from "moment";
import { TailwindBox } from "../../../../../tailwind";

const UserAnalytics = () => {
    const { userAnalyticsData } = useSelector(state => state.analytics);
    // userAnalyticsData have users array and users array have users.count 
    const totalUsersInLastMonth = userAnalyticsData?.users && _.reduce(userAnalyticsData.users, (sum, user) => sum + user.count, 0);



    return (
        <TailwindBox title="User Analytics">
            <p className="text-xs opacity-80 mt-2">
                Last 28 days:
            </p>
            <div className="flex flex-wrap mt-4">
                {
                    userAnalyticsData && (
                        <div className="w-full -mt-4">
                            <div className="text-black">
                                <Chart
                                    options={{
                                        // hide top and right border
                                        chart: {
                                            toolbar: {
                                                show: false,
                                            },
                                            sparkline: {
                                                enabled: true,
                                            },
                                        },
                                        stroke: {
                                            curve: 'smooth'
                                        },
                                        // chenge color of text and line
                                        // colors: ['#D75483'],
                                        // hide legend
                                        // legend: {
                                        //     show: false,
                                        // },
                                        xaxis: {
                                            categories: userAnalyticsData.users.map(user => moment
                                                (user._id).format("DD MMM")),
                                        }
                                    }}
                                    series={[{
                                        name: 'total users',
                                        data: userAnalyticsData.users.map(user => user.count)
                                    }]}
                                    type="area"
                                    width={'100%'}
                                />

                                {/* <Line
                                    options={{
                                        responsive: true,
                                    }}
                                    data={{
                                        labels: userAnalyticsData.users.map(user => user._id),
                                        datasets: [
                                            {
                                                label: "Total Users",
                                                data: userAnalyticsData.users.map(user => user.count),
                                                backgroundColor: "rgba(0, 225, 0, 0.75)",
                                            },
                                        ],
                                    }}
                                /> */}
                            </div>
                            <div className="">
                                <div className="flex justify-between items-center mt-5 text-sm">
                                    <h6>
                                        Total Users
                                    </h6>
                                    <p>
                                        {
                                            userAnalyticsData.totalUsers || 0
                                        }
                                    </p>
                                </div>
                                <div className="flex justify-between items-center mt-4 text-sm">
                                    <h6>
                                        Total Users in last month
                                    </h6>
                                    <p>
                                        {
                                            totalUsersInLastMonth || 0
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </TailwindBox>
    )
}

export default UserAnalytics