import Layout from "../../components/Layout/Layout";
import { Box, Title } from "../../tailwind";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { fetchOrder } from "../../redux/slice​/ordersSlice";
import { useParams } from "react-router-dom";
import moment from "moment";

const OrderView = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { singleOrder } = useSelector(state => state.orders);
    useEffect(() => {
        dispatch(fetchOrder(id))
    }, [])
    return (
        <Layout>
            <Title>Order Details</Title>
            <>
                <div className="my-5 grid grid-cols-2 gap-5">
                    <Box title="Order Info">
                        <div className="flex justify-between items-center">
                            <div className="flex flex-col gap-4">
                                <p>Order ID</p>
                                <p>Order Entity</p>
                                <p>Order Amount</p>
                                <p>Order Amount Paid</p>
                                <p>Order Amount Due</p>
                                <p>Order Currency</p>
                                <p>Order Receipt</p>
                                <p>Order Offer Id</p>
                                <p>Order Status</p>
                                <p>Order Attempts</p>
                                <p>Order Created At</p>
                            </div>
                            <div className="flex flex-col gap-4">
                                <p>{singleOrder.id}</p>
                                <p>{singleOrder.entity}</p>
                                <p>{singleOrder.amount}</p>
                                <p>{singleOrder.amount_paid}</p>
                                <p>{singleOrder.amount_due}</p>
                                <p>{singleOrder.currency}</p>
                                <p>{singleOrder.receipt}</p>
                                <p>{singleOrder.offer_id ? singleOrder.offer_id : 'Null'}</p>
                                <p>{singleOrder.status}</p>
                                <p>{singleOrder.attempts}</p>
                                <p>{moment(singleOrder.createdAt).format("MMMM Do YYYY, h:mm:ss a")}</p>
                            </div>
                        </div>

                    </Box>
                    <Box title="Action" >

                    </Box>
                </div>
            </>
        </Layout>
    )
}

export default OrderView;