import { useSelector, useDispatch } from "react-redux";
import Layout from "../../components/Layout/Layout";
import { useEffect } from "react";
import { fetchMovieReport, fetchMovies } from "../../redux/slice​/movieSlice";
import { fetchUserReport, fetchUsers } from "../../redux/slice​/userSlice";
import { fetchAnalytics, fetchAnalyticsContent, fetchAnalyticsLatestMoviePerformance, fetchAnalyticsTrendings, fetchAnalyticsUser, fetchRevenue } from "../../redux/slice​/analyticsSlice";
import qs from "qs";
import moment from "moment";
import { Link } from "react-router-dom";
import UserAnalytics from "./components/Card/Users/Users";
import RevenueAnalytics from "./components/Card/Revenue/Revenue";
import ContentAnalytics from "./components/Card/Content/Content";
import { fetchAllContant } from "../../redux/slice​/contentSlice";
import { TailwindBox } from "../../tailwind";
import { RootState } from "../../redux/store";

const Dashboard = () => {
    const dispatch = useDispatch();
    const { user } = useSelector((state: RootState) => state.auth);
    const { users } = useSelector((state: RootState) => state.users);
    const { analytics, LatestMoviePerformance, trendings } = useSelector((state: RootState) => state.analytics);
    const { content } = useSelector((state: RootState) => state.content);

    // get last month 2021-09-01 to 2021-09-30
    const lastMonth = () => {
        const date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth();
        const firstDay = new Date(year, month, 1);
        const lastDay = new Date(year, month + 1, 0);
        return {
            firstDay: firstDay.toISOString().slice(0, 10),
            lastDay: lastDay.toISOString().slice(0, 10)
        }
    }
    const query = qs.stringify({
        startDate: lastMonth().firstDay,
        endDate: lastMonth().lastDay
    })
    const queryLatestMoviePerformance = qs.stringify({
        startDate: lastMonth().firstDay,
        endDate: lastMonth().lastDay,
        contant_id: content[0]?._id,
    })


    useEffect(() => {
        dispatch(fetchAllContant(null, null) as any);
        dispatch(fetchMovieReport() as any);
        dispatch(fetchUserReport() as any);
        dispatch(fetchMovies(1, 1) as any);
        dispatch(fetchAnalytics(query) as any);
        dispatch(fetchUsers(1, 10, 'desc') as any);
        dispatch(fetchAnalyticsTrendings(query) as any);
        dispatch(fetchAnalyticsUser(query) as any);
        dispatch(fetchAnalyticsContent(query) as any);
        dispatch(fetchRevenue(query) as any);
        return () => {}
    }, []);

    useEffect(() => {
        if (content) {
            const timeOut = setTimeout(() => {
                dispatch(fetchAnalyticsLatestMoviePerformance(queryLatestMoviePerformance) as any);
            }, 1000);
            return () => clearTimeout(timeOut);
        }
        return () => {}
    }, [content]);

    return (
        <Layout>
            <div className="w-full">
                <div className="flex justify-between pr-5">
                    <h1 className="text-[1.4rem] tracking-wider opacity-70">
                        Welcome back, <span className="font-semibold">{user.name}</span>
                    </h1>
                </div>
                <div className="grid grid-cols-3 gap-5 pr-5 mt-5">
                    <div className="flex flex-col gap-5">
                        <TailwindBox
                            title="Latest Content Performance"
                        >
                            <div>
                                <div className="aspect-video w-full overflow-hidden bg-gray-900 border border-gray-700 rounded my-4 relative">
                                    <img
                                        src={content[0]?.thumbnail}
                                    />
                                    <div className="absolute bottom-0 dashbord__card__content w-full p-3">
                                        <h1 className="mt-10 text-base font-bold tracking-wide opacity-90 line-clamp-1 text-white">
                                            {content[0]?.name}
                                        </h1>
                                    </div>
                                </div>
                                <p className="text-xs opacity-80 mt-2">
                                    Last 28 days:
                                </p>
                                <div className="flex justify-between items-center mt-4 text-sm">
                                    <h6>
                                        Views
                                    </h6>
                                    <p>
                                        {
                                            LatestMoviePerformance[0]?.count || 0
                                        }
                                    </p>
                                </div>
                                <div className="flex justify-between items-center mt-3 text-sm">
                                    <h6>
                                        Watch time
                                    </h6>
                                    <p>
                                        {/* {
                                            content[0]?.watch_time ? + 'h' || 0 : 0
                                        } */}
                                    </p>
                                </div>
                                <div className="mt-7 mb-5">
                                    <button className="uppercase text-blue-400 font-semibold ">
                                        go to content analytics
                                    </button>
                                </div>
                                <div className="mt-4 mb-3">
                                    <Link
                                        to={`/admin/content/edit/${content[0]?._id}`}
                                    >
                                        <button className="uppercase text-blue-400 font-semibold ">
                                            Edit content
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </TailwindBox>
                        <UserAnalytics />
                    </div>
                    <div className="flex flex-col gap-5">
                        <TailwindBox
                            title="Analytics">
                            <div>
                                <p className="text-xs  opacity-80 mt-2">
                                    Last 28 days:
                                </p>
                                <div className="flex justify-between items-center mt-4 text-sm">
                                    <h6>
                                        Views
                                    </h6>
                                    <p>
                                        {/* {
                                            analytics && analytics?.views === 0 ? analytics?.views : 0
                                        } */}
                                    </p>
                                </div>
                                <div className="flex justify-between items-center mt-3 text-sm">
                                    <h6>
                                        Watch time
                                    </h6>
                                    <p>
                                        {/* {
                                            analytics && analytics?.watchTime === 0 ? 0 : + analytics?.watchTime + "h"
                                        } */}
                                    </p>
                                </div>
                                <div>
                                    <h1 className=" text-base font-bold opacity-90 tracking-wide mt-7 mb-5">
                                        Best Perfoming Content
                                    </h1>
                                    <div>
                                        {
                                            content && content?.map((item, index) => (
                                                <div key={index} className="flex items-center justify-between mt-5">
                                                    <div className="flex items-center">
                                                        <div className="w-11 h-11 bg-secondary-color rounded overflow-hidden">
                                                            <img
                                                                src={item.poster || '/default-product.png'}
                                                            />
                                                        </div>
                                                        <div className="ml-3">
                                                            <h1 className=" text-sm font-semibold opacity-90 tracking-wide">
                                                                {item.name}
                                                            </h1>
                                                            <p className="text-xs  opacity-80 mt-0.5">
                                                                {
                                                                    moment(item.createdAt).format('DD MMM YYYY')
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="ml-3">
                                                            <p className="text-xs  opacity-80">
                                                                {/* {
                                                                    item.views
                                                                } */}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )).slice(0, 5)
                                        }
                                    </div>
                                </div>
                                <div className="mt-7 mb-5">
                                    <button className="uppercase text-blue-400 font-semibold ">
                                        go to analytics
                                    </button>
                                </div>
                            </div>
                        </TailwindBox>
                        <ContentAnalytics />
                        <RevenueAnalytics />
                    </div>
                    <TailwindBox title="New User">
                            <div>
                                <p className="text-xs  opacity-80 mt-2">
                                    New 7 users:
                                </p>
                                <div>
                                {
                                    users && users.map((user, index) => (
                                        <div key={index} className="flex items-center justify-between mt-5">
                                            <div className="flex items-center">
                                                <div className="w-11 h-11 bg-secondary-color rounded-full overflow-hidden">
                                                    <img
                                                        src={user.avatar || 'https://i.stack.imgur.com/dr5qp.jpg'}
                                                    />
                                                </div>
                                                <div className="ml-3">
                                                    <h1 className=" text-sm font-semibold opacity-90 tracking-wide">
                                                        {user.name || 'N/A'}
                                                    </h1>
                                                    <p className="text-xs  opacity-80">
                                                        {user.email || user.phone}
                                                    </p>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="ml-3">
                                                    <p className="text-xs  opacity-80">
                                                        {moment(user.createdAt).format("MMM Do YY")}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )).slice(0, 7)
                                }
                            </div>
                                <div className="mt-7 mb-5">
                                    <Link to="/admin/users">
                                        <button className="uppercase text-blue-400 font-semibold ">
                                            go to users
                                        </button>
                                    </Link>
                                </div>
                            </div>
                    </TailwindBox>
                </div>
            </div>
            <div className="flex justify-end pr-3 my-3">
                <p className={` text-xs px-3`}>
                    Version 1.0.0 | <a href="https://www.zezosoft.com/" target="_blank" rel="noreferrer">
                        Powered by Zezo
                    </a>
                </p>
            </div>
        </Layout >
    );
}
export default Dashboard;