import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import { Button, InputField, SelectField, TagField } from "../../tailwind";
import { useSelector, useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { UpdateSubscription } from "../../http/Apis";
import { fetchSubscriptions } from "../../redux/slice​/SubscriptionSlice";
import { RootState } from "../../redux/store";
import LoadingButton from "@mui/lab/LoadingButton";


const EditSubscriptions = () => {
    const { id } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchSubscriptions(`id=${id}`, 'single') as any);
    }, [])

    const { single } = useSelector((state: RootState) => state.subscription);

    interface ISubscriptionState {
        name: string;
        description: string;
        price: number;
        points: string[];
        duration: 'weekly' | 'monthly' | 'quarterly' | 'half-yearly' | 'yearly';
        currency: string;
        maxVideoQuality: string;
    }

    const [subscription, setSubscription] = useState<ISubscriptionState>({
        name: "",
        description: "",
        price: 0,
        points: [],
        duration: "monthly",
        currency: "INR",
        maxVideoQuality: "720",
    });

    useEffect(() => {
        setSubscription({
            name: single.name,
            description: single.description,
            price: single.price,
            points: single.points ? single.points : [],
            duration: single.duration,
            currency: single.currency,
            maxVideoQuality: single.maxVideoQuality,
        });
    }, [single]);

    const handleSubmit = async (e: any) => {
        try {
            const res = await UpdateSubscription(id, subscription);
            if (res.status === 200) {
                toast.success("Subscription updated successfully");
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.error?.message || "Something went wrong");
        }
    }

    // duration:
    const durationType = [
        { name: 'Weekly', _id: 'weekly' },
        { name: 'Monthly', _id: 'monthly' },
        { name: 'Quarterly', _id: 'quarterly' },
        { name: 'Half yearly', _id: 'half-yearly' },
        { name: 'Yearly', _id: 'yearly' },
    ]
    return (
        <Layout>
            <h1 className="font-medium text-2xl pr-5 mb-3">
                Edit Subscription
            </h1>
            <div className='py-4 pr-5 flex-col flex gap-4'>
                <InputField
                    type="text"
                    placeholder="Type name"
                    fieldName="Name"
                    value={subscription.name}
                    onChange={(e) => setSubscription({ ...subscription, name: e.target.value })}
                />
                <InputField
                    type="text"
                    placeholder="Type description"
                    fieldName="Description"
                    value={subscription.description}
                    onChange={(e) => setSubscription({ ...subscription, description: e.target.value })}
                />
                <InputField
                    type="number"
                    placeholder="Type price"
                    fieldName="Price"
                    value={subscription.price}
                    onChange={(e) => setSubscription({ ...subscription, price: e.target.value as any })}
                />

                <TagField
                    placeholder="Type points"
                    fieldName="Points"
                    value={subscription.points}
                    onKeyDown={(e) => {
                        if (e.key !== 'Enter') return
                        const value = e.target.value
                        if (!value.trim()) return
                        setSubscription({ ...subscription, points: [...subscription.points, value] })
                        e.target.value = ''
                    }}
                    onRemove={(index) => {
                        const newPoints = subscription.points.filter((point, i) => i !== index)
                        setSubscription({ ...subscription, points: newPoints })
                    }}
                />

                <SelectField
                    options={durationType}
                    onChange={(e) => {
                        setSubscription({ ...subscription, duration: e._id })
                    }}
                    placeholder={'Duration'}
                    isMulti={false}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option._id}
                    defaultValue={durationType.find((item) => item._id === subscription.duration) as any}
                />
                <div className='flex justify-end mt-5'>
                    <LoadingButton
                        variant="contained"
                        onClick={handleSubmit}
                        loading={false}
                        loadingPosition="start"
                        startIcon={null}
                    >
                        Update
                    </LoadingButton>

                </div>
            </div>
        </Layout>
    );
}

export default EditSubscriptions;