import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface IAuthInitialState {
    isAuthenticated: boolean
    user: {
        _id: string
        role: string
        name: string
        email: string
    }
}

const initialState: IAuthInitialState = {
    isAuthenticated: false,
    user: {
        _id: '',
        role: '',
        name: '',
        email: '',
    },
}

export const AuthSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuth: (state, actions) => {
            state.user = actions.payload
            console.log('actions.payload', actions.payload)
            state.isAuthenticated = actions.payload ? true : false
        },
    },
})

// Action creators are generated for each case reducer function
export const { setAuth } = AuthSlice.actions

export default AuthSlice.reducer