import { useEffect, useState } from "react"
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { createJob, getJob, movieDelete, movieRestore, movieStatus, premanentlyDeleteMovie, updateJob } from "../../http/Apis";
import { fetchMovies, setBulkActionCall } from "../../redux/slice​/movieSlice";
import moment from "moment";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Hr } from "../../components";
import { MdVisibility, MdVisibilityOff } from "react-icons/md"
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import EditIcon from '@mui/icons-material/Edit';


const MoviesTable = ({ data, page }) => {
    const dispatch = useDispatch();
    const [allSelectArray, setallSelectArray] = useState([])
    const { bulkAction, bulkActionCall } = useSelector(state => state.movies);
    const [singleSelect, setSingleSelect] = useState([])
    const [status, setStatus] = useState(false)
    const [premanentlyDelete, setPremanentlyDelete] = useState(false)
    // status dialog
    const [statusDialogOpen, setStatusDialogOpen] = useState(false);
    const handleClickDialogOpen = () => {
        setStatusDialogOpen(true);
    };

    const handleDialogClose = () => {
        setStatusDialogOpen(false);
    };
    // delete dialog
    const [deleteDialogOpen, setSeleteDialogOpen] = useState(false);
    const handleClickDialogDeleteOpen = () => {
        setSeleteDialogOpen(true);
    };

    const handleDialogDeleteClose = () => {
        setSeleteDialogOpen(false);
    };

    // restore dialog
    const [restoreDialogOpen, setRestoreDialogOpen] = useState(false);
    const handleClickDialogRestoreOpen = () => {
        setRestoreDialogOpen(true);
    };

    const handleDialogRestoreClose = () => {
        setRestoreDialogOpen(false);
    };


    const [pagination, setPagination] = useState({
        page: 1,
        limit: process.env.REACT_APP_PAGE_SIZE,
    })
    const [job, setJob] = useState(false)
    // handleStatus
    const handleStatus = async () => {
        const data = {
            isPublished: status,
            id: singleSelect,
        }
        try {
            const response = await movieStatus(data)
            if (response.status === 200) {
                toast.success('Status updated successfully')
                dispatch(fetchMovies(page, pagination.limit))
                handleDialogClose(false)
            }
        } catch (error) {
            toast.error('Something went wrong')
        }
    }
    // handle Delete
    const handleDelete = async () => {
        try {
            const response = await movieDelete({ id: singleSelect })
            if (response.status === 200) {
                toast.success('Movies deleted successfully')
                dispatch(fetchMovies(page, pagination.limit))
                handleDialogDeleteClose(false)
            }
        }
        catch (error) {
            toast.error('Something went wrong')
        }
    }
    // restore
    const handleRestore = async () => {
        try {
            const response = await movieRestore({ id: singleSelect })
            if (response.status === 200) {
                toast.success('Movies restored successfully')
                dispatch(fetchMovies(page, pagination.limit))
                handleDialogRestoreClose(false)
            }
        } catch (error) {
            toast.error('Something went wrong')
        }
    }
    // headle checkbox
    const headleCheckbox = (e) => {
        const { name: id, checked } = e.target;
        if (id === 'selectAll') {
            if (checked) {
                setallSelectArray(data.map(data => data.id))
            } else {
                setallSelectArray([])
            }
        } else {
            setallSelectArray(prev => {
                if (checked) {
                    return [...prev, id]
                } else {
                    return prev.filter(item => item !== id)
                }
            })
        }

    }

    useEffect(() => {
        if (bulkActionCall) {
            headleBulkActions()
            dispatch(setBulkActionCall(false))
        }
    }, [bulkActionCall])
    // headle bulk actions
    const headleBulkActions = async () => {
        const formData = new FormData();
        if (allSelectArray.length < 1) {
            formData.append('id', allSelectArray)
        } else {
            for (let i = 0; i < allSelectArray.length; i++) {
                formData.append('id', allSelectArray[i])
            }
        }
        const newFormData = allSelectArray.length > 0 ? { id: allSelectArray } : formData

        if (bulkAction === 'none') {
            toast.error("Please select bulk action")
        }
        if (bulkAction === 'Delete') {
            if (allSelectArray.length === 0) {
                toast.error("Please select movie")
            } else {
                try {
                    const res = await movieDelete(newFormData);
                    if (res.status === 200) {
                        dispatch(fetchMovies(page, pagination.limit))
                        toast.success("Movie deleted successfully")

                    }
                } catch (e) {
                    toast.error("Something went wrong")
                }
            }
        }
        if (bulkAction === 'UnPublish') {
            if (allSelectArray.length === 0) {
                toast.error("Please select movie")
            } else {
                const data = {
                    isPublished: false,
                    id: allSelectArray
                }
                try {
                    const res = await movieStatus(data);
                    if (res.status === 200) {
                        dispatch(fetchMovies(page, pagination.limit))
                        toast.success("Status updated")

                    }
                } catch (e) {
                    toast.error("Something went wrong")
                }
            }
        }
        if (bulkAction === 'Publish') {
            if (allSelectArray.length === 0) {
                toast.error("Please select movie")
            } else {
                const data = {
                    isPublished: true,
                    id: allSelectArray
                }
                try {
                    const res = await movieStatus(data);
                    if (res.status === 200) {
                        dispatch(fetchMovies(page, pagination.limit))
                        toast.success("Status updated")

                    }
                } catch (e) {
                    toast.error("Something went wrong")
                }
            }
        }
        if (bulkAction === 'Restore') {
            if (allSelectArray.length === 0) {
                toast.error("Please select movie")
            }
            try {
                const response = await movieRestore(newFormData)
                if (response.status === 200) {
                    toast.success('Movie restored successfully')
                    dispatch(fetchMovies(page, pagination.limit))
                }
            } catch (error) {
                toast.error('Something went wrong')
            }
        }
        if (bulkAction === 'harddelete') {
            if (!premanentlyDelete) return toast.error('Please select atleast one series')
            try {
                const response = await premanentlyDeleteMovie(premanentlyDelete)
                if (response.status === 200) {
                    setPremanentlyDelete(false)
                    toast.success('Series deleted successfully')
                    dispatch(fetchMovies(page, pagination.limit))
                }
            } catch (error) {
                toast.error(error.response.data.error.message || 'Something went wrong')
            }
        }
    }
    function secondsToHms(d, type) {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);

        if (type) {
            var hDisplay = h > 0 ? h + (h == 1 ? ":" : ":") : "";
            var mDisplay = m > 0 ? m + (m == 1 ? ":" : ":") : "";
            var sDisplay = s > 0 ? s + (s == 1 ? "" : "") : "";
        } else {
            var hDisplay = h > 0 ? h + (h == 1 ? " h, " : " h, ") : "";
            var mDisplay = m > 0 ? m + (m == 1 ? " m, " : " m, ") : "";
            var sDisplay = s > 0 ? s + (s == 1 ? " s" : " s") : "";
        }

        return hDisplay + mDisplay + sDisplay;
    }
    // handle start transcodeing
    const handleStartTranscodeing = async () => {
        const id = singleSelect[0] || singleSelect
        try {
            const response = await createJob({ id })
            if (response.status === 201) {
                toast.success('Job created successfully')
                dispatch(fetchMovies(page, pagination.limit))
            }
        } catch (error) {
            toast.error('Something went wrong')
        }
    }
    // get job status
    const getJobStatus = async (id) => {
        try {
            const response = await getJob(id)
            if (response.status === 200) {
                setJob(response.data.data.Job)
            }
        }
        catch (error) {
            toast.error('Something went wrong')
        }
    }
    // update job status
    const updateJobStatus = async () => {
        const id = singleSelect[0] || ''
        try {
            const response = await updateJob(id)
            if (response.status === 200) {
                toast.success('Job updated successfully')
                dispatch(fetchMovies(page, pagination.limit))
            }
        }
        catch (error) {
            toast.error('Something went wrong')
        }

    }

    // handlePremanentlyDelete
    const handlePremanentlyDelete = async () => {
        if (!premanentlyDelete) return toast.error('Please select atleast one series')
        try {
            const response = await premanentlyDeleteMovie(premanentlyDelete)
            if (response.status === 200) {
                setPremanentlyDelete(false)
                toast.success('Series deleted successfully')
                dispatch(fetchMovies(page, pagination.limit))
                handleDialogRestoreClose(false)
            }
        } catch (error) {
            toast.error(error.response.data.error.message || 'Something went wrong')
        }
    }






    return (
        <div className="">
            <div className="overflow-x-scroll w-full">
                <div className="flex px-5 mb-2.5">
                    <div className="w-36">
                        <div className="tooltip tooltip-right" data-tip="Select All">
                            <label>
                                <input
                                    type="checkbox"
                                    className="checkbox checkbox-primary border-blue-500 checked:bg-blue-500"
                                    name="selectAll"
                                    checked={allSelectArray.length === data.length}
                                    onChange={headleCheckbox}
                                />
                            </label>
                        </div>
                    </div>
                    <div className="w-[200rem]">
                        <h1 className="text-gray-300">Movie</h1>
                    </div>
                    <div className="w-full">
                        <h1 className="text-gray-300">Visibility</h1>
                    </div>
                    <div className="w-full">
                        <h1 className="text-gray-300">Restrictions</h1>
                    </div>
                    <div className="w-full">
                        <h1 className="text-gray-300">Date</h1>
                    </div>
                    <div className="w-full">
                        <h1 className="text-gray-300">Views</h1>
                    </div>
                    <div className="w-full">
                        <h1 className="text-gray-300"></h1>
                    </div>
                </div>
                <Hr />
                {
                    data.length > 0 && data.map((item, index) => {
                        return (
                            <div className="flex justify-start px-5 mt-3 mb-6">
                                <div className="w-36">
                                    <label>
                                        <input
                                            type="checkbox"
                                            className="checkbox checkbox-primary border-blue-500 checked:bg-blue-500"
                                            name={item.id}
                                            onChange={headleCheckbox}
                                            checked={allSelectArray.includes(item.id)}
                                        />
                                    </label>
                                </div>
                                <div className="w-[200rem]">
                                    <div className="flex">
                                        <div className="w-32 h-[70px] aspect-video rounded overflow-hidden relative">
                                            <img
                                                src={item.thumbnail ? item.thumbnail : '/default-product.png'}
                                                alt="Thumbnail"
                                            />
                                            <span className="absolute bottom-1.5 right-1.5 rounded bg-black opacity-80 w-fit px-2 text-sm">{secondsToHms(item.duration, true)}</span>
                                        </div>
                                        <div className="ml-3 w-60">
                                            <h1 className="text-white text-base line-clamp-1">{item.title}</h1>
                                            <p className="text-gray-400 text-xs mt-1 line-clamp-2">{item.description}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full cursor-pointer"

                                >
                                    <label
                                        onClick={() => {
                                            setSingleSelect([item.id])
                                            setStatus(item.isPublished ? false : true)
                                            handleClickDialogOpen(true)
                                        }}
                                    >
                                        <h1 className="text-gray-300 cursor-pointer">
                                            {item.isPublished ? <div className="flex items-center gap-2">
                                                <MdVisibility size={20} className="text-green-500" />
                                                <span className="text-sm">Public</span>
                                            </div> : <div>
                                                <div className="flex items-center gap-2">
                                                    <MdVisibilityOff size={20} className="text-red-500" />
                                                    <span className="text-sm">Private</span>
                                                </div>
                                            </div>}
                                        </h1>
                                    </label>
                                </div>
                                <div className="w-full">
                                    <h1 className="text-gray-300">None</h1>
                                </div>
                                <div className="w-full">
                                    <h1 className="text-gray-300 capitalize">
                                        {moment(item.createdAt).format('MMM DD, YYYY')}
                                    </h1>
                                    <p className="text-xs text-gray-400 mt-0.5">Published</p>
                                </div>
                                <div className="w-full">
                                    <h1 className="text-gray-300">
                                        {item.viewId?.views?.length ? item.viewId.views.length : 0}
                                    </h1>
                                </div>
                                <div className="w-full">
                                    {
                                        item.isDeleted ? <div className="tooltip tooltip-accent" data-tip="Restore"> <Button
                                            onClick={() => {
                                                setSingleSelect([item.id])
                                                handleClickDialogRestoreOpen(true)
                                                setPremanentlyDelete([item.id])
                                            }}
                                            variant="text">
                                            <RestoreFromTrashIcon className="cursor-pointer" />
                                        </Button> </div> : <div className="tooltip tooltip-accent" data-tip="Delete"> <Button
                                            onClick={() => {
                                                setSingleSelect([item.id])
                                                handleClickDialogDeleteOpen(true)
                                            }}
                                            variant="text">
                                            <DeleteIcon className="cursor-pointer" />
                                        </Button>
                                        </div>
                                    }
                                    <Link to={`/admin/content/movie/edit/${item.id}`} className="cursor-pointer">
                                        <div className="tooltip tooltip-accent" data-tip="Edit">
                                            <Button
                                                className="w-10 h-10 bg-gray-800 hover:bg-gray-700"
                                                variant="text">
                                                <EditIcon className="cursor-pointer" />
                                            </Button>
                                        </div>
                                    </Link>

                                </div>
                            </div>
                        )
                    })
                }
                <div className="-mt-3">
                    <Hr />
                </div>

            </div>
            {/* Status dialog */}
            <div className="bg-dark-color">
                <Dialog
                    open={statusDialogOpen}
                    onClose={handleDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Movie Status"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to change the status of this movie?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogClose}>
                            Cancel
                        </Button>
                        <Button onClick={handleStatus} autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

            {/* delete dialog */}
            <div className="bg-dark-color">
                <Dialog
                    open={deleteDialogOpen}
                    onClose={handleDialogDeleteClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Move to Trash"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to move this movie to trash?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogDeleteClose}>
                            Cancel
                        </Button>
                        <Button onClick={handleDelete} autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

            {/* restore dialog */}
            <div className="bg-dark-color">
                <Dialog
                    open={restoreDialogOpen}
                    onClose={handleDialogRestoreClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Restore from Trash"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to restore this movie from trash?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handlePremanentlyDelete}>
                            Delete Forever
                        </Button>
                        <Button onClick={handleRestore} autoFocus>
                            Restore
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>


            {/* <table className="overflow-x-auto w-full">
                <thead className="px-5 w-full">
                    <tr className="w-full items-center flex flex-row px-5">
                        <th>
                            <div className="tooltip tooltip-right" data-tip="Select All">
                                <label>
                                    <input
                                        type="checkbox"
                                        className="checkbox checkbox-primary"
                                        name="selectAll"
                                        checked={allSelectArray.length === data.length}
                                        onChange={headleCheckbox}
                                    />
                                </label>
                            </div>
                        </th>
                        <th className="capitalize">Movie</th>
                        <th className="capitalize">duration</th>
                        <th className="capitalize">View</th>
                    </tr>
                </thead>
                <Hr className='my-5' />

                <tr className="w-full bg-red-900">
                    <th className="bg-secondary-color">
                        <div className="tooltip tooltip-right" data-tip="Select All">
                            <label>
                                <input
                                    type="checkbox"
                                    className="checkbox checkbox-primary"
                                    name="selectAll"
                                    checked={allSelectArray.length === data.length}
                                    onChange={headleCheckbox}
                                />
                            </label>
                        </div>
                    </th>
                    <th className="bg-secondary-color">Name</th>
                    <th className="bg-secondary-color">duration</th>
                    <th className="bg-secondary-color">View</th>
                    <th className="bg-secondary-color"></th>
                </tr>

                <table className="table w-full">
                    <thead>
                        <tr>
                            <th className="bg-secondary-color">
                                <div className="tooltip tooltip-right" data-tip="Select All">
                                    <label>
                                        <input
                                            type="checkbox"
                                            className="checkbox checkbox-primary"
                                            name="selectAll"
                                            checked={allSelectArray.length === data.length}
                                            onChange={headleCheckbox}
                                        />
                                    </label>
                                </div>
                            </th>
                            <th className="bg-secondary-color">Name</th>
                            <th className="bg-secondary-color">duration</th>
                            <th className="bg-secondary-color">View</th>
                            <th className="bg-secondary-color"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.length > 0 && data.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <th className="bg-slate-800">
                                            <div className="tooltip tooltip-right" data-tip="Select One">
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox checkbox-primary"
                                                        name={item.id}
                                                        onChange={headleCheckbox}
                                                        checked={allSelectArray.includes(item.id)}
                                                    />
                                                </label>
                                            </div>
                                        </th>
                                        <td className="bg-slate-800 border-slate-500">
                                            <div className="flex items-center space-x-3">
                                                <div className="avatar">
                                                    <div className="mask mask-squircle w-10 h-10">
                                                        <img src={item.thumbnail ? item.thumbnail : '/default-product.png'} alt="Thumbnail" />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="font-bold text-white opacity-80 capitalize text-sm">{item.title}</div>
                                                    <div className="text-xs text-white opacity-70 mt-0.5">{
                                                        moment(item.createdAt).format('MMMM Do YYYY, h:mm:ss a')
                                                    }</div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="bg-slate-800 border-slate-500 text-white text-sm">
                                            <span>{
                                                secondsToHms(item.duration)
                                            }</span>
                                        </td>
                                        <td className="bg-slate-800 border-slate-500 text-white text-sm">
                                            <span>{
                                                item.viewId?.views?.length ? item.viewId.views.length : 0
                                            }</span>
                                        </td>
                                        <th className="bg-slate-800 border-slate-500 flex gap-4">
                                            <div className="tooltip" data-tip="Status">
                                                <label htmlFor="movie-status" onClick={() => {
                                                    setSingleSelect([item.id])
                                                    setStatus(item.isPublished ? false : true)
                                                }} className="cursor-pointer"> {item.isPublished ? <VisibilityIcon width={45} height={45} backgroundColor="#172337" /> : <VisibilityOffIcon width={45} height={45} backgroundColor="#172337" />}</label>
                                            </div>
                                            <Link to={`/admin/movies/edit/${item.id}`} className="cursor-pointer">
                                                <div className="tooltip" data-tip="Edit">
                                                    <span className="cursor-pointer"><EditIcon width={45} height={45} backgroundColor="#172337" /></span>
                                                </div>
                                            </Link>
                                            {
                                                !item.isDeleted && <div className="tooltip" data-tip="Delete">
                                                    <label htmlFor="movie-delete" onClick={() => {
                                                        setSingleSelect([item.id])
                                                    }}
                                                        className="cursor-pointer"> <DeleteIcon width={45} height={45} /></label>
                                                </div>
                                            }
                                            {
                                                item.isDeleted && <div className="tooltip" data-tip="Restore">
                                                    <label htmlFor="movie-restore" onClick={() => {
                                                        setSingleSelect([item.id])
                                                        setPremanentlyDelete(item.id)
                                                    }}
                                                        className="cursor-pointer"> <ReStore width={45} height={45} /></label>
                                                </div>
                                            }
                                            {
                                                item.isTranscoded === 'NOT_STARTED' && <div className="tooltip" data-tip="Start Transcoding">
                                                    <label htmlFor="movie-start-transcodeing" onClick={() => {
                                                        setSingleSelect([item.id])
                                                    }}
                                                        className="cursor-pointer"> <TrascodeingIcon width={45} height={45} /></label>
                                                </div>
                                            }
                                            {
                                                item.isTranscoded === 'PENDING' && <div className="tooltip"
                                                    data-tip="Check Transcodeing Status">
                                                    <label htmlFor="movie-cancel-transcodeing" onClick={() => {
                                                        setSingleSelect([item.id])
                                                        getJobStatus(item.transcodingJobId)
                                                    }}
                                                        className="cursor-pointer"> <TrascodeInProsessIcon width={45} height={45} /></label>
                                                </div>
                                            }
                                            {
                                                item.isTranscoded === 'SUCCESS' && <div className="tooltip"
                                                    data-tip="Transcoding Completed">
                                                    <label className="cursor-pointer"> <TrascodeingDoneIcon width={45} height={45} /></label>
                                                </div>
                                            }
                                        </th>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </table> */}

            {/* Status Update Code */}
            <input type="checkbox" id="movie-status" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box relative">
                    <label htmlFor="movie-status" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                    <h3 className="font-bold text-lg">Movie Status</h3>
                    <p className="py-2">Are you sure you want to chance status?</p>
                    <div className="modal-action flex items-center justify-between">
                        <label htmlFor="movie-status" onClick={handleStatus} className="btn w-1/2 bg-red-800 hover:bg-red-900">Yes</label>
                        <label htmlFor="movie-status" className="btn w-1/2 bg-secondary-color hover:bg-primary-color">Cancel</label>
                    </div>
                </div>
            </div>

            {/* Delete Code */}
            <input type="checkbox" id="movie-delete" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box relative">
                    <label htmlFor="movie-delete" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                    <h3 className="font-bold text-lg">Movie Delete</h3>
                    <p className="py-2">Are you sure you want to delete this movie?</p>
                    <div className="modal-action flex items-center justify-between">
                        <label htmlFor="movie-delete" onClick={handleDelete} className="btn w-1/2 bg-red-800 hover:bg-red-900">Yes</label>
                        <label htmlFor="movie-delete" className="btn w-1/2 bg-secondary-color hover:bg-primary-color">Cancel</label>
                    </div>
                </div>
            </div>

            {/* Restore Code */}
            <input type="checkbox" id="movie-restore" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box relative">
                    <label htmlFor="movie-restore" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                    <h3 className="font-bold text-lg">Movie Restore</h3>
                    <p className="py-2">Are you sure you want to restore this Movie?</p>
                    <div className="modal-action flex items-center justify-between">
                        <label htmlFor="movie-restore" onClick={handlePremanentlyDelete} className="btn w-1/2 bg-red-800 hover:bg-red-900">Permanently Delete</label>
                        <label htmlFor="movie-restore" onClick={handleRestore} className="btn w-1/2 bg-secondary-color hover:bg-primary-color">Restore</label>
                    </div>
                </div>
            </div>

            {/* movie-start-transcodeing */}
            <input type="checkbox" id="movie-start-transcodeing" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box relative">
                    <label htmlFor="movie-start-transcodeing" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                    <h3 className="font-bold text-lg">Start Transcodeing</h3>
                    <p className="py-2">Are you sure you want to start transcodeing?</p>
                    <div className="modal-action flex items-center justify-between">
                        <label htmlFor="movie-start-transcodeing" onClick={handleStartTranscodeing} className="btn w-1/2 bg-red-800 hover:bg-red-900">Yes</label>
                        <label htmlFor="movie-start-transcodeing" className="btn w-1/2 bg-secondary-color hover:bg-primary-color">Cancel</label>
                    </div>
                </div>
            </div>

            {/* movie-cancel-transcodeing */}
            <input type="checkbox" id="movie-cancel-transcodeing" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box relative">
                    <label htmlFor="movie-cancel-transcodeing" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                    <h3 className="font-bold text-lg">Transcodeing Status</h3>
                    {
                        job ? <div className="my-5 grid grid-cols-3 gap-5">
                            <div className="w-32">
                                <CircularProgressbar value={
                                    job.Status === 'COMPLETE' ? '100' : '0'
                                } text={
                                    job.Status === 'COMPLETE' ? '100%' : job.JobPercentComplete ? job.JobPercentComplete : '0%'
                                } />
                            </div>
                            <div className="w-full flex flex-col col-span-2 gap-5 mt-1">
                                <label htmlFor="movie-cancel-transcodeing" onClick={() => {
                                    job.Status === 'COMPLETE' && updateJobStatus()
                                }} className="btn w-full bg-green-500 hover:bg-green-900 text-white">
                                    {
                                        job.Status === 'COMPLETE' ? 'Update Status Completed' : 'Service not available'
                                    }
                                </label>
                                <label htmlFor="movie-cancel-transcodeing"
                                    onClick={() => { }}
                                    className="btn bg-secondary-color hover:bg-primary-color">{
                                        job.Status === 'PROGRESSING' ? 'Transcodeing Cancel' : 'Cancel not allowed'
                                    }</label>
                            </div>
                        </div> : <div className="flex justify-center items-center">
                            <h1>
                                Loading...
                            </h1>
                        </div>
                    }

                </div>
            </div>
        </div>
    )
}

export default MoviesTable;