import { useEffect, useState } from 'react';
import Layout from '../../../components/Layout/Layout'
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { useDebounce } from 'use-debounce';
import { setBulkAction, setBulkActionCall } from '../../../redux/slice​/userSlice';
import { Hr } from '../../../components';
import axios from 'axios';
import Base64 from 'base-64';
import { RootState } from '../../../redux/store';

const TranscoderDashboard = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState();
    const [jobData, setJobData] = useState([]);
    const [value] = useDebounce(search, 1000);


    const [pagination, setPagination] = useState({
        page: 1,
        limit: process.env.REACT_APP_PAGE_SIZE,
    })

    useEffect(() => {
        fetchJobs();
    }, [pagination]);

    const fetchJobs = async (page = 1, limit = 25, order = '1') => {
        try {
            const { data, status } = await axios.get(`${process.env.REACT_APP_ZEZO_CLOUD_TRANSCODER_URL}/v1/jobs?page=${page}&limit=${limit}&order=${order}`, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Basic " + Base64.encode(`${'admin'}:${'supersecret'}`),
                }
            });
            if (status === 200) {
                setJobData(data.data);
            }
        } catch (error) {
            console.log(error);
            toast.error('Something went wrong');
        }
    }

    const headlePrev = () => {
        if (pagination.page > 1) {
            setPagination({
                ...pagination,
                page: pagination.page - 1
            })
        } else {
            toast.error("You are on first page")
        }
    }

    // const handleNext = () => {
    //     if (pagination.page < Math.ceil(userReport.total / pagination.limit)) {
    //         setPagination({
    //             ...pagination,
    //             page: pagination.page + 1
    //         })
    //     } else {
    //         toast.error("No more users")
    //     }
    // }

    const checkStatus = (status: string) => {
        switch (status) {
            case 'pending':
                return 'bg-orange-500 text-white';
            case 'processing':
                return 'bg-orange-500 text-white';
            case 'completed':
                return 'bg-green-500 text-white';
            case 'failed':
                return 'bg-red-500 text-white';
            default:
                return 'bg-gray-500 text-white';
        }
    }

    const checkProgress = (progress: number, status: string) => {
        if (progress === 100) {
            console.log('completed');
            return 'bg-emerald-500';
        } else if (progress > 0 && progress < 100) {
            console.log(progress);
            return 'bg-orange-500';
        } else if (progress === 0 && status === 'failed') {
            console.log('failed');
            return 'bg-red-900';
        }
        else {
            return 'bg-red-900';
        }
    }

    const checkProgressbg = (progress: number, status: string) => {
        if (progress === 0 && status === 'failed') {
            return 'bg-red-500';
        }
        else {
            return 'bg-gray-500';
        }
    }

    return (
        <>
            <Layout>
                <h1 className="font-medium text-2xl px-5 mb-3">
                    List of Transcoding
                </h1>
                <Hr />
                <div className='flex justify-between items-center px-5'>
                    <div className='flex items-center gap-4'>
                        <select
                            className="select max-w-xs bg-transparent text-sm font-normal pl-0 -ml-1"
                            defaultValue={'none'}
                            onChange={(e) => dispatch(setBulkAction(e.target.value))}>
                            <option value="none">Bulk Actions</option>
                            <option value='Delete'>Delete</option>
                            <option value='Deactivate'>Deactivate</option>
                            <option value='Activate'>Activate</option>
                            <option value='Restore'>Restore</option>
                        </select>
                        <span onClick={() => dispatch(setBulkActionCall(true))} className="bg-transparent py-3.5 px-6 text-sm cursor-pointer hover:bg-secondary-blue duration-100 text-gray-300" >Apply</span>
                    </div>
                    <div className='flex gap-2 items-center'>
                        {/* <SearchBar
                            onChange={(e) => setSearch(e.target.value)}
                        /> */}
                        {/* <Button
                            title={'+ Add New'}
                            titleStyle={'text-white font-medium'}
                            label={'add-new-user'}
                            backgroundColor={'bg-transparent'}
                            hoverBackgroundColor={'bg-transparent'}
                            height={'h-11'}
                        /> */}
                    </div>
                </div>
                <Hr />
                <div>

                    <section className="relative">
                        <div className="w-full">
                            <div className="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded bg-transparent">
                                <div className="block w-full overflow-x-auto ">
                                    <table className="items-center w-full bg-transparent border-collapse">
                                        <thead>
                                            <tr>
                                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 border-t-0 whitespace-nowrap font-semibold text-left bg-transparent text-white border-gray-700">
                                                    ID
                                                </th>
                                                {/* <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 border-t-0 whitespace-nowrap font-semibold text-left bg-transparent text-white border-gray-700">
                                                    Budget
                                                </th> */}
                                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 border-t-0 whitespace-nowrap font-semibold text-left bg-transparent border-gray-700">
                                                    Status
                                                </th>
                                                {/* <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 border-t-0 whitespace-nowrap font-semibold text-left bg-transparent text-white border-gray-700">
                                                    Users
                                                </th> */}
                                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 border-t-0 whitespace-nowrap font-semibold text-left bg-transparent border-gray-700">
                                                    Progress{" "}
                                                </th>
                                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 border-t-0 whitespace-nowrap font-semibold text-left bg-transparent border-gray-700" />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {jobData.map((job: any, index) => {
                                                return (
                                                    <tr>
                                                        <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center border border-gray-700">
                                                            <img
                                                                src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c2/U%2B2160.svg/640px-U%2B2160.svg.png"
                                                                className="h-10 w-10 bg-white rounded-full border object-contain"
                                                                alt="..."
                                                            />
                                                            <span className="ml-3 font-bold">
                                                                {" "}
                                                                {job._id}{" "}
                                                            </span>
                                                        </th>
                                                        {/* <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 border border-gray-700">
                                                            $2,500 USD
                                                        </td> */}
                                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 border border-gray-700">
                                                            <div className='flex items-center'>
                                                                <div className={`w-3 h-3 ${checkStatus(job.status)} rounded-full mr-2`} />
                                                                <h6 className='capitalize'>
                                                                    {job.status}
                                                                </h6>

                                                            </div>
                                                        </td>
                                                        {/* <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 border border-gray-700">
                                                            <div className="flex">
                                                                <img
                                                                    src="https://demos.creative-tim.com/notus-js/assets/img/team-1-800x800.jpg"
                                                                    alt="..."
                                                                    className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow"
                                                                />
                                                                <img
                                                                    src="https://demos.creative-tim.com/notus-js/assets/img/team-2-800x800.jpg"
                                                                    alt="..."
                                                                    className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow -ml-4"
                                                                />
                                                                <img
                                                                    src="https://demos.creative-tim.com/notus-js/assets/img/team-3-800x800.jpg"
                                                                    alt="..."
                                                                    className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow -ml-4"
                                                                />
                                                                <img
                                                                    src="https://demos.creative-tim.com/notus-js/assets/img/team-4-470x470.png"
                                                                    alt="..."
                                                                    className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow -ml-4"
                                                                />
                                                            </div>
                                                        </td> */}
                                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 border border-gray-700">
                                                            <div className="flex items-center">
                                                                <span className="mr-2">{job.progress}%</span>
                                                                <div className="relative w-full">
                                                                    <div className={`overflow-hidden h-2 text-xs flex rounded ${checkProgressbg(job.progress, job.status)}`}>
                                                                        <div
                                                                            style={{ width: `${job.progress}%` }}
                                                                            className={`shadow-none flex flex-col text-center whitespace-nowrap justify-center ${checkProgress(job.progress, job.status)}`}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right border border-gray-700">
                                                            <a
                                                                href="#pablo"
                                                                className="text-blueGray-500 block py-1 px-3"
                                                                //onclick="openDropdown(event,'table-dark-1-dropdown')"
                                                            >
                                                                <i className="fas fa-ellipsis-v" />
                                                            </a>
                                                            <div
                                                                className="hidden bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
                                                                id="table-dark-1-dropdown"
                                                            >
                                                                <a
                                                                    href="#pablo"
                                                                    className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
                                                                >
                                                                    Action
                                                                </a>
                                                                <a
                                                                    href="#pablo"
                                                                    className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
                                                                >
                                                                    Another action
                                                                </a>
                                                                <a
                                                                    href="#pablo"
                                                                    className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
                                                                >
                                                                    Something else here
                                                                </a>
                                                                <div className="h-0 my-2 border border-solid border-blueGray-100" />
                                                                <a
                                                                    href="#pablo"
                                                                    className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
                                                                >
                                                                    Seprated link
                                                                </a>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>
         
                <Hr />
            </Layout>
        </>

    )
}

export default TranscoderDashboard;
