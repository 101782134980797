import { Login } from './Pages/index'
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Toaster } from 'react-hot-toast'
import { useLoadingWithRefresh } from './hooks/useLoadingWithRefresh';
import { useSelector, useDispatch } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { fetchAppConfig, fetchConfig } from './redux/slice​/configSlice';
import { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { RootState } from './redux/store';
import { lightTheme, darkTheme } from './theme/theme';
import { toggleTheme } from './redux/slice​/themeSlice';
import { AdminAuthGuard } from './utils/AuthGuard';
import Strings from './localData/strings.json';
import { AllRoutes } from './localData/Routes';
import { isDay } from './utils/Utils';

const App = () => {
  const { loading } = useLoadingWithRefresh();
  const dispatch = useDispatch();

  const { isAuthenticated, user } = useSelector((state: RootState) => state.auth);
  const { config } = useSelector((state: RootState) => state.config);
  const { darkMode } = useSelector((state: RootState) => state.theme);

  const [selectedLanguage, setSelectedLanguage] = useState(Strings.en);

  useEffect(() => {
    dispatch(fetchConfig() as any);
    dispatch(fetchAppConfig() as any);
    // is day function check if the time is between 6am and 6pm and return true or false for dakr mode or light mode
    isDay() ? dispatch(toggleTheme(false)) : dispatch(toggleTheme(true))
  }, []);

  return loading ? (<div className='flex items-center justify-center h-screen'>Loading...</div>) : (
    <div className='w-full'>
      <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
        <CssBaseline />
        <Helmet>
          <meta charSet="utf-8" />
          <title>{config.name}</title>
          <link rel="icon" href={config?.favicon} />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="theme-color" content="#000000" />
          <meta name="description" content={config.description} />
          <link rel="apple-touch-icon" href={config?.favicon} />
        </Helmet>
        <Toaster position='top-right' />
        <BrowserRouter>
          <Routes>
            <Route element={<AdminAuthGuard />}>
              {AllRoutes && AllRoutes.map((route, index) => <Route key={index} path={route.path} element={route.element} />)}
            </Route>
            {!isAuthenticated && <Route path="/" element={<Login />} />}
            <Route path="*" element={<Navigate to={isAuthenticated ? user.role === 'admin' ? "/admin/dashboard" : "/" : "/"} />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  )
}

export default App