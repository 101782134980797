import { createSlice } from '@reduxjs/toolkit'
import { getMovie, getMovies, getStore, getStores, movieReport, movieSearch, storeReport, storeSearch } from '../../http/Apis'


export const STATUS = Object.freeze({
    SECCESS: 'secces',
    FAILED: 'failed',
    LOADING: 'loading',
})

const initialState = {
    data: false,
    status: STATUS.SECCESS,
    bulkAction: 'none',
    bulkActionCall: false,
    single: false,
    report: false,
}

export const MovieSlice = createSlice({
    name: 'movie',
    initialState,
    reducers: {
        setMovies: (state, actions) => {
            const { data } = actions.payload
            state.data = data
        },
        setStatus: (state, actions) => {
            state.status = actions.payload
        },
        setBulkAction: (state, actions) => {
            state.bulkAction = actions.payload
        },
        setBulkActionCall: (state, actions) => {
            state.bulkActionCall = actions.payload
        },
        setSingle: (state, actions) => {
            const { data } = actions.payload
            state.single = data
        },
        setReport: (state, actions) => {
            const { data } = actions.payload
            state.report = data
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    setMovies,
    setStatus,
    setBulkAction,
    setBulkActionCall,
    setSingle,
    setReport
} = MovieSlice.actions

export default MovieSlice.reducer

// Thunks
export function fetchMovies(page = 1, limit = 25, order = 'desc') {
    return async function fetchMoviesThunk(dispatch) {
        dispatch(setStatus(STATUS.LOADING))
        try {
            const res = await getMovies(page, limit, order);
            dispatch(setMovies(res.data))
            dispatch(setStatus(STATUS.SECCESS))
        } catch (error) {
            console.log(error)
            dispatch(setStatus(STATUS.FAILED))
        }
    }
}
// fetch Reports
export function fetchMovieReport() {
    return async function fetchMovieReportThunk(dispatch) {
        dispatch(setStatus(STATUS.LOADING))
        try {
            const res = await movieReport();
            dispatch(setReport(res.data))
            dispatch(setStatus(STATUS.SECCESS))
        } catch (error) {
            console.log(error)
            dispatch(setStatus(STATUS.FAILED))
        }
    }
}

export function fetchSearchMovie(search) {
    return async function fetchMovieThemeThunk(dispatch) {
        dispatch(setStatus(STATUS.LOADING))
        try {
            const res = await movieSearch(search);
            dispatch(setMovies(res.data))
            dispatch(setStatus(STATUS.SECCESS))
        } catch (error) {
            console.log(error)
            dispatch(setStatus(STATUS.FAILED))
        }
    }
}

export function fetchMovie(id) {
    return async function fetchMovieThunk(dispatch) {
        dispatch(setStatus(STATUS.LOADING))
        try {
            const res = await getMovie(id);
            dispatch(setSingle(res.data))
            dispatch(setStatus(STATUS.SECCESS))
        } catch (error) {
            console.log(error)
            dispatch(setStatus(STATUS.FAILED))
        }
    }
}

