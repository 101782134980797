import { Outlet, Navigate } from 'react-router-dom';
import { useSelector, } from 'react-redux'
import { RootState } from '../../redux/store';

const AdminAuthGuard = () => {
    const { isAuthenticated, user } = useSelector((state: RootState) => state.auth);
    return(
        isAuthenticated && user.role === 'admin' ? <Outlet /> : <Navigate to="/" />
    )
}

export default AdminAuthGuard;