import DashboardIcon from '@mui/icons-material/Dashboard';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import SubjectIcon from '@mui/icons-material/Subject';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import MovieFilterOutlinedIcon from '@mui/icons-material/MovieFilterOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import TranslateOutlinedIcon from '@mui/icons-material/TranslateOutlined';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import SubscriptionsOutlinedIcon from '@mui/icons-material/SubscriptionsOutlined';
import { MarketingIcon, RTMPServerIcon, TranscoderIcon } from '../tailwind/Icon';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import SignalCellularAltOutlinedIcon from '@mui/icons-material/SignalCellularAltOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import Strings from '../localData/strings.json';
import RouteStrings from '../localData/routes.json';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
const { en: { HomePage: { Menu } } } = Strings;

export interface ISideBarchildren {
    id: number;
    name: string;
    path: string;
    icon: any;
}

export const SideBarData = [
    {
        id: 1,
        name: `${Menu.Dashboard}`,
        path: `${RouteStrings.Dashboard.Link}`,
        pathName: `${RouteStrings.Dashboard.PathName}`,
        icon: <DashboardIcon />,
        children: [] as Array<ISideBarchildren>,
    },
    {
        id: 2,
        name: `${Menu.Content}`,
        path: `${RouteStrings.Content.Link}`,
        pathName: `${RouteStrings.Content.PathName}`,
        icon: <VideoLibraryIcon />,
    },
    {
        id: 3,
        name: `${Menu.Sections}`,
        path: `${RouteStrings.Sections.Link}`,
        pathName: `${RouteStrings.Sections.PathName}`,
        icon: <SubjectIcon />,
    },
    {
        id: 4,
        name: `${Menu.FileManager}`,
        path: `${RouteStrings.FileManager.Link}`,
        pathName: `${RouteStrings.FileManager.PathName}`,
        icon: <FolderOutlinedIcon />,
    },
    {
        id: 5,
        name: `${Menu.Users}`,
        path: `${RouteStrings.Users.Link}`,
        pathName: `${RouteStrings.Users.PathName}`,
        icon: <PeopleOutlinedIcon />,
    },
    {
        id: 6,
        name: `${Menu.Genres}`,
        path: `${RouteStrings.Genres.Link}`,
        pathName: `${RouteStrings.Genres.PathName}`,
        icon: <MovieFilterOutlinedIcon />,
    },
    {
        id: 7,
        name: `${Menu.Categories}`,
        path: `${RouteStrings.Categories.Link}`,
        pathName: `${RouteStrings.Categories.PathName}`,
        icon: <CategoryOutlinedIcon />,
    },
    {
        id: 8,
        name: `${Menu.Languages}`,
        path: `${RouteStrings.Languages.Link}`,
        pathName: `${RouteStrings.Languages.PathName}`,
        icon: <TranslateOutlinedIcon />,
    },
    {
        id: 9,
        name: `${Menu.CastAndCrew}`,
        path: `${RouteStrings.CastAndCrew.Link}`,
        pathName: `${RouteStrings.CastAndCrew.PathName}`,
        icon: <SupervisorAccountOutlinedIcon />,
    },
    {
        id: 10,
        name: `${Menu.Subscriptions}`,
        path: `${RouteStrings.Subscriptions.Link}`,
        pathName: `${RouteStrings.Subscriptions.PathName}`,
        icon: <SubscriptionsOutlinedIcon />,
    },
    {
        id: 11,
        name: `${Menu.Transcoding.Title}`,
        path: "",
        pathName: `${RouteStrings.Transcoding.PathName}`,
        icon: <TranscoderIcon className={`w-6 h-6`} />,
        children: [
            {
                id: 12,
                name: `${Menu.Transcoding.Dashboard}`,
                path: `${RouteStrings.Transcoding.Children.Dashboard.Link}`,

                icon: <DashboardIcon />,
            }
        ],
    },
    {
        id: 13,
        name: `${Menu.RTMP.Title}`,
        path: "",
        pathName: `${RouteStrings.RTMP.PathName}`,
        icon: <RTMPServerIcon className={`w-6 h-6`} />,
        children: [
            {
                id: 14,
                name: `${Menu.RTMP.Dashboard}`,
                path: `${RouteStrings.RTMP.Children.Dashboard.Link}`,
                icon: <DashboardIcon />,
            },
            {
                id: 15,
                name: `${Menu.RTMP.Streams}`,
                path: `${RouteStrings.RTMP.Children.Streams.Link}`,
                icon: <DashboardIcon />,
            },
            {
                id: 16,
                name: `${Menu.RTMP.ServerProfile}`,
                path: `${RouteStrings.RTMP.Children.ServerProfiles.Link}`,
                icon: <DashboardIcon />,
            }
        ],
    },
    {
        id: 17,
        name: `${Menu.Marketing.Title}`,
        path: "",
        pathName: `${RouteStrings.Marketing.PathName}`,
        icon: <MarketingIcon className="w-6 h-6" />,
        children: [
            {
                id: 18,
                name: `${Menu.Marketing.PushNotifications}`,
                path: `${RouteStrings.Marketing.Children.PushNotifications.Link}`,
                icon: <NotificationsActiveOutlinedIcon />,
            }
        ],
    },
    {
        id: 19,
        name: `${Menu.Analytics}`,
        path: `${RouteStrings.Analytics.Link}`,
        pathName: `${RouteStrings.Analytics.PathName}`,
        icon: <SignalCellularAltOutlinedIcon />,
    },
    {
        id: 20,
        name: `${Menu.Settings.Title}`,
        path: "",
        pathName: `${RouteStrings.Settings.PathName}`,
        icon: <SettingsOutlinedIcon />,
        children: [
            {
                id: 21,
                name: `${Menu.Settings.General}`,
                path: `${RouteStrings.Settings.Children.GeneralSettings.Link}`,
                icon: <SettingsOutlinedIcon />,
            },
            {
                id: 22,
                name: `${Menu.Settings.Seo}`,
                path: `${RouteStrings.Settings.Children.SeoSettings.Link}`,
                icon: <SettingsOutlinedIcon />,
            }
        ]
    },
    {
        id: 23,
        name: `${Menu.AppSettings}`,
        path: `${RouteStrings.AppSettings.Link}`,
        pathName: `${RouteStrings.AppSettings.PathName}`,
        icon: <AppSettingsAltIcon />,
    },
    {
        id: 24,
        name: `${Menu.Reports}`,
        path: `${RouteStrings.Reports.Link}`,
        pathName: `${RouteStrings.Reports.PathName}`,
        icon: <ReportOutlinedIcon />,
    }
]

