import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import Logo from "../Logo/Logo";
import { SideBarData } from "../../localData";
import { useLocation, Link } from "react-router-dom";
import Header from '../Header/Header';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

export interface LayoutProps {
    children: React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
    const location = useLocation();
    const { pathname } = location;
    const { darkMode } = useSelector((state: RootState) => state.theme);
    const path = pathname.split('/')[2];
    return (
        <div className="flex bg-transparent min-h-screen w-full">
            <Sidebar
                rootStyles={{
                    border: 'none',
                }}
            >
                <div className="px-5 py-4 bg-transparent">
                    <Logo className='w-24' />
                </div>
                <Menu
                    menuItemStyles={{
                        button: ({ level, active, disabled }) => {
                            // only apply styles on first level elements of the tree
                                return {
                                    color: active ? '#1A73E8' : darkMode ? '#fff' : '#5f6368',
                                    backgroundColor: active ? 'transparent' : darkMode ? 'transparent' : 'transparent',
                                    '&:hover': {
                                        color: active ? '#1A73E8' : darkMode ? '#fff' : '#5f6368',
                                        backgroundColor: active ? 'transparent' : darkMode ? 'transparent' : 'transparent',
                                    },
                                    fontSmooth: 'always',
                                    fontWeight: '500',
                                    fontSize: '15px',
                                    height: '43px',
                                };
                        },
                    }}
                >
                    {
                        SideBarData.map((item, index) => {
                            if (item.children && item.children.length > 0) {
                                return (
                                    <SubMenu
                                        rootStyles={{
                                            border: 'none',
                                            backgroundColor: darkMode ? 'transparent' : 'transparent',
                                        }}
                                        key={index} label={item.name} icon={item.icon} active={item.pathName === path} >
                                        {
                                            item.children.map((child, index) => {
                                                return (
                                                    <MenuItem key={index} icon={child.icon} routerLink={<Link to={child.path} />} >
                                                        {child.name}
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                    </SubMenu>
                                )
                            } else {
                                return (
                                    <MenuItem
                                        key={index} icon={item.icon} active={item.pathName === path} routerLink={<Link to={item.path} />}  >

                                        {item.name}

                                    </MenuItem>
                                )
                            }
                        })
                    }
                </Menu>
            </Sidebar>
            <main className='w-full'>
                <Header />
                <div className='pl-5'>
                    {children}
                </div>
            </main>
        </div>
    )
}

export default Layout;