import { useEffect, useState } from "react";
import { bytesToBand, secondsToDhmsSimple } from "../../../utils/Utils";
import Chart from 'react-apexcharts'
import Base64 from 'base-64';
import { CopyToClipboard } from 'react-copy-to-clipboard';


import Layout from "../../../components/Layout/Layout";
import { UploadIcon } from "../../../tailwind/Icon";
import { useSelector } from "react-redux";
import { sendPushNotification, updateSettingApi } from "../../../http/Apis";
import toast from "react-hot-toast";
import { Hr, VideoPlayer } from "../../../components";

import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Draggable from 'react-draggable';

function createData(app, name, id, ip, audioCodec, audioFreq, audioChan, videoCodec, videoFreq, videoChan, time, clients) {
    return {
        app,
        name,
        id,
        ip,
        audioCodec,
        audioFreq,
        audioChan,
        videoCodec,
        videoFreq,
        videoChan,
        time,
        clients
    };
}

const rows = [
    createData('Cupcake', 305, 3.7, 67, 4.3),
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'app',
        numeric: false,
        disablePadding: true,
        label: 'App',
    },
    {
        id: 'name',
        numeric: true,
        disablePadding: false,
        label: 'Name',
    },
    {
        id: 'id',
        numeric: true,
        disablePadding: false,
        label: 'ID',
    },
    {
        id: 'ip',
        numeric: true,
        disablePadding: false,
        label: 'IP',
    },
    {
        id: 'audioCodec',
        numeric: true,
        disablePadding: false,
        label: 'Audio codec',
    },
    {
        id: 'audioFreq',
        numeric: true,
        disablePadding: false,
        label: 'Audio Freq',
    },
    {
        id: 'audioChan',
        numeric: true,
        disablePadding: false,
        label: 'Audio Chan',
    },
    {
        id: 'videoCodec',
        numeric: true,
        disablePadding: false,
        label: 'Video codec',
    },
    {
        id: 'videoFreq',
        numeric: true,
        disablePadding: false,
        label: 'Video Freq',
    },
    {
        id: 'videoChan',
        numeric: true,
        disablePadding: false,
        label: 'Video Fps',
    },
    {
        id: 'time',
        numeric: true,
        disablePadding: false,
        label: 'Time',
    },
    {
        id: 'clients',
        numeric: true,
        disablePadding: false,
        label: 'Clients',
    },
];

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                        sx={{ pl: 2 }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}

                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    Streams
                </Typography>
            )}

            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton>
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};


function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

const RtmpStreams = () => {
    const { user } = useSelector(state => state.auth);
    const [loading, setLoading] = useState(false);

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.name);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const [data, setData] = useState([]);
    console.log("🚀 ~ file: Streams.jsx ~ line 345 ~ RtmpStreams ~ data", data)

    useEffect(() => {
        fetch(`${process.env.REACT_APP_RTMP_SERVER_URL}/api/streams`, {
            method: 'GET',
            headers: {
                "Authorization": "Basic " + Base64.encode(`${'admin'}:${'nms2018'}`),
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                // Read total count from server
                let streamsData = [];
                let index = 0;
                for (let app in data) {
                    for (let name in data[app]) {
                        let stream = data[app][name].publisher;
                        let clients = data[app][name].subscribers;
                        if (stream) {
                            let now = new Date().getTime() / 1000;
                            let str = new Date(stream.connectCreated).getTime() / 1000;
                            let streamData = {
                                key: index++,
                                app,
                                name,
                                id: stream.clientId,
                                ip: stream.ip,
                                audioac: stream.audio ? stream.audio.codec + " " + stream.audio.profile : "",
                                audiofreq: stream.audio ? stream.audio.samplerate : "",
                                audiochan: stream.audio ? stream.audio.channels : "",
                                videoCodec: stream.video ? stream.video.codec + " " + stream.video.profile : "",
                                size: stream.video ? stream.video.width + "x" + stream.video.height : "",
                                fps: stream.video ? Math.floor(stream.video.fps) : "",
                                time: secondsToDhmsSimple(now - str),
                                clients: clients.length
                            };
                            streamsData.push(streamData);
                        }
                    }

                }
                setData(streamsData);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            });
    }, []);

    const [open, setOpen] = useState(false);
    const [streamUrl, setStreamUrl] = useState("");

    const handleClickOpen = (name) => {
        console.log("🚀 ~ file: Streams.jsx ~ line 415 ~ handleClickOpen ~ name", name)
        setOpen(true);
        setStreamUrl(`${process.env.REACT_APP_RTMP_SERVER_URL}/live/${name}/index.m3u8`);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const videoJsOptions = {
        onReady: function (player) {
            console.log('onReady', player)
        },
        options: {
            autoplay: true,
            controls: true,
            responsive: true,
            fluid: true,
            width: 640,
            sources: [
                {
                    src: 'http://sample.vodobox.net/skate_phantom_flex_4k/skate_phantom_flex_4k.m3u8',
                    type: 'application/x-mpegURL',
                },
            ],
        },
    };

    return (
        <Layout>
            <div className="px-5">
                <h1 className="text-2xl text-white opacity-80 font-semibold tracking-wide">
                    RTMP Streams
                </h1>
                <p className="mt-2 text-white opacity-70">
                    Here you can see all the streams that are currently active on your server.
                </p>
            </div>
            <div className="my-5">
                <Hr />
            </div>
            <div>
                <Box sx={{ width: '100%' }}>
                    <Paper sx={{ width: '100%', mb: 2, pr: 2 }}>
                        <EnhancedTableToolbar numSelected={selected.length} />
                        <TableContainer>
                            <Table
                                sx={{ minWidth: 750 }}
                                aria-labelledby="tableTitle"
                                size={dense ? 'small' : 'medium'}
                            >
                                <EnhancedTableHead
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={handleSelectAllClick}
                                    onRequestSort={handleRequestSort}
                                    rowCount={data.length}
                                />
                                <TableBody>
                                    {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.sort(getComparator(order, orderBy)).slice() */}
                                    {stableSort(data, getComparator(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => {
                                            const isItemSelected = isSelected(row.name);
                                            const labelId = `enhanced-table-checkbox-${index}`;

                                            return (
                                                <TableRow
                                                    hover
                                                    onClick={(event) => handleClick(event, row.name)}
                                                    role="checkbox"
                                                    aria-checked={isItemSelected}
                                                    tabIndex={-1}
                                                    key={row.name}
                                                    selected={isItemSelected}
                                                    sx={{ pl: 2 }}
                                                >
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            sx={{ pl: 2 }}
                                                            color="primary"
                                                            checked={isItemSelected}
                                                            inputProps={{
                                                                'aria-labelledby': labelId,
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        id={labelId}
                                                        scope="row"
                                                        padding="none"
                                                    >
                                                        {row.app}
                                                    </TableCell>
                                                    <TableCell
                                                        align="right"
                                                        onClick={() => handleClickOpen(row.name)}
                                                        sx={{ cursor: 'pointer' }}>{row.name}</TableCell>
                                                    <TableCell align="right">{row.id}</TableCell>
                                                    <TableCell align="right">{row.ip}</TableCell>
                                                    <TableCell align="right">{row.audioac}</TableCell>
                                                    <TableCell align="right">{row.audiofreq}</TableCell>
                                                    <TableCell align="right">{row.audiochan}</TableCell>

                                                    <TableCell align="right">{row.videoCodec}</TableCell>
                                                    <TableCell align="right">{row.size}</TableCell>
                                                    <TableCell align="right">{row.fps}</TableCell>
                                                    <TableCell align="right">{row.time}</TableCell>
                                                    <TableCell align="right">{row.clients}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{
                                                height: (dense ? 33 : 53) * emptyRows,
                                            }}
                                        >
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                    {/* <FormControlLabel
                        control={<Switch checked={dense} onChange={handleChangeDense} />}
                        label="Dense padding"
                    /> */}
                </Box>
            </div>

            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                >
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        Video Player
                    </DialogTitle>
                    <DialogContent>
                        <div className="w-[35rem]">
                            <VideoPlayer
                                url={streamUrl}
                            />
                            <div className="mt-5">
                                {/* copy stream url */}
                                <h1 className="text-center">
                                        {streamUrl}
                                </h1>

                                {/* <p>
                                    <CopyToClipboard text={streamUrl}>
                                        <button>Copy to clipboard with button</button>
                                    </CopyToClipboard>

                                </p> */}


                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleClose}>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </Layout>

    );
}

export default RtmpStreams;
