import { useState } from "react"
import { useParams } from "react-router-dom"
import Layout from "../../components/Layout/Layout"
import { InputField, SelectField, TextAreaField, Title } from "../../tailwind"
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import toast from "react-hot-toast"
import { contantCreateEpisode, contantEpisodeDelete, contantUpdateEpisode, contantUpdateSeason } from "../../http/Apis"
import qs from "qs"
import { fetchAllContant } from "../../redux/slice​/contentSlice"
import { FullScreenDialog, MuiDialog } from "../../components"
import { RootState } from "../../redux/store"
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Switch } from "@mui/material"
import { useFormik } from "formik";
import * as Yup from "yup";
import { ImageField } from "../../tailwind/Input/inputField";
import { IMuiDialog } from "./EditContent";



const EditContentSeason = () => {
    const dispatch = useDispatch()
    const { id, seasonId } = useParams()
    const [loading, setLoading] = useState(false)
    const { season, episode } = useSelector((state: RootState) => state.content)
    const { darkMode } = useSelector((state: RootState) => state.theme);
    const [episodeId, setEpisodeId] = useState<string>()
    const [progress, setProgress] = useState(false)
    const [job, setJob] = useState(false)
    interface ISeasonData {
        name: string;
        status: boolean;
    }
    const [seasonData, setSeasonData] = useState<ISeasonData>({
        name: '',
        status: false,
    })



    const [muiDialogContant, setMuiDialogContant] = useState<IMuiDialog>({
        title: "",
        contentText: "",
        buttonOneText: "",
        buttonTwoText: "",
        twoAction: false,
        actionKey: false,
        bulkDeleteData: false,
    })

    interface IFullScreenDialogConfig {
        title: string;
        children: React.ReactNode | null;
    }

    const [fullScreenDialogConfig, setFullScreenDialogConfig] = useState<IFullScreenDialogConfig>({
        title: '',
        children: null,
    })

    // status dialog
    const [muiDialogOpen, setMuiDialogOpen] = useState(false);
    const handleMuiDialogOpen = () => {
        setMuiDialogOpen(true);
    };
    const handleMuiDialogClose = () => {
        setMuiDialogOpen(false);
        setTimeout(() => {
            setMuiDialogContant({
                title: '',
                contentText: '',
            })
        }, 100);
    };







    interface IContentForm {
        title?: string;
        name: string;
        slug?: boolean;
        placeholder: string;
        type: string;
        value?: any;
        error?: any;
        required?: boolean;
        maxLength?: number;
        limitValidation?: boolean;
        options?: any;
        isMulti?: boolean;
        promiseOptions?: any;
        urlName?: string;
        url?: string;
        className?: string;
        imageStyle?: string;
        placeholderStyle?: string;
        col?: string;
    }

    const sourceType = [
        { name: 'HLS', id: 'HLS' },
        { name: 'MP4', id: 'MP4' },
    ]

    // type
    const contentOfferingType = [
        { name: 'Premium', id: 'PREMIUM' },
        { name: 'Free', id: 'FREE' },
    ]



    const [fullScreenDialog, setFullScreenDialog] = useState(false);
    const fullScreenDialogOpen = () => {
        setFullScreenDialog(true);
    };
    const handleFullScreenDialogClose = () => {
        setFullScreenDialog(false);
    };

    const [episodeData, setEpisodeData] = useState({
        name: '',
        description: '',
        duration: '',
        source_link: '',
        source_type: '',
        content_offering_type: '',
    })

    const [updateEpisodeData, setUpdateEpisodeData] = useState({
        name: '',
        description: '',
        duration: '',
        source_link: '',
        thumbnailUrl: '',
        source_type: '',
        status: false,
        content_offering_type: '',
    })

    const queryString = qs.stringify({
        season_id: seasonId,
    })
    const queryStringEpisode = (episodeId: string) => qs.stringify({
        episode_id: episodeId,
    })

    useEffect(() => {
        dispatch(fetchAllContant(queryString, 'season') as any)
    }, [])

    // set season data
    useEffect(() => {
        setSeasonData({
            name: season.name,
            status: season.status,
        })
    }, [season])

    // set episode data
    useEffect(() => {
        if (episodeId) {
            const episode = season.episodes?.find(episode => episode._id === episodeId)
            if (!episode) return
            setUpdateEpisodeData({
                name: episode.name,
                description: episode.description,
                duration: episode.duration as any,
                source_link: episode.source_link,
                thumbnailUrl: episode.thumbnail,
                source_type: episode.source_type,
                status: episode.status,
                content_offering_type: episode.content_offering_type,
            })
        }
    }, [episodeId])

    // handle update season
    const handleUpdateSeason = async () => {
        if (seasonData.name === '') {
            return alert('Please enter season name')
        }
        setLoading(true)
        try {
            const res = await contantUpdateSeason(seasonId, seasonData)
            setLoading(false)
            if (res.status === 200) {
                toast.success('Season updated successfully')
                setLoading(false)
            }
        } catch (error: any) {
            setLoading(false)
            toast.error(error?.response?.data?.error?.message || 'Something went wrong')
        }
    }

    //handleRemoveEpisode
    const handleRemoveEpisode = async () => {
        if (!episodeId) return toast.error('Something went wrong')
        try {
            setLoading(true)
            const res = await contantEpisodeDelete(episodeId)
            setLoading(false)
            setEpisodeId('')
            if (res.status === 200) {
                toast.success('Episode deleted successfully')
                setEpisodeId('')
                handleMuiDialogClose()
                setLoading(false)
                setTimeout(() => {
                    dispatch(fetchAllContant(queryString, 'season') as any)
                }, 1000)
            }
        } catch (error: any) {
            setEpisodeId('')
            handleMuiDialogClose()
            setLoading(false)
            toast.error(error?.response?.data?.error?.message || 'Something went wrong')
        }
    }

    const CreateEpisode = () => {
        const [loading, setLoading] = useState(false)
        const addEpisode = async (values: any, action:any) => {
            const fromData = new FormData()
            fromData.append('name', values.name)
            fromData.append('description', values.description)
            fromData.append('duration', values.duration)
            if (values.content_offering_type.length === 0) return toast.error('Please select content offering type')
            fromData.append('content_offering_type', values.content_offering_type.id)
            if (values.source_type.length === 0) return toast.error('Please select source type')
            fromData.append('source_type', values.source_type.id)
            if (values.source_link === '') return toast.error('Please enter url')
            fromData.append('source_link', values.source_link)
            if (values.thumbnail) fromData.append('thumbnail', values.thumbnail)
            try {
                setLoading(true)
                const res = await contantCreateEpisode(seasonId, fromData)
                if (res.status === 201) {
                    toast.success('Episode added successfully')
                    setLoading(false)
                    dispatch(fetchAllContant(queryString, 'season') as any)
                    handleFullScreenDialogClose()
                    action.resetForm()
                }
            } catch (error: any) {
                setLoading(false)
                toast.error(error.response.data.error.message || 'Something went wrong')
            }
        }
        const initialValues = {
            name: "",
            description: "",
            duration: "",
            thumbnail: "",
            thumbnailUrl: "",
            content_offering_type: "",
            source_type: "",
            source_link: "",
        };

        const createContentSchema = Yup.object().shape({
            name: Yup.string().required("Title is required").min(3, "Title is too short").max(50, "Title is too long"),
            description: Yup.string().required("Description is required").min(50, "Description is too short").max(1000, "Description is too long"),
            duration: Yup.number().required("Duration is required"),
            thumbnail: Yup.mixed().required("Thumbnail is required")
                .test("fileSize", "File too large limit is 1mb", value => value && value.size <= 1000000) // 1mb
                .test("fileFormat", "Unsupported Format. try JPEG, PNG, JPG, WEBP", value => value && ["image/jpeg", "image/png", "image/jpg", "image/webp"].includes(value.type)),
        });
        const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } =
            useFormik({
                initialValues,
                validationSchema: createContentSchema,
                onSubmit: (values, action) => {
                    addEpisode(values, action)
                },
            });

        const contentForm: IContentForm[] = [
            {
                "name": "name",
                "placeholder": "Episode name",
                "type": "text",
                "value": values.name,
                "error": errors.name,
                "required": true,
                "maxLength": 50,
                "limitValidation": true,
            },
            {
                "name": "description",
                "placeholder": "Type description here",
                "type": "textarea",
                "value": values.description,
                "error": errors.description,
                "required": true,
                "maxLength": 1000,
                "limitValidation": true,
            },
            {
                "name": "duration",
                "placeholder": "Type duration in seconds",
                "type": "text",
                "value": values.duration,
                "error": errors.duration,
                "required": true,
                "limitValidation": false,
            },
            {
                "name": "thumbnail",
                "urlName": "thumbnailUrl",
                "placeholder": "Please upload a thumbnail 1280x720px.",
                "type": "imagewithPreview",
                "value": values.thumbnail,
                "url": values.thumbnailUrl,
                "error": errors.thumbnail,
                "limitValidation": false,
                className: 'w-full aspect-video rounded overflow-hidden border cursor-pointer',
            },
            {
                "name": "source_type",
                "options": sourceType,
                "placeholder": "Select source type",
                "type": "select",
                "isMulti": false,
                "value": values.source_type,
            },
            {
                "name": "source_link",
                "title": "Source Link",
                "placeholder": "Type source link here",
                "type": "text",
                "value": values.source_link,
                "limitValidation": false,
            },
            {
                "name": "content_offering_type",
                "options": contentOfferingType,
                "placeholder": "Select content offering type",
                "type": "select",
                "isMulti": false,
                "value": values.content_offering_type,
                "col": "col-span-1"
            },
        ]
        return (
            <form id='episode-create' onSubmit={handleSubmit} >
                <div className="flex flex-col gap-4">
                    {
                        contentForm.map((item) => {
                            switch (item.type) {
                                case 'text':
                                    return (<InputField
                                        title={item.title}
                                        fieldName={item.name}
                                        placeholder={item.placeholder}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={item.value}
                                        type='text'
                                        required={item.required}
                                        maxLength={item.maxLength}
                                        error={item.error}
                                        limitValidation={item.limitValidation}
                                        col={item.col}
                                    />)
                                case 'textarea':
                                    return (
                                        <TextAreaField
                                            fieldName={item.name}
                                            placeholder={item.placeholder}
                                            textAreaOnBlur={handleBlur}
                                            textAreaOnChange={handleChange}
                                            value={item.value}
                                            type='text'
                                            required
                                            maxLength={item.maxLength}
                                            error={item.error}
                                            limitValidation={item.limitValidation}
                                            col={item.col}
                                        />
                                    )
                                case 'select':
                                    return (
                                        <SelectField
                                            options={item.options}
                                            onChange={(e) => {
                                                let event = { target: { name: item.name, value: e } }
                                                handleChange(event)
                                            }}
                                            onBlur={handleBlur}
                                            placeholder={item.placeholder}
                                            isMulti={item.isMulti}
                                            getOptionLabel={option => option.name}
                                            getOptionValue={option => option.id}
                                            promiseOptions={item.promiseOptions}
                                            col={item.col}
                                        />
                                    )
                                case 'imagewithPreview':
                                    return (
                                        <ImageField
                                            fieldName={item.name}
                                            placeholder={item.placeholder}
                                            onChange={(e) => {
                                                // set image in thumbnail field and url in thumbnailUrl field
                                                let event = { target: { name: item.name, value: e.target.files[0] } }
                                                handleChange(event)
                                                let urlEvent = { target: { name: item.urlName, value: URL.createObjectURL(e.target.files[0]) } }
                                                handleChange(urlEvent)
                                            }}
                                            onBlur={handleBlur}
                                            url={item.url}
                                            value={item.value}
                                            className={item.className}
                                            error={item.error}
                                            imageStyle={item.imageStyle}
                                            placeholderStyle={item.placeholderStyle}
                                            col={item.col}
                                        />
                                    )
                            }
                        })
                    }
                </div>
                <div className="flex justify-end my-5">
                    <LoadingButton
                        id='episode-create'
                        type='submit'
                        loading={loading}
                        disabled={loading}
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant='contained'>
                        Create
                    </LoadingButton>
                </div>
            </form>
        )
    }

    const EditEpisode = () => {
        const { episode: editEpisode } = useSelector((state: RootState) => state.content);
        const [loading, setLoading] = useState(false)
        const updateEpisode = async (values: any, action: any) => {
            const fromData = new FormData()
            fromData.append('name', values.name)
            fromData.append('description', values.description)
            fromData.append('duration', values.duration)
            if (values.content_offering_type.length === 0) return toast.error('Please select content offering type')
            fromData.append('content_offering_type', values.content_offering_type.id)
            if (values.source_type.length === 0) return toast.error('Please select source type')
            fromData.append('source_type', values.source_type.id)
            if (values.source_link === '') return toast.error('Please enter url')
            fromData.append('source_link', values.source_link)
            if (values.thumbnail) fromData.append('thumbnail', values.thumbnail)
            fromData.append('status', values.status)
            try {
                setLoading(true)
                const res = await contantUpdateEpisode(editEpisode._id, fromData)
                setLoading(false)
                if (res.status === 200) {
                    toast.success('Episode updated successfully')
                    setLoading(false)
                    dispatch(fetchAllContant(queryString, 'season') as any)
                    handleFullScreenDialogClose()
                    action.resetForm();
                }
            } catch (error: any) {
                setLoading(false)
                toast.error(error.response.data.error.message || 'Something went wrong')
            }
        }

        const editinitialValues = {
            name: editEpisode.name || "",
            description: editEpisode.description || "",
            duration: editEpisode.duration || "",
            thumbnail: "",
            thumbnailUrl: editEpisode.thumbnail || "",
            content_offering_type: contentOfferingType.find((item: any) => item.id === editEpisode.content_offering_type),
            source_type: sourceType.find((item: any) => item.id === editEpisode.source_type),
            source_link: editEpisode.source_link || "",
            status: editEpisode.status,
        };

        const editEpisodeSchema = Yup.object().shape({
            name: Yup.string().required("Title is required").min(3, "Title is too short").max(50, "Title is too long"),
            description: Yup.string().required("Description is required").min(50, "Description is too short").max(1000, "Description is too long"),
            duration: Yup.number().optional(),
            thumbnail: Yup.mixed().optional()
                .test("fileSize", "File too large limit is 1mb", (value: any) => {
                    if (!value) return true
                    return value && value.size <= 1024 * 1024 * 1 // 1mb
                })
                .test("fileFormat", "Unsupported Format. try JPEG, PNG, JPG, WEBP", (value: any) => {
                    if (!value) return true
                    return value && ["image/jpeg", "image/png", "image/jpg", "image/webp"].includes(value.type)
                }),
        });
        const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } =
            useFormik({
                initialValues: editinitialValues,
                validationSchema: editEpisodeSchema,
                enableReinitialize: true,
                onSubmit: (values, action) => {
                    updateEpisode(values, action)
                  
                },
            });

        const contentForm: IContentForm[] = [
            {
                "name": "name",
                "placeholder": "Episode name",
                "type": "text",
                "value": values.name,
                "error": errors.name,
                "required": true,
                "maxLength": 50,
                "limitValidation": true,
            },
            {
                "name": "description",
                "placeholder": "Type description here",
                "type": "textarea",
                "value": values.description,
                "error": errors.description,
                "required": true,
                "maxLength": 1000,
                "limitValidation": true,
            },
            {
                "name": "duration",
                "placeholder": "Type duration in seconds",
                "type": "text",
                "value": values.duration,
                "limitValidation": false,
            },
            {
                "name": "thumbnail",
                "urlName": "thumbnailUrl",
                "placeholder": "Please upload a thumbnail 1280x720px.",
                "type": "imagewithPreview",
                "value": values.thumbnail,
                "url": values.thumbnailUrl,
                "error": errors.thumbnail,
                "limitValidation": false,
                className: 'w-full aspect-video rounded overflow-hidden border cursor-pointer',
            },
            {
                "name": "source_type",
                "options": sourceType,
                "placeholder": "Select source type",
                "type": "select",
                "isMulti": false,
                "value": values.source_type,
            },
            {
                "name": "source_link",
                "title": "Source Link",
                "placeholder": "Type source link here",
                "type": "text",
                "value": values.source_link,
                "limitValidation": false,
            },
            {
                "name": "content_offering_type",
                "options": contentOfferingType,
                "placeholder": "Select content offering type",
                "type": "select",
                "isMulti": false,
                "value": values.content_offering_type,
                "col": "col-span-1"
            },
        ]
        return (
            <form id='episode-edit' onSubmit={handleSubmit} >
                <div className="flex flex-col gap-4">
                    {
                        contentForm.map((item) => {
                            switch (item.type) {
                                case 'text':
                                    return (<InputField
                                        title={item.title}
                                        fieldName={item.name}
                                        placeholder={item.placeholder}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={item.value}
                                        type='text'
                                        required={item.required}
                                        maxLength={item.maxLength}
                                        error={item.error}
                                        limitValidation={item.limitValidation}
                                        col={item.col}
                                    />)
                                case 'textarea':
                                    return (
                                        <TextAreaField
                                            fieldName={item.name}
                                            placeholder={item.placeholder}
                                            textAreaOnBlur={handleBlur}
                                            textAreaOnChange={handleChange}
                                            value={item.value}
                                            type='text'
                                            required
                                            maxLength={item.maxLength}
                                            error={item.error}
                                            limitValidation={item.limitValidation}
                                            col={item.col}
                                        />
                                    )
                                case 'select':
                                    return (
                                        <SelectField
                                            options={item.options}
                                            onChange={(e) => {
                                                let event = { target: { name: item.name, value: e } }
                                                handleChange(event)
                                            }}
                                            onBlur={handleBlur}
                                            placeholder={item.placeholder}
                                            isMulti={item.isMulti}
                                            getOptionLabel={option => option.name}
                                            getOptionValue={option => option.id}
                                            promiseOptions={item.promiseOptions}
                                            col={item.col}
                                            defaultValue={item.value}
                                        />
                                    )
                                case 'imagewithPreview':
                                    return (
                                        <ImageField
                                            fieldName={item.name}
                                            placeholder={item.placeholder}
                                            onChange={(e) => {
                                                // set image in thumbnail field and url in thumbnailUrl field
                                                let event = { target: { name: item.name, value: e.target.files[0] } }
                                                handleChange(event)
                                                let urlEvent = { target: { name: item.urlName, value: URL.createObjectURL(e.target.files[0]) } }
                                                handleChange(urlEvent)
                                            }}
                                            onBlur={handleBlur}
                                            url={item.url}
                                            value={item.value}
                                            className={item.className}
                                            error={item.error}
                                            imageStyle={item.imageStyle}
                                            placeholderStyle={item.placeholderStyle}
                                            col={item.col}
                                        />
                                    )
                            }
                        })
                    }
                </div>
                <div className="form-control mt-2">
                        <label className="label cursor-pointer">
                            <span>Publish</span>
                            <Switch
                                checked={values.status}
                                onChange={(e) => {
                                    let event = { target: { name: 'status', value: e.target.checked } }
                                    handleChange(event)
                                }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </label>
                    </div>
                <div className="flex justify-end my-5">
                    <LoadingButton
                        id='episode-edit'
                        type='submit'
                        loading={loading}
                        disabled={loading}
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant='contained'>
                        Save
                    </LoadingButton>
                </div>
            </form>
        )
    }

    return (
        <Layout>
            <div className='grid grid-cols-3 gap-10 pr-5 mt-2'>
                <div className='flex flex-col gap-2 col-span-1'>
                    <Title className="font-medium text-xl mb-4 opacity-80">Edit Season</Title>
                    <InputField
                        fieldName="name"
                        title="Name"
                        value={seasonData.name}
                        onChange={(e: any) => setSeasonData({ ...seasonData, name: e.target.value })}
                        type="text"
                        placeholder="Enter season name"
                    />
                    <div className="form-control">
                        <label className="label cursor-pointer">
                            <span>Publish</span>
                            <Switch
                                checked={seasonData.status}
                                onChange={(e) => setSeasonData({ ...seasonData, status: e.target.checked })}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </label>
                    </div>
                    <LoadingButton
                        loading={loading}
                        onClick={handleUpdateSeason}
                        variant="contained">
                        Save
                    </LoadingButton>
                </div>
                <div className="col-span-2">
                    <div className="flex justify-between items-center">
                        <Title className="font-medium text-xl mb-5">
                            Episodes
                        </Title>
                        <Button
                            onClick={() => {
                                setFullScreenDialogConfig({
                                    title: 'Add Episode',
                                    children: <CreateEpisode />
                                })
                                fullScreenDialogOpen()
                            }}
                            variant="contained">Add Episode</Button>
                    </div>
                    <div className="mt-3.5">
                        <div className="overflow-x-auto">
                            <table className="table w-full">
                                <thead>
                                    <tr>
                                        <th className={`bg-transparent border-y ${darkMode ? 'border-[#343434]' : 'border-gray-200'}`}>No.</th>
                                        <th className={`bg-transparent border-y ${darkMode ? 'border-[#343434]' : 'border-gray-200'}`}>Name</th>
                                        <th className={`bg-transparent border-y ${darkMode ? 'border-[#343434]' : 'border-gray-200'}`}>
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        season && season.episodes && season.episodes.length > 0 && season.episodes.map((episodeItem, index) => {
                                            return (
                                                <tr key={index}>
                                                    <th className={`bg-transparent border-y ${darkMode ? 'border-[#343434]' : 'border-gray-200'}`}> {index + 1} </th>
                                                    <td className={`bg-transparent border-y ${darkMode ? 'border-[#343434]' : 'border-gray-200'}`}> {episodeItem.name} </td>
                                                    <td className={`bg-transparent border-y ${darkMode ? 'border-[#343434]' : 'border-gray-200'}`}>
                                                        <div className='flex gap-4 items-center'>

                                                            <div className="tooltip tooltip-accent" data-tip="Edit">
                                                                <Button
                                                                    onClick={() => {
                                                                        setEpisodeId(episodeItem._id)
                                                                        dispatch(fetchAllContant(queryStringEpisode(episodeItem._id), 'episode') as any)
                                                                        setFullScreenDialogConfig({
                                                                            title: 'Edit Episode',
                                                                            children: <EditEpisode />
                                                                        })
                                                                        fullScreenDialogOpen()
                                                                    }}
                                                                    className="w-10 h-10 bg-gray-800 hover:bg-gray-700"
                                                                    variant="text">
                                                                    <EditIcon className="cursor-pointer" />
                                                                </Button>
                                                            </div>
                                                            {/* <div className="tooltip" data-tip="Edit">
                                                                <label htmlFor="update-episode" onClick={() => setEpisodeId(episode._id)} className="cursor-pointer">
                                                                    <EditIcon
                                                                        width={50}
                                                                        height={50}
                                                                        backgroundColor="#172337"
                                                                    />
                                                                </label>
                                                            </div> */}
                                                            {/* </Link> */}
                                                            {
                                                                <div className="tooltip tooltip-accent" data-tip="Delete"> <Button
                                                                    onClick={() => {
                                                                        setEpisodeId(episodeItem._id)
                                                                        handleMuiDialogOpen()
                                                                        setMuiDialogContant({
                                                                            title: 'Delete Episode',
                                                                            contentText: 'Are you sure you want to delete this episode permanently?',
                                                                            buttonOneText: 'Cancel',
                                                                            buttonTwoText: 'Yes'
                                                                        })
                                                                    }}
                                                                    variant="text">
                                                                    <DeleteIcon />
                                                                </Button>
                                                                </div>
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>


            <FullScreenDialog
                title={fullScreenDialogConfig.title}
                open={fullScreenDialog}
                onClose={handleFullScreenDialogClose}>
                <div className='py-4 container m-auto max-w-3xl'>
                    {fullScreenDialogConfig.children}
                </div>
            </FullScreenDialog>

            <MuiDialog
                open={muiDialogOpen}
                onClose={handleMuiDialogClose}
                title={muiDialogContant.title}
                contentText={muiDialogContant.contentText}
                onClickClose={handleMuiDialogClose}
                onClickYes={handleRemoveEpisode}
                buttonOneText={muiDialogContant.buttonOneText}
                buttonTwoText={muiDialogContant.buttonTwoText}
            />
        </Layout>
    )
}

export default EditContentSeason


