import Layout from "../../components/Layout/Layout";
import { Button, ContantTable } from "../../tailwind";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDebounce } from "use-debounce";
import SearchBar from "../../components/SearchBar";
import { setBulkAction, setBulkActionCall } from "../../redux/slice​/movieSlice";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Hr } from "../../components";
import { fetchAllContant } from "../../redux/slice​/contentSlice";
import qs from 'qs';
import { RootState } from "../../redux/store";

interface IQuery {
    page: number;
    limit: number;
    sort: string;
    keyword?: string;
}

const Content = () => {
    const { content, meta } = useSelector((state: RootState) => state.content);
    const { darkMode } = useSelector((state: RootState) => state.theme);
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    // get query params 
    const {
        page = 1,
        limit = process.env.REACT_APP_PAGE_SIZE || 25,
        sort = "desc",
    } = qs.parse(location.search, { ignoreQueryPrefix: true });
    // max limit is 25 if greater than 25 then set to 25
    const [limitValue, setLimitValue] = useState(limit > 25 ? 25 : limit);
    const [query, setQuery] = useState<IQuery>({
        page: Number(page),
        limit: Number(limitValue),
        sort: sort as string,
    })
    // set limit value greater than 25 then update limit value to 25 in url using navigate
    useEffect(() => {
        if (limit > 25 || limit < 1) {
            setLimitValue(25);
            navigate(`?page=${page}&limit=25&sort=${sort}`);
            toast.error("Limit can't be greater than 25 or less than 1");
        }
        // limit must be a number
        if (isNaN(limit as number)) {
            setLimitValue(25);
            navigate(`?page=${page}&limit=25&sort=${sort}`);
            toast.error("Limit must be a number");
        }
        // if sort is not desc or asc then set to desc
        if (sort !== "desc" && sort !== "asc") {
            navigate(`?page=${page}&limit=${limit}&sort=desc`);
            toast.error("Sort must be desc or asc");
        }
        // if page is not a number then set to 1
        if (isNaN(page as number)) {
            navigate(`?page=1&limit=${limit}&sort=${sort}`);
            toast.error("Page must be a number");
        }
        navigate(`?page=${query.page}&limit=${query.limit}&sort=${query.sort}`);
    }, [query]);


    const [search, setSearch] = useState<string>('');
    const [value] = useDebounce(search, 1000);
    const queryString: string = qs.stringify(query);

    useEffect(() => {
        if (value) {
            setQuery({
                ...query,
                keyword: value,
            })
            dispatch(fetchAllContant(queryString, null) as any);
        }
    }, [value]);



    useEffect(() => {
        dispatch(fetchAllContant(queryString, null) as any);
    }, [query]);

    const handleRefresh = () => {
        // remove keyword from query
        setQuery({
            page: 1,
            limit: 25,
            sort: "desc",
        })
        dispatch(fetchAllContant(queryString, null) as any);
        toast.success("Content list refreshed");
    }


    const headlePrev = () => {
        if (query.page > 1) {
            setQuery({
                ...query,
                page: query.page - 1
            })
        } else {
            toast.error("You are on first page")
        }
    }

    const handleNext = () => {
        if (query.page < Math.ceil(meta.report.total / query.limit)) {
            setQuery({
                ...query,
                page: query.page + 1
            })
        } else {
            toast.error("No more pages")
        }
    }
    return (
        <Layout>
            <div>
                <h1 className="text-2xl opacity-80 font-semibold tracking-wide">
                    List of Content
                </h1>
                <p className="mt-2 opacity-70 mb-4">
                    Here you can see your all content list and you can also add new content from here.
                </p>
            </div>
            <Hr />
            <div className='flex justify-between items-center pr-4 pl-1 my-1 w-full'>
                <div className='flex items-center gap-4'>
                    <select
                        className="select max-w-xs bg-transparent text-sm font-normal pl-0 -ml-1 focus:outline-none"
                        defaultValue={'none'}
                        onChange={(e) => dispatch(setBulkAction(e.target.value))}>
                        <option value="none">Bulk Actions</option>
                        <option value='public'>Public</option>
                        <option value='private'>Private</option>
                        <option value='restore'>Restore</option>
                        <option value='trash'>Move to Trash</option>
                        <option value='delete'>Delete Forever</option>
                    </select>
                    <span onClick={() => dispatch(setBulkActionCall(true))} className="bg-transparent py-3.5 px-6 text-sm cursor-pointer hover:bg-secondary-blue duration-100" >Apply</span>
                    <span onClick={handleRefresh} className="bg-transparent py-3.5 px-6 text-sm cursor-pointer hover:bg-secondary-blue duration-100" >
                        Refresh
                    </span>
                </div>
                <div className='flex gap-2 items-center'>
                    <SearchBar
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    <Link to='/admin/content/create'>
                        <Button
                            title={'+ Add New'}
                            titleStyle={'font-medium'}
                            backgroundColor={'bg-transparent'}
                            hoverBackgroundColor={'bg-transparent'}
                            height={'h-11'}
                            onClick={() => { }}
                        />
                    </Link>
                </div>
            </div>
            <Hr />
            <div className="mt-3.5 w-full">
                <ContantTable data={content} query={queryString} />
            </div>
            <div className='flex justify-between items-center my-1.5 pr-5'>
                {
                    content.length > 0 && <div className="flex gap-8 items-center">
                        <div className="flex gap-3.5">
                            <div className="">Total Content</div>
                            <div className="">{meta?.report?.total}</div>
                        </div>
                        <div className="flex gap-3.5">
                            <div className="">
                                Public
                            </div>
                            <div className="">
                                {meta?.report?.totalPublic}
                            </div>
                        </div>
                        <div className="flex gap-3.5">
                            <div className="">
                                Private
                            </div>
                            <div className="">{
                                meta?.report?.totalPrivate
                            }</div>
                        </div>
                    </div>
                }
                <div>
                    <div className="btn-group">
                        <button className={`btn bg-transparent hover:bg-transparent border-none ${darkMode ? 'text-white' : 'text-black'}`} onClick={headlePrev}>«</button>
                        <button className={`btn bg-transparent hover:bg-transparent border-none capitalize text-sm ${darkMode ? 'text-white' : 'text-black'}`}>Page {query.page}</button>
                        <button className={`btn bg-transparent hover:bg-transparent border-none ${darkMode ? 'text-white' : 'text-black'}`} onClick={handleNext} >»</button>
                    </div>
                </div>
            </div>
            <Hr />
        </Layout>
    )
}

export default Content;