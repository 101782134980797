import { useEffect, useState } from 'react';
import Layout from '../../components/Layout/Layout'
import SearchBar from '../../components/SearchBar';
import { CategoryTable, GenreTable } from '../../tailwind';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { useDebounce } from 'use-debounce';
import { createCategoryEndPoint, createGenreApi } from '../../http/Apis';
import { Hr } from '../../components';
import { RootState } from '../../redux/store';
import { fetchCategories, setBulkAction, setBulkActionCall } from '../../redux/slice​/categorySlice';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import qs from 'qs';

const Category = () => {
    const dispatch = useDispatch();
    const { darkMode } = useSelector((state: RootState) => state.theme);
    const { categories, meta, status } = useSelector((state: RootState) => state.category);
    const [loading, setLoading] = useState(false);
    const [itemView, setItemView] = useState(5);
    const [search, setSearch] = useState<string>();
    const [value] = useDebounce(search, 1000);





    useEffect(() => {
        if (value) {
            if (value.length > 2) {
                dispatch(fetchCategories(`keyword=${value}`) as any);
            } else {
                toast.error('Please enter more than 2 characters');
            }
        }
    }, [value]);

    const [pagination, setPagination] = useState({
        page: 1,
        limit: process.env.REACT_APP_PAGE_SIZE ? parseInt(process.env.REACT_APP_PAGE_SIZE) : 25
    })

    const fetchQueryStr = qs.stringify({
        page: pagination.page,
        limit: pagination.limit,
        order: 'desc',
    })

    useEffect(() => {
        dispatch(fetchCategories(fetchQueryStr) as any);
    }, [pagination]);

    const headlePrev = () => {
        if (pagination.page > 1) {
            setPagination({
                ...pagination,
                page: pagination.page - 1
            })
        } else {
            toast.error("You are on first page")
        }
    }

    const handleNext = () => {
        if (pagination.page < Math.ceil(meta.report.total / pagination.limit)) {
            setPagination({
                ...pagination,
                page: pagination.page + 1
            })
        } else {
            toast.error("No more pages")
        }
    }

    // create category
    const [createCategory, setCreateCategoryOpen] = useState(false);

    const handleClickCreateCategoryOpen = () => {
        setCreateCategoryOpen(true);
    };
    const handleCreateCategoryClose = () => {
        setCreateCategoryOpen(false);
    };

    // createCategoryFunction
    const [categoryName, setCategoryName] = useState<string>();
    const [categoryDescription, setCategoryDescription] = useState<string>();

    const createCategoryFunc = async () => {
        if (!categoryName || !categoryDescription) {
            toast.error('Please fill all fields');
            return;
        }
        setLoading(true);
        try {
            const { status } = await createCategoryEndPoint({ name: categoryName, description: categoryDescription });
            if (status === 201) {
                toast.success('Category created successfully');
                dispatch(fetchCategories(fetchQueryStr) as any);
                handleCreateCategoryClose();
                setLoading(false);
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.error?.message || 'Something went wrong');
            handleCreateCategoryClose();
            setLoading(false);
        }
    }


    return (
        <>
            <Layout>
                <h1 className="font-medium text-2xl pr-5 mb-3 opacity-80">
                    Categories
                </h1>
                <Hr />
                <div className='flex justify-between items-center px-5'>
                    <div className='flex items-center gap-4'>
                        <select
                            className="select max-w-xs bg-transparent text-sm font-normal pl-0 -ml-1 focus:outline-none"
                            defaultValue={'none'}
                            onChange={(e) => dispatch(setBulkAction(e.target.value))}>
                            <option value="none">Bulk Actions</option>
                            <option value='public'>Public</option>
                            <option value='private'>Private</option>
                            <option value='restore'>Restore</option>
                            <option value='trash'>Move to Trash</option>
                            <option value='delete'>Delete Forever</option>
                        </select>
                        <span onClick={() => dispatch(setBulkActionCall(true))} className="bg-transparent py-3.5 px-6 text-sm cursor-pointer hover:bg-secondary-blue duration-100" >Apply</span>
                    </div>
                    <div className='flex gap-2 items-center'>
                        <SearchBar
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        <Button
                            variant='text'
                            onClick={handleClickCreateCategoryOpen}
                            className='bg-primary-blue text-white hover:bg-primary-blue-dark duration-100'
                        >
                            + Add New
                        </Button>
                    </div>
                </div>
                <Hr />
                <div className="w-full">
                    {
                        status === 'loading' ? <div className='text-center'>Loading...</div> :
                            categories.length > 0 ? <>
                                <CategoryTable Data={categories} page={pagination.page} />
                                <div className='flex justify-between items-center my-1.5 pr-5'>
                                    {
                                        categories.length > 0 && <div className="flex gap-8 items-center">
                                            <div className="flex gap-3.5">
                                                <div className="">Total Category</div>
                                                <div className="">{meta.report.total}</div>
                                            </div>
                                            <div className="flex gap-3.5">
                                                <div className="">
                                                    Public
                                                </div>
                                                <div className="">
                                                    {meta.report.public}
                                                </div>
                                            </div>
                                            <div className="flex gap-3.5">
                                                <div className="">
                                                    Private
                                                </div>
                                                <div className="">{
                                                    meta.report.private
                                                }</div>
                                            </div>
                                        </div>
                                    }
                                    <div>
                                        <div className="btn-group">
                                            <button className={`btn bg-transparent hover:bg-transparent border-none ${darkMode ? 'text-white' : 'text-black'}`} onClick={headlePrev}>«</button>
                                            <button className={`btn bg-transparent hover:bg-transparent border-none capitalize text-sm ${darkMode ? 'text-white' : 'text-black'}`}>Page {pagination.page}</button>
                                            <button className={`btn bg-transparent hover:bg-transparent border-none ${darkMode ? 'text-white' : 'text-black'}`} onClick={handleNext} >»</button>
                                        </div>
                                    </div>
                                </div>
                                {/* <Hr /> */}
                            </> : <div className='text-center my-8'>No categories found</div>
                    }
                </div>
                <Hr />

                <Dialog open={createCategory} onClose={handleCreateCategoryClose}>
                    <DialogTitle>
                        Create Category
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            To create a category, please enter the category name here.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Genre Name"
                            type="text"
                            fullWidth
                            value={categoryName}
                            onChange={(e) => setCategoryName(e.target.value)}
                            variant="standard"
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Genre Description"
                            type="text"
                            fullWidth
                            value={categoryDescription}
                            onChange={(e) => setCategoryDescription(e.target.value)}
                            variant="standard"
                        />

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCreateCategoryClose}>Cancel</Button>
                        <LoadingButton
                            loading={loading}
                            onClick={createCategoryFunc}
                            variant='text'
                            color='primary'
                        >
                            Create
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            </Layout>
        </>

    )
}

export default Category;



// import Layout from "../../components/Layout/Layout";
// import { Button, CategoryTable } from "../../tailwind";
// import { useSelector, useDispatch } from "react-redux";
// import { useEffect, useState } from "react";
// import toast from "react-hot-toast";
// import { useDebounce } from "use-debounce";
// import SearchBar from "../../components/SearchBar";
// import { Link } from "react-router-dom";
// import { fetchCategories, fetchCategoryReport, fetchSearchCategory, setBulkAction, setBulkActionCall } from "../../redux/slice​/categorySlice";
// import { Hr } from "../../components";

// const Category = () => {
//     const { data, report } = useSelector(state => state.category);
//     const dispatch = useDispatch();

//     const [loading, setLoading] = useState(false);
//     const [itemView, setItemView] = useState(5);
//     const [search, setSearch] = useState();
//     const [value] = useDebounce(search, 1000);

//     useEffect(() => {
//         if (value) {
//             dispatch(fetchSearchCategory(value));
//         }
//     }, [value]);

//     const [pagination, setPagination] = useState({
//         page: 1,
//         limit: process.env.REACT_APP_PAGE_SIZE,
//     })

//     useEffect(() => {
//         dispatch(fetchCategories(pagination.page, pagination.limit));
//         dispatch(fetchCategoryReport())
//     }, [pagination]);


//     const headlePrev = () => {
//         if (pagination.page > 1) {
//             setPagination({
//                 ...pagination,
//                 page: pagination.page - 1
//             })
//         } else {
//             toast.error("You are on first page")
//         }
//     }

//     const handleNext = () => {
//         if (pagination.page < Math.ceil(report.total / pagination.limit)) {
//             setPagination({
//                 ...pagination,
//                 page: pagination.page + 1
//             })
//         } else {
//             toast.error("No more pages")
//         }
//     }
//     return (
//         <Layout>
//             <h1 className="font-medium text-2xl px-5 mb-3">
//                 Categories 
//             </h1> 
//             <Hr />
//             <div className='flex justify-between items-center px-5'>
//                 <div className='flex items-center gap-4'>
//                     <select
//                         className="select max-w-xs bg-transparent text-gray-300 text-sm font-normal pl-0 -ml-1"
//                         defaultValue={'none'}
//                         onChange={(e) => dispatch(setBulkAction(e.target.value))}>
//                         <option value="none">Bulk Actions</option>
//                         <option value='Delete'>Delete</option>
//                         <option value='UnPublish'>UnPublish</option>
//                         <option value='Publish'>Publish</option>
//                         <option value='Restore'>Restore</option>
//                     </select>
//                     <span onClick={() => dispatch(setBulkActionCall(true))} className="bg-transparent py-3.5 px-6 text-sm cursor-pointer hover:bg-secondary-blue duration-100 text-gray-300" >Apply</span>
//                 </div>
//                 <div className='flex gap-2 items-center'>
//                     <SearchBar
//                         onChange={(e) => setSearch(e.target.value)}
//                     />
//                     <Link to='/admin/categories/add'>
//                         <Button
//                             title={'+ Add New'}
//                             titleStyle={'text-white font-medium'}
//                             backgroundColor={'bg-transparent'}
//                             hoverBackgroundColor={'bg-transparent'}
//                             height={'h-11'}
//                         />
//                     </Link>
//                 </div>
//             </div>
//             <Hr />
//             <div>
//                 <CategoryTable data={data} page={pagination.page} />
//             </div>
//             <div className='flex justify-between items-center my-4 px-5'>
//                 {
//                     data.length > 0 && <div className="stats stats-vertical lg:stats-horizontal text-gray-300 bg-transparent rounded-md">
//                         <div className="stat flex py-3">
//                             <div className="stat-title">Total Categories</div>
//                             <div className="">{report.total}</div>
//                         </div>
//                         <div className="stat flex py-3">
//                             <div className="stat-title">Deactivate</div>
//                             <div className="">
//                                 {report.Deactivate}
//                             </div>
//                         </div>
//                         <div className="stat flex py-3">
//                             <div className="stat-title">Active</div>
//                             <div className="">{
//                                 report.Active
//                             }</div>
//                         </div>
//                     </div>
//                 }
//                 <div>
//                     <div className="btn-group">
//                         <button className="btn bg-transparent hover:bg-transparent border-none text-gray-300" onClick={headlePrev}>«</button>
//                         <button className="btn bg-transparent hover:bg-transparent border-none capitalize text-sm text-gray-300">Page {pagination.page}</button>
//                         <button className="btn bg-transparent hover:bg-transparent border-none text-gray-300" onClick={handleNext} >»</button>
//                     </div>
//                 </div>
//             </div>
//         </Layout >
//     )
// }

// export default Category;