import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface ToolState {
  isFullScreen: boolean
}

const initialState: ToolState = {
    isFullScreen: false,
}

export const ToolSlice = createSlice({
  name: 'tool',
  initialState,
  reducers: {
    handleFullScreen: (
      state,
      actions: PayloadAction<{ isFullScreen: boolean }>
    ) => {
        const isFullScreen = actions.payload
        state.isFullScreen = isFullScreen.isFullScreen
    },
  },
})

// Action creators are generated for each case reducer function
export const { handleFullScreen } = ToolSlice.actions

export default ToolSlice.reducer