import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import { ComponentsStrings } from '../../../localData/components';
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store';
import { DashboardIcon } from '../../../tailwind';
import { Link } from 'react-router-dom';

export interface ISearchResult {
    id: number;
    name: string;
    description: string;
    link: string;
    icon: React.ReactNode;
}

export default function CustomizedInputBase() {
    const [search, setSearch] = React.useState('');
    const [searchResult, setSearchResult] = React.useState<ISearchResult[]>([]);
    const { darkMode } = useSelector((state: RootState) => state.theme);

    const searchComponents = async () => {
        // now search for the component from the local json file
        const result = ComponentsStrings.filter((item) => {
            return item.name.toLowerCase().includes(search.toLowerCase());
        }
        );
        setSearchResult(result);
    }

    React.useEffect(() => {
        if (search.length > 0) {
            searchComponents();
        } else {
            setSearchResult([]);
        }
    }, [search]);

    return (
        <div className='relative'>
            <Paper
                component="form"
                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 550, backgroundColor: darkMode ? '' : '#dedede ', boxShadow: 'none' }}
            >
                <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                    <SearchIcon />
                </IconButton>
                <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search Components Here"
                    inputProps={{ 'aria-label': 'Search Components Here' }}
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                />
            </Paper>
            {
                searchResult.length > 0 && (<div className={`${darkMode ? 'bg-[#282828] border-gray-700 shadow-gray-600' : 'bg-[#dedede] shadow-[#dedede] border-[#dedede]'} shadow-sm  border-r border-l rounded-b-md p-5 absolute  w-full z-50 max-h-96 overflow-y-scroll scrollbar-thin scrollbar-track-transparent`}>
                    <h1 className='mb-7'>Zezo OTT features</h1>
                    <div className='flex flex-col gap-8'>


                        {
                            searchResult.map((item, index) => {
                                return (
                                    <Link to={item.link} key={index} className='flex items-center gap-4'>
                                        <div key={index} className='flex gap-6 items-center cursor-pointer'>
                                            <div>
                                                {item.icon}
                                            </div>
                                            <div>
                                                <h1 className='text-sm'>{item.name}</h1>
                                                <p className='text-2xs'>
                                                    {item.description}
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                )
                            })
                        }
                    </div>

                </div>)
            }

        </div>

    );
}