import { createSlice } from '@reduxjs/toolkit'
import { getContant } from '../../http/Apis'
import { STATUS } from './configSlice'

export interface IContent {
    content: {
        _id: string,
        name: string,
        slug: string,
        u_age: string,
        description: string,
        duration: string,
        rating: number,
        source_link: string | null,
        source_type: 'HLS' | 'MP4' | 'LIVE_STREAM_HLS'
        trailer_source_link: string | null,
        trailer_source_type: 'HLS' | 'MP4',
        language: {
            _id: string,
            name: string,
        }[] | null,
        cast: {
            _id: string,
            name: string,
            avatar: string | null,
            castType: string,
        }[] | null,
        poster: string,
        thumbnail: string,
        tags: string[],
        job_id: string | null,
        seasons: {
            _id: string,
            name: string,
            contant_id: string,
            order: number,
            episodes: {
                _id: string,
                name: string,
                description: string,
                duration: number,
                source_link: string,
                source_type: 'HLS' | 'MP4',
                content_offering_type: 'FREE' | 'PREMIUM',
                thumbnail: string,
                createdAt: string,
                updatedAt: string,
            }[] | null,
            status: boolean,
            created_by: string,
            createdAt: string,
            updatedAt: string,
        }[] | null,
        status: 'PUBLIC' | 'PRIVATE' | 'PENDING' | 'REJECTED',
        trash: boolean,
        type: 'series' | 'movie' | 'live_stream',
        content_offering_type: 'FREE' | 'PREMIUM',
        updated_by: string,
        created_by: string,
        createdAt: string,
        updatedAt: string,
        category: {
            _id: string,
            name: string,
        }[] | null,
        genres: {
            _id: string,
            name: string,
        }[] | null,



    }[]
    meta: {
        pagination: {
            page: number,
            pageSize: number,
            pageCount: number,
            total: number,
        },
        report: {
            total: number,
            totalPublic: number,
            totalPrivate: number,
        }
    }
    status: 'idle' | 'loading' | 'succeeded' | 'failed'
    bulkAction: string
    bulkActionCall: boolean
    single: {
        _id: string,
        name: string,
        slug: string,
        u_age: string,
        description: string,
        duration: string,
        rating: number,
        source_link: string | null,
        source_type: 'HLS' | 'MP4' | 'LIVE_STREAM_HLS'
        trailer_source_link: string | null,
        trailer_source_type: 'HLS' | 'MP4',
        language: {
            _id: string,
            name: string,
        }[] | null,
        cast: {
            _id: string,
            name: string,
            avatar: string | null,
            castType: string,
        }[] | null,
        poster: string,
        thumbnail: string,
        tags: string[],
        job_id: string | null,
        seasons: {
            _id: string,
            name: string,
            contant_id: string,
            order: number,
            episodes: {
                _id: string,
                name: string,
                description: string,
                duration: number,
                source_link: string,
                source_type: 'HLS' | 'MP4',
                content_offering_type: 'FREE' | 'PREMIUM',
                thumbnail: string,
                createdAt: string,
                updatedAt: string,
            }[] | null,
            status: boolean,
            created_by: string,
            createdAt: string,
            updatedAt: string,
        }[] | null,
        status: 'PUBLIC' | 'PRIVATE' | 'PENDING' | 'REJECTED',
        trash: boolean,
        type: 'series' | 'movie' | 'live_stream',
        content_offering_type: 'FREE' | 'PREMIUM',
        updated_by: string,
        created_by: string,
        createdAt: string,
        updatedAt: string,
        category: {
            _id: string,
            name: string,
        }[] | null,
        genres: {
            _id: string,
            name: string,
        }[] | null,



    },
    season: {
        _id: string,
        name: string,
        content_id: {
            _id: string,
            name: string,
        },
        order: number,
        episodes: {
            _id: string,
            name: string,
            description: string,
            season_id: string,
            content_id: string,
            duration: number,
            source_link: string,
            source_type: 'HLS' | 'MP4',
            subtitles: [],
            content_offering_type: 'FREE' | 'PREMIUM',
            thumbnail: string,
            views: number,
            watch_time: number,
            job_id: string | null,
            status: boolean,
            order: number,
            created_by: string,
            updated_by: string,
            createdAt: string,
            updatedAt: string,
        }[] | null,
        status: boolean,
        created_by: string,
        createdAt: string,
        updatedAt: string,
    }
    episode: {
        _id: string,
        name: string,
        description: string,
        season_id: {
            _id: string,
            name: string,
        },
        content_id: {
            _id: string,
            name: string,
        },
        duration: number,
        source_link: string,
        source_type: 'HLS' | 'MP4',
        subtitles: [],
        content_offering_type: 'FREE' | 'PREMIUM',
        thumbnail: string,
        views: number,
        watch_time: number,
        job_id: string | null,
        status: boolean,
        order: number,
        created_by: string,
        updated_by: string,
        createdAt: string,
        updatedAt: string,
    },
    report: boolean,
}




const initialState: IContent = {
    content: [],
    meta: {
        pagination: {
            page: 1,
            pageSize: 10,
            pageCount: 1,
            total: 0,
        },
        report: {
            total: 0,
            totalPublic: 0,
            totalPrivate: 0,
        }
    },
    status: STATUS.IDLE,
    bulkAction: 'none',
    bulkActionCall: false,
    single: {
        _id: '',
        name: '',
        slug: '',
        u_age: '',
        description: '',
        duration: '',
        rating: 0,
        source_link: null,
        source_type: 'HLS',
        trailer_source_link: null,
        trailer_source_type: 'HLS',
        language: null,
        cast: null,
        poster: '',
        thumbnail: '',
        tags: [],
        job_id: null,
        seasons: null,
        status: 'PUBLIC',
        trash: false,
        type: 'movie',
        content_offering_type: 'FREE',
        updated_by: '',
        created_by: '',
        createdAt: '',
        updatedAt: '',
        category: null,
        genres: null,
    },
    season: {
        _id: '',
        name: '',
        content_id: {
            _id: '',
            name: '',
        },
        order: 0,
        episodes: [],
        status: false,
        created_by: '',
        createdAt: '',
        updatedAt: '',
    },
    episode: {
        _id: '',
        name: '',
        description: '',
        season_id: {
            _id: '',
            name: '',
        },
        content_id: {
            _id: '',
            name: '',
        },
        duration: 0,
        source_link: '',
        source_type: 'HLS',
        subtitles: [],
        content_offering_type: 'FREE',
        thumbnail: '',
        views: 0,
        watch_time: 0,
        job_id: null,
        status: false,
        order: 0,
        created_by: '',
        updated_by: '',
        createdAt: '',
        updatedAt: '',
    },
    report: false,
}

export const contantSlice = createSlice({
    name: 'content',
    initialState,
    reducers: {
        setContent: (state, actions) => {
            const { data, meta } = actions.payload
            state.content = data
            state.meta = meta
        },
        setStatus: (state, actions) => {
            state.status = actions.payload
        },
        setBulkAction: (state, actions) => {
            state.bulkAction = actions.payload
        },
        setBulkActionCall: (state, actions) => {
            state.bulkActionCall = actions.payload
        },
        setSingle: (state, actions) => {
            const { data } = actions.payload
            state.single = data[0]
        },
        setReport: (state, actions) => {
            const { data } = actions.payload
            state.report = data
        },
        setSeason: (state, actions) => {
            const { data } = actions.payload
            state.season = data[0]
        },
        setEpisode: (state, actions) => {
            const { data } = actions.payload
            state.episode = data[0]
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    setContent,
    setStatus,
    setBulkAction,
    setBulkActionCall,
    setSingle,
    setReport,
    setSeason,
    setEpisode
} = contantSlice.actions

export default contantSlice.reducer

export interface IFetchAllContentTypes {
    queryString: string
    type?: 'single' | 'season' | 'episode'
}

export function fetchAllContant(queryString: IFetchAllContentTypes['queryString'] | null, type: IFetchAllContentTypes['type'] | null) {
    return async (dispatch: any) => {
        dispatch(setStatus(STATUS.LOADING))
        try {
            const res = await getContant(queryString && queryString);
            switch (type) {
                case 'single':
                    dispatch(setSingle(res.data))
                    break;
                case 'season':
                    dispatch(setSeason(res.data))
                    break;
                case 'episode':
                    dispatch(setEpisode(res.data))
                    break;
                default:
                    dispatch(setContent(res.data))
                    break;
            }
            dispatch(setStatus(STATUS.SUCCEEDED))
        } catch (error) {
            console.log(error)
            dispatch(setStatus(STATUS.FAILED))
        }
    }
}

