import * as React from 'react';
import { emphasize, styled } from '@mui/material/styles';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Chip from '@mui/material/Chip';
import HomeIcon from '@mui/icons-material/Home';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
}) as typeof Chip; // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591

function handleClick(event: React.MouseEvent<Element, MouseEvent>) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
    console.log(event.currentTarget.textContent)
    // now get onclick text on click
    console.log(event.currentTarget)
    // give only left side of click
    console.log(event.currentTarget.parentElement) 
    // give all left side of click
    console.log(event.currentTarget.parentElement?.children)
}

interface IBreadcrumbsProps {
    data: string
    onClick?: (event: React.MouseEvent<Element, MouseEvent>) => void
}

export default function CustomizedBreadcrumbs({ data, onClick }: IBreadcrumbsProps) {
    // prefix data split by '/' create array
    const prefix = data.split('/').filter((item) => item !== '');
    
    return (
        <div role="presentation" onClick={handleClick}>
            <Breadcrumbs 
            maxItems={6}
            aria-label="breadcrumb">
                <StyledBreadcrumb
                    component="a"
                    href="#"
                    label="Home"
                    icon={<HomeIcon fontSize="small" />}
                    onClick={onClick}
                />
                
                {
                prefix.length > 0 &&
                prefix.map((item, index) => {
                    return (
                        <StyledBreadcrumb
                            key={index}
                            component="a"
                            href="#"
                            label={item}
                            //onClick={onClick}
                            //icon={<ExpandMoreIcon fontSize="small" />}
                        />
                    );
                })
                }
            </Breadcrumbs>
        </div>
    );
}