interface SearchBarProps {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const SearchBar = ({ onChange }:SearchBarProps) => {
    return (
        <div className="flex items-center">
            <input
                type="text"
                className="bg-transparent rounded px-4 py-2.5 focus:outline-none border-0 h-full font-medium"
                placeholder="Search"
                onChange={onChange}
            />
        </div>

    )
}

export default SearchBar;