import { createSlice } from '@reduxjs/toolkit'
import { getUsers, getUserReport, searchUser, getUser, getOrders, getOrderReport } from '../../http/Apis'


export const STATUS = Object.freeze({
    SECCESS: 'secces',
    FAILED: 'failed',
    LOADING: 'loading',
})

const initialState = {
    data: false,
    status: STATUS.SECCESS,
    bulkAction: 'none',
    bulkActionCall: false,
    singleOrder: false,
    userReport: false,
}

export const OrdrsSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        setOrders: (state, actions) => {
            const { data } = actions.payload
            state.data = data.items
        },
        setStatus: (state, actions) => {
            state.status = actions.payload
        },
        setBulkAction: (state, actions) => {
            state.bulkAction = actions.payload
        },
        setBulkActionCall: (state, actions) => {
            state.bulkActionCall = actions.payload
        },
        setSingleOrder: (state, actions) => {
            const { data } = actions.payload
            state.singleOrder = data
        },
        setUserReport: (state, actions) => {
            const { data } = actions.payload
            state.userReport = data
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    setOrders,
    setStatus,
    setBulkAction,
    setBulkActionCall,
    setSingleOrder,
    setUserReport
} = OrdrsSlice.actions

export default OrdrsSlice.reducer

// Thunks
export function fetchOrders(page = 1, limit = 5, order = 'desc') {
    return async function fetchOrdersThunk(dispatch) {
        dispatch(setStatus(STATUS.LOADING))
        try {
            const res = await getOrders(page, limit, order);
            dispatch(setOrders(res.data))
            dispatch(setStatus(STATUS.SECCESS))
        } catch (error) {
            console.log(error)
            dispatch(setStatus(STATUS.FAILED))
        }
    }
}
// fetch Reports
export function fetchUserReport() {
    return async function fetchUserReportThunk(dispatch) {
        dispatch(setStatus(STATUS.LOADING))
        try {
            const res = await getUserReport();
            dispatch(setUserReport(res.data))
            dispatch(setStatus(STATUS.SECCESS))
        } catch (error) {
            console.log(error)
            dispatch(setStatus(STATUS.FAILED))
        }
    }
}

export function fetchSearchUsers(search) {
    return async function fetchSearchUsersThunk(dispatch) {
        dispatch(setStatus(STATUS.LOADING))
        try {
            const res = await searchUser(search);
            dispatch(setOrders(res.data))
            dispatch(setStatus(STATUS.SECCESS))
        } catch (error) {
            console.log(error)
            dispatch(setStatus(STATUS.FAILED))
        }
    }
}

export function fetchOrder(id) {
    return async function fetchOrderThunk(dispatch) {
        dispatch(setStatus(STATUS.LOADING))
        try {
            const res = await getOrderReport(id);
            dispatch(setSingleOrder(res.data))
            dispatch(setStatus(STATUS.SECCESS))
        } catch (error) {
            console.log(error)
            dispatch(setStatus(STATUS.FAILED))
        }
    }
}

