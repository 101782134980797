import React from 'react'

const EditSlider = () => {
  return (
    <div>EditSlider</div>
  )
}

export default EditSlider
// import React from 'react'
// import Layout from '../../components/Layout/Layout'
// import { Button, Input, Title } from '../../tailwind'
// import toast from 'react-hot-toast'
// import { createGenre, updateCategory, updateGenre } from '../../http/Apis'
// import { useParams } from 'react-router-dom'
// import { useSelector, useDispatch } from 'react-redux'
// import { useEffect } from 'react'
// import { fetchGenre } from '../../redux/slice​/genreSlice'
// import { fetchCategory } from '../../redux/slice​/categorySlice'

// const EditSlider = () => {
//     const { id } = useParams()
//     const dispatch = useDispatch()
//     const { single } = useSelector(state => state.category)

//     const [loading, setLoading] = React.useState(false)

//     const [categoryData, setCategoryData] = React.useState({
//         name: '',
//         order: '',
//         description: '',
//     })

//     useEffect(() => {
//         dispatch(fetchCategory(id))
//     }, [])

//     useEffect(() => {
//         setCategoryData({
//             name: single.name,
//             order: single.order,
//             description: single.description,
//         })
//     }, [single])


//     const handleSubmit = async () => {
//         setLoading(true)
//         try {
//             const res = await updateCategory(id, categoryData)
//             if (res.status === 200) {
//                 toast.success('Category update successfully')
//                 setLoading(false)
//             }
//         } catch (error) {
//             toast.error(error.response.data.message)
//             setLoading(false)
//         }
//     }

//     return (
//         <Layout>
//             <Title className="text-white font-medium text-xl mb-5">Edit Slider</Title>

//             <div className='flex flex-col gap-4'>
//                 <Input
//                     type="text"
//                     name="name"
//                     placeholder="Name"
//                     className="w-full"
//                     value={categoryData.name}
//                     onChange={(e) => setCategoryData({ ...categoryData, name: e.target.value })}
//                 />
//                 <Input
//                     type="number"
//                     name="order"
//                     placeholder="Order"
//                     className="w-full"
//                     value={categoryData.order}
//                     onChange={(e) => setCategoryData({ ...categoryData, order: e.target.value })}
//                 />
//                 <Input
//                     type="textarea"
//                     inputType='textarea'
//                     name="description"
//                     placeholder="Description"
//                     className="w-full"
//                     rows='6'
//                     defaultValue={categoryData.description}
//                     onChange={(e) => setCategoryData({ ...categoryData, description: e.target.value })}
//                 />

//             </div>
//             <div className="flex justify-end mt-5">
//                 <Button
//                     onClick={() => handleSubmit()}
//                     title='Submit'
//                     width='w-32'
//                     className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
//                     loading={loading}
//                 />
//             </div>
//         </Layout>
//     )
// }

// export default EditSlider