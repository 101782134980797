import React from 'react'
import Layout from '../../components/Layout/Layout'
import { Button, Input, Title } from '../../tailwind'
import toast from 'react-hot-toast'
import { createGenreApi } from '../../http/Apis'

const AddNewGenre = () => {
    const [loading, setLoading] = React.useState(false)

    const [genreData, setGenreData] = React.useState({
        name: '',
        slug: '',
        description: '',
    })

    const setNameWithSlug = (name) => {
        setGenreData({
            ...genreData,
            name,
            slug: name.toLowerCase().replace(/ /g, '-')
        })
    }


    const handleSubmit = async () => {
        setLoading(true)
        try {
            const res = await createGenreApi(genreData)
            if (res.status === 201) {
                toast.success('Genre created successfully')
                setLoading(false)
            }
        } catch (error) {
            toast.error(error.response.data.message)
            setLoading(false)
        }
    }

    return (
        <Layout>
            <Title className="text-white font-medium text-xl mb-5 px-5">Add a Genre</Title>

            <div className='flex flex-col gap-4 px-5'>
                <Input
                    type="text"
                    name="name"
                    placeholder="Name"
                    className="w-full bg-transparent border border-gray-700"
                    value={genreData.name}
                    onChange={(e) => setNameWithSlug(e.target.value)}
                />
                <Input
                    type="textarea"
                    inputType='textarea'
                    name="description"
                    placeholder="Description"
                    className="w-full bg-transparent border border-gray-700"
                    rows='6'
                    defaultValue={genreData.description}
                    onChange={(e) => setGenreData({ ...genreData, description: e.target.value })}
                />

            </div>
            <div className="flex justify-end mt-5 px-5">
                <Button
                    onClick={() => handleSubmit()}
                    title='Submit'
                    width='w-32'
                    className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
                    loading={loading}
                />
            </div>
        </Layout>
    )
}

export default AddNewGenre