import React from 'react';
import ReactPlayer from 'react-player'

export const VideoPlayer = ({url}) => {
    return (
        <di className='w-full'>
            <ReactPlayer
                url={url}
                controls={true}
                width='100%'
                height='100%'
            />
        </di>
    );
}

export default VideoPlayer;