import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layout/Layout'
import { Title } from '../../tailwind'
import toast from 'react-hot-toast'
import { createSection, getCategories } from '../../http/Apis'
import { useDispatch, useSelector } from 'react-redux'
import { fetchContent } from '../../redux/slice​/contentSliceold'
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { StrictModeDroppable } from "../../components/Droppable/StrictModeDroppable";
import { useDebounce } from 'use-debounce'
import { fetchAllContant } from '../../redux/slice​/contentSlice'
import qs from 'qs'
import { RootState } from '../../redux/store'
import { TextField } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import SaveIcon from '@mui/icons-material/Save';

const AddNewSection = () => {
    const dispatch = useDispatch()
    const { content } = useSelector((state: RootState) => state.content)
    const [loading, setLoading] = React.useState(false)
    const [search, setSearch] = useState<string>();
    const [value] = useDebounce(search, 1000);
    interface ISearchQuery {
        page: number;
        limit: number;
        sort: string;
        keyword?: string | undefined;
    }
    const [searchQueryString, setSearchQueryString] = useState<ISearchQuery>({
        page: 1,
        limit: 10,
        sort: 'desc',
        keyword: ''
    });

    const queryString = qs.stringify({
        page: 1,
        limit: 25,
        sort: "desc",
    });

    const queryStringWithKeyword = qs.stringify({
        page: searchQueryString.page,
        limit: searchQueryString.limit,
        sort: searchQueryString.sort,
        keyword: searchQueryString.keyword
    });

    useEffect(() => {
        // push kayword to queryString
        setSearchQueryString({ ...searchQueryString, keyword: value })
    }, [search]);

    useEffect(() => {
        if (value) {
            if (searchQueryString.keyword) {
                dispatch(fetchAllContant(queryStringWithKeyword, null) as any);
            }
        } else {
            dispatch(fetchAllContant(queryString, null) as any);
        }
    }, [value]);

    interface ISectionData {
        title: string;
        content: any[];
        category: any;
    }

    const [sectionData, setSectionData] = React.useState<ISectionData>({
        title: '',
        content: [],
        category: '',
    })

    const [categories, setCategories] = React.useState([])

    const fetchCategories = async () => {
        const { data } = await getCategories();
        setCategories(data.data)
    };
    const [contentType, setContentType] = React.useState('movie')

    useEffect(() => {
        fetchCategories()
        dispatch(fetchContent(`type=${contentType}`) as any)
    }, [contentType])

    const [sectionList, setSectionList] = useState<any[]>([]);
    const [sectionListDrag, setSectionListDrag] = useState<any[]>([]);

    const handleSubmit = async () => {
        const fromData = new FormData()
        if (sectionData.title.length < 1) {
            toast.error('Please enter title')
            return;
        }
        fromData.append('title', sectionData.title)
        // category
        if (sectionData.category.length > 0) {
            for (let key of sectionData.category.entries()) {
                fromData.append('category', key[1].id);
            }
        }
        // contentID
        if (sectionList.length > 0) {
            for (let key of sectionList?.entries() as any) {
                fromData.append('content', key[1]);
            }
        }

        setLoading(true)
        try {
            const res = await createSection(fromData)
            if (res.status === 201) {
                toast.success('Section created successfully')
                setLoading(false)
            }
        } catch (error: any) {
            toast.error(error.response.data.error.message || 'Something went wrong')
            setLoading(false)
        }
    }


    function handleOnDragEnd(result: any) {
        //console.log("🚀 ~ file: AddNewSection.jsx ~ line 90 ~ handleOnDragEnd ~ result", result)
        if (!result.destination) return;

        const { source, destination } = result;
        // result push to setSectionList if result.destination.droppableId === 'sectionList'
        if (destination.droppableId === 'sectionList') {
            // if result.draggableId includes already in sectionList then return
            if (sectionList.includes(result.draggableId as never)) {
                toast.error('Already added in section list')
                return;
            }
            // draggableId more then 25 character then return
            if (result.draggableId.length > 25) {
                // now reodering sectionList
                const items = Array.from(sectionList);
                const [reorderedItem] = items.splice(source.index, 1);
                items.splice(destination.index, 0, reorderedItem);
                setSectionList(items);
                // now reodering sectionListDrag
                const itemsDrag = Array.from(sectionListDrag);
                const [reorderedItemDrag] = itemsDrag.splice(source.index, 1);
                itemsDrag.splice(destination.index, 0, reorderedItemDrag);
                setSectionListDrag(itemsDrag);
                return;
            }
            // else push to sectionList
            setSectionList([...sectionList, result.draggableId])
            // find sectionDataDrag by result.draggableId and push to sectionListDrag 
            const findSectionDataDrag = content.find((item) => {
                //console.log("🚀 ~ file: AddNewSection.jsx ~ line 96 ~ findSectionDataDrag ~ item", item)
                return item._id === result.draggableId
            })
            // push new sectionListDrag in first index
            setSectionListDrag([findSectionDataDrag, ...sectionListDrag])

            //setSectionListDrag([...sectionListDrag, findSectionDataDrag])

        }
        // if result.destination.droppableId === 'sectionContent' then remove from sectionList
        if (destination.droppableId === 'sectionContent') {
            // remove from sectionList
            const newSectionList = sectionListDrag.filter((item) => {
                return item.thumbnail !== result.draggableId
            })
            setSectionList(newSectionList)
            // remove from sectionListDrag
            const newSectionListDrag = sectionListDrag.filter((item) => {
                return item.thumbnail !== result.draggableId
            })
            setSectionListDrag(newSectionListDrag)
        }

        // const items = Array.from(sectionDataDrag);
        // const [reorderedItem] = items.splice(result.source.index, 1);
        // items.splice(result.destination.index, 0, reorderedItem);
        // updateSectionDataDrag(items);
    }

    return (

        <Layout>
            <Title className="font-medium text-xl pr-5 opacity-80">Add a Section</Title>
            <div className='grid grid-cols-2 gap-4'>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <StrictModeDroppable droppableId="sectionList" type="CONTENT">
                        {(provided: any) => (
                            <div className='pr-5'>
                                <div className='flex flex-col gap-4'>
                                    <TextField
                                        id="section-title"
                                        label="Section Title"
                                        variant="outlined"
                                        placeholder='Enter section title'
                                        value={sectionData.title}
                                        sx={{ mt: 2 }}
                                        onChange={(e: any) => setSectionData({ ...sectionData, title: e.target.value })}
                                    />
                                    <div className='w-full h-[500px] overflow-scroll rounded'>
                                        <div className="w-full" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                            {sectionListDrag.map((item, index) => (
                                                <Draggable key={item.thumbnail} draggableId={item.thumbnail} index={index}>
                                                    {(provided) => (
                                                        <div className="w-full" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                            <div className='flex gap-4 my-2 items-start'>
                                                                <img
                                                                    src={`${item.thumbnail}?w=248&fit=crop&auto=format`}
                                                                    srcSet={`${item.thumbnail}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                                                    alt={item.title || item.name}
                                                                    loading="lazy"
                                                                    className='w-36 aspect-video rounded'
                                                                />
                                                                <div>
                                                                    <p className='line-clamp-1'>{item.title || item.name}</p>
                                                                    <p className='text-gray-500 line-clamp-2'>{
                                                                    item.description.length > 80 ? item.description.substring(0, 80) + '...' : item.description
                                                                }</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {
                                                sectionList.length === 0 && <div className='flex justify-center items-center h-[500px]'>
                                                    <p className='w-[80%] text-center'>
                                                        Right side is the section content and left side is the content list to add in the section content. You can drag and drop the content from left side to right side.
                                                    </p>
                                                </div>
                                            }
                                            {provided.placeholder}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-end mt-5">
                                    <LoadingButton
                                        onClick={() => handleSubmit()}
                                        loading={loading}
                                        disabled={loading}
                                        loadingPosition="start"
                                        startIcon={<SaveIcon />}
                                        variant='contained'>
                                        Create
                                    </LoadingButton>
                                </div>
                            </div>
                        )}
                    </StrictModeDroppable>
                    <StrictModeDroppable droppableId="sectionContent" type="CONTENT">
                        {(provided: any) => (
                            <div className='pr-5 w-full'>
                                <div className='w-full min-h-screen overflow-auto'>
                                    <TextField
                                        label="Search"
                                        variant="outlined"
                                        placeholder='Search content here'
                                        value={search}
                                        sx={{ width: '100%', mt: 2, }}
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                    
                                
                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                        {content && content.map((item, index) => (
                                            <Draggable key={index} draggableId={item._id} index={index}>
                                                {(provided) => (
                                                    <div className="w-full" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                        <div className='flex gap-4 my-2'>
                                                            <img
                                                                src={`${item.thumbnail}`}
                                                                alt={item.name}
                                                                loading="lazy"
                                                                className='w-36 aspect-video rounded'
                                                            />
                                                            <div>
                                                                <p className='line-clamp-1'>{item.name}</p>
                                                                <p className='text-gray-500 line-clamp-2'>{
                                                                    item.description.length > 80 ? item.description.substring(0, 80) + '...' : item.description
                                                                }</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>

                                </div>
                            </div>
                        )}
                    </StrictModeDroppable>

                </DragDropContext>
            </div>
        </Layout>

    )
}

export default AddNewSection