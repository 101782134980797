import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { getSection, getSections, sectionReport, sectionSearch } from '../../http/Apis'


export interface ISecctionInitialState {
    data: {
        _id: string,
        title: string,
        contant: [],
        isPublished: boolean,
        createdAt: string,
        updatedAt: string,
        order: number
    }[],
    status: 'idle' | 'loading' | 'succeeded' | 'failed',
    bulkAction: string,
    bulkActionCall: boolean,
    single: {
        _id: string,
        title: string,
        category: string,
        content: {
            _id: string,
            name: string,
            description: string,
            thumbnail: string,
            poster: string,
        }[],
        isPublished: boolean,
        createdAt: string,
        updatedAt: string,
        order: number
    },
    report: {
        total: number,
        Active: number,
        Deactivate: number,
    }
}

const initialState: ISecctionInitialState = {
    data: [],
    status: 'idle',
    bulkAction: 'none',
    bulkActionCall: false,
    single: {
        _id: '',
        title: '',
        category: '',
        content: [],
        isPublished: false,
        createdAt: '',
        updatedAt: '',
        order: 0
    },
    report: {
        total: 0,
        Active: 0,
        Deactivate: 0,
    },
}

export const sectionSlice = createSlice({
    name: 'section',
    initialState,
    reducers: {
        setSections: (state, actions) => {
            const { data } = actions.payload
            state.data = data
        },
        setStatus: (state, actions: PayloadAction<ISecctionInitialState['status']>) => {
            state.status = actions.payload
        },
        setBulkAction: (state, actions) => {
            state.bulkAction = actions.payload
        },
        setBulkActionCall: (state, actions) => {
            state.bulkActionCall = actions.payload
        },
        setSingle: (state, actions) => {
            const { data } = actions.payload
            state.single = data
        },
        setReport: (state, actions) => {
            const { data } = actions.payload
            state.report = data
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    setSections,
    setStatus,
    setBulkAction,
    setBulkActionCall,
    setSingle,
    setReport
} = sectionSlice.actions

export default sectionSlice.reducer

// Thunks
export function fetchSections(page = 1, limit = 5, order = 'desc') {
    return async (dispatch: any) => {
        dispatch(setStatus('loading'))
        try {
            const res = await getSections(page, limit, order);
            dispatch(setSections(res.data))
            dispatch(setStatus('succeeded'))
        } catch (error) {
            console.log(error)
            dispatch(setStatus('failed'))
        }
    }
}
// fetch Reports
export function fetchSectionReport() {
    return async (dispatch: any) => {
        dispatch(setStatus('loading'))
        try {
            const res = await sectionReport();
            dispatch(setReport(res.data))
            dispatch(setStatus('succeeded'))
        } catch (error) {
            console.log(error)
            dispatch(setStatus('failed'))
        }
    }
}

export function fetchSearchSection(search: string) {
    return async (dispatch: any) => {
        dispatch(setStatus('loading'))
        try {
            const res = await sectionSearch(search);
            dispatch(setSections(res.data))
            dispatch(setStatus('succeeded'))
        } catch (error) {
            console.log(error)
            dispatch(setStatus('failed'))
        }
    }
}

export function fetchSection(id: string ) {
    return async (dispatch: any) => {
        dispatch(setStatus('loading'))
        try {
            const res = await getSection(id);
            dispatch(setSingle(res.data))
            dispatch(setStatus('succeeded'))
        } catch (error) {
            console.log(error)
            dispatch(setStatus('failed'))
        }
    }
}

