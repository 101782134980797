import { useEffect, useState } from "react"
import { DeleteIcon, EditIcon, ReStore, VisibilityIcon, VisibilityOffIcon } from "../Icon"
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { themeDelete, themeRestore, themeStatus } from "../../http/Apis";
import { fetchTheme, setBulkActionCall } from "../../redux/slice​/themesSlice";

const ThemeCard = ({ Data }) => {
    const dispatch = useDispatch();
    const [allSelectArray, setallSelectArray] = useState([])
    const { bulkAction, bulkActionCall } = useSelector(state => state.themes);
    const [singleSelect, setSingleSelect] = useState([])
    const [status, setStatus] = useState(false)
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 8,
    })
    // handleStatus
    const handleStatus = async () => {
        const data = {
            isPublished: status,
            id: singleSelect,
        }
        try {
            const response = await themeStatus(data)
            if (response.status === 200) {
                toast.success('Status updated successfully')
                dispatch(fetchTheme(pagination.page, pagination.limit))
            }
        } catch (error) {
            toast.error('Something went wrong')
        }
    }
    // handle Delete
    const handleDelete = async () => {
        try {
            const response = await themeDelete({ id: singleSelect })
            if (response.status === 200) {
                toast.success('Theme deleted successfully')
                dispatch(fetchTheme(pagination.page, pagination.limit))
            }
        }
        catch (error) {
            toast.error('Something went wrong')
        }
    }
    // restore
    const handleRestore = async () => {
        try {
            const response = await themeRestore({ id: singleSelect })
            if (response.status === 200) {
                toast.success('Theme restored successfully')
                dispatch(fetchTheme(pagination.page, pagination.limit))
            }
        } catch (error) {
            toast.error('Something went wrong')
        }
    }

    // headle checkbox
    const headleCheckbox = (e) => {
        const { name: id, checked } = e.target;
        if (id === 'selectAll') {
            if (checked) {
                setallSelectArray(Data.map(user => user.id))

            } else {
                setallSelectArray([])
            }
        } else {
            setallSelectArray(prev => {
                if (checked) {
                    return [...prev, id]
                } else {
                    return prev.filter(item => item !== id)
                }
            })
        }

    }

    useEffect(() => {
        if (bulkActionCall) {
            headleBulkActions()
            dispatch(setBulkActionCall(false))
        }
    }, [bulkActionCall])


    // headle bulk actions
    const headleBulkActions = async () => {
        const formData = new FormData();
        if (allSelectArray.length < 1) {
            formData.append('id', allSelectArray)
        } else {
            for (let i = 0; i < allSelectArray.length; i++) {
                formData.append('id', allSelectArray[i])
            }
        }
        const newFormData = allSelectArray.length > 0 ? { id: allSelectArray } : formData

        if (bulkAction === 'none') {
            toast.error("Please select bulk action")
        }
        if (bulkAction === 'Delete') {
            if (allSelectArray.length === 0) {
                toast.error("Please select theme")
            } else {
                try {
                    const res = await themeDelete(newFormData);
                    if (res.status === 200) {
                        toast.success('Theme deleted successfully')
                        dispatch(fetchTheme(pagination.page, pagination.limit))
                    }
                } catch (e) {
                    toast.error("Something went wrong")
                }
            }
        }
        if (bulkAction === 'Deactivate') {
            if (allSelectArray.length === 0) {
                toast.error("Please select theme")
            } else {
                const data = {
                    isPublished: false,
                    id: allSelectArray
                }
                try {
                    const res = await themeStatus(data);
                    if (res.status === 200) {
                        dispatch(fetchTheme(pagination.page, pagination.limit))
                        toast.success("Status updated")

                    }
                } catch (e) {
                    toast.error("Something went wrong")
                }
            }
        }
        if (bulkAction === 'Activate') {
            if (allSelectArray.length === 0) {
                toast.error("Please select theme")
            } else {
                const data = {
                    isPublished: true,
                    id: allSelectArray
                }
                try {
                    const res = await themeStatus(data);
                    if (res.status === 200) {
                        dispatch(fetchTheme(pagination.page, pagination.limit))
                        toast.success("Status updated")

                    }
                } catch (e) {
                    toast.error("Something went wrong")
                }
            }
        }
        if (bulkAction === 'Restore') {
            if (allSelectArray.length === 0) {
                toast.error("Please select Theme")
            }
            try {
                const response = await themeRestore(newFormData)
                if (response.status === 200) {
                    toast.success('Theme restored successfully')
                    dispatch(fetchTheme(pagination.page, pagination.limit))
                }
            } catch (error) {
                toast.error('Something went wrong')
            }
        }
    }

    return (
        <>
            <div className="tooltip tooltip-right" data-tip="Select All">
                <label className="flex gap-3 items-center">
                    <span className="label-text">Select All</span>
                    <input
                        type="checkbox"
                        className="checkbox checkbox-secondary -mt-1"
                        name="selectAll"
                        checked={allSelectArray.length === Data.length}
                        onChange={headleCheckbox}
                    />
                </label>
            </div>
            <div className="grid grid-cols-4 gap-5 my-5">
                {
                    Data.length > 0 && Data.map((theme, index) => {
                        return (
                            <div key={index} className="relative">
                                <div className="form-control absolute top-1 right-0.5">
                                    <label className="cursor-pointer label">
                                        <input
                                            type="checkbox"
                                            className="checkbox checkbox-secondary"
                                            name={theme.id}
                                            onChange={headleCheckbox}
                                            checked={allSelectArray.includes(theme.id)}
                                        />
                                    </label>
                                </div>
                                <div className="bg-secondary-color h-fit rounded-xl shadow-lg overflow-hidden col-span-1">
                                    <div>
                                        <img className="w-full object-cover h-56" src={theme.thumbnail} />
                                    </div>
                                    <div className="flex justify-between py-2 px-3">
                                        <div>
                                            <h1 className="capitalize">{theme.name}</h1>
                                            <span className="text-sm">{theme.price ? '₹ ' + theme.price : 'Free'}</span>
                                        </div>
                                        <div className="flex">
                                            <div className="cursor-pointer">
                                                <div className="tooltip" data-tip="Status">
                                                    <label htmlFor="theme-status" onClick={() => {
                                                        setSingleSelect([theme.id])
                                                        setStatus(theme.isPublished ? false : true)
                                                    }} className="cursor-pointer">  {
                                                            theme.isPublished ? <VisibilityIcon
                                                                width={45}
                                                                height={45}
                                                                backgroundColor="#22C55E"
                                                            /> : <VisibilityOffIcon
                                                                width={45}
                                                                height={45}
                                                                backgroundColor="#B80041"
                                                            />
                                                        }
                                                    </label>
                                                </div>

                                            </div>
                                            <div className="cursor-pointer">
                                                <div className="tooltip" data-tip="Edit">
                                                    <Link to={`/admin/themes/edit/${theme.id}`}>
                                                        <EditIcon
                                                            width={45}
                                                            height={45}
                                                            backgroundColor="#F1A542"
                                                        />
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="cursor-pointer">
                                                {
                                                    !theme.isDeleted && <div className="tooltip" data-tip="Delete">
                                                        <label htmlFor="theme-delete" onClick={() => {
                                                            setSingleSelect([theme.id])
                                                        }}
                                                            className="cursor-pointer">
                                                            <DeleteIcon width={45} height={45} backgroundColor="#B80041" />
                                                        </label>
                                                    </div>
                                                }
                                                {
                                                    theme.isDeleted && <div className="tooltip" data-tip="Restore">
                                                        <label htmlFor="theme-restore" onClick={() => {
                                                            setSingleSelect([theme.id])
                                                        }}
                                                            className="cursor-pointer">
                                                            <ReStore width={45} height={45} backgroundColor="#22C55E" />
                                                        </label>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        )
                    })
                }

            </div>

            {/* Status Update Code */}
            <input type="checkbox" id="theme-status" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box relative">
                    <label htmlFor="theme-status" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                    <h3 className="font-bold text-lg">Status</h3>
                    <p className="py-2">Are you sure you want to chance status?</p>
                    <div className="modal-action flex items-center justify-between">
                        <label htmlFor="theme-status" onClick={handleStatus} className="btn w-1/2 bg-red-800 hover:bg-red-900">Yes</label>
                        <label htmlFor="theme-status" className="btn w-1/2 bg-secondary-color hover:bg-primary-color">Cancel</label>
                    </div>
                </div>
            </div>

            {/* Delete Code */}
            <input type="checkbox" id="theme-delete" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box relative">
                    <label htmlFor="theme-delete" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                    <h3 className="font-bold text-lg">Delete</h3>
                    <p className="py-2">Are you sure you want to delete this theme?</p>
                    <div className="modal-action flex items-center justify-between">
                        <label htmlFor="theme-delete" onClick={handleDelete} className="btn w-1/2 bg-red-800 hover:bg-red-900">Yes</label>
                        <label htmlFor="theme-delete" className="btn w-1/2 bg-secondary-color hover:bg-primary-color">Cancel</label>
                    </div>
                </div>
            </div>

            {/* Restore Code */}
            <input type="checkbox" id="theme-restore" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box relative">
                    <label htmlFor="theme-restore" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                    <h3 className="font-bold text-lg">Restore</h3>
                    <p className="py-2">Are you sure you want to restore this theme?</p>
                    <div className="modal-action flex items-center justify-between">
                        <label htmlFor="theme-restore" onClick={handleRestore} className="btn w-1/2 bg-red-800 hover:bg-red-900">Yes</label>
                        <label htmlFor="theme-restore" className="btn w-1/2 bg-secondary-color hover:bg-primary-color">Cancel</label>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ThemeCard;