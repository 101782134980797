import Layout from "../../components/Layout/Layout";
import { Button, CategoryTable, GenreTable, SliderTable } from "../../tailwind";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDebounce } from "use-debounce";
import SearchBar from "../../components/SearchBar";
import { Link } from "react-router-dom";
import { fetchSearchSlider, fetchSliderReport, fetchSliders, setBulkAction, setBulkActionCall } from "../../redux/slice​/sliderSlice";

const Sliders = () => {
    const { data, report } = useSelector(state => state.slider);
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [itemView, setItemView] = useState(5);
    const [search, setSearch] = useState();
    const [value] = useDebounce(search, 1000);

    useEffect(() => {
        if (value) {
            dispatch(fetchSearchSlider(value));
        }
    }, [value]);

    const [pagination, setPagination] = useState({
        page: 1,
        limit: 5,
    })

    useEffect(() => {
        dispatch(fetchSliders(pagination.page, pagination.limit));
        dispatch(fetchSliderReport())
    }, [pagination]);


    const headlePrev = () => {
        if (pagination.page > 1) {
            setPagination({
                ...pagination,
                page: pagination.page - 1
            })
        } else {
            toast.error("You are on first page")
        }
    }

    const handleNext = () => {
        if (pagination.page < Math.ceil(report.total / pagination.limit)) {
            setPagination({
                ...pagination,
                page: pagination.page + 1
            })
        } else {
            toast.error("No more pages")
        }
    }
    return (
        <Layout>
            <div className='flex justify-between items-center'>
                <div className='flex items-center gap-4'>
                    <div>
                        <h1 className='text-white font-medium text-xl'>Sliders</h1>
                        <span className='text-white opacity-50 text-xs tracking-wider'>Your all sliders list.</span>
                    </div>
                    <select
                        className="select max-w-xs bg-secondary-color"
                        defaultValue={'none'}
                        onChange={(e) => dispatch(setBulkAction(e.target.value))}>
                        <option value="none">Bulk Actions</option>
                        <option value='Delete'>Delete</option>
                        <option value='UnPublish'>UnPublish</option>
                        <option value='Publish'>Publish</option>
                    </select>
                    <span onClick={() => dispatch(setBulkActionCall(true))} className="bg-secondary-color py-3.5 px-6 rounded-md shadow-md text-sm cursor-pointer hover:bg-secondary-blue duration-100 text-white hover:text-white" >Apply</span>
                    <div className="tooltip" data-tip="Show Limit">
                        <select className="select max-w-xs bg-secondary-color"
                            defaultValue={itemView}
                            onChange={(e) => {
                                setItemView(e.target.value)
                                dispatch(fetchSliders(pagination.page, e.target.value));
                            }}>
                            <option value="5">Show {itemView}</option>
                            <option value="10">Show 10</option>
                            <option value="15">Show 15</option>
                            <option value="20">Show 20</option>
                            <option value="25">Show 25</option>
                        </select>
                    </div>
                </div>
                <div className='flex gap-2 items-center'>
                    <SearchBar
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    <Link to='/admin/slider/add'>
                        <Button
                            title={'+ Add New'}
                            titleStyle={'text-white font-medium'}
                            height={'h-11'}
                        />
                    </Link>
                </div>
            </div>
            <div className="my-5">
                <SliderTable data={data} />
            </div>
            <div className='flex justify-between items-center my-4'>
                {
                    data.length > 0 && <div className="stats stats-vertical lg:stats-horizontal shadow bg-secondary-color rounded-md">
                        <div className="stat flex py-3">
                            <div className="stat-title">Total Slider</div>
                            <div className="">{report.total}</div>
                        </div>
                        <div className="stat flex py-3">
                            <div className="stat-title">Deactivate</div>
                            <div className="">
                                {report.Deactivate}
                            </div>
                        </div>
                        <div className="stat flex py-3">
                            <div className="stat-title">Active</div>
                            <div className="">{
                                report.Active
                            }</div>
                        </div>
                    </div>
                }
                <div>
                    <div className="btn-group">
                        <button className="btn bg-secondary-color" onClick={headlePrev}>«</button>
                        <button className="btn bg-secondary-color-800">Page {pagination.page}</button>
                        <button className="btn bg-secondary-color" onClick={handleNext} >»</button>
                    </div>
                </div>
            </div>
        </Layout >
    )
}

export default Sliders;