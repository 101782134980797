import { useState } from 'react';
import Chart from 'react-apexcharts'
import { useSelector } from 'react-redux';
import { TailwindBox } from "../../../../../tailwind"

const RevenueAnalytics = () => {
    const { revenue } = useSelector(state => state.analytics);
    const [series, setSeries] = useState([0, revenue, 0]);
    const [options, setOptions] = useState({
        chart: {
            width: '100%',
            type: 'pie',
            foreColor: '#fff'
        },
        labels: ['AVOD', 'SVOD', 'TVOD'],
        colors: ['#f44336', '#008FFB', '#2196f3'],
        legend: {
            position: 'bottom',
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    });
    return (
        <TailwindBox title="Revenue">
                <p className="text-xs opacity-80 mt-2">
                    Last 30 days:
                </p>
                <div className='w-full'>
                    <Chart
                        series={series}
                        options={options}
                        type="pie"
                        width={'100%'}
                    />
                </div>
        </TailwindBox>
    )
}

export default RevenueAnalytics