import React from 'react';
import Base64 from 'base-64';


import { Hr } from '../../../components';
import Layout from '../../../components/Layout/Layout';
import { bytesToSize, secondsToDhms } from '../../../utils/Utils';



const RtmpProfile = () => {
    const [data, setData] = React.useState(false);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        fetch(`${process.env.REACT_APP_RTMP_SERVER_URL}/api/server`, {
            method: 'GET',
            headers: {
                "Authorization": "Basic " + Base64.encode(`${'admin'}:${'nms2018'}`),
                "Content-Type": "application/json"
            }

        })
            .then(response => response.json())
            .then(data => {
                // Read total count from server
                let osInfo = { key: 0, name: 'OS', value: data.os.arch + "_" + data.os.platform + "_" + data.os.release };
                let cpuInfo = { key: 1, name: "CPU", value: data.cpu.num + " x " + data.cpu.model };
                let memInfo = { key: 2, name: "Memory", value: bytesToSize(data.mem.totle) };
                let nodeInfo = { key: 3, name: "Node.js", value: data.nodejs.version };
                let uptimeInfo = { key: 4, name: "Uptime", value: secondsToDhms(data.nodejs.uptime) };
                let versionInfo = { key: 5, name: "Version", value: data.version };
                setData({ osInfo, cpuInfo, memInfo, nodeInfo, uptimeInfo, versionInfo });
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            });
    }, []);
    return (
        <Layout>
            <div className="px-5">
                <h1 className="text-2xl text-white opacity-80 font-semibold tracking-wide">
                    Server Info
                </h1>
                <p className="mt-2 text-white opacity-70">
                    Server information and statistics.
                </p>
            </div>
            <div className="my-5">
                <Hr />
            </div>
            <div className="px-5">
                {
                    loading ? (
                        <div className="flex justify-center items-center">
                            <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6 mb-4"></div>
                        </div>
                    ) : <>
                        {
                            data ? <div className="grid grid-cols-2 gap-4">
                                <div className="bg-dark-color-box rounded-lg p-5">
                                    <h1 className="text-white text-lg font-semibold">
                                        {data.osInfo.name}
                                    </h1>
                                    <p className="text-white opacity-80 mt-2">
                                        {data.osInfo.value}
                                    </p>
                                </div>
                                <div className="bg-dark-color-box rounded-lg p-5">
                                    <h1 className="text-white text-lg font-semibold">
                                        {data.cpuInfo.name}
                                    </h1>
                                    <p className="text-white opacity-80 mt-2">
                                        {data.cpuInfo.value}
                                    </p>
                                </div>
                                <div className="bg-dark-color-box rounded-lg p-5">
                                    <h1 className="text-white text-lg font-semibold">
                                        {data.memInfo.name}
                                    </h1>
                                    <p className="text-white opacity-80 mt-2">
                                        {data.memInfo.value}
                                    </p>
                                </div>
                                <div className="bg-dark-color-box rounded-lg p-5">
                                    <h1 className="text-white text-lg font-semibold">
                                        {data.nodeInfo.name}
                                    </h1>
                                    <p className="text-white opacity-80 mt-2">
                                        {data.nodeInfo.value}
                                    </p>
                                </div>
                                <div className="bg-dark-color-box rounded-lg p-5">
                                    <h1 className="text-white text-lg font-semibold">
                                        {data.uptimeInfo.name}
                                    </h1>
                                    <p className="text-white opacity-80 mt-2">
                                        {data.uptimeInfo.value}
                                    </p>
                                </div>
                                <div className="bg-dark-color-box rounded-lg p-5">
                                    <h1 className="text-white text-lg font-semibold">
                                        {data.versionInfo.name}
                                    </h1>
                                    <p className="text-white opacity-80 mt-2">
                                        {data.versionInfo.value}
                                    </p>
                                </div>

                            </div> : <div className="flex justify-center items-center">
                                <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6 mb-4"></div>
                            </div>

                        }
                    </>
                }

            </div>
        </Layout>
    );

}

export default RtmpProfile;