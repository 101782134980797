import { useEffect, useState } from "react"
import { DeleteIcon, EditIcon, VisibilityIcon, VisibilityOffIcon } from "../Icon"
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { sliderDelete, sliderStatus } from "../../http/Apis";
import { fetchSliders, setBulkActionCall } from "../../redux/slice​/sliderSlice";

const SliderTable = ({ data }) => {
    const dispatch = useDispatch();
    const [allSelectArray, setallSelectArray] = useState([])
    const { bulkAction, bulkActionCall } = useSelector(state => state.slider);
    const [singleSelect, setSingleSelect] = useState([])
    const [status, setStatus] = useState(false)
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 5,
    })
    // handleStatus
    const handleStatus = async () => {
        const data = {
            isPublished: status,
            id: singleSelect,
        }
        try {
            const response = await sliderStatus(data)
            if (response.status === 200) {
                toast.success('Status updated successfully')
                dispatch(fetchSliders(pagination.page, pagination.limit))
            }
        } catch (error) {
            toast.error('Something went wrong')
        }
    }
    // handle Delete
    const handleDelete = async () => {
        try {
            const response = await sliderDelete({ id: singleSelect })
            if (response.status === 200) {
                toast.success('Slider deleted successfully')
                dispatch(fetchSliders(pagination.page, pagination.limit))
            }
        }
        catch (error) {
            toast.error('Something went wrong')
        }
    }

    // headle checkbox
    const headleCheckbox = (e) => {
        const { name: id, checked } = e.target;
        if (id === 'selectAll') {
            if (checked) {
                setallSelectArray(data.map(data => data.id))

            } else {
                setallSelectArray([])
            }
        } else {
            setallSelectArray(prev => {
                if (checked) {
                    return [...prev, id]
                } else {
                    return prev.filter(item => item !== id)
                }
            })
        }

    }

    useEffect(() => {
        if (bulkActionCall) {
            headleBulkActions()
            dispatch(setBulkActionCall(false))
        }
    }, [bulkActionCall])


    // headle bulk actions
    const headleBulkActions = async () => {
        const formData = new FormData();
        if (allSelectArray.length < 1) {
            formData.append('id', allSelectArray)
        } else {
            for (let i = 0; i < allSelectArray.length; i++) {
                formData.append('id', allSelectArray[i])
            }
        }
        const newFormData = allSelectArray.length > 0 ? { id: allSelectArray } : formData

        if (bulkAction === 'none') {
            toast.error("Please select bulk action")
        }
        if (bulkAction === 'Delete') {
            if (allSelectArray.length === 0) {
                toast.error("Please select at least one item")
            } else {
                try {
                    const res = await sliderDelete(newFormData);
                    if (res.status === 200) {
                        dispatch(fetchSliders(pagination.page, pagination.limit))
                        toast.success("Slider deleted successfully")

                    }
                } catch (e) {
                    toast.error("Something went wrong")
                }
            }
        }
        if (bulkAction === 'UnPublish') {
            if (allSelectArray.length === 0) {
                toast.error("Please select at least one item")
            } else {
                const data = {
                    isPublished: false,
                    id: allSelectArray
                }
                try {
                    const res = await sliderStatus(data);
                    if (res.status === 200) {
                        dispatch(fetchSliders(pagination.page, pagination.limit))
                        toast.success("Status updated")

                    }
                } catch (e) {
                    toast.error("Something went wrong")
                }
            }
        }
        if (bulkAction === 'Publish') {
            if (allSelectArray.length === 0) {
                toast.error("Please select at least one item")
            } else {
                const data = {
                    isPublished: true,
                    id: allSelectArray
                }
                try {
                    const res = await sliderStatus(data);
                    if (res.status === 200) {
                        dispatch(fetchSliders(pagination.page, pagination.limit))
                        toast.success("Status updated")
                    }
                } catch (e) {
                    toast.error("Something went wrong")
                }
            }
        }
    }

    return (
        <>
            <div className="overflow-x-auto w-full">
                <table className="table w-full">
                    <thead>
                        <tr>
                            <th className="bg-secondary-color">
                                <div className="tooltip tooltip-right" data-tip="Select All">
                                    <label>
                                        <input
                                            type="checkbox"
                                            className="checkbox checkbox-primary"
                                            name="selectAll"
                                            checked={allSelectArray.length === data.length}
                                            onChange={headleCheckbox}
                                        />
                                    </label>
                                </div>
                            </th>
                            <th className="bg-secondary-color">Title</th>
                            <th className="bg-secondary-color"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.length > 0 && data.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <th className="bg-slate-800">
                                            <div className="tooltip tooltip-right" data-tip="Select One">
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox checkbox-primary"
                                                        name={item.id}
                                                        onChange={headleCheckbox}
                                                        checked={allSelectArray.includes(item.id)}
                                                    />
                                                </label>
                                            </div>
                                        </th>
                                        <td className="bg-slate-800 border-slate-500">
                                            <div className="flex items-center space-x-3">
                                                <div>
                                                    <div className="font-bold text-white opacity-80 capitalize">{item.title}</div>
                                                </div>
                                            </div>
                                        </td>
                                        <th className="bg-slate-800 border-slate-500 flex gap-4">
                                            <div className="tooltip" data-tip="Status">
                                                <label htmlFor="slider-status" onClick={() => {
                                                    setSingleSelect([item.id])
                                                    setStatus(item.isPublished ? false : true)
                                                }} className="cursor-pointer"> {item.isPublished ? <VisibilityIcon backgroundColor="#172337" /> : <VisibilityOffIcon backgroundColor="#172337" />}</label>
                                            </div>
                                            <Link to={`/admin/slider/edit/${item.id}`} className="cursor-pointer">
                                                <div className="tooltip" data-tip="Edit">
                                                    <span className="cursor-pointer"><EditIcon backgroundColor="#172337" /></span>
                                                </div>
                                            </Link>
                                            <div className="tooltip" data-tip="Delete">
                                                <label htmlFor="slider-delete" onClick={() => {
                                                    setSingleSelect([item.id])
                                                }}
                                                    className="cursor-pointer"> <DeleteIcon /></label>
                                            </div>
                                        </th>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>

            {/* Status Update Code */}
            <input type="checkbox" id="slider-status" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box relative">
                    <label htmlFor="slider-status" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                    <h3 className="font-bold text-lg">Slider Status</h3>
                    <p className="py-2">Are you sure you want to chance status?</p>
                    <div className="modal-action flex items-center justify-between">
                        <label htmlFor="slider-status" onClick={handleStatus} className="btn w-1/2 bg-red-800 hover:bg-red-900">Yes</label>
                        <label htmlFor="slider-status" className="btn w-1/2 bg-secondary-color hover:bg-primary-color">Cancel</label>
                    </div>
                </div>
            </div>

            {/* Delete Code */}
            <input type="checkbox" id="slider-delete" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box relative">
                    <label htmlFor="slider-delete" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                    <h3 className="font-bold text-lg">Slider Delete</h3>
                    <p className="py-2">Are you sure you want to delete this slider?</p>
                    <div className="modal-action flex items-center justify-between">
                        <label htmlFor="slider-delete" onClick={handleDelete} className="btn w-1/2 bg-red-800 hover:bg-red-900">Yes</label>
                        <label htmlFor="slider-delete" className="btn w-1/2 bg-secondary-color hover:bg-primary-color">Cancel</label>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SliderTable;