import { useSelector } from "react-redux"
import { TailwindBox } from "../../../../../tailwind"

const ContentAnalytics = () => {
    const { content, meta } = useSelector(state => state.content)
    return (
        <TailwindBox title="Content">
            <div>
                <p className="text-xs opacity-80 mt-2">
                    All time:
                </p>
                <div className="">
                    {
                        content && <>
                            <div className="flex justify-between items-center mt-5 text-sm">
                                <h6>
                                    Total Content
                                </h6>
                                <p>
                                    {
                                        meta?.report?.total
                                    }
                                </p>
                            </div>
                            <div className="flex justify-between items-center mt-4 text-sm">
                                <h6>
                                    Total Public
                                </h6>
                                <p>
                                    {
                                        meta?.report?.totalPublic
                                    }
                                </p>
                            </div>
                            <div className="flex justify-between items-center mt-4 text-sm">
                                <h6>
                                    Total Private
                                </h6>
                                <p>
                                    {
                                        meta?.report?.totalPrivate
                                    }
                                </p>
                            </div>
                        </>
                    }

                </div>

            </div>
        </TailwindBox>

    )
}

export default ContentAnalytics