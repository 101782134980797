import { useState } from "react";
import Layout from "../../components/Layout/Layout";
import { Button, DeleteIcon, Input, InputField, TextAreaField, Title } from "../../tailwind";
import { UploadIcon } from "../../tailwind/Icon";
import { useSelector } from "react-redux";
import { sendPushNotification } from "../../http/Apis";
import toast from "react-hot-toast";
import { Hr } from "../../components";
import { RootState } from "../../redux/store";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from '@mui/icons-material/Send';


const PushNotification = () => {
    const { user } = useSelector((state: RootState) => state.auth);
    const { config } = useSelector((state: RootState) => state.config);
    const { credentials } = config;
    const [loading, setLoading] = useState(false);
    const [pushNotification, setPushNotification] = useState({
        title: "",
        body: "",
        image: "",
        image_url: "",
        url: "",
    });

    const handleLogoChange = (e: any) => {
        // get object url and set it to imageUrl
        if (e.target.files[0]) {
            setPushNotification({ ...pushNotification, image: e.target.files[0], image_url: URL.createObjectURL(e.target.files[0]) });
        }
    }

    const sentPushNotifiction = async () => {
        setLoading(true);
        if (pushNotification.title === "") {
            toast.error("Title is required");
            setLoading(false);
            return;
        }
        if (pushNotification.body === "") {
            toast.error("Body is required");
            setLoading(false);
            return;
        }
        const formData = new FormData();
        formData.append("title", pushNotification.title);
        formData.append("body", pushNotification.body);
        if (pushNotification.image) {
            formData.append("notification_image", pushNotification.image);
        }
        if (pushNotification.url) {
            formData.append("url", pushNotification.url);
        }
        try {
            const response = await sendPushNotification(formData);
            if (response.status === 200) {
                toast.success("Push notification sent successfully");
                setPushNotification({
                    title: "",
                    body: "",
                    image: "",
                    image_url: "",
                    url: "",
                });
                // reload page
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
                setLoading(false);
            }
        } catch (error: any) {
            console.log(error);
            setLoading(false);
            toast.error(error.response.data.error.message || "Something went wrong");
        }
    }

    return (
        <Layout>
            <div className="pr-5">
                <h1 className="text-2xl opacity-80 font-semibold tracking-wide">
                    Push Notification
                </h1>
                <p className="mt-2 opacity-70">
                    Send push notification to your users from here. You can send push notification to all users or to a specific user.
                </p>
            </div>
            <div className="my-5">
                <Hr />
            </div>
            <h6 className="opacity-70 pr-5">* – Required fields. Enter all fields.</h6>
            <div className="my-5 pr-36 flex flex-col gap-5">
                <InputField
                    title="Notification Title"
                    required
                    fieldName="title"
                    type="text"
                    placeholder="Enter your notification title"
                    value={pushNotification.title}
                    onChange={(e) => setPushNotification({ ...pushNotification, title: e.target.value })}
                />
                <div className="flex w-full">
                    <TextAreaField
                        title="Notification Body"
                        required
                        fieldName="body"
                        type="textarea"
                        placeholder="Enter your notification body"
                        value={pushNotification.body}
                        textAreaOnChange={(e) => setPushNotification({ ...pushNotification, body: e.target.value })}
                    />
                </div>
                <div className="my-4">
                    <div className="flex w-full items-center">
                        <div className="w-full p-5 border border-gray-700 rounded">
                            <Title required={false} className='w-1/3'>
                                Notification Image
                            </Title>
                            <div className="flex flex-col justify-center items-center">
                                <input
                                    type="file"
                                    className="hidden"
                                    id="logo"
                                    onChange={handleLogoChange}
                                />
                                <div className="relative">
                                    <label htmlFor="logo" className="cursor-pointer">
                                        <img
                                            src={pushNotification.image_url}
                                            className="w-96 h-60 object-contain rounded-3xl shadow-sm bg-dark-color-box text-center p-5"
                                        />
                                    </label>
                                    <div className="cursor-pointer absolute -top-2 -right-2">
                                        <DeleteIcon
                                            width={40}
                                            height={40}
                                        />
                                    </div>
                                </div>

                                <div className="mt-8">
                                    <label htmlFor="logo" className="cursor-pointer flex items-center gap-3">
                                        <div className="mt-[2px]">
                                            <UploadIcon width={22} height={22} />
                                        </div>
                                        <p className="opacity-70 text-lg font-medium cursor-pointer">
                                            Replace
                                        </p>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="my-14 pr-16 flex flex-col gap-8">
                <div className="flex justify-end">
                    <div className="w-44">
                        <LoadingButton
                            loading={loading}
                            loadingPosition="end"
                            endIcon={<SendIcon />}
                            variant="outlined"
                            color="primary"
                            onClick={sentPushNotifiction}

                        >
                            Send
                        </LoadingButton>
                        {/* <Button
                            title={"Send Notification"}
                            onClick={sentPushNotifiction}
                            loading={loading}
                        /> */}
                    </div>

                </div>
            </div>
        </Layout>

    );
}

export default PushNotification;
