import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'

interface BoxProps {
    title?: string
    className?: string
    children: React.ReactNode
}


const Box = ({ title, className = 'rounded py-5 px-7 h-fit', children }: BoxProps) => {
    const { darkMode } = useSelector((state: RootState) => state.theme)
    return (
        <div className={`${darkMode ? 'bg-dark-color-box border border-gray-700' : 'bg-white shadow border border-gray-200'} ${className}`}>
            <h1 className="text-lg font-bold opacity-90 tracking-wide">{title}</h1>
            {children}
        </div>
    )
}

export default Box