import { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import { DeleteIcon, InputField, Title } from "../../tailwind";
import { useSelector, useDispatch } from "react-redux";
import { updateAppSettingApi, updateSettingApi } from "../../http/Apis";
import toast from "react-hot-toast";
import { Hr } from "../../components";
import { RootState } from "../../redux/store";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { SketchPicker } from 'react-color';
import { UploadIcon } from "../../tailwind/Icon";
import { fetchAppConfig } from "../../redux/slice​/configSlice";


const AppSettings = () => {
    const dispatch = useDispatch()
    const { appConfig } = useSelector((state: RootState) => state.config)
    const { darkMode } = useSelector((state: RootState) => state.theme)
    const { splesh_screen, _id } = appConfig;
    const [loading, setLoading] = useState(false);
    interface IAppSettings {
        splesh_screen: {
            background_color?: string;
            screen_time?: number;
            splesh_logo?: string;
            splesh_logoUrl?: string;
            logoSize?: number;
        };
        logo: {
            logo?: string;
            logoUrl?: string;
        };
        data: {
            ipinfo_api_key: string
        }
    }
    const [appSettings, setAppSettings] = useState<IAppSettings>({
        splesh_screen: {
            background_color: "#000",
            screen_time: 5,
            splesh_logo: "",
            splesh_logoUrl: "",
            logoSize: 100,
        },
        logo: {
            logo: "",
            logoUrl: "",
        },
        data: {
            ipinfo_api_key: ""
        }
    });


    useEffect(() => {
        setAppSettings({
            splesh_screen: {
                background_color: splesh_screen.background_color,
                screen_time: splesh_screen.screen_time,
                splesh_logoUrl: splesh_screen.splesh_logo,
                logoSize: splesh_screen.logoSize
            },
            logo: {
                logoUrl: appConfig.logo
            },
            data: {
                ipinfo_api_key: appConfig.data.ipinfo_api_key
            }
        })
    }, [appConfig]);

    useEffect(() => {
        dispatch(fetchAppConfig() as any);
    }, []);

    const handleLogoChange = (e: any) => {
        // get object url and set it to logoUrl
        if (e.target.files[0]) {
            const splesh_logoUrl = URL.createObjectURL(e.target.files[0]);
            setAppSettings({ ...appSettings, splesh_screen: { ...appSettings.splesh_screen, splesh_logo: e.target.files[0], splesh_logoUrl } });
        }
    }

    const getLogoChange = (e: any) => {
        // get object url and set it to logoUrl
        if (e.target.files[0]) {
            const logoUrl = URL.createObjectURL(e.target.files[0]);
            setAppSettings({ ...appSettings, logo: { ...appSettings.logo, logo: e.target.files[0], logoUrl } });
        }
    }

    const updateSettings = async () => {
        if (!appConfig._id) return toast.error("Config not found");
        setLoading(true);
        const formData = new FormData();
        formData.append("splesh_screen", JSON.stringify({
            background_color: appSettings.splesh_screen.background_color,
            screen_time: appSettings.splesh_screen.screen_time,
            logoSize: appSettings.splesh_screen.logoSize
        }));

        if (appSettings.splesh_screen.splesh_logo) {
            formData.append("splesh_logo", appSettings.splesh_screen.splesh_logo);
        }

        if (appSettings.logo.logo) {
            formData.append("logo", appSettings.logo.logo);
        }

        formData.append("data", JSON.stringify({
            ipinfo_api_key: appSettings.data.ipinfo_api_key
        }));
        try {
            const response = await updateAppSettingApi(_id, formData);
            if (response.status === 200) {
                toast.success("Settings updated successfully");
                setLoading(false);
                dispatch(fetchAppConfig() as any);
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
            toast.error("Something went wrong");
        }
    }

    return (
        <Layout>
            <div className="pr-5">
                <h1 className="text-2xl opacity-80 font-semibold tracking-wide">
                    App Settings
                </h1>
                <p className="mt-2 opacity-70">
                    Edit your app configuration here and save. You can change your logo and splash screen from here.
                </p>
            </div>
            <div className="my-5">
                <Hr />
            </div>
            <h6 className="opacity-70 pr-5">* – Required fields. Enter all fields.</h6>
            <h1 className="my-6 opacity-80 font-medium tracking-wide text-xl pr-5">
                App Information
            </h1>
            <h6 className="opacity-70 pr-5">
                set your app logo and splash screen.
            </h6>
            <h1 className="my-5 opacity-80 font-medium tracking-wide text-xl pr-5">Graphics</h1>
            <div className="my-10 flex flex-col  gap-8">
                <div className="flex w-full justify-between pr-56">
                    <div className='w-1/2 flex flex-col gap-5'>
                        <div className="w-full">
                            <h6 className="opacity-70 pr-5 pb-2">
                                Splash Logo
                            </h6>
                            <input
                                type="file"
                                className={`file-input file-input-bordered w-full border ${darkMode ? 'border-[#343434]' : 'border-gray-200'} p-2.5 rounded`}
                                onChange={handleLogoChange}
                            />
                        </div>
                        <div className="w-full">
                            <h6 className="opacity-70 pr-5 pb-2">
                                Background Color
                            </h6>
                            <SketchPicker
                                styles={{
                                    default: {
                                        picker: {
                                            background: darkMode ? "#222" : "#fff",
                                            color: darkMode ? "#000" : "#000",
                                        },


                                    }
                                }}
                                color={appSettings.splesh_screen.background_color}
                                onChange={(color) => {
                                    setAppSettings({
                                        ...appSettings,
                                        splesh_screen: {
                                            ...appSettings.splesh_screen,
                                            background_color: color.hex,
                                        },
                                    });
                                }}
                            />
                        </div>

                        <InputField
                            fieldName="screen-time"
                            placeholder="Screen Time in seconds"
                            title="Screen Time"
                            type="number"
                            value={appSettings.splesh_screen.screen_time}
                            onChange={(e) => {
                                setAppSettings({
                                    ...appSettings,
                                    splesh_screen: {
                                        ...appSettings.splesh_screen,
                                        screen_time: parseInt(e.target.value),
                                    },
                                });
                            }}
                        />

                        <InputField
                            fieldName="logo-size"
                            placeholder="Logo Size in number"
                            title="Logo Size"
                            type="number"
                            value={appSettings.splesh_screen.logoSize}
                            onChange={(e) => {
                                setAppSettings({
                                    ...appSettings,
                                    splesh_screen: {
                                        ...appSettings.splesh_screen,
                                        logoSize: parseInt(e.target.value),
                                    },
                                });
                            }}
                        />

                    </div>
                    <div className="mt-7">
                        <div className="mockup-phone border-primary w-[18.5rem]">
                            <div className="camera"></div>
                            <div className="display">
                                <div
                                    style={{ backgroundColor: appSettings.splesh_screen.background_color }}
                                    className={`artboard artboard-demo phone-1`}>
                                    <div className="w-[18.5rem]">
                                        <img
                                            src={appSettings.splesh_screen.splesh_logoUrl}
                                            className="w-60 h-60 object-contain rounded-3xl text-center p-5"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {/* <div className="flex justify-end pr-56 my-20">
                    <LoadingButton
                        loading={loading}
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="contained"
                        onClick={updateSettings}>
                        Save
                    </LoadingButton>
                </div> */}
            </div>
            <div className="flex w-full items-center pr-56 mb-16 mt-20">
                <Title required={true} className='w-1/3'>
                    Logo
                </Title>
                <div className="w-full p-10 border border-gray-700 rounded">
                    <div className="flex flex-col justify-center items-center">
                        <input
                            type="file"
                            className="hidden"
                            id="logo"
                            onChange={getLogoChange}
                        />
                        <div className="relative">
                            <label htmlFor="logo" className="cursor-pointer">
                                <img
                                    src={appSettings.logo?.logoUrl}
                                    className="w-60 h-60 object-contain rounded-3xl shadow-sm bg-dark-color-box text-center p-5"
                                />
                            </label>
                            <div className="cursor-pointer absolute -top-2 -right-2">
                                <DeleteIcon
                                    width={40}
                                    height={40}
                                />
                            </div>
                        </div>

                        <div className="mt-8">
                            <label htmlFor="logo" className="cursor-pointer flex items-center gap-3">
                                <div className="mt-[2px]">
                                    <UploadIcon width={22} height={22} />
                                </div>
                                <p className="opacity-70 text-lg font-medium cursor-pointer">
                                    Replace
                                </p>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-end pr-56 my-20">
                <LoadingButton
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    onClick={updateSettings}>
                    Save
                </LoadingButton>
            </div>

            <h1 className="my-2 opacity-80 font-medium tracking-wide text-xl pr-5">
                API Credentials
            </h1>
            <h6 className="opacity-70 pr-5">
                set your api credentials to use the app features like ip tracking and more.
            </h6>
            <div>
                <div className="flex w-full items-center pr-56 mb-16 mt-20">
                    <Title required={true} className='w-1/3'>
                        IPinfo Api Key
                    </Title>
                    <div className="w-full">
                        <div className="flex flex-col justify-center items-center">
                            <InputField
                                fieldName="ipinfo-api-key"
                                placeholder="Enter your IPinfo Api Key"
                                title="IPinfo Api Key"
                                type="text"
                                value={appSettings.data.ipinfo_api_key}
                                onChange={(e) => {
                                    setAppSettings({
                                        ...appSettings,
                                        data: {
                                            ...appSettings.data,
                                            ipinfo_api_key: e.target.value,
                                        },
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </Layout>

    );
}

export default AppSettings;
