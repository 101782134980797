import React from 'react'
import Layout from '../../components/Layout/Layout'
import { InputField, TextAreaField, Title } from '../../tailwind'
import toast from 'react-hot-toast'
import { updateCategoryEndPoint } from '../../http/Apis'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { RootState } from '../../redux/store'
import LoadingButton from '@mui/lab/LoadingButton'
import SaveIcon from '@mui/icons-material/Save';
import { fetchCategories } from '../../redux/slice​/categorySlice'

const EditCategory = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const { category } = useSelector((state: RootState) => state.category)

    const [loading, setLoading] = React.useState(false)

    const [categoryData, setCategoryData] = React.useState({
        name: '',
        description: '',
    })

    useEffect(() => {
        dispatch(fetchCategories(`id=${id}`, 'single') as any)
    }, [])

    useEffect(() => {
      setCategoryData({
            name: category.name,
            description: category.description,
        })
    }, [category])


    const handleSubmit = async () => {
        setLoading(true)
        try {
            const res = await updateCategoryEndPoint(id, categoryData)
            if (res.status === 200) {
                toast.success('Category update successfully')
                setLoading(false)
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.error?.message || 'Something went wrong')
            setLoading(false)
        }
    }

    return (
        <Layout>
            <Title className="font-medium text-xl mb-5 pr-5">Edit Category</Title>

            <div className='flex flex-col gap-4 pr-5'>
                <InputField
                title='Name'
                    type="text"
                    fieldName='Name'
                    placeholder="Name"
                    value={categoryData.name}
                    onChange={(e) => setCategoryData({ ...categoryData, name: e.target.value })}
                />
                <TextAreaField
                    type="textarea"
                    title='Description'
                    fieldName='Description'
                    placeholder="Description"
                    value={categoryData.description}
                    textAreaOnChange={(e) => setCategoryData({ ...categoryData, description: e.target.value })}
                />

            </div>
            <div className="flex justify-end mt-5 px-5">
                <LoadingButton
                loading={loading}
                onClick={handleSubmit}
                variant="contained"
                startIcon={<SaveIcon />}
                >
                    Save
                </LoadingButton>
            </div>
        </Layout>
    )
}

export default EditCategory