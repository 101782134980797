import AWS from "aws-sdk";
import { v4 as uuid } from 'uuid'

const s3 = new AWS.S3({
    region: 'ap-south-1',
    accessKeyId: 'AKIAWO3GNAD5ABWWXKN5',
    secretAccessKey: '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'
});

const useS3 = (file, bucket) => {
    console.log("🚀 ~ file: useS3.js ~ line 13 ~ useS3 ~ file", file)
    // generate unique key
    const key = uuid();
    // add extension to key
    const extension = file.name.split('.').pop();
    const keyWithExtension = `${key}.${extension}`;
    const upload = () => {
        const options = {
            Bucket: 'zezo',
            Body: file,
            Key: keyWithExtension
        }
        return s3.upload(options);
    }
    return upload;
}

export default useS3;