import { useEffect, useState } from 'react';
import Layout from '../../components/Layout/Layout'
import SearchBar from '../../components/SearchBar';
import { Button, Table } from '../../tailwind';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { useDebounce } from 'use-debounce';
import { fetchSearchUsers, fetchUserReport, fetchUsers, setBulkAction, setBulkActionCall } from '../../redux/slice​/userSlice';
import { RegisterUser } from '../../http/Apis';
import { Hr, MuiTable } from '../../components';
import { RootState } from '../../redux/store';

const Users = () => {
    const dispatch = useDispatch();
    const { darkMode } = useSelector((state: RootState) => state.theme);
    const { users, meta, status, userReport } = useSelector((state: RootState) => state.users);
    const [user, setUser] = useState({
        name: '',
        email: '',
        password: '',
        phone: '',
    });
    const [loading, setLoading] = useState(false);
    const [itemView, setItemView] = useState(5);
    const [search, setSearch] = useState<string>();
    const [value] = useDebounce(search, 1000);

    useEffect(() => {
        if (value) {
            if (value.length > 2) {
                dispatch(fetchSearchUsers(value) as any);
            } else {
                toast.error('Please enter more than 2 characters');
            }
        }
    }, [value]);

    const [pagination, setPagination] = useState({
        page: 1,
        limit: process.env.REACT_APP_PAGE_SIZE ? parseInt(process.env.REACT_APP_PAGE_SIZE) : 25
    })

    useEffect(() => {
        dispatch(fetchUsers(pagination.page, pagination.limit) as any);
        dispatch(fetchUserReport() as any)
    }, [pagination]);

    const handleSubmit = async () => {
        const { name, email, password, phone } = user;
        // validate
        if (!name || !email || !password || !phone) {
            toast.error('Please fill all fields');
            return;
        }
        // email validate
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            toast.error('Please enter a valid email');
            return;
        }
        // password validate
        if (password.length < 6) {
            toast.error('Password must be at least 6 characters');
            return;
        }
        // phone validate
        if (!/^[0-9]{10}$/.test(phone)) {
            toast.error('Please enter a valid phone number');
            return;
        }
        try {
            setLoading(true);
            const res = await RegisterUser(user);
            if (res.status === 200) {
                toast.success('User created successfully');
                setLoading(false);

            }
        } catch (error: any) {
            toast.error(error.response.data.message);
            setLoading(false);
        }
    }

    const headlePrev = () => {
        if (pagination.page > 1) {
            setPagination({
                ...pagination,
                page: pagination.page - 1
            })
        } else {
            toast.error("You are on first page")
        }
    }

    const handleNext = () => {
        if (pagination.page < Math.ceil(meta.report.total / pagination.limit)) {
            setPagination({
                ...pagination,
                page: pagination.page + 1
            })
        } else {
            toast.error("No more users")
        }
    }

    return (
        <>
            <Layout>
                <h1 className="font-medium text-2xl pr-5 mb-3 opacity-80">
                    Users
                </h1>
                <Hr />
                <div className='flex justify-between items-center px-5'>
                    <div className='flex items-center gap-4'>
                        <select
                            className="select max-w-xs bg-transparent text-sm font-normal pl-0 -ml-1 focus:outline-none"
                            defaultValue={'none'}
                            onChange={(e) => dispatch(setBulkAction(e.target.value))}>
                            <option value="none">Bulk Actions</option>
                            <option value='activate'>Activate</option>
                            <option value='deactivate'>Deactivate</option>
                            <option value='restore'>Restore</option>
                            <option value='trash'>Move to Trash</option>
                            <option value='delete'>Delete Forever</option>
                        </select>
                        <span onClick={() => dispatch(setBulkActionCall(true))} className="bg-transparent py-3.5 px-6 text-sm cursor-pointer hover:bg-secondary-blue duration-100" >Apply</span>
                    </div>
                    <div className='flex gap-2 items-center'>
                        <SearchBar
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        {/* <Button
                            title={'+ Add New'}
                            titleStyle={'font-medium'}
                            // label={'add-new-user'}
                            backgroundColor={'bg-transparent'}
                            hoverBackgroundColor={'bg-transparent'}
                            height={'h-11'}
                            onClick={() => { }}
                        /> */}
                    </div>
                </div>
                <Hr />
                <div className="w-full">
                    {
                        status === 'loading' ? <div className='text-center'>Loading...</div> :
                            users.length > 0 ? <>
                                <Table Data={users} page={pagination.page} />
                                <div className='flex justify-between items-center my-1.5 pr-5'>
                                    {
                                        users.length > 0 && <div className="flex gap-8 items-center">
                                            <div className="flex gap-3.5">
                                                <div className="">Total Users</div>
                                                <div className="">{meta.report.total}</div>
                                            </div>
                                            <div className="flex gap-3.5">
                                                <div className="">
                                                    Active
                                                </div>
                                                <div className="">
                                                    {meta.report.ActiveUsers}
                                                </div>
                                            </div>
                                            <div className="flex gap-3.5">
                                                <div className="">
                                                    Deactivate
                                                </div>
                                                <div className="">{
                                                    meta.report.Deactivate
                                                }</div>
                                            </div>
                                        </div>
                                    }
                                    <div>
                                        <div className="btn-group">
                                            <button className={`btn bg-transparent hover:bg-transparent border-none ${darkMode ? 'text-white' : 'text-black'}`} onClick={headlePrev}>«</button>
                                            <button className={`btn bg-transparent hover:bg-transparent border-none capitalize text-sm ${darkMode ? 'text-white' : 'text-black'}`}>Page {pagination.page}</button>
                                            <button className={`btn bg-transparent hover:bg-transparent border-none ${darkMode ? 'text-white' : 'text-black'}`} onClick={handleNext} >»</button>
                                        </div>
                                    </div>
                                </div>
                                {/* <Hr /> */}
                            </> : <div className='text-center my-8'>No users found</div>
                    }
                </div>
                <Hr />

                {/* add new User */}
                <input type="checkbox" id="add-new-user" className="modal-toggle" />
                <div className="modal">
                    <div className="modal-box relative bg-dark-card-color">
                        <label id='test' htmlFor="add-new-user" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                        <h3 className="text-lg font-bold">Add New User</h3>
                        <div className='py-4'>
                            <input
                                type="text"
                                placeholder="Type name"
                                className="input w-full bg-dark-card-color border border-gray-700 rounded"
                                value={user.name}
                                onChange={(e) => setUser({ ...user, name: e.target.value })}
                            />
                            <input
                                type="text"
                                placeholder="Type email"
                                className="input w-full bg-dark-card-color border border-gray-700 rounded mt-3"
                                value={user.email}
                                onChange={(e) => setUser({ ...user, email: e.target.value })}
                            />
                            <input
                                type="text"
                                placeholder="Type password"
                                className="input w-full bg-dark-card-color border border-gray-700 rounded mt-3"
                                value={user.password}
                                onChange={(e) => setUser({ ...user, password: e.target.value })}
                            />
                            <input
                                type="text"
                                placeholder="Type phone"
                                className="input w-full bg-dark-card-color border border-gray-700 rounded mt-3"
                                value={user.phone}
                                onChange={(e) => setUser({ ...user, phone: e.target.value })}
                            />
                            <div className='flex justify-end mt-5'>
                                <Button
                                    title={'Save'}
                                    titleStyle={'text-white font-medium'}
                                    label={'add-new-user'}

                                    width={'w-20'}
                                    backgroundColor={'border-gray-700'}
                                    onClick={handleSubmit}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>

    )
}

export default Users;
