import Layout from "../../components/Layout/Layout";
import { Button, SectionTable } from "../../tailwind";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDebounce } from "use-debounce";
import SearchBar from "../../components/SearchBar";
import { Link } from "react-router-dom";
import { fetchSearchSlider, setBulkAction, setBulkActionCall } from "../../redux/slice​/sliderSlice";
import { fetchSectionReport, fetchSections } from "../../redux/slice​/sectionSlice";
import { Hr } from "../../components";
import RouteStrings from "../../localData/routes.json";
import { RootState } from "../../redux/store";

const Sections = () => {
    const { data, report } = useSelector((state: RootState) => state.section);
    const { darkMode } = useSelector((state: RootState) => state.theme);
    const dispatch = useDispatch();
    const [search, setSearch] = useState();
    const [value] = useDebounce(search, 5000);

    useEffect(() => {
        if (value) dispatch(fetchSearchSlider(value) as any);
    }, [value]);

    const [pagination, setPagination] = useState({
        page: 1,
        limit: 5,
    })

    useEffect(() => {
        dispatch(fetchSections(pagination.page, pagination.limit) as any);
        dispatch(fetchSectionReport() as any);
    }, [pagination]);


    const headlePrev = () => {
        if (pagination.page > 1) {
            setPagination({
                ...pagination,
                page: pagination.page - 1
            })
        } else {
            toast.error("You are on first page")
        }
    }

    const handleNext = () => {
        if (pagination.page < Math.ceil(report.total / pagination.limit)) {
            setPagination({
                ...pagination,
                page: pagination.page + 1
            })
        } else {
            toast.error("No more pages")
        }
    }
    return (
        <Layout>
            <h1 className="font-medium text-2xl pr-5 mb-3">
                Sections
            </h1>
            <p className="mt-2 opacity-70 mb-4">
                Sections are the collection of movies and series. You can create a section and add movies and series to it.
            </p>
            <Hr />
            <div className='flex justify-between items-center pr-5'>
                <div className='flex items-center gap-4'>
                    <select
                        className="select max-w-xs bg-transparent text-sm font-normal pl-0 -ml-1"
                        defaultValue={'none'}
                        onChange={(e) => dispatch(setBulkAction(e.target.value))}>
                        <option value="none">Bulk Actions</option>
                        <option value='Delete'>Delete</option>
                        <option value='UnPublish'>UnPublish</option>
                        <option value='Publish'>Publish</option>
                    </select>
                    <span onClick={() => dispatch(setBulkActionCall(true))} className="bg-transparent py-3.5 px-6 text-sm cursor-pointer hover:bg-secondary-blue duration-100" >Apply</span>
                </div>
                <div className='flex gap-2 items-center'>
                    <SearchBar
                        onChange={(e) => setSearch(e.target.value as any)}
                    />
                    <Link to={`${RouteStrings.Sections.Children.CreateSection.Link}`}>
                        <Button
                            title={'+ Add New'}
                            titleStyle={'font-medium'}
                            backgroundColor={'bg-transparent'}
                            hoverBackgroundColor={'bg-transparent'}
                            height={'h-11'}
                            onClick={() => { }}
                        />
                    </Link>
                </div>
            </div>
            <Hr />

            <div className="w-full">
                <SectionTable data={data} />
            </div>
            <div className='flex justify-between items-center my-2 pr-5'>
                {
                    data.length > 0 && <div className="flex gap-5">
                        <p>Total Sections &nbsp; {report.total}</p>
                        <p>Public &nbsp; {report.Active}</p>
                        <p>Private &nbsp; {report.Deactivate}</p>
                    </div>
                }
                {
                    data.length > 0 &&
                    <div>
                        <div className="btn-group">
                            <button className={`btn bg-transparent hover:bg-transparent border-none ${darkMode ? 'text-white' : 'text-black'}`} onClick={headlePrev}>«</button>
                            <button className={`btn bg-transparent hover:bg-transparent border-none capitalize text-sm ${darkMode ? 'text-white' : 'text-black'}`}>Page {pagination.page}</button>
                            <button className={`btn bg-transparent hover:bg-transparent border-none ${darkMode ? 'text-white' : 'text-black'}`} onClick={handleNext} >»</button>
                        </div>
                    </div>
                }
            </div>
            <Hr />
        </Layout >
    )
}

export default Sections;