import axios from "axios";

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
    headers: {
        "Accept": "application/json",
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": process.env.REACT_APP_URL,
    }
});

// list all endpoints

// auth endpoints
export const login = (data) => api.post("/auth/login/email", data);
export const logout = () => api.get("/1/auth/logout");

// user endpoints
export const updateUser = (id, data) => api.put(`/user/update/${id}`, data);

// theme endpoints
export const createTheme = (data) => api.post("/theme/create", data);
export const updateTheme = (id, data) => api.put(`/theme/update/${id}`, data);

// store endpoints
export const updateStore = (id, data) => api.put(`/store/update/${id}`, data);


// Products endpoints
export const getProducts = (page, limit, order) => api.get(`/product?page=${page}&limit=${limit}&order=${order}`);
export const createProduct = (data) => api.post("/product", data);
export const getProduct = (id) => api.get(`/product/${id}`);
export const updateProduct = (id, data) => api.put(`/product/${id}`, data);

// Categories endpoints
export const createCategory = (data) => api.post("/category", data);
export const updateCategory = (id, data) => api.put(`/category/${id}`, data);

// Brands endpoints
export const updateBrand = (id, data) => api.put(`/brand/${id}`, data);

// Interceptor for handling 401 errors
api.interceptors.response.use(
    (config) => {
        return config;
    },
    async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && originalRequest && !originalRequest.isRetry) {
            originalRequest.isRetry = true;
            try {
                await axios.get(
                    `${process.env.REACT_APP_API_URL}/auth/refresh`,
                    {
                        withCredentials: true,
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                        }
                    }
                );

                return api.request(originalRequest);
            } catch (err) {
                console.log(err.message);
            }
        }

        throw error;
    }
);

export default api;