
const Button = ({
    title,
    uppercase = false,
    height = 'h-full',
    width = 'w-full',
    borderRadius = 'rounded',
    backgroundColor = 'bg-dark-color-box',
    hoverBackgroundColor = 'hover:bg-blue-800',
    onClick,
    loading = false,
    label = 'my-label',
    titleStyle = '',
}) => {
    return (
        <>
            {
                title && loading ?
                    <div onClick={onClick} className="cursor-pointer">
                        <div className={`${backgroundColor} ${hoverBackgroundColor} btn loading duration-300 ${uppercase ? 'uppercase' : ''} font-bold py-2 px-4 ${borderRadius} ${width} ${height} cursor-pointer`}>
                            <span className={`${titleStyle} cursor-pointer`}>
                                {loading ? 'loading' : title}
                            </span>
                        </div>
                    </div> :
                    <label htmlFor={label} onClick={onClick} className={`${backgroundColor} ${hoverBackgroundColor} duration-300 ${uppercase ? 'uppercase' : ''} font-normal py-2 px-4 ${borderRadius} ${width} ${height} flex justify-center items-center cursor-pointer`}>
                        <span className={`${titleStyle} cursor-pointer`}>
                            {title}
                        </span>
                    </label>
            }
        </>
    );
}

export default Button;