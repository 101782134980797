import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { logout } from "../../http";
import toast from "react-hot-toast";
import { RootState } from "../../redux/store";

const Profile = () => {
    const { user } = useSelector((state: RootState) => state.auth);
    const { darkMode } = useSelector((state: RootState) => state.theme);
    // LogoutFunction
    const LogoutFunc = async () => {
        try {
            const response = await logout();
            if (response.status === 200) {
                toast.success("Logout Successfully");
                setTimeout(() => {
                    window.location.href = "/";
                }, 1000);
            }
        } catch (error : any) {
            toast.error(error.response.data.message || "Something went wrong");
        }
    }

    return (
        <div className="container flex flex-wrap justify-between items-center mx-auto">
            <div className="dropdown dropdown-end">
                <label tabIndex={0} className="btn btn-ghost btn-circle avatar">
                    <div className="w-9 rounded-full bg-secondary-color">
                        <img src="https://api.lorem.space/image/face?hash=33791" />
                    </div>
                </label>
                <ul tabIndex={0} className={`m-0 p-0 shadow menu menu-compact dropdown-content ${darkMode ? 'bg-[#282828]' : 'bg-white'} rounded  w-52`}>
                    <div className={`z-50 pb-4 pt-2 px-2 text-base list-none ${darkMode ? 'bg-[#282828]' : 'bg-white'} rounded  divide-y divide-gray-100 shadow dark:divide-gray-400`} id="dropdown">
                        <div className="p-2">
                            <span className="block text-sm">{user.name}</span>
                            <span className="block text-sm font-medium">{user.email}</span>
                        </div>
                        <ul className="py-1" aria-labelledby="dropdown">
                            <Link to="/admin/dashboard">
                                <li>
                                    <span className="block py-2 px-4 text-sm">Dashboard</span>
                                </li>
                            </Link>
                            <li>
                                <span className="block py-2 px-4 text-sm">Settings</span>
                            </li>
                            <li onClick={LogoutFunc}>
                                <span className="block py-2 px-4 text-sm">Sign out</span>
                            </li>
                        </ul>
                    </div>
                </ul>
            </div>
        </div>

    )
}

export default Profile;