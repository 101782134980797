import React from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface IProps {
    open: boolean;
    onClose: () => void;
    title: string;
    contentText: string;
    onClickYes: () => void;
    onClickClose: () => void;
    buttonOneText: string | undefined;
    buttonTwoText: string | undefined;

}

const MuiDialog = ({
    open,
    onClose,
    title,
    contentText,
    onClickYes,
    onClickClose,
    buttonOneText,
    buttonTwoText,
}: IProps) => {
    return (
        <div className="bg-dark-color z-50">
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {contentText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClickClose}>
                        {buttonOneText || 'Close'}
                    </Button>
                    <Button onClick={onClickYes}>
                        {buttonTwoText || 'Yes'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default MuiDialog