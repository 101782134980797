import React from 'react';
import {
    Dashboard,
    Login,
    Users,
    EditUser,
    Orders,
    OrderView,
    // Subscriptions Components
    Subscriptions,
    EditSubscriptions,
    // Theme Components
    Themes,
    CreateTheme,
    EditTheme,
    // Movies Components
    Movies,
    EditMovie,
    AddNewMovie,
    // Genres Components
    Genres,
    EditGenre,
    AddNewGenre,
    // categories Components
    Category,
    EditCategory,
    AddNewCategory,
    // language Components
    Language,
    EditLanguage,
    AddNewLanguage,
    // cast Components
    Cast,
    EditCast,
    AddNewCast,
    // slider Components
    Slider,
    EditSlider,
    AddNewSlider,
    // sction Components
    Section,
    EditSection,
    AddNewSection,
    // series Components
    Series,
    AddNewSeries,
    EditSeries,
    EditSeason,
    // file manager Components
    FileManager,
    // settings Components
    Settings,
    SeoSetting,
    // marketing Components
    PushNotification,
    // RTMP server Components
    RtmpDashboard,
    RtmpStreams,
    RtmpProfile,
    // Transcoding server Components
    TranscodingDashboard,
    Content,
    CreateContent,
    EditContent,
    EditContentSeason,
    AppSettings,
    // contact Components

} from '../Pages'

import RouteStrings from './routes.json';

export interface IAllRoutes {
    path: string;
    element: React.ReactNode;
}

export const AllRoutes: IAllRoutes[] = [
    {
        path: `${RouteStrings.Dashboard.Link}`,
        element: <Dashboard />,
    },
    {
        path: `${RouteStrings.Users.Link}`,
        element: <Users />,
    },
    {
        path: `${RouteStrings.Users.Children.EditUser.Link}`,
        element: <EditUser />,
    },
    {
        path: `${RouteStrings.Orders.Link}`,
        element: <Orders />,
    },
    {
        path: `${RouteStrings.Orders.Children.ViewOrder.Link}`,
        element: <OrderView />,
    },
    {
        path: `${RouteStrings.Themes.Link}`,
        element: <Themes />,
    },
    {
        path: `${RouteStrings.Themes.Children.CreateTheme.Link}`,
        element: <CreateTheme />,
    },
    {
        path: `${RouteStrings.Themes.Children.EditTheme.Link}`,
        element: <EditTheme />,
    },
    {
        path: `${RouteStrings.Movies.Link}`,
        element: <Movies />,
    },
    {
        path: `${RouteStrings.Movies.Children.EditMovie.Link}`,
        element: <EditMovie />,
    },
    {
        path: `${RouteStrings.Movies.Children.CreateMovie.Link}`,
        element: <AddNewMovie />,
    },
    {
        path: `${RouteStrings.Genres.Link}`,
        element: <Genres />,
    },
    {
        path: `${RouteStrings.Genres.Children.EditGenre.Link}`,
        element: <EditGenre />,
    },
    {
        path: `${RouteStrings.Genres.Children.CreateGenre.Link}`,
        element: <AddNewGenre />,
    },
    {
        path: `${RouteStrings.Categories.Link}`,
        element: <Category />,
    },
    {
        path: `${RouteStrings.Categories.Children.EditCategory.Link}`,
        element: <EditCategory />,
    },
    {
        path: `${RouteStrings.Categories.Children.CreateCategory.Link}`,
        element: <AddNewCategory />,
    },
    {
        path: `${RouteStrings.Languages.Link}`,
        element: <Language />,
    },
    {
        path: `${RouteStrings.Languages.Children.EditLanguage.Link}`,
        element: <EditLanguage />,
    },
    {
        path: `${RouteStrings.Languages.Children.CreateLanguage.Link}`,
        element: <AddNewLanguage />,
    },
    {
        path: `${RouteStrings.CastAndCrew.Link}`,
        element: <Cast />,
    },
    {
        path: `${RouteStrings.CastAndCrew.Children.EditCastAndCrew.Link}`,
        element: <EditCast />,
    },
    {
        path: `${RouteStrings.CastAndCrew.Children.CreateCastAndCrew.Link}`,
        element: <AddNewCast />,
    },
    {
        path: `${RouteStrings.Subscriptions.Link}`,
        element: <Subscriptions />,
    },
    {
        path: `${RouteStrings.Subscriptions.Children.EditSubscription.Link}`,
        element: <EditSubscriptions />,
    },
    {
        path: `${RouteStrings.Sliders.Link}`,
        element: <Slider />,
    },
    {
        path: `${RouteStrings.Sliders.Children.EditSlider.Link}`,
        element: <EditSlider />,
    },
    {
        path: `${RouteStrings.Sliders.Children.CreateSlider.Link}`,
        element: <AddNewSlider />,
    },
    {
        path: `${RouteStrings.Sections.Link}`,
        element: <Section />,
    },
    {
        path: `${RouteStrings.Sections.Children.EditSection.Link}`,
        element: <EditSection />,
    },
    {
        path: `${RouteStrings.Sections.Children.CreateSection.Link}`,
        element: <AddNewSection />,
    },
    {
        path: `${RouteStrings.Series.Link}`,
        element: <Series />,
    },
    {
        path: `${RouteStrings.Series.Children.CreateSeries.Link}`,
        element: <AddNewSeries />,
    },
    {
        path: `${RouteStrings.Series.Children.EditSeries.Link}`,
        element: <EditSeries />,
    },
    {
        path: `${RouteStrings.FileManager.Link}`,
        element: <FileManager />,
    },
    {
        path: `${RouteStrings.Settings.Children.GeneralSettings.Link}`,
        element: <Settings />,
    },
    {
        path: `${RouteStrings.Settings.Children.SeoSettings.Link}`,
        element: <SeoSetting />,
    },
    {
        path: `${RouteStrings.Marketing.Children.PushNotifications.Link}`,
        element: <PushNotification />,
    },
    {
        path: `${RouteStrings.RTMP.Children.Dashboard.Link}`,
        element: <RtmpDashboard />,
    },
    {
        path: `${RouteStrings.RTMP.Children.Streams.Link}`,
        element: <RtmpStreams />,
    },
    {
        path: `${RouteStrings.RTMP.Children.ServerProfiles.Link}`,
        element: <RtmpProfile />,
    },
    {
        path: `${RouteStrings.Transcoding.Children.Dashboard.Link}`,
        element: <TranscodingDashboard />,
    },
    {
        path: `${RouteStrings.Content.Link}`,
        element: <Content />,
    },
    {
        path: `${RouteStrings.Content.Children.CreateContent.Link}`,
        element: <CreateContent />,
    },
    {
        path: `${RouteStrings.Content.Children.EditContent.Link}`,
        element: <EditContent />,
    },
    {
        path: `${RouteStrings.Content.Children.EditContentSeason.Link}`,
        element: <EditContentSeason />,
    },
    {
        path: `${RouteStrings.AppSettings.Link}`,
        element: <AppSettings />,
    }

]